import {SETTINGS_SHIFTS_URL,SHIFT_ASSIGN_ACTION_URL} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
import { getShiftAssignToStaffSlice, getShiftDetailsSlice, getShiftSlice } from "../Slice/ShiftsSlice";
const api = new AxiosApi();



export const getShiftsAction = () => {
    return (dispatch) => {
      api.invoke(SETTINGS_SHIFTS_URL, "get", (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getShiftSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      });
    };
  };


  export const postShiftsAction = (val,callback) => {
    return (dispatch) => {
      api.invoke(
        SETTINGS_SHIFTS_URL,
        "post",
        (data, success, statusCode) => {
          callback(false)
          if (success) {
            if (statusCode === 200) {
              callback(true)
              message.destroy();
              message.success(data.message, 3);
              dispatch(getShiftsAction());
            } else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        },
        val
      );
    };
  };


  export const getShiftsDetailsAction = (id) => {
    return (dispatch) => {
      api.invoke(SETTINGS_SHIFTS_URL+"/"+id, "get", (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getShiftDetailsSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      });
    };
  };



  export const deleteShiftsAction = (id) => {
    return (dispatch) => {
      api.invoke(
        SETTINGS_SHIFTS_URL + "/" + id,
        "delete",
        (data, success, statusCode) => {
          // callback(false);
          if (success) {
            if (statusCode === 200) {
              // callback(true);
              message.destroy();
              message.success(data.message, 3);
              dispatch(getShiftsAction());
            } else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        }
      );
    };
  };


  export const putDepartmentAction = (val, callback) => {
    console.log(val,"Action Values");
    return (dispatch) => {
      api.invoke(
        SETTINGS_SHIFTS_URL + "/" + val.id,
        "put",
        (data, success, statusCode) => {
          callback(false);
          if (success) {
            if (statusCode === 200) {
              callback(true);
              message.destroy();
              message.success(data.message, 3);
              dispatch(getShiftsAction());
            } else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        },
        val
      );
    };
  };
  

  //Assign Staff to shift 

  export const getShiftsAssignAction = (val) => {
    return (dispatch) => {
      api.invoke(SHIFT_ASSIGN_ACTION_URL+"/"+val, "get", (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getShiftAssignToStaffSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      });
    };
  };


  export const postShiftsAssignAction = (val,callback) => {
    return (dispatch) => {
      api.invoke(
        SHIFT_ASSIGN_ACTION_URL,
        "post",
        (data, success, statusCode) => {
          callback(false)
          if (success) {
            if (statusCode === 200) {
              callback(true)
              message.destroy();
              message.success(data.message, 3);
              
            } else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        },
        val
      );
    };
  };

  //Assign Staff to shift end