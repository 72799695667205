import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  attendance: null,
};

export const AttendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    getAttendanceFilterSlice(state, action) {
      state.attendance = action.payload;
    },
  },
});

export const { getAttendanceFilterSlice } = AttendanceSlice.actions;

export default AttendanceSlice.reducer;
