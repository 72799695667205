import React, { useEffect, useState } from "react";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  RightOutlined,
  UserOutlined,
  BankOutlined,
  SettingOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { FilterOutlinedIcon } from "../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useLocation, useNavigate } from "react-router";
import { connect } from "react-redux";
import {
  deleteStaffAction,
  getStaffDetailsAction,
  getStaffDocumentsDetailsAction,
  postStaffDocumentsAction,
  putStaffProfileDetailsAction,
} from "../../../Reusable/Store/Action/StaffAction";
import { getHolidayPolicyAction } from "../../../Reusable/Store/Action/HolidayPolicyAction";
import { getLeavePolicyAction } from "../../../Reusable/Store/Action/LeavePolicyAction";
import UploadDocumentModal from "./UploadDocumentModal";

function SraffProfile({
  getStaffDetailsAction,
  staffDetails,
  holidayPolicy,
  getHolidayPolicyAction,
  leavePolicy,
  getLeavePolicyAction,
  putStaffProfileDetailsAction,
  deleteStaffAction,
  postStaffDocumentsAction,
  staffDocumentsDetails,
}) {
  useEffect(() => {
    getHolidayPolicyAction();
    getLeavePolicyAction();
  }, []);

  const handleBackClick = () => {
    Navigate(-1);
  };
  const navigate = useNavigate();

  //delete staff modal
  const [isDeleteStaffModalOpen, setDeleteStaffModalOpen] = useState(false);
  const showDeleteStaffeModal = () => {
    setDeleteStaffModalOpen(true);
  };
  const handleAllowanceDeleteModalOk = () => {
    deleteStaffAction(location.state.id, (success) => {
      if (success) {
        console.log("Staff Deleted Successfully");
        navigate("/staff");
      }
    });
    setDeleteStaffModalOpen(false);
  };
  const handleAllowanceDeleteModalCancel = () => {
    setDeleteStaffModalOpen(false);
  };
  //delete staff modal end
  const location = useLocation();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [weeklyHolidaysForm] = Form.useForm();

  useEffect(() => {
    if (location && location.state && location.state.id) {
      getStaffDetailsAction(location.state.id);
      getStaffDocumentsDetailsAction(location.state.id);
    }
  }, [location?.state?.id]);

  console.log(staffDetails && staffDetails);

  //weekly holiday modal
  const [weekHolidaysModal, setWeekHolidaysModal] = useState(false);

  const showWeeklyHolidaysModal = () => {
    setWeekHolidaysModal(true);
  };
  const handleWeeklyHolidaysOk = () => {
    setWeekHolidaysModal(false);
  };
  const handleWeeklyHolidaysCancel = () => {
    setWeekHolidaysModal(false);
  };

  const onFinishWeeklyHolidays = (values) => {
    const selectedDays = Object.keys(values).filter((day) => values[day]);
    const selectedDaysString = selectedDays.join(",");
    setWeekHolidaysModal(false);
    const WeeklyHolidaysData = {
      updateType: "payroll",
      _method: "PUT",
      id: location.state.id,
      weeklyHolidays: selectedDaysString,
    };

    putStaffProfileDetailsAction(WeeklyHolidaysData, (success) => {
      if (success) {
        getStaffDetailsAction(location.state.id);
        handleWeeklyHolidaysOk();
      }
    });
  };

  //weekly holiday modal end

  //Holiday Policy
  const [holidayPolicyModal, setHolidayPolicyModal] = useState(false);

  const showHolidayPolicyModal = () => {
    setHolidayPolicyModal(true);
  };
  const handleHolidayPolicyOk = () => {
    setHolidayPolicyModal(false);
  };
  const handleHolidayPolicyCancel = () => {
    setHolidayPolicyModal(false);
  };
  //Holiday Policy end

  // leave Policy
  const [leavePolicyModal, setLeavePolicyModal] = useState(false);

  const showLeavePolicyModal = () => {
    setLeavePolicyModal(true);
  };
  const handleLeavePolicyOk = () => {
    setLeavePolicyModal(false);
  };
  const handleLeavePolicyCancel = () => {
    setLeavePolicyModal(false);
  };

  // leave Policy end
  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        handleWeeklyHolidaysOk();
        handleHolidayPolicyCancel();
        handleLeavePolicyCancel();
      }}
    />
  );

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link to="/staff/staff-detail-page">
            <h1 className={commonCssStyles.BackButtonTxt}>
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>

      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Staff Profile</h1>
        </Col>
        <Col>
          <p> </p>
        </Col>
      </Row>

      <Row justify={"end"}>
        <Col>
          {" "}
          <Link
            to={"/staff/staff-detail-profile/edit"}
            state={{ id: location.state.id }}
          >
            {" "}
            <span
              className={commonCssStyles.staffProfileDetailsHeadingBluClr}
              style={{ cursor: "pointer" }}
            >
              <EditOutlined />
              {""}&nbsp;Edit
            </span>
          </Link>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                <UserOutlined
                  style={{ fontSize: "16px", marginRight: "20px" }}
                />
                Personal Info
              </p>
            }
            className="custom-card1"
          >
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Staff Name</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.firstName
                  ? staffDetails.firstName
                  : "NA"}{" "}
                {staffDetails && staffDetails.lastName
                  ? staffDetails.lastName
                  : ""}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b> Email</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.email ? staffDetails.email : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Phone Number</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.phone ? staffDetails.phone : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>

            <Link
              to={"/staff/staff-other-details-profile"}
              state={{ id: location.state.id }}
            >
              <div className={commonCssStyles.commonTableStyles}>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  <b>Other Details</b>
                </p>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  Staff's Details
                </p>
                <p className={commonCssStyles.staffProfileDetailsBody}>
                  <RightOutlined />
                </p>
              </div>
            </Link>

            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>UPI ID</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.upi
                  ? staffDetails.upi
                  : "Yet to be added"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Add Account Details</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.AccountNumber
                  ? staffDetails.AccountNumber
                  : "Yet to be added"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                <SettingOutlined
                  style={{ fontSize: "16px", marginRight: "20px" }}
                />
                Staff Settings
              </p>
            }
            className="custom-card1"
          >
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Shift hours</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.shiftHours
                  ? staffDetails.shiftHours
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                showWeeklyHolidaysModal();
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Staff Weekly Holidays</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.weeklyHolidays
                  ? staffDetails.weeklyHolidays
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>

            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                showHolidayPolicyModal();
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b> Holiday Policy</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.holidayolicyName
                  ? staffDetails.holidayolicyName
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>

            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                if (staffDetails && staffDetails.leavePolicyName) {
                  // Do something specific when leavePolicyName is present
                } else {
                  showLeavePolicyModal();
                }
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Leave Policy</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.leavePolicyName
                  ? staffDetails.leavePolicyName
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {staffDetails && staffDetails.leavePolicyName ? (
                  // Do something when leavePolicyName is present
                  <React.Fragment>
                    {/* Your code when leavePolicyName is present */}
                  </React.Fragment>
                ) : (
                  <RightOutlined />
                )}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Salary Cycle</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.salaryCycle
                  ? staffDetails.salaryCycle
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                Documents
              </p>
            }
            className="custom-card1"
          >
            <Link to={"/staff-documents"} state={{ id: location.state.id }}>
              <div
                className={commonCssStyles.commonTableStyles}
                // onClick={()=>{
                //   showUploadDocumentsModal();
                // }}
              >
                <p className={commonCssStyles.staffProfileDetailsBodyFixed1}>
                  <b>Documents</b>
                </p>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed1}>
                  {staffDocumentsDetails && staffDocumentsDetails.length > 0 ? (
                    <>{staffDocumentsDetails.length} Documnets</>
                  ) : (
                    "Yet to be added"
                  )}
                </p>

                <p className={commonCssStyles.staffProfileDetailsBody}>
                  <RightOutlined />
                </p>
              </div>
            </Link>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                Others
              </p>
            }
            className="custom-card1"
          >
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed1}>
                <b> Staff Status</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed1}>
                {staffDetails && staffDetails.status == "active"
                  ? "Active"
                  : "Inactive"}
              </p>

              {staffDetails &&
              staffDetails.status &&
              staffDetails.status === "active" ? (
                <div className={commonCssStyles.staffProfileDetailsBody}>
                  <Switch
                    checked={true}
                    onChange={(val) => {
                      const data = {
                        _method: "PUT",
                        updateType: "personal",
                        id: location.state.id,
                        status: "inactive",
                      };
                      console.log(data, "Status Active ");
                      putStaffProfileDetailsAction(data, (success) => {
                        if (success) {
                          console.log("Success Data");
                          getStaffDetailsAction(location.state.id);
                        }
                      });
                    }}
                  />
                </div>
              ) : (
                <div className={commonCssStyles.staffProfileDetailsBody}>
                  <Switch
                    onChange={(val) => {
                      const data = {
                        _method: "PUT",
                        updateType: "personal",
                        id: location.state.id,
                        status: "active",
                      };
                      console.log(data, "Status Inactive ");
                      putStaffProfileDetailsAction(data, (success) => {
                        if (success) {
                          console.log("Inactive Success Data");
                          getStaffDetailsAction(location.state.id);
                        }
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed1}>
                <b>Self Attendance</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed1}>
                {staffDetails && staffDetails.attendanceAccess == 1
                  ? "Enable"
                  : "Disable"}
              </p>

              {staffDetails &&
              staffDetails.attendanceAccess &&
              staffDetails.attendanceAccess === 1 ? (
                <div className={commonCssStyles.staffProfileDetailsBody}>
                  <Switch
                    checked={true}
                    onChange={(val) => {
                      const data = {
                        _method: "PUT",
                        updateType: "payroll",
                        id: location.state.id,
                        attendanceAccess: 0,
                      };
                      console.log(data, "Status Active ");
                      putStaffProfileDetailsAction(data, (success) => {
                        if (success) {
                          console.log("Success Data");
                          getStaffDetailsAction(location.state.id);
                        }
                      });
                    }}
                  />
                </div>
              ) : (
                <div className={commonCssStyles.staffProfileDetailsBody}>
                  <Switch
                    onChange={(val) => {
                      const data = {
                        _method: "PUT",
                        updateType: "payroll",
                        id: location.state.id,
                        attendanceAccess: 1,
                      };
                      console.log(data, "Status Inactive ");
                      putStaffProfileDetailsAction(data, (success) => {
                        if (success) {
                          console.log("Inactive Success Data");
                          getStaffDetailsAction(location.state.id);
                        }
                      });
                    }}
                  />
                </div>
              )}
              {/* {staffDetails &&
              staffDetails.status &&
              staffDetails.status === "active" ? (
                <div className={commonCssStyles.staffProfileDetailsBody}>
                  <Switch defaultChecked onChange={onChange} />
                </div>
              ) : (
                <div className={commonCssStyles.staffProfileDetailsBody}>
                  <Switch onChange={onChange} />
                </div>
              )} */}
            </div>

            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Delete Staff</b>
              </p>

              <Button
                type="primary"
                className={commonCssStyles.logoutBtnStyles}
                onClick={() => {
                  showDeleteStaffeModal();
                }}
              >
                <Row
                  style={{ gap: "10px", marginTop: "1px" }}
                  justify={"center"}
                >
                  <Col>
                    <p className={`${commonCssStyles.logoutTextStyle} `}>
                      Delete Staff
                    </p>
                  </Col>
                </Row>
              </Button>
            </div>
          </Card>
        </Col>
      </Row>

      {/* weekly holiday modal */}
      <Modal
        title="Staff Weekly Holidays"
        open={weekHolidaysModal}
        onOk={handleWeeklyHolidaysOk}
        onCancel={handleWeeklyHolidaysCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="WeeklyHolidays"
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24}>
            <Form
              id="WeeklyHolidays"
              form={weeklyHolidaysForm}
              initialValues={{
                sunday:
                  staffDetails &&
                  staffDetails.weeklyHolidays &&
                  staffDetails.weeklyHolidays.includes("sunday"),
                monday:
                  staffDetails &&
                  staffDetails.weeklyHolidays &&
                  staffDetails.weeklyHolidays.includes("monday"),
                tuesday:
                  staffDetails &&
                  staffDetails.weeklyHolidays &&
                  staffDetails.weeklyHolidays.includes("tuesday"),
                wednesday:
                  staffDetails &&
                  staffDetails.weeklyHolidays &&
                  staffDetails.weeklyHolidays.includes("wednesday"),
                thursday:
                  staffDetails &&
                  staffDetails.weeklyHolidays &&
                  staffDetails.weeklyHolidays.includes("thursday"),
                friday:
                  staffDetails &&
                  staffDetails.weeklyHolidays &&
                  staffDetails.weeklyHolidays.includes("friday"),
                saturday:
                  staffDetails &&
                  staffDetails.weeklyHolidays &&
                  staffDetails.weeklyHolidays.includes("saturday"),
              }}
              onFinish={onFinishWeeklyHolidays}
            >
              <Form.Item name="sunday" valuePropName="checked">
                <Checkbox>
                  {" "}
                  <span className={commonCssStyles.formLabelTxt}>Sunday</span>
                </Checkbox>
              </Form.Item>
              <Form.Item name="monday" valuePropName="checked">
                <Checkbox>
                  <span className={commonCssStyles.formLabelTxt}>Monday</span>
                </Checkbox>
              </Form.Item>
              <Form.Item name="tuesday" valuePropName="checked">
                <Checkbox>
                  <span className={commonCssStyles.formLabelTxt}>Tuesday</span>
                </Checkbox>
              </Form.Item>
              <Form.Item name="wednesday" valuePropName="checked">
                <Checkbox>
                  <span className={commonCssStyles.formLabelTxt}>
                    Wednesday
                  </span>
                </Checkbox>
              </Form.Item>
              <Form.Item name="thursday" valuePropName="checked">
                <Checkbox>
                  <span className={commonCssStyles.formLabelTxt}>Thursday</span>
                </Checkbox>
              </Form.Item>
              <Form.Item name="friday" valuePropName="checked">
                <Checkbox>
                  <span className={commonCssStyles.formLabelTxt}>Friday</span>
                </Checkbox>
              </Form.Item>
              <Form.Item name="saturday" valuePropName="checked">
                <Checkbox>
                  <span className={commonCssStyles.formLabelTxt}>Saturday</span>
                </Checkbox>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
      {/* weekly holiday modal end */}

      {/* Holiday Policy   */}
      <Modal
        title="Holiday Policy"
        open={holidayPolicyModal}
        onOk={handleHolidayPolicyOk}
        onCancel={handleHolidayPolicyCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              className={commonCssStyles.applyFilterStylesFull}
              form="HolidayPolicy"
            >
              Apply
            </Button>
          </div>,
        ]}
      >
        <Form
          hideRequiredMark
          layout="vertical"
          id="HolidayPolicy"
          form={form1}
          onFinish={(val) => {
            const hpVal = {
              updateType: "payroll",
              _method: "PUT",
              id: location.state.id,
              holidayPolicyId: val.holidayPolicyId,
            };
            putStaffProfileDetailsAction(hpVal, (success) => {
              if (success) {
                getStaffDetailsAction(location.state.id);
                handleHolidayPolicyCancel();
              }
            });
          }}
          style={{ marginTop: "24px" }}
        >
          <Form.Item
            name="holidayPolicyId"
            // label={
            //   <p className={commonCssStyles.formLabelTxt}>
            //     Holiday Policy
            //   </p>
            // }
            rules={[
              { required: true, message: "please select holiday policy" },
            ]}
          >
            <Select
              className="addStaffForm"
              style={{ width: "100%" }}
              allowClear
            >
              {holidayPolicy && holidayPolicy ? (
                holidayPolicy.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.id}>
                      {data.name}
                    </Select.Option>
                  );
                })
              ) : (
                <p>No Data</p>
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* Holiday Policy end */}

      {/* leave Policy  */}
      <Modal
        title="Leave Policy"
        open={leavePolicyModal}
        onOk={handleLeavePolicyOk}
        onCancel={handleLeavePolicyCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="LeavePolicy"
            >
              Apply
            </Button>
          </div>,
        ]}
      >
        <Form
          hideRequiredMark
          style={{ marginTop: "24px" }}
          layout="vertical"
          id="LeavePolicy"
          form={form2}
          onFinish={(val) => {
            const lpVal = {
              updateType: "payroll",
              _method: "PUT",
              id: location.state.id,
              leavePolicyId: val.leavePolicyId,
            };

            putStaffProfileDetailsAction(lpVal, (success) => {
              if (success) {
                getStaffDetailsAction(location.state.id);
                handleLeavePolicyCancel();
              }
            });
          }}
        >
          <Form.Item
            name="leavePolicyId"
            rules={[{ required: true, message: "please select leave policy" }]}
            // label={   <p className={commonCssStyles.formLabelTxt}>
            //   Leave Policy
            // </p>}
          >
            <Select
              className="addStaffForm"
              style={{ width: "100%" }}
              allowClear
            >
              {leavePolicy && leavePolicy ? (
                leavePolicy.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.id}>
                      {data.name}
                    </Select.Option>
                  );
                })
              ) : (
                <p>No Data</p>
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      {/* leave Policy end  */}

      {/* delete Staff  */}
      <Modal
        title="Delete Staff"
        open={isDeleteStaffModalOpen}
        onOk={handleAllowanceDeleteModalOk}
        onCancel={handleAllowanceDeleteModalCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleAllowanceDeleteModalCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleAllowanceDeleteModalOk();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <p className={commonCssStyles.modalSubHeadingStyles}>
          Are you sure you want to delete
        </p>
      </Modal>
      {/* delete Staff end  */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    staffDetails: state.staff.staffDetails,
    holidayPolicy: state.holidayPolicy.holidayPolicy,
    leavePolicy: state.leavePolicy.leavePolicy,

    staffDocumentsDetails: state.staff.staffDocumentsDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaffDetailsAction: (id) => {
      dispatch(getStaffDetailsAction(id));
    },
    getHolidayPolicyAction: () => {
      dispatch(getHolidayPolicyAction());
    },

    getLeavePolicyAction: () => {
      dispatch(getLeavePolicyAction());
    },

    putStaffProfileDetailsAction: (val, callback) => {
      dispatch(putStaffProfileDetailsAction(val, callback));
    },

    deleteStaffAction: (val, callback) => {
      dispatch(deleteStaffAction(val, callback));
    },

    getStaffDocumentsDetailsAction: (val) => {
      dispatch(getStaffDocumentsDetailsAction(val));
    },
    // putLeavePolicyAction: (val,callback) => {
    //   dispatch(putLeavePolicyAction(val,callback));
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SraffProfile);
