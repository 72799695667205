import React, { useEffect, useState } from "react";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  RightOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { FilterOutlinedIcon } from "../../IconsComp/Icon";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";
import { Navigate, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  getWeeklyHolidayAction,
  postWeeklyHolidayAction,
} from "../../../Reusable/Store/Action/SettingsAction";
import { connect } from "react-redux";
import { getStaffFilterAction } from "../../../Reusable/Store/Action/StaffAction";
const { Option } = Select;
const { Panel } = Collapse;

function StaffWeeklyHolidays({
  getWeeklyHolidayAction,
  weeklyHolidays,
  postWeeklyHolidayAction,
  staffs,
  getStaffFilterAction,
}) {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [value4, setValue4] = useState("business");

  const [weekHoliday, setWeekHoliday] = useState("");

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    getWeeklyHolidayAction();
    getStaffFilterAction({});
  }, []);

  useEffect(() => {
    if (weeklyHolidays) {
      setValue4(weeklyHolidays.type);
    }
    if (weeklyHolidays && weeklyHolidays.type == "business") {
      form.setFieldsValue({
        sunday:
          weeklyHolidays &&
          weeklyHolidays.result &&
          weeklyHolidays.result &&
          weeklyHolidays.result.includes("sunday"),
        monday:
          weeklyHolidays &&
          weeklyHolidays.result &&
          weeklyHolidays.result &&
          weeklyHolidays.result.includes("monday"),
        tuesday:
          weeklyHolidays &&
          weeklyHolidays.result &&
          weeklyHolidays.result &&
          weeklyHolidays.result.includes("tuesday"),
        wednesday:
          weeklyHolidays &&
          weeklyHolidays.result &&
          weeklyHolidays.result &&
          weeklyHolidays.result.includes("wednesday"),
        thursday:
          weeklyHolidays &&
          weeklyHolidays.result &&
          weeklyHolidays.result &&
          weeklyHolidays.result.includes("thursday"),
        friday:
          weeklyHolidays &&
          weeklyHolidays.result &&
          weeklyHolidays.result &&
          weeklyHolidays.result.includes("friday"),
        saturday:
          weeklyHolidays &&
          weeklyHolidays.result &&
          weeklyHolidays.result &&
          weeklyHolidays.result.includes("saturday"),
      });
    }
  }, [weeklyHolidays]);



  useEffect(() => {
    if (staffs && staffs.data) {
      form1.resetFields(); 
      const updatedInitialValues = staffs.data.reduce((acc, item) => {
        if (Array.isArray(staffList) && staffList.some((staff) => staff.id === item.id)) {
          return { ...acc, [item.id]: true };
        }
        return acc;
      }, {});
      form1.setFieldsValue(updatedInitialValues);
    }
  }, [staffList, staffs]);
  
  


  const handleFormSubmit = (values) => {
    const checkedIds = Object.entries(values)
      .filter(([id, checked]) => checked)
      .map(([id]) => id);
    const data = {
      type: "staff",
      userId: checkedIds,
      weeklyHolidays: weekHoliday,
    };
    postWeeklyHolidayAction(data, (success) => {
      if (success) {
        setStaffList([]);
        getWeeklyHolidayAction();
      }
    });
  };

  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (value4 == "business") {
      postWeeklyHolidayAction({ type: "staff" }, (success) => {
        if (success) {
          getWeeklyHolidayAction();
        }
      });
    } else {
      postWeeklyHolidayAction({ type: "business" }, (success) => {
        if (success) {
          getWeeklyHolidayAction();
        }
      });
    }

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  //Modal end

  //Assign Staff Modal
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  //Assign Staff Modal end

  
  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        setStaffList([]);
        handleCancel();
       
      }}
    />
  );

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  const optionsWithDisabled = [
    {
      label: "Business",
      value: "business",
    },
    {
      label: "Staff",
      value: "staff",
    },
  ];

  const onChange4 = ({ target: { value } }) => {
    showModal();
    console.log("radio4 checked", value);
    // setValue4(value);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {" "}
      <Row justify={"space-between"}>
        <Col>
          <Link to="/settings">
            <h1 className={commonCssStyles.BackButtonTxt}>
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>
      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>
            Staff Weekly Holidays
          </h1>
          <h1 className={commonCssStyles.SubPageHeadingTxt}>
            Assign weekly off days of your business to automatically mark
            attendance for those days
          </h1>
        </Col>
      </Row>
      <Row style={{ marginTop: "40px" }}>
        <Col md={24}>
          <div
            className={commonCssStyles.commonTableStyles}
            style={{ backgroundColor: "#ffffff" }}
            onClick={() => {
              // showAttendancModal();
            }}
          >
            <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
              <b> Weekly Off Preference</b>
            </p>
            <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
              Choose if you wish to keep same holidays for all your staff or
              different
            </p>
            <p className={commonCssStyles.staffProfileDetailsBody}>
              <Radio.Group
                options={optionsWithDisabled}
                onChange={onChange4}
                value={value4}
                optionType="button"
                buttonStyle="solid"
              />
            </p>
          </div>
        </Col>
      </Row>
      {/* business Holidays  */}
      {value4 === "business" ? (
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card
              title={
                <p className={commonCssStyles.staffProfileDetailsHeading}>
                  Holiday Days
                </p>
              }
              className="custom-card1"
            >
              <Form
                form={form}
                onFinish={(val) => {
                  const selectedDays = Object.keys(val).filter(
                    (day) => val[day]
                  );
                  const selectedDaysString = selectedDays.join(",");
                  const BusinessHolidaysData = {
                    type: "business",
                    weeklyHolidays: selectedDaysString,
                  };
                  console.log(
                    BusinessHolidaysData,
                    "BusinessHolidaysData Final Value"
                  );

                  postWeeklyHolidayAction(BusinessHolidaysData, (success) => {
                    if (success) {
                      getWeeklyHolidayAction();
                    }
                  });
                }}
                style={{ marginLeft: "40px", marginTop: "20px" }}
              >
                <Form.Item name="sunday" valuePropName="checked">
                  <Checkbox>
                    {" "}
                    <span className={commonCssStyles.formLabelTxt}>Sunday</span>
                  </Checkbox>
                </Form.Item>
                <Form.Item name="monday" valuePropName="checked">
                  <Checkbox>
                    <span className={commonCssStyles.formLabelTxt}>Monday</span>
                  </Checkbox>
                </Form.Item>
                <Form.Item name="tuesday" valuePropName="checked">
                  <Checkbox>
                    <span className={commonCssStyles.formLabelTxt}>
                      Tuesday
                    </span>
                  </Checkbox>
                </Form.Item>
                <Form.Item name="wednesday" valuePropName="checked">
                  <Checkbox>
                    <span className={commonCssStyles.formLabelTxt}>
                      Wednesday
                    </span>
                  </Checkbox>
                </Form.Item>
                <Form.Item name="thursday" valuePropName="checked">
                  <Checkbox>
                    <span className={commonCssStyles.formLabelTxt}>
                      Thursday
                    </span>
                  </Checkbox>
                </Form.Item>
                <Form.Item name="friday" valuePropName="checked">
                  <Checkbox>
                    <span className={commonCssStyles.formLabelTxt}>Friday</span>
                  </Checkbox>
                </Form.Item>
                <Form.Item name="saturday" valuePropName="checked">
                  <Checkbox>
                    <span className={commonCssStyles.formLabelTxt}>
                      Saturday
                    </span>
                  </Checkbox>
                </Form.Item>

                <Row>
                  <Col xs={24}>
                    <Form.Item>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                          marginRight: "40px",
                        }}
                      >
                        <Button
                          type="primary"
                          className={commonCssStyles.addBtnStyles}
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              <Collapse
                style={{ marginBottom: "20px" }}
                className="custom-collapse"
              >
                <Panel
                  // header="Staff Holidays"
                  header={
                    <Row justify={"space-between"}>
                      <Col>
                        <p
                          className={commonCssStyles.staffProfileDetailsHeading}
                          style={{ marginTop: "5px" }}
                        >
                          Sunday
                        </p>
                      </Col>
                      <Col>
                        <Button
                          className={commonCssStyles.resetFilterStyles}
                          onClick={() => {
                            setStaffList([]);
                            setStaffList(
                              weeklyHolidays &&
                                weeklyHolidays.result &&
                                weeklyHolidays.result.staffDetails &&
                                weeklyHolidays.result.staffDetails[0].staffs
                            );
                            setWeekHoliday("sunday");
                            showModal1();
                          }}
                        >
                          Manage Staff
                        </Button>
                      </Col>
                    </Row>
                  }
                  key="1"
                >
                  <Card
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        {weeklyHolidays &&
                        weeklyHolidays.result &&
                        weeklyHolidays.result.staffDetails &&
                        weeklyHolidays.result.staffDetails[0].staffs &&
                        weeklyHolidays.result.staffDetails[0].staffs.length >
                          0 ? (
                          weeklyHolidays.result.staffDetails[0].staffs.map(
                            (data, index) => (
                              <p
                                key={index}
                                className={commonCssStyles.tableDataTxt}
                              >
                                {data.name} &#40; {data.employmentType} &#41;
                              </p>
                            )
                          )
                        ) : (
                          <p className={commonCssStyles.tableDataTxt}>
                            No data
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Card>

                  {/* Add more cards as needed */}
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              <Collapse
                style={{ marginBottom: "20px" }}
                className="custom-collapse"
              >
                <Panel
                  // header="Staff Holidays"
                  header={
                    <Row justify={"space-between"}>
                      <Col>
                        <p
                          className={commonCssStyles.staffProfileDetailsHeading}
                          style={{ marginTop: "5px" }}
                        >
                          Monday
                        </p>
                      </Col>
                      <Col>
                        <Button
                          className={commonCssStyles.resetFilterStyles}
                          onClick={() => {
                            setStaffList([]);
                            setStaffList(
                              weeklyHolidays &&
                                weeklyHolidays.result &&
                                weeklyHolidays.result.staffDetails &&
                                weeklyHolidays.result.staffDetails[1].staffs
                            );

                            setWeekHoliday("monday");
                            showModal1();
                          }}
                        >
                          Manage Staff
                        </Button>
                      </Col>
                    </Row>
                  }
                  key="1"
                >
                  <Card
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        {weeklyHolidays &&
                        weeklyHolidays.result &&
                        weeklyHolidays.result.staffDetails &&
                        weeklyHolidays.result.staffDetails[1].staffs &&
                        weeklyHolidays.result.staffDetails[1].staffs.length >
                          0 ? (
                          weeklyHolidays.result.staffDetails[1].staffs.map(
                            (data, index) => (
                              <p
                                key={index}
                                className={commonCssStyles.tableDataTxt}
                              >
                                {data.name} &#40; {data.employmentType} &#41;
                              </p>
                            )
                          )
                        ) : (
                          <p className={commonCssStyles.tableDataTxt}>
                            No data
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Card>

                  {/* Add more cards as needed */}
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              <Collapse
                style={{ marginBottom: "20px" }}
                className="custom-collapse"
              >
                <Panel
                  // header="Staff Holidays"
                  header={
                    <Row justify={"space-between"}>
                      <Col>
                        <p
                          className={commonCssStyles.staffProfileDetailsHeading}
                          style={{ marginTop: "5px" }}
                        >
                          Tuesday
                        </p>
                      </Col>
                      <Col
                        onClick={() => {
                          setStaffList(
                            weeklyHolidays &&
                              weeklyHolidays.result &&
                              weeklyHolidays.result.staffDetails &&
                              weeklyHolidays.result.staffDetails[2].staffs &&
                              weeklyHolidays.result.staffDetails[2].staffs
                          );
                          setWeekHoliday("tuesday");
                          showModal1();
                        }}
                      >
                        <Button className={commonCssStyles.resetFilterStyles}>
                          Manage Staff
                        </Button>
                      </Col>
                    </Row>
                  }
                  key="1"
                >
                  <Card
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        {weeklyHolidays &&
                        weeklyHolidays.result &&
                        weeklyHolidays.result.staffDetails &&
                        weeklyHolidays.result.staffDetails[2].staffs &&
                        weeklyHolidays.result.staffDetails[2].staffs.length >
                          0 ? (
                          weeklyHolidays.result.staffDetails[2].staffs.map(
                            (data, index) => (
                              <p
                                key={index}
                                className={commonCssStyles.tableDataTxt}
                              >
                                {data.name} &#40; {data.employmentType} &#41;
                              </p>
                            )
                          )
                        ) : (
                          <p className={commonCssStyles.tableDataTxt}>
                            No data
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Card>

                  {/* Add more cards as needed */}
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              <Collapse
                style={{ marginBottom: "20px" }}
                className="custom-collapse"
              >
                <Panel
                  // header="Staff Holidays"
                  header={
                    <Row justify={"space-between"}>
                      <Col>
                        <p
                          className={commonCssStyles.staffProfileDetailsHeading}
                          style={{ marginTop: "5px" }}
                        >
                          Wednesday
                        </p>
                      </Col>
                      <Col>
                        <Button
                          className={commonCssStyles.resetFilterStyles}
                          onClick={() => {
                            setStaffList(
                              weeklyHolidays &&
                                weeklyHolidays.result &&
                                weeklyHolidays.result.staffDetails &&
                                weeklyHolidays.result.staffDetails[3].staffs &&
                                weeklyHolidays.result.staffDetails[3].staffs
                            );
                            setWeekHoliday("wednesday");
                            showModal1();
                          }}
                        >
                          Manage Staff
                        </Button>
                      </Col>
                    </Row>
                  }
                  key="1"
                >
                  <Card
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        {weeklyHolidays &&
                        weeklyHolidays.result &&
                        weeklyHolidays.result.staffDetails &&
                        weeklyHolidays.result.staffDetails[3].staffs &&
                        weeklyHolidays.result.staffDetails[3].staffs.length >
                          0 ? (
                          weeklyHolidays.result.staffDetails[3].staffs.map(
                            (data, index) => (
                              <p
                                key={index}
                                className={commonCssStyles.tableDataTxt}
                              >
                                {data.name} &#40; {data.employmentType} &#41;
                              </p>
                            )
                          )
                        ) : (
                          <p className={commonCssStyles.tableDataTxt}>
                            No data
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Card>

                  {/* Add more cards as needed */}
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              <Collapse
                style={{ marginBottom: "20px" }}
                className="custom-collapse"
              >
                <Panel
                  // header="Staff Holidays"
                  header={
                    <Row justify={"space-between"}>
                      <Col>
                        <p
                          className={commonCssStyles.staffProfileDetailsHeading}
                          style={{ marginTop: "5px" }}
                        >
                          Thursday
                        </p>
                      </Col>
                      <Col>
                        <Button
                          className={commonCssStyles.resetFilterStyles}
                          onClick={() => {
                            setStaffList(
                              weeklyHolidays &&
                                weeklyHolidays.result &&
                                weeklyHolidays.result.staffDetails &&
                                weeklyHolidays.result.staffDetails[4].staffs &&
                                weeklyHolidays.result.staffDetails[4].staffs
                            );
                            setWeekHoliday("thursday");
                            showModal1();
                          }}
                        >
                          Manage Staff
                        </Button>
                      </Col>
                    </Row>
                  }
                  key="1"
                >
                  <Card
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        {weeklyHolidays &&
                        weeklyHolidays.result &&
                        weeklyHolidays.result.staffDetails &&
                        weeklyHolidays.result.staffDetails[4].staffs &&
                        weeklyHolidays.result.staffDetails[4].staffs.length >
                          0 ? (
                          weeklyHolidays.result.staffDetails[4].staffs.map(
                            (data, index) => (
                              <p
                                key={index}
                                className={commonCssStyles.tableDataTxt}
                              >
                                {data.name} &#40; {data.employmentType} &#41;
                              </p>
                            )
                          )
                        ) : (
                          <p className={commonCssStyles.tableDataTxt}>
                            No data
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Card>

                  {/* Add more cards as needed */}
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              <Collapse
                style={{ marginBottom: "20px" }}
                className="custom-collapse"
              >
                <Panel
                  // header="Staff Holidays"
                  header={
                    <Row justify={"space-between"}>
                      <Col>
                        <p
                          className={commonCssStyles.staffProfileDetailsHeading}
                          style={{ marginTop: "5px" }}
                        >
                          Friday
                        </p>
                      </Col>
                      <Col>
                        <Button
                          className={commonCssStyles.resetFilterStyles}
                          onClick={() => {
                            setStaffList(
                              weeklyHolidays &&
                                weeklyHolidays.result &&
                                weeklyHolidays.result.staffDetails &&
                                weeklyHolidays.result.staffDetails[5].staffs &&
                                weeklyHolidays.result.staffDetails[5].staffs
                            );
                            setWeekHoliday("friday");
                            showModal1();
                          }}
                        >
                          Manage Staff
                        </Button>
                      </Col>
                    </Row>
                  }
                  key="1"
                >
                  <Card
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        {weeklyHolidays &&
                        weeklyHolidays.result &&
                        weeklyHolidays.result.staffDetails &&
                        weeklyHolidays.result.staffDetails[5].staffs &&
                        weeklyHolidays.result.staffDetails[5].staffs.length >
                          0 ? (
                          weeklyHolidays.result.staffDetails[5].staffs.map(
                            (data, index) => (
                              <p
                                key={index}
                                className={commonCssStyles.tableDataTxt}
                              >
                                {data.name} &#40; {data.employmentType} &#41;
                              </p>
                            )
                          )
                        ) : (
                          <p className={commonCssStyles.tableDataTxt}>
                            No data
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Card>

                  {/* Add more cards as needed */}
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              <Collapse
                style={{ marginBottom: "20px" }}
                className="custom-collapse"
              >
                <Panel
                  // header="Staff Holidays"
                  header={
                    <Row justify={"space-between"}>
                      <Col>
                        <p
                          className={commonCssStyles.staffProfileDetailsHeading}
                          style={{ marginTop: "5px" }}
                        >
                          Saturday
                        </p>
                      </Col>
                      <Col>
                        <Button
                          className={commonCssStyles.resetFilterStyles}
                          onClick={() => {
                            setStaffList(
                              weeklyHolidays &&
                                weeklyHolidays.result &&
                                weeklyHolidays.result.staffDetails &&
                                weeklyHolidays.result.staffDetails[6].staffs &&
                                weeklyHolidays.result.staffDetails[6].staffs
                            );
                            setWeekHoliday("saturday");
                            showModal1();
                          }}
                        >
                          Manage Staff
                        </Button>
                      </Col>
                    </Row>
                  }
                  key="1"
                >
                  <Card
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        {weeklyHolidays &&
                        weeklyHolidays.result &&
                        weeklyHolidays.result.staffDetails &&
                        weeklyHolidays.result.staffDetails[6].staffs &&
                        weeklyHolidays.result.staffDetails[6].staffs.length >
                          0 ? (
                          weeklyHolidays.result.staffDetails[6].staffs.map(
                            (data, index) => (
                              <p
                                key={index}
                                className={commonCssStyles.tableDataTxt}
                              >
                                {data.name} &#40; {data.employmentType} &#41;
                              </p>
                            )
                          )
                        ) : (
                          <p className={commonCssStyles.tableDataTxt}>
                            No data
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Card>

                  {/* Add more cards as needed */}
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </>
      )}
      {/* business Holidays End   */}
      {/* Modal  */}
      <Modal
        // title="Switch to Staff Level?"
        title={
          value4 == "business"
            ? "Switch to Staff Level?"
            : "Switch to Business Level?"
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleOk();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Continue
            </Button>
          </div>,
        ]}
      >
        <p className={commonCssStyles.modalSubHeadingStyles}>
          Your current weekly off setting will be removed.
        </p>
      </Modal>
      {/* Modal end  */}
      {/* add staff to holiay Modal  */}
      {/* Modal  */}
      <Modal
        title="Staff List"
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                setStaffList([]);
                handleCancel1();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              form="staffAttendance"
              onClick={() => {
                handleOk1();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Continue
            </Button>
          </div>,
        ]}
      >
        <Form
          id="staffAttendance" 
          onFinish={handleFormSubmit}
          layout="vertical"
          form={form1}
          // initialValues={
          //   staffs &&
          //   staffs.data &&
          //   staffList &&
          //   staffs.data.reduce((acc, item) => {
          //     if (staffList.some((staff) => staff.id === item.id)) {
          //       return { ...acc, [item.id]: true };
          //     }
          //     return acc;
          //   }, {})
          // }
        >
          <Row style={{ marginTop: "10px" }}>
            <Col xs={24}>
              <p className={commonCssStyles.modalSubHeadingStyles}>
                Selfie & Location
              </p>
              {staffs &&
                staffs.data &&
                staffs.data.map((item, index) => (
                  <>
                    <Row
                      key={item.id}
                      justify="space-between"
                      style={{
                        borderBottom:
                          index === staffs.data.length - 1
                            ? "none"
                            : "1px solid #e8e8e8",
                        paddingBottom: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <Col>
                        <Form.Item
                          name={item.id}
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox name={item.id}>
                            <span className={commonCssStyles.formLabelTxt}>
                              {item.fullName}
                            </span>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col>
                        <span className={commonCssStyles.formLabelTxt}>
                          {item.phone}
                        </span>
                      </Col>
                    </Row>
                  </>
                ))}
            </Col>
          </Row>
          {/* Rest of the modal content */}
        </Form>
      </Modal>
      {/* Modal end  */}
      {/* add staff to holiay Modal end  */}
    </div>
  );
}

export const mapStateToProps = (state) => {
  return {
    weeklyHolidays: state.settings.weeklyHolidays,
    staffs: state.staff.staffs,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    getWeeklyHolidayAction: () => {
      dispatch(getWeeklyHolidayAction());
    },
    postWeeklyHolidayAction: (val, callback) => {
      dispatch(postWeeklyHolidayAction(val, callback));
    },

    getStaffFilterAction: (val) => {
      dispatch(getStaffFilterAction(val));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffWeeklyHolidays);
