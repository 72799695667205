import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leavePolicy: null,
  leavePolicyDetails: null,
};

export const LeavePolicySlice = createSlice({
  name: "leavePolicy",
  initialState,
  reducers: {
    getLeavePolicySlice(state, action) {
      state.leavePolicy = action.payload.result;
    },
    getLeavePolicyDetails(state, action) {
      state.leavePolicyDetails = action.payload.result;
    },
  },
});

export const { getLeavePolicySlice, getLeavePolicyDetails } =
  LeavePolicySlice.actions;
export default LeavePolicySlice.reducer;
