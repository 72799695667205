import { SETTINGS_EARLY_EXIT_RULE_URL,USER_PAYROLL_EARLY_EXIT_RULE_URL } from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
import { getEarlyExitDetailsSlice, getEarlyExitRuleAssignSlice, getEarlyExitSlice } from "../Slice/EarlyExitRuleSlice";
const api = new AxiosApi();


export const getEarlyExitAction = () => {
    return (dispatch) => {
      api.invoke(
        SETTINGS_EARLY_EXIT_RULE_URL,
        "get",
        (data, success, statusCode) => {
          if (success) {
            if (statusCode === 200) dispatch(getEarlyExitSlice(data));
            else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        }
      );
    };
  };
  
  
  export const postEarlyExitAction = (val,callback) => {
      return (dispatch) => {
        api.invoke(
            SETTINGS_EARLY_EXIT_RULE_URL,
          "post",
          (data, success, statusCode) => {
              callback(false);
            if (success) {
              if (statusCode === 200) {
                  callback(true);
                message.destroy();
                message.success(data.message, 3);
                dispatch(getEarlyExitAction());
              } else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          },
          val
        );
      };
    };
  


    export const deleteEarlyExitRuleAction = (id) => {
      return (dispatch) => {
        api.invoke(
          SETTINGS_EARLY_EXIT_RULE_URL + "/" + id,
          "delete",
          (data, success, statusCode) => {
            // callback(false);
            if (success) {
              if (statusCode === 200) {
                // callback(true);
                message.destroy();
                message.success(data.message, 3);
                dispatch(getEarlyExitAction());
              } else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          }
        );
      };
    };



    export const getEarlyExitDetailsAction = (id) => {
      return (dispatch) => {
        api.invoke(
          SETTINGS_EARLY_EXIT_RULE_URL + "/" + id,
          "get",
          (data, success, statusCode) => {
            if (success) {
              if (statusCode === 200) dispatch(getEarlyExitDetailsSlice(data));
              else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          }
        );
      };
    };
    
    export const putEarlyExitRuleAction = (val, callback) => {
      return (dispatch) => {
        api.invoke(
          SETTINGS_EARLY_EXIT_RULE_URL + "/" + val.id,
          "put",
          (data, success, statusCode) => {
            callback(false);
            if (success) {
              if (statusCode === 200) {
                callback(true);
                message.destroy();
                message.success(data.message, 3);
                dispatch(getEarlyExitAction());
              } else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          },
          val
        );
      };
    };
    



    //Assign Staff to rules

export const getBEarlyExitRuleAssignAction = (val) => {
  return (dispatch) => {
    api.invoke(
      USER_PAYROLL_EARLY_EXIT_RULE_URL + "/" + val,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getEarlyExitRuleAssignSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postEarlyExitRuleAssignAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      USER_PAYROLL_EARLY_EXIT_RULE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//Assign Staff to rules end