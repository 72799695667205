import React, { useEffect, useState } from "react";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TimePicker,
  message,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { FilterOutlinedIcon } from "../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";
import {
  getAttendanceFilterAction,
  putAttendanceAction,
} from "../../../Reusable/Store/Action/AttendanceAction";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { getStaffLeaveBalanceAction } from "../../../Reusable/Store/Action/StaffAction";

function Attendance({
  getAttendanceFilterAction,
  attendance,
  putAttendanceAction,
  getStaffLeaveBalanceAction,
  staffLeaveBalanceData,
}) {
  const [selectedDate, setSelecteddate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [logsDisp, setLogsDisp] = useState("");

  const [dispSave, setDispSave] = useState(false);

  const [dispOvertimeSave, setDispOvertimeSave] = useState(false);

  const { TextArea } = Input;

  useEffect(() => {
    getAttendanceFilterAction({ attendanceDate: dayjs().format("YYYY-MM-DD") });
  }, []);

  const [form] = Form.useForm();

  console.log(attendance && attendance, "attendance");
  const [selectedButton, setSelectedButton] = useState("present");

  const [overTimeHoursDisp, setOverTimeHoursDisp] = useState("fixed");
  const [earlyOverTimeHoursDisp, setEarlyOverTimeHoursDisp] = useState("fixed");

  const [markPresentId, setMarkPresentId] = useState("");
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleSearch = (value) => {
    console.log("Search query:", value);
  };

  const handleSearch1 = (event) => {
    console.log("Search handleSearch1:", event.target.value);
    const dataSearch = {
      attendanceDate: selectedDate,
      searchText: event.target.value,
    };
    getAttendanceFilterAction(dataSearch);
  };

  const handleClear = () => {
    console.log("Clear search query");
  };




  //Modal For Attendance
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //end

  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        setDispSave(false);
        setDispOvertimeSave(false);
        form.resetFields();
        handleCancel();
      }}
    />
  );
  //Modal For Attendance end
  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Attendance Summary</h1>
        </Col>
        {/* <Col>
          <Row style={{ gap: "20px" }}>
            <Col>
            <Link>
                      <p className={commonCssStyles.addNoteBtnStyles}>
                        Daily Report <DownloadOutlined style={{fontSize:"20px"}} />
                      </p>{" "}
                    </Link>
            </Col>
          </Row>
        </Col> */}
      </Row>

      <Row style={{ marginTop: "40px" }}>
        <Col md={24}>
          <Card className={commonCssStyles.attendaceSmryCardDsgn}>
            <Row justify={"space-between"} style={{ marginLeft: "20px" }}>
              <Col>
                <Form
                  initialValues={{
                    date: dayjs(),
                  }}
                >
                  <Form.Item name="date">
                    <DatePicker
                      locale="en"
                      format="DD-MM-YYYY"
                      disabledDate={(currentDate) =>
                        currentDate && currentDate.isAfter(dayjs(), "day")
                      }
                      onChange={(val) => {
                        setSelecteddate(dayjs(val).format("YYYY-MM-DD"));
                        const attendanceDate = dayjs(val).format("YYYY-MM-DD");
                        getAttendanceFilterAction({
                          attendanceDate: attendanceDate,
                        });
                      }}
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col>
                <h1 className={commonCssStyles.DashboardSubHeadingLabelTxt}>
                  {/* All Approved */}
                </h1>
              </Col>
            </Row>

            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                <Card
                  // className={commonCssStyles.AttendanceOverViewCard}
                  style={{
                    border: "none",
                  }}
                >
                  <h1 className={commonCssStyles.attendanceLableTxt}>
                    Total Staff
                  </h1>
                  <h1 className={commonCssStyles.attendanceNumberTxt}>
                    {attendance &&
                    attendance.summary &&
                    attendance.summary.totalStaffs
                      ? attendance.summary.totalStaffs
                      : "0"}
                  </h1>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                <Card
                  style={{
                    border: "none",
                  }}
                >
                  <h1 className={commonCssStyles.attendanceLableTxt}>
                    Present
                  </h1>
                  <h1 className={commonCssStyles.attendanceNumberTxt}>
                    {attendance &&
                    attendance.summary &&
                    attendance.summary.present
                      ? attendance.summary.present
                      : "0"}
                  </h1>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                <Card
                  style={{
                    border: "none",
                  }}
                >
                  <h1 className={commonCssStyles.attendanceLableTxt}>Absent</h1>
                  <h1 className={commonCssStyles.attendanceNumberTxt}>
                    {attendance &&
                    attendance.summary &&
                    attendance.summary.absent
                      ? attendance.summary.absent
                      : "0"}
                  </h1>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                <Card
                  style={{
                    border: "none",
                  }}
                >
                  <h1 className={commonCssStyles.attendanceLableTxt}>
                    Half Day
                  </h1>
                  <h1 className={commonCssStyles.attendanceNumberTxt}>
                    {attendance &&
                    attendance.summary &&
                    attendance.summary.halfDay
                      ? attendance.summary.halfDay
                      : "0"}
                  </h1>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                <Card
                  style={{
                    border: "none",
                  }}
                >
                  <h1 className={commonCssStyles.attendanceLableTxt}>
                    Overtime hours
                  </h1>
                  <h1 className={commonCssStyles.attendanceNumberTxt}>
                    {attendance &&
                    attendance.summary &&
                    attendance.summary.otHours
                      ? attendance.summary.otHours
                      : "0"}
                  </h1>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                <Card
                  style={{
                    border: "none",
                  }}
                >
                  <h1 className={commonCssStyles.attendanceLableTxt}>
                    Fine hours
                  </h1>
                  <h1 className={commonCssStyles.attendanceNumberTxt}>
                    {attendance &&
                    attendance.summary &&
                    attendance.summary.fineHours
                      ? attendance.summary.fineHours
                      : "0"}
                  </h1>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                <Card
                  style={{
                    border: "none",
                  }}
                >
                  <h1 className={commonCssStyles.attendanceLableTxt}>
                    Paid Leave
                  </h1>
                  <h1 className={commonCssStyles.attendanceNumberTxt}>
                    {attendance &&
                    attendance.summary &&
                    attendance.summary.paidLeave
                      ? attendance.summary.paidLeave
                      : "0"}
                  </h1>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col xs={24} sm={24} md={24}>
          {/* <Card> */}
          <Input.Search
            placeholder="Search anything!!!!"
            onSearch={handleSearch}
            allowClear
            enterButton
            onClear={handleClear}
            onChange={handleSearch1}
            className="custom-input"
          />
          {/* </Card> */}
        </Col>
      </Row>

      <Row style={{ marginTop: "40px" }}>
        <h1 className={commonCssStyles.TableSecHeadingTxt}>
          Staff &#40;{" "}
          {attendance && attendance.result.total && attendance.result.total > 0
            ? attendance.result.total
            : 0}{" "}
          &#41;
        </h1>
      </Row>

      <div>
        {attendance &&
          attendance.result &&
          attendance.result.data &&
          attendance.result.data.map((data, index) => {
            return (
              <>
                <Card
                  className={`${commonCssStyles.tableCardsContainer} attendance-custom-card`}
                  style={{ padding: "0px !important" }}
                >
                  <Row justify={"space-between"}>
                    <Col>
                      <Row>
                        <Col>
                          <p className={commonCssStyles.attendancetableDataTxt}>
                            {data.name}
                          </p>
                          <p className={commonCssStyles.attendancetableTimeTxt}>
                            {data.subText}
                          </p>
                          <p
                            className={commonCssStyles.attendancetableTimeTxt}
                            style={{ margin: "0px" }}
                          >
                            {data.note}
                          </p>
                          <div style={{ display: "flex" }}>
                            <p
                              className={commonCssStyles.addNoteBtnStyles}
                              onClick={() => {
                                form.setFieldsValue({
                                  note: data.note,
                                });
                                setMarkPresentId(data.id);
                                handleButtonClick("Add Note");
                                showModal();
                              }}
                            >
                              {data.note ? "Edit Note" : "Add Note"}
                            </p>
                            <p className={commonCssStyles.addNoteBtnStyles}>
                              &nbsp; | &nbsp;
                            </p>
                            <p
                              className={commonCssStyles.addNoteBtnStyles}
                              onClick={() => {
                                setMarkPresentId(data.id);
                                setLogsDisp(data.log);
                                handleButtonClick("View Logs");
                                showModal();
                              }}
                            >
                              View Logs
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                              <Button
                                type={
                                  data.status === "present"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() => {
                                  setMarkPresentId(data.id);
                                  handleButtonClick("present");
                                  // form.setFieldsValue({
                                  //   startTime:
                                  //     data.startTime == "00:00"
                                  //       ? dayjs()
                                  //       : data.startTime,
                                  //   endTime:
                                  //     data.endTime == "00:00"
                                  //       ? dayjs()
                                  //       : data.endTime,
                                  // });
                                  form.setFieldsValue({
                                    startTime:
                                      data.startTime === "00:00"
                                        ? dayjs()
                                        : dayjs(data.startTime, "hh:mm A"),
                                    endTime:
                                      data.endTime === "00:00"
                                        ? dayjs()
                                        : dayjs(data.endTime, "hh:mm A"),
                                  });

                                  showModal();
                                }}
                                style={{
                                  backgroundColor:
                                    data.status === "present"
                                      ? "rgb(16, 170, 112)"
                                      : null,
                                }}
                                className={
                                  commonCssStyles.presentAbsentBtnStyles
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "5px",
                                  }}
                                >
                                  {/* <p className={commonCssStyles.presentAbsentBtnTxtStyles}>P</p>
                          <p className={commonCssStyles.presentAbsentBtnTxtStyles}>&nbsp;|&nbsp;</p> */}
                                  <p
                                    className={
                                      commonCssStyles.presentAbsentBtnTxtStyles
                                    }
                                  >
                                    Present
                                  </p>
                                </div>
                              </Button>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                              <Button
                                type={
                                  data.status === "halfDay"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() => {
                                  console.log(data, "halfDay");
                                  setMarkPresentId(data.id);
                                  handleButtonClick("halfDay");
                                  form.setFieldsValue({
                                    startTime:
                                      data.startTime === "00:00"
                                        ? dayjs()
                                        : dayjs(data.startTime, "hh:mm A"),
                                    endTime:
                                      data.endTime === "00:00"
                                        ? dayjs()
                                        : dayjs(data.endTime, "hh:mm A"),
                                  });
                                  showModal();
                                }}
                                style={{
                                  backgroundColor:
                                    data.status === "halfDay"
                                      ? "rgb(239, 122, 44)"
                                      : null,
                                }}
                                className={
                                  commonCssStyles.presentAbsentBtnStyles
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "5px",
                                  }}
                                >
                                  {/* <p className={commonCssStyles.presentAbsentBtnTxtStyles}>HD</p>
                          <p className={commonCssStyles.presentAbsentBtnTxtStyles}>&nbsp;|&nbsp;</p> */}
                                  <p
                                    className={
                                      commonCssStyles.presentAbsentBtnTxtStyles
                                    }
                                  >
                                    Half Day
                                  </p>
                                </div>
                              </Button>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                              <Button
                                type={
                                  data.status === "absent"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() => {
                                  const mkAbsent = {
                                    id: data.id,
                                    status: "absent",
                                  };
                                  setMarkPresentId(data.id);
                                  handleButtonClick("absent");

                                  putAttendanceAction(mkAbsent, (success) => {
                                    if (success) {
                                      getAttendanceFilterAction({
                                        attendanceDate: selectedDate,
                                      });
                                      form.resetFields();
                                    }
                                  });
                                }}
                                style={{
                                  backgroundColor:
                                    data.status === "absent"
                                      ? "rgb(224, 32, 32)"
                                      : null,
                                }}
                                className={
                                  commonCssStyles.presentAbsentBtnStyles
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "5px",
                                  }}
                                >
                                  {/* <p className={commonCssStyles.presentAbsentBtnTxtStyles} >A</p>
                          <p className={commonCssStyles.presentAbsentBtnTxtStyles} >&nbsp;|&nbsp;</p> */}
                                  <p
                                    className={
                                      commonCssStyles.presentAbsentBtnTxtStyles
                                    }
                                  >
                                    Absent
                                  </p>
                                </div>
                              </Button>
                            </Col>
                          </Row>

                          <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                              <Button
                                type={data.fineText ? "primary" : "default"}

                                onClick={() => {
                                  setMarkPresentId(data.id);
                                  handleButtonClick("fine");

                                  if( data &&
                                    Object.keys(data.fine).length > 0){
                                      setDispSave(true);
                                    }

                                  if (
                                    data.status === "present" ||
                                    data.status === "halfDay"
                                  ) {
                                    if (
                                      data &&
                                      Object.keys(data.fine).length > 0
                                    ) {
                                      form.setFieldsValue({
                                        lateEntryHours: dayjs(
                                          data.fine.lateEntryHours,
                                          "HH:mm"
                                        ),
                                        lateEntryType: data.fine.lateEntryType,
                                        lateEntryAmount:
                                          data.fine.lateEntryAmount,
                                        earlyOutAmount:
                                          data.fine.earlyOutAmount,
                                        earlyOutHours: dayjs(
                                          data.fine.earlyOutHours,
                                          "HH:mm"
                                        ),
                                        earlyOutType: data.fine.earlyOutType,
                                        excessBreakAmount:
                                          data.fine.excessBreakAmount,
                                        excessBreakHours: dayjs(
                                          data.fine.excessBreakHours,
                                          "HH:mm"
                                        ),
                                        excessBreakType:
                                          data.fine.excessBreakType,
                                      });
                                    }

                                    showModal();
                                  } else {
                                    message.error(
                                      "Please mark the attendance first to add a Fine entry"
                                    );
                                  }
                                }}
                                // style={{
                                //   backgroundColor: data.fineText
                                //     ? "rgb(224, 32, 32)"
                                //     : "transparent",
                                // }}

                                style={{
                                  backgroundColor:
                                    data.fine &&
                                    data.fine.status &&
                                    data.fine.status == "pending"
                                      ? "rgb(224 32 32 / 50%)"
                                      : data.fine.status == "approved"
                                      ? "rgb(224, 32, 32)"
                                      : "transparent",
                                }}
                                className={
                                  commonCssStyles.presentAbsentBtnStyles
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "5px",
                                  }}
                                >
                                  <p
                                    className={
                                      commonCssStyles.presentAbsentBtnTxtStyles
                                    }
                                  >
                                    Fine{" "}
                                    {data.fineText ? (
                                      <> | {data.fineText} Hrs</>
                                    ) : (
                                      <></>
                                    )}
                                  </p>
                                </div>
                              </Button>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                              <Button
                                type={data.otText ? "primary" : "default"}
                                // type="primary"
                                onClick={() => {
                                  setMarkPresentId(data.id);
                                  handleButtonClick("overtime");
                                  if( data &&
                                    Object.keys(data.overtime).length > 0){
                                      setDispOvertimeSave(true);
                                    }
                                  if (
                                    data.status === "present" ||
                                    data.status === "halfDay"
                                  ) {
                                    if (
                                      data &&
                                      Object.keys(data.overtime).length > 0
                                    ) {
                                      setOverTimeHoursDisp(
                                        data.overtime.otRule
                                      );
                                      setEarlyOverTimeHoursDisp(
                                        data.overtime.earlyOtRule
                                      );

                                      form.setFieldsValue({
                                        earlyOtHours: dayjs(
                                          data.overtime.earlyOtHours,
                                          "HH:mm"
                                        ),
                                        otHours: dayjs(
                                          data.overtime.otHours,
                                          "HH:mm"
                                        ),
                                        otRule: data.overtime.otRule,
                                        otAmount: data.overtime.otAmount,
                                        earlyOtRule: data.overtime.earlyOtRule,
                                        earlyOtAmount:
                                          data.overtime.earlyOtAmount,
                                        earlyOtMultiplier:
                                          data.overtime.earlyOtMultiplier,
                                        otMultiplier:
                                          data.overtime.otMultiplier,
                                      });
                                    }
                                    showModal();
                                  } else {
                                    message.error(
                                      "Please mark the attendance first to add overtime"
                                    );
                                  }
                                }}
                                // style={{
                                //   backgroundColor: data.otText ? "rgb(16, 170, 112)" : null,
                                // }}
                                style={{
                                  backgroundColor:
                                    data.overtime &&
                                    data.overtime.status &&
                                    data.overtime.status == "pending"
                                      ? "rgb(16 170 112 / 50%)"
                                      : data.overtime.status == "approved"
                                      ? "rgb(16, 170, 112)"
                                      : "transparent",
                                }}
                                className={
                                  commonCssStyles.presentAbsentBtnStyles
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "5px",
                                  }}
                                >
                                  <p
                                    className={
                                      commonCssStyles.presentAbsentBtnTxtStyles
                                    }
                                  >
                                    Overtime{" "}
                                    {data.otText ? (
                                      <> | {data.otText} Hrs</>
                                    ) : (
                                      <></>
                                    )}
                                  </p>
                                </div>
                              </Button>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                              <Button
                                type={
                                  data.status === "leave"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() => {
                                  getStaffLeaveBalanceAction(data.userId);
                                  setMarkPresentId(data.id);
                                  handleButtonClick("leave");
                                  showModal();
                                }}
                                style={{
                                  backgroundColor:
                                    data.status === "leave"
                                      ? "rgb(16, 170, 112)"
                                      : null,
                                }}
                                className={
                                  commonCssStyles.presentAbsentBtnStyles
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "5px",
                                  }}
                                >
                                  {/* <p className={commonCssStyles.presentAbsentBtnTxtStyles} >L</p>
                          <p className={commonCssStyles.presentAbsentBtnTxtStyles} >&nbsp;|&nbsp;</p> */}
                                  <p
                                    className={
                                      commonCssStyles.presentAbsentBtnTxtStyles
                                    }
                                  >
                                    Leave
                                  </p>
                                </div>
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </>
            );
          })}
      </div>
      <Modal
        // title="Present"
        title={
          selectedButton == "present"
            ? "Present"
            : selectedButton == "halfDay"
            ? "Half Day"
            : selectedButton == "Add Note"
            ? "Add Note"
            : selectedButton == "fine"
            ? "Fine"
            : selectedButton == "overtime"
            ? "Overtime"
            : selectedButton == "View Logs"
            ? "View Logs"
            : selectedButton == "leave"
            ? "leave"
            : "NA"
        }
        closeIcon={closeIcon}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          selectedButton === "View Logs" ? (
            <>
              <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
                <Button
                  key="applyFilter"
                  type="primary"
                  className={commonCssStyles.applyFilterStylesFull}
                  onClick={handleCancel}
                >
                  Done
                </Button>
              </div>
            </>
          ) : (
            <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
              {selectedButton == "fine" ? (
                <>
                  {dispSave && (
                    <Button
                      key="applyFilter"
                      type="primary"
                      htmlType="submit"
                      // onClick={closeAttendancModal}
                      className={commonCssStyles.applyFilterStylesFull}
                      form="markPresent"
                    >
                      Save
                    </Button>
                  )}
                </>
              ) : selectedButton == "overtime" ? (
                <>
                 {dispOvertimeSave &&
                 (
                    <Button
                      key="applyFilter"
                      type="primary"
                      htmlType="submit"
                      // onClick={closeAttendancModal}
                      className={commonCssStyles.applyFilterStylesFull}
                      form="markPresent"
                    >
                      Save
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  key="applyFilter"
                  type="primary"
                  htmlType="submit"
                  // onClick={closeAttendancModal}
                  className={commonCssStyles.applyFilterStylesFull}
                  form="markPresent"
                >
                  Save
                </Button>
              )}
            </div>
          ),
        ]}
      >
        <Form
          style={{
            height: 300,
            overflowY: "auto",
          }}
          form={form}
          layout="vertical"
          id="markPresent"
          initialValues={{
            lateEntryHours: dayjs("00:00", "HH:mm"),
            excessBreakHours: dayjs("00:00", "HH:mm"),
            earlyOutHours: dayjs("00:00", "HH:mm"),

            lateEntryType: "fixed",
            excessBreakType: "fixed",
            earlyOutType: "fixed",

            otRule: "fixed",
            earlyOtRule: "fixed",
          }}
          onFinish={(val) => {
            if (selectedButton === "present" || selectedButton === "halfDay") {
              const formatTime = (time) => dayjs(time).format("hh:mm A");
              const formattedStartTime = formatTime(val.startTime);
              const formattedEndTime = formatTime(val.endTime);

              const mkPresent = {
                id: markPresentId,
                status: selectedButton,
                timeDetails: {
                  startTime: formattedStartTime,
                  endTime: formattedEndTime,
                },
              };
              putAttendanceAction(mkPresent, (success) => {
                if (success) {
                  getAttendanceFilterAction({
                    attendanceDate: selectedDate,
                  });
                  handleCancel();
                  form.resetFields();
                }
              });
            } else if (selectedButton === "Add Note") {
              const mkPresent = {
                id: markPresentId,
                note: val.note,
              };
              putAttendanceAction(mkPresent, (success) => {
                if (success) {
                  getAttendanceFilterAction({
                    attendanceDate: selectedDate,
                  });
                  handleCancel();
                  form.resetFields();
                }
              });
            } else if (selectedButton === "fine") {
              const mkPresent = {
                id: markPresentId,
                status: selectedButton,
                fineDetails: [
                  {
                    lateEntryHours: dayjs(val.lateEntryHours).format("HH:mm"),
                    excessBreakHours: dayjs(val.excessBreakHours).format(
                      "HH:mm"
                    ),
                    earlyOutHours: dayjs(val.earlyOutHours).format("HH:mm"),
                    lateEntryType: val.lateEntryType,
                    excessBreakType: val.excessBreakType,
                    earlyOutType: val.earlyOutType,
                    lateEntryAmount: val.lateEntryAmount
                      ? val.lateEntryAmount
                      : 0,
                    excessBreakAmount: val.excessBreakAmount
                      ? val.excessBreakAmount
                      : 0,
                    earlyOutAmount: val.earlyOutAmount ? val.earlyOutAmount : 0,
                  },
                ],
              };
              putAttendanceAction(mkPresent, (success) => {
                if (success) {
                  getAttendanceFilterAction({
                    attendanceDate: selectedDate,
                  });
                  handleCancel();
                  form.resetFields();
                }
              });
            } else if (selectedButton === "overtime") {
              const mkPresent = {
                id: markPresentId,
                status: selectedButton,
                overtimeDetails: [
                  {
                    otHours: val.otHours
                      ? dayjs(val.otHours).format("HH:mm")
                      : 0,
                    otRule: val.otRule,
                    otMultiplier: val.otMultiplier,
                    otAmount: val.otAmount,
                    earlyOtHours: val.earlyOtHours
                      ? dayjs(val.earlyOtHours).format("HH:mm")
                      : 0,
                    earlyOtRule: val.earlyOtRule,
                    earlyOtMultiplier: val.earlyOtMultiplier,
                    earlyOtAmount: val.earlyOtAmount,
                  },
                ],
              };

              putAttendanceAction(mkPresent, (success) => {
                if (success) {
                  getAttendanceFilterAction({
                    attendanceDate: selectedDate,
                  });
                  handleCancel();
                  form.resetFields();
                }
              });
              console.log(mkPresent);
            } else if (selectedButton === "leave") {
              const mkPresent = {
                id: markPresentId,
                status: selectedButton,
                leaveCategoryId: val.leaveCategoryId,
              };
              putAttendanceAction(mkPresent, (success) => {
                if (success) {
                  getAttendanceFilterAction({
                    attendanceDate: selectedDate,
                  });
                  handleCancel();
                  form.resetFields();
                }
              });
            }
          }}
        >
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              {selectedButton === "present" || selectedButton === "halfDay" ? (
                <>
                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>Start Time</p>
                    }
                    name="startTime"
                    rules={[
                      { required: true, message: "Please select a start time" },
                    ]}
                  >
                    <TimePicker
                      format="hh:mm A"
                      changeOnBlur={true}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>End Time</p>
                    }
                    name="endTime"
                    rules={[
                      { required: true, message: "Please select an end time" },
                    ]}
                  >
                    <TimePicker
                      format="hh:mm A"
                      changeOnBlur={true}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </>
              ) : selectedButton === "Add Note" ? (
                <>
                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>Add Note</p>
                    }
                    name="note"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </>
              ) : selectedButton === "fine" ? (
                <>
                  <Row justify={"space-around"}>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Late Entry
                          </p>
                        }
                        name="lateEntryHours"
                      >
                        <TimePicker
                          format={"HH:mm"}
                          changeOnBlur={true}
                          onChange={() => {
                            setDispSave(true);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Fine Amount
                          </p>
                        }
                        name="lateEntryAmount"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>Type</p>
                        }
                        name="lateEntryType"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            console.log(val, "selected value");
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="halfDay">HalfDay</Select.Option>
                          <Select.Option value="fullDay">FullDay</Select.Option>
                          <Select.Option value="pardon">Pardon</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify={"space-around"}>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Excess Breaks
                          </p>
                        }
                        name="excessBreakHours"
                      >
                        <TimePicker
                          format={"HH:mm"}
                          changeOnBlur={true}
                          onChange={() => {
                            setDispSave(true);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Fine Amount
                          </p>
                        }
                        name="excessBreakAmount"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>Type</p>
                        }
                        name="excessBreakType"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            console.log(val, "selected value");
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="halfDay">HalfDay</Select.Option>
                          <Select.Option value="fullDay">FullDay</Select.Option>
                          <Select.Option value="pardon">Pardon</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify={"space-around"}>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Early Out
                          </p>
                        }
                        name="earlyOutHours"
                      >
                        <TimePicker
                          format={"HH:mm"}
                          changeOnBlur={true}
                          onChange={() => {
                            setDispSave(true);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Fine Amount
                          </p>
                        }
                        name="earlyOutAmount"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>Type</p>
                        }
                        name="earlyOutType"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            console.log(val, "selected value");
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="halfDay">HalfDay</Select.Option>
                          <Select.Option value="fullDay">FullDay</Select.Option>
                          <Select.Option value="pardon">Pardon</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : selectedButton === "overtime" ? (
                <>
                  <Row justify={"space-around"}>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Overtime Hours
                          </p>
                        }
                        name="otHours"
                      >
                        <TimePicker
                          style={{ width: "100%" }}
                          format={"HH:mm"}
                          changeOnBlur={true}
                          onChange={()=>{
                            setDispOvertimeSave(true)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Overtime Rule
                          </p>
                        }
                        name="otRule"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            setOverTimeHoursDisp(val);
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="multiplier">
                            Multiplier
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {overTimeHoursDisp == "fixed" ? (
                      <Col xs={24}>
                        <Form.Item
                          label={
                            <p className={commonCssStyles.formLabelTxt}>
                              Overtime Amount
                            </p>
                          }
                          name="otAmount"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col xs={24}>
                        <Form.Item
                          label={
                            <p className={commonCssStyles.formLabelTxt}>
                              Overtime Multiplier
                            </p>
                          }
                          name="otMultiplier"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <Row justify={"space-around"}>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Early Overtime Hours
                          </p>
                        }
                        name="earlyOtHours"
                      >
                        <TimePicker
                          style={{ width: "100%" }}
                          format={"HH:mm"}
                          changeOnBlur={true}
                          onChange={()=>{
                            setDispOvertimeSave(true);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Early Overtime Rule
                          </p>
                        }
                        name="earlyOtRule"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            setEarlyOverTimeHoursDisp(val);
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="multiplier">
                            Multiplier
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {earlyOverTimeHoursDisp == "fixed" ? (
                      <>
                        {" "}
                        <Col xs={24}>
                          <Form.Item
                            label={
                              <p className={commonCssStyles.formLabelTxt}>
                                Early Overtime Amount
                              </p>
                            }
                            name="earlyOtAmount"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={24}>
                          <Form.Item
                            label={
                              <p className={commonCssStyles.formLabelTxt}>
                                Early Overtime Multiplier
                              </p>
                            }
                            name="earlyOtMultiplier"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              ) : selectedButton === "leave" ? (
                <>
                  <Form.Item label="" name="leaveCategoryId">
                    <Radio.Group>
                      {staffLeaveBalanceData &&
                        staffLeaveBalanceData.map((data, index) => (
                          <div key={index} style={{ marginTop: "10px" }}>
                            <Radio value={data.id}>
                              <p className={commonCssStyles.formLabelTxt}>
                                {data.type} &#40;{data.balance}&#41;
                              </p>
                            </Radio>
                          </div>
                        ))}
                      <br />
                      <Radio value={"others"} style={{ marginTop: "10px" }}>
                        <p className={commonCssStyles.formLabelTxt}>Others</p>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              ) : (
                <>
                  <p>
                    {logsDisp && logsDisp.length > 0 ? (
                      <>
                        {logsDisp.map((data, index) => (
                          <>
                            <p
                              className={commonCssStyles.AddStaffcardHeadingTxt}
                              style={{ padding: "0px", margin: "0px" }}
                              key={index}
                            >
                              <b>{data.description}</b>
                            </p>
                            <p
                              className={
                                commonCssStyles.AddStaffcardSubHeadingTxt
                              }
                              key={index}
                            >
                              By {data.addedBy} on {data.createdAt}
                            </p>
                          </>
                        ))}
                      </>
                    ) : (
                      "No Data"
                    )}
                  </p>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export const mapStateToProps = (state) => {
  return {
    attendance: state.attendance.attendance,

    staffLeaveBalanceData: state.staff.staffLeaveBalance,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getAttendanceFilterAction: (val) => {
      dispatch(getAttendanceFilterAction(val));
    },
    putAttendanceAction: (val, callback) => {
      dispatch(putAttendanceAction(val, callback));
    },

    //Leave Balance
    getStaffLeaveBalanceAction: (id) => {
      dispatch(getStaffLeaveBalanceAction(id));
    },
    //Leave Balance end
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
