import React, { useEffect, useState } from "react";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Checkbox,
  Collapse,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  BonusIcon,
  DeductionsIcon,
  FilterOutlinedIcon,
  OverTimeIcon,
  PaymentIcon,
  RaiseHandIcon,
} from "../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router";
import {
  getSettingsCompOffLeaveAction,
  postSettingsACompOffLeaveAction,
} from "../../../Reusable/Store/Action/SettingsAction";
import { connect } from "react-redux";

function CompoffLeaveDetailPage({
  getSettingsCompOffLeaveAction,
  postSettingsACompOffLeaveAction,
  settingsCompOffLeave,
}) {
  useEffect(() => {
    getSettingsCompOffLeaveAction();
  }, []);


  const [form] = Form.useForm();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  //Checkbox related


  const updateSelectAllCheckbox = () => {
    const formValues = form.getFieldsValue();
    const allChecked = Object.values(formValues).every((checked) => checked);
    const someChecked = Object.values(formValues).some((checked) => checked);

    // setSelectAllChecked(allChecked);
    setIndeterminate(!allChecked && someChecked);
  };

  useEffect(() => {
    updateSelectAllCheckbox();
  }, [form]);

 
  const initialValues = {};

useEffect(() => {
  if (settingsCompOffLeave && settingsCompOffLeave.length > 0) {
    settingsCompOffLeave.forEach((item) => {
      initialValues[item.id] = item.compoffLeave === 1;
    });

    initialValues["selectAll"] = false;

    form.setFieldsValue(initialValues);
  }
}, [settingsCompOffLeave, form]);


  const handleFormSubmit = (values) => {
    const someChecked = Object.values(values).some((checked) => checked);
    setIndeterminate(false);

    const checkedIds = Object.entries(values)
      .filter(([id, checked]) => checked)
      .map(([id]) => id);
    const data = {
      userId: checkedIds,
    };

    postSettingsACompOffLeaveAction(data, (success) => {
      if (success) {
        console.log("Success Compoff Leave");
      }
    });
  };

  const handleSelectAllChange = (e) => {
    const newValues = settingsCompOffLeave.reduce((acc, item) => {
      acc[item.id] = e.target.checked;
      return acc;
    }, {});

    setSelectAllChecked(e.target.checked);
    setIndeterminate(false);
    form.setFieldsValue(newValues);
  };

  const CheckboxGroup = Checkbox.Group;
  const plainOptions = ["Apple", "Pear", "Orange"];
  const defaultCheckedList = ["Apple", "Orange"];

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setSelectAllChecked(list.length === plainOptions.length);
  };

  //Checkbox related end

  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link to="/settings">
            <h1 className={commonCssStyles.BackButtonTxt}>
              {" "}
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>

      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Comp Off Leave</h1>
        </Col>
      </Row>
      <h1
        className={commonCssStyles.modalDataTxtStyles}
        style={{ marginLeft: "0px" }}
      >
        Staff can take leave on a working day as a compensation for working on a
        holiday or weekend
      </h1>

      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Assign Staff</h1>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col xs={24}>
          <Card className="custom-card1">
            <Collapse defaultActiveKey={["1"]} ghost>
              <Collapse.Panel
                header={<p className={commonCssStyles.formLabelTxt}>Staff</p>}
                //   header="Assigned Staff "
                key="1"
              >
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={handleSelectAllChange}
                  checked={selectAllChecked}
                >
                  <span className={commonCssStyles.formLabelTxt}>
                    Select All
                  </span>
                </Checkbox>
                <Form
                  id="attendanceForm"
                  onFinish={handleFormSubmit}
                  layout="vertical"
                  initialValues={initialValues}
                  form={form}
                >
                  <Row style={{ paddingTop: "10px", paddingLeft: "20px" }}>
                    <Col xs={24}>
                      {settingsCompOffLeave.map((item, index) => (
                        <Row
                          key={item.id}
                          justify="space-between"
                          style={{
                            borderBottom:
                              index === settingsCompOffLeave.length - 1
                                ? "none"
                                : "1px solid #e8e8e8",
                            paddingBottom: "15px",
                            paddingTop: "15px",
                            marginBottom: "5px",
                          }}
                        >
                          <Col>
                            <Form.Item
                              name={item.id}
                              valuePropName="checked"
                              noStyle
                            >
                              <Checkbox name={item.id}>
                                <span className={commonCssStyles.formLabelTxt}>
                                  {item.name}
                                </span>
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                  <Row style={{marginTop:"20px"}}>
                    <Col md={24}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Form.Item>
                          <Button
                            type="primary"
                            className={commonCssStyles.addBtnStyles}
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Collapse.Panel>
            </Collapse>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    settingsCompOffLeave: state.settings.settingsCompOffLeave,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettingsCompOffLeaveAction: () => {
      dispatch(getSettingsCompOffLeaveAction());
    },

    postSettingsACompOffLeaveAction: (val, callback) => {
      dispatch(postSettingsACompOffLeaveAction(val, callback));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompoffLeaveDetailPage);
