import { SETTINGS_DEPARTMENT_RULE_URL } from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
import { getDepartmentDetailSlice, getDepartmentsSlice } from "../Slice/DepartmentsSlice";
const api = new AxiosApi();

export const getDepartmentAction = () => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_DEPARTMENT_RULE_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getDepartmentsSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postDepartmentAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_DEPARTMENT_RULE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getDepartmentAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const deleteDepartmentAction = (id) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_DEPARTMENT_RULE_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        // callback(false);
        if (success) {
          if (statusCode === 200) {
            // callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getDepartmentAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const putDepartmentAction = (val, callback) => {
  console.log(val,"Action Values");
  return (dispatch) => {
    api.invoke(
      SETTINGS_DEPARTMENT_RULE_URL + "/" + val.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getDepartmentAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const getDepartmentDetailsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_DEPARTMENT_RULE_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getDepartmentDetailSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
