import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    departments: null,
    departmentDetail: null,
  };


  export const DepartmentSlice = createSlice({
    name: "departments",
    initialState,
    reducers: {
      getDepartmentsSlice(state, action) {
        state.departments = action.payload.result;
      },
      getDepartmentDetailSlice(state, action) {
        state.departmentDetail = action.payload.result;
      },
     
    },
  });

  export const {
    getDepartmentsSlice,
    getDepartmentDetailSlice
  } = DepartmentSlice.actions;
  export default DepartmentSlice.reducer;