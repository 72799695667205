import { Button, Card, Form, Input, Select } from 'antd';
import React from 'react';
const { Option } = Select;

function SampleForm() {
  const formRef = React.useRef(null);

  const onGenderChange = (value) => {
    switch (value) {
      case 'male':
        formRef.current?.setFieldsValue({
          note: 'Hi, man!',
        });
        break;
      case 'female':
        formRef.current?.setFieldsValue({
          note: 'Hi, lady!',
        });
        break;
      case 'other':
        formRef.current?.setFieldsValue({
          note: 'Hi there!',
        });
        break;
      default:
        break;
    }
  };

  const onFinish = (values) => {
    console.log(values);
  };

  const onReset = () => {
    formRef.current?.resetFields();
  };

  const onFill = () => {
    formRef.current?.setFieldsValue({
      note: 'Hello world!',
      gender: 'male',
    });
  };

  return (
    <>
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 8 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 16 } }}
      ref={formRef}
      name="control-ref"
      onFinish={onFinish}
      style={{
        maxWidth: 500,
        margin: '0 auto',
      }}
    >
      <Form.Item
        name="note"
        label="Note"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="gender"
        label="Gender"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select an option and change the input text above"
          onChange={onGenderChange}
          allowClear
        >
          <Option value="male">Male</Option>
          <Option value="female">Female</Option>
          <Option value="other">Other</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="gender"
        label="Gender (long label)"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select an option and change the input text above"
          onChange={onGenderChange}
          allowClear
        >
          <Option value="male">Male</Option>
          <Option value="female">Female</Option>
          <Option value="other">Other</Option>
        </Select>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
      >
        {({ getFieldValue }) =>
          getFieldValue('gender') === 'other' ? (
            <Form.Item
              name="customizeGender"
              label="Customize Gender"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 16, offset: 8 } }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Reset
        </Button>
        <Button type="link" htmlType="button" onClick={onFill}>
          Fill form
        </Button>
      </Form.Item>
    </Form>

<Card 
// style={{backgroundColor:'red'}}
>
<h1
style={{
  color:'var(---primary-color)',
  fontSize:"100px"
}}
>Primary color</h1>
<h1
style={{
  color:'var(--layout-bgColor)',
  fontSize:"100px"
}}
>layout bg color</h1>
<h1
style={{
  color:'var(--orange-Color)',
  fontSize:"100px"
}}
>orange color</h1>
<h1
style={{
  color:'var(--menu-txt-clr)',
  fontSize:"100px"
}}
>menu txt color</h1>

<h1
style={{
  color:'var(--main-heading-clr)',
  fontSize:"100px"
}}
>Main Heading color </h1>

<h1
style={{
  color:'var(--sub-heading-clr)',
  fontSize:"100px"
}}
>Sub Heading color </h1>


<h1
style={{
  color:'var(--form-label-clr)',
  fontSize:"100px"
}}
>Form Label clr</h1>

<h1
style={{
  color:'var(--form-placeholder-clr)',
  fontSize:"100px"
}}
>placeholder label  </h1>


</Card>

    </>
  );
}

export default SampleForm;
