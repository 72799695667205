import {
  getPayrollGeneralSettingsSlice,
  getSettingsAttendanceAccessSlice,
  getSettingsCompOffSlice,
  getSettingsOverviewSlice,
  getSettingsSlice,
  getWeeklyHolidaysSlice,
} from "../Slice/SettingsSlice";
import {
  SETTINGS_URL,
  SETTINGS_PAYROLL_URL,
  SETTINGS_OVERVIEW_URL,
  SETTINGS_ATTENDANCE_ACCESS_URL,
  SETTINGS_COMPOFFLEAVE_URL,
  SETTINGS_USERPAYROLL_URL,
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
const api = new AxiosApi();

export const getSettingsAction = () => {
  return (dispatch) => {
    api.invoke(SETTINGS_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getSettingsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const getSettingsOverviewAction = () => {
  return (dispatch) => {
    api.invoke(SETTINGS_OVERVIEW_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getSettingsOverviewSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const postSettingsAction = (val, callback) => {
  console.log(val, "Final Value");
  return (dispatch) => {
    api.invoke(
      SETTINGS_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getSettingsAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

// Settings - Payroll General Settings Payroll related settings data management

export const getSettingsGeneralPayrollAction = () => {
  return (dispatch) => {
    api.invoke(SETTINGS_PAYROLL_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getPayrollGeneralSettingsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const postSettingsGeneralPayrollAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_PAYROLL_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getSettingsGeneralPayrollAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

// Settings - Payroll General Settings Payroll related settings data management end

//Get and post settings staff attendance access

export const getSettingsAttendanceAccessAction = () => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_ATTENDANCE_ACCESS_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200)
            dispatch(getSettingsAttendanceAccessSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postSettingsAttendanceAccessAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_ATTENDANCE_ACCESS_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getSettingsGeneralPayrollAction());
            dispatch(getSettingsAttendanceAccessAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//Get and post settings staff attendance access end

//Compoff leave action slice

export const getSettingsCompOffLeaveAction = () => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_COMPOFFLEAVE_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getSettingsCompOffSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postSettingsACompOffLeaveAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_COMPOFFLEAVE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getSettingsCompOffLeaveAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//Compoff leave action slice end

//Weekly holiday

export const getWeeklyHolidayAction = () => {
  return (dispatch) => {
    api.invoke(SETTINGS_USERPAYROLL_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getWeeklyHolidaysSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};



export const postWeeklyHolidayAction = (val, callback) => {
  console.log(val, "Final Value");
  return (dispatch) => {
    api.invoke(
      SETTINGS_USERPAYROLL_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getWeeklyHolidayAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};
//Weekly holiday end
