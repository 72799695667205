import React, { useEffect, useState } from "react";
import Headers from "../../../UI/headersComp/Headers";
import commonCssStyles from "../../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TimePicker,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  BonusIcon,
  DeductionsIcon,
  FilterOutlinedIcon,
  OverTimeIcon,
  PaymentIcon,
  RaiseHandIcon,
} from "../../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { postShiftsAction } from "../../../../Reusable/Store/Action/ShiftsAction";

function AddShift({ postShiftsAction }) {
  const [form] = Form.useForm();

useEffect(() => {
  const initialShiftDetails = [{}, {}].map((shiftDetail) => ({
    ...shiftDetail,
    unpaidBreak: dayjs("00:00", "HH:mm"),
  }));

  form.setFieldsValue({
    shiftDetails: initialShiftDetails,
  });
}, []);

  const navigate = useNavigate();

  //new time calculation code
  const [timeDiff, setTimeDiff] = useState("");
  const [unpaidHrsDiff, setUnpaidHrsDiff] = useState("");
  const calculateUnpaidHours = (changedValues, allValues) => {
    setTimeDiff("");
    setUnpaidHrsDiff("");
    const { timeRange, unpaidBreak } = allValues;
  
    if (timeRange && timeRange.length === 2) {
      const startTime = dayjs(timeRange[0], "hh:mm A");
      const endTime = dayjs(timeRange[1], "hh:mm A");
      const unpaidBreakHrs = dayjs(unpaidBreak, "HH:mm");
  
      let timeDiffMinutes;
      if (endTime.isBefore(startTime)) {
        const nextDayEndTime = endTime.add(1, "day");
        timeDiffMinutes = nextDayEndTime.diff(startTime, "minute");
      } else {
        timeDiffMinutes = endTime.diff(startTime, "minute");
      }
  
      const hours = Math.floor(timeDiffMinutes / 60);
      const minutes = timeDiffMinutes % 60;
      // const timeDiffFormatted = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

      const timeDiffFormatted = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
  
      form.setFieldsValue({
        time3: timeDiffFormatted,
      });
  
      if (timeDiffFormatted) {
        setTimeDiff(timeDiffFormatted);
      }
      console.log(timeDiffFormatted, "timeDiffFormatted");
  
      // Calculate unpaid break in minutes
      const unpaidBreakMinutes =
        unpaidBreakHrs.hour() * 60 + unpaidBreakHrs.minute();
  
      // Calculate unpaid hours in minutes
      const unpaidHoursMinutes = timeDiffMinutes - unpaidBreakMinutes;
  
      // Convert unpaid hours back to hours and minutes format
      const unpaidHoursHours = Math.floor(unpaidHoursMinutes / 60);
      const unpaidHoursMinutesRemainder = unpaidHoursMinutes % 60;
      const unpaidHoursFormatted = `${unpaidHoursHours.toString().padStart(2, "0")}:${unpaidHoursMinutesRemainder.toString().padStart(2, "0")}`;
  
      console.log(unpaidBreakHrs, "unpaidBreakHrs");
      console.log(unpaidHoursFormatted, "unpaidHoursFormatted");
      if (unpaidHoursFormatted) {
        setUnpaidHrsDiff(unpaidHoursFormatted);
      }
    }
  };
  

  //new time calculation code end

  //time calculation

  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [timeDuration, setTimeDuration] = useState(null);
  const [unpaidHours, setUnpaidHours] = useState(null);
  const [unpaidErrorMessage, setUnpaidErrorMessage] = useState(null);

  //time calculation end

  const format = "HH:mm";

  const [shift, setShift] = useState("fixed");

  //Checkbox related

  const CheckboxGroup = Checkbox.Group;
  const plainOptions = ["Apple", "Pear", "Orange"];
  const defaultCheckedList = ["Apple", "Orange"];

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  //Checkbox related end

  const formatTime = (time) => dayjs(time).format("hh:mm A");
  const formatTime4 = (time) => dayjs(time).format("HH:mm");

  const onFinish = (values) => {
    if (shift == "fixed") {
      const formattedStartTime = formatTime(values.startTimefixed);
      const formattedEndTime = formatTime(values.endTimefixed);
      const formattedUnpaidBreak = formatTime4(values.unpaidBreak);
      const data = {
        shiftStartTime: formattedStartTime,
        shiftEndTime: formattedEndTime,
        name: values.name,
        shiftType: values.shifttype,
        unpaidBreak: formattedUnpaidBreak,
      };

      console.log(data, "data");

      // if (!unpaidErrorMessage) {
      postShiftsAction(data, (success) => {
        if (success) {
          console.log("Added successfully");
          navigate("/settings/shifts");
        }
      });
      // }
    }

    if (shift == "open") {
      // const formattedWorkHours = formatTime4(values.workhours);
      const formattedWorkHours = formatTime4(values.netPayableHours);

      const data1 = {
        name: values.name,
        shiftType: values.shifttype,
        // workhours: formattedWorkHours,
        netPayableHours: formattedWorkHours,

      };
      postShiftsAction(data1, (success) => {
        if (success) {
          console.log("Added successfully");
          navigate("/settings/shifts");
        }
      });

      console.log(data1,"data1")
    }

    if (shift == "rotational") {
      const formattedValues = {
        ...values,
        shiftType:"rotational",
        shiftDetails: values.shiftDetails.map((timeObj) => ({
          ...timeObj,
          startTime: formatTime(timeObj.startTime),
          endTime: formatTime(timeObj.endTime),
          unpaidBreak: formatTime4(timeObj.unpaidBreak),
        })),
      };

      postShiftsAction(formattedValues, (success) => {
        if (success) {
          console.log("Added successfully");
          navigate("/settings/shifts");
        }
      });
    }

    // postShiftsAction(data, (success) => {
    //   if (success) {
    //     console.log("Added successfully")
    //   }
    // });
  };

  //demo test code

  //demo test code end
  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link to="/settings/shifts">
            <h1 className={commonCssStyles.BackButtonTxt}>
              {" "}
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>

      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Create Shift</h1>
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col xs={24}>
          <Card>
            <Row>
              <Col md={14}>
                <Form
                  style={{ margin: "5px" }}
                  initialValues={{
                    shifttype: "fixed",
                    unpaidBreak: dayjs("00:00", "HH:mm"),
                  }}
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                >
                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>Shift Type</p>
                    }
                    name="shifttype"
                    rules={[
                      { required: true, message: "Please select a shift type" },
                    ]}
                  >
                    <Select
                      className="addStaffForm"
                      style={{ width: "100%" }}
                      onChange={(val) => {
                        console.log(val, "selected value");
                        setShift(val);
                      }}
                    >
                      <Select.Option value="fixed">Fixed Shift</Select.Option>
                      <Select.Option value="rotational">
                        Rotational Shift
                      </Select.Option>
                      <Select.Option value="open">Open Shift</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                   label={
                    <p className={commonCssStyles.formLabelTxt}>Shift Name</p>
                  }
                    name="name"
                    rules={[
                      { required: true, message: "Please enter a Shift Name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  {shift && shift == "fixed" && (
                    <> 
                    <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>
                        Start Time
                      </p>
                    }
                    name="startTimefixed"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Start Time",
                      },
                    ]}
                  >
                    <TimePicker
                      format="hh:mm A"
                      style={{ width: "100%" }}
                      changeOnBlur={true}
                    />
                  </Form.Item>
                  
                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>
                        End Time
                      </p>
                    }
                    name="endTimefixed"
                    rules={[
                      {
                        required: true,
                        message: "Please select an End Time",
                      },
                    ]}
                  >
                    <TimePicker
                      format="hh:mm A"
                      style={{ width: "100%" }}
                      changeOnBlur={true}
                    />
                  </Form.Item>
                  </>
                  )}

                  {shift && shift == "fixed" && (
                    <Form.Item
                      label={
                        <p className={commonCssStyles.formLabelTxt}>
                          Unpaid Break
                        </p>
                      }
                      name="unpaidBreak"
                      validateStatus={unpaidErrorMessage ? "error" : ""}
                      help={unpaidErrorMessage}
                    >
                      <TimePicker
                        format="HH:mm"
                        // format="HH:mm A"
                        style={{ width: "100%" }}
                        changeOnBlur={true}
                      />
                    </Form.Item>
                  )}

                  {shift &&
                    shift == "fixed" &&
                    selectedStartTime &&
                    selectedEndTime && (
                      <div>
                        <p>Time Duration: {timeDuration} hours</p>
                        {unpaidHours !== null && (
                          <p>Unpaid Hours: {unpaidHours} hours</p>
                        )}
                      </div>
                    )}

                  {timeDiff && timeDiff && (
                    <>
                      <p>Time Duration: {timeDiff}</p>{" "}
                    </>
                  )}

                  {unpaidHrsDiff && unpaidHrsDiff && (
                    <>
                      <p>Net Payable Hours: {unpaidHrsDiff}</p>{" "}
                    </>
                  )}
                  {shift && shift == "open" && (
                    <Form.Item
                      label={
                        <p className={commonCssStyles.formLabelTxt}>
                          Work hours
                        </p>
                      }
                      // name="workhours"
                      name="netPayableHours"
                    >
                      <TimePicker
                        // defaultValue={dayjs("12:08", format)}
                        format={format}
                        style={{ width: "100%" }}
                        changeOnBlur={true}
                        
                      />
                    </Form.Item>
                  )}

                  {shift && shift == "rotational" && (
                    <>
                      <h1>Add Shift Details</h1>

                      <Form.List name="shiftDetails">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field, index) => (
                              <Row gutter={[16, 16]} key={field.key}>
                                <Col xs={6} md={6}>
                                  <Form.Item
                                    {...field}
                                    label={
                                      index === 0 ? "Rotational Shift Name" : ""
                                    }
                                    name={[field.name, "rotationalShiftName"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please enter the rotational shift name",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Item
                                    {...field}
                                    label={index === 0 ? "Start Time" : ""}
                                    name={[field.name, "startTime"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select the start time",
                                      },
                                    ]}
                                  >
                                    <TimePicker
                                      format="hh:mm A" 
                                      changeOnBlur={true}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Item
                                    {...field}
                                    label={index === 0 ? "End Time" : ""}
                                    name={[field.name, "endTime"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select the end time",
                                      },
                                    ]}
                                  >
                                    <TimePicker
                                      format="hh:mm A" 
                                      changeOnBlur={true}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Item
                                    {...field}
                                    label={index === 0 ? "Unpaid Break" : ""}
                                    name={[field.name, "unpaidBreak"]}
                                    validateStatus={field.errors ? "error" : ""}
                                    help={
                                      field.errors
                                        ? field.errors.join(", ")
                                        : null
                                    }
                                  >
                                    <TimePicker format="HH:mm"  changeOnBlur={true}/>
                                  </Form.Item>
                                </Col>
                            
                                <Col xs={6} md={2}>
                                  {/* Delete button with CloseCircleOutlined icon */}
                                  {index > 1 && (
                                    <Button
                                      onClick={() => remove(field.name)}
                                      icon={<CloseCircleOutlined />}
                                    />
                                  )}
                                </Col>
                              </Row>
                            ))}
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} block>
                                Add Row
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </>
                  )}

                  {/* Demo  */}

                  {/* Demo end   */}

                  {/* <Row style={{ marginTop: "20px" }}>
                    <Col xs={24}>
                      <Card className="custom-card1">
                        <Collapse defaultActiveKey={["1"]} ghost>
                          <Collapse.Panel
                            header={
                              <p className={commonCssStyles.formLabelTxt}>
                                Assigned Staff
                              </p>
                            }
                            //   header="Assigned Staff "

                            key="1"
                          >
                            <Checkbox
                              indeterminate={indeterminate}
                              onChange={onCheckAllChange}
                              checked={checkAll}
                            >
                              Check all
                            </Checkbox>
                            <Divider />
                            <CheckboxGroup
                              options={plainOptions}
                              value={checkedList}
                              onChange={onChange}
                            />
                          </Collapse.Panel>
                        </Collapse>
                      </Card>
                    </Col>
                  </Row> */}

                  <Row style={{ marginTop: "30px" }}>
                    <Col xs={24}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginTop: "20px" }}
                            className={commonCssStyles.addBtnStyles}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    postShiftsAction: (val, callback) => {
      dispatch(postShiftsAction(val, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShift);
