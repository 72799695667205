import {
    loginReducer,
    logoutReducer,
    passwordResetReducer,
    profileReducer,
    profileUpdateReducer,
    refreshReducer,
    resetPasswordReducer,
    sendResetLinkReducer,
    verifyLinkReducer,
  } from "../Slice/AuthenticationSlice";
  import {
    LOGIN_URL,
    LOGOUT_URL,
    PASSWORD_RESET_URL,
    PROFILE_UPDATE_URL,
    PROFILE_URL,
    REFRESH_URL,
    RESET_PASSWORD_URL,
    SEND_RESET_LINK_URL,
    VERIFY_LINK_URL,
  } from "../apipath";
  import { message } from "antd";
  import AxiosApi from "../api";
  const api = new AxiosApi();
  export const loginAction = (val) => {
    console.log(val,"login values");
    return (dispatch) => {
      api.invoke(
        LOGIN_URL,
        "post",
        (data, success, statusCode) => {
          if (success) {
            if (statusCode === 200) {
              dispatch(loginReducer(data));
              window.location.reload();
            } else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        },
        val
      );
    };
  };
  export const logoutAction = (navigate) => {
    return (dispatch) => {
      api.invoke(LOGOUT_URL, "post", (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(logoutReducer());
            setTimeout(() => {
              navigate("/");
  
              window.location.reload();
            }, 100);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      });
    };
  };
  export const refreshAction = () => {
    return (dispatch) => {
      api.invoke(REFRESH_URL, "post", (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(refreshReducer(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      });
    };
  };
  export const sendResetLinkhAction = (val, navigate) => {
    return (dispatch) => {
      api.invoke(
        SEND_RESET_LINK_URL,
        "post",
        (data, success, statusCode) => {
          if (success) {
            if (statusCode === 200) {
              message.destroy();
              message.success(data.message, 3);
              navigate("/");
              dispatch(sendResetLinkReducer(data));
            } else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        },
        val
      );
    };
  };
  export const verifyLinkAction = (val) => {
    return (dispatch) => {
      api.invoke(
        VERIFY_LINK_URL,
        "post",
        (data, success, statusCode) => {
          if (success) {
            if (statusCode === 200) {
              dispatch(verifyLinkReducer(true));
              message.destroy();
              message.success(data.message);
            } else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        },
        val
      );
    };
  };
  export const resetPasswordAction = (val, navigate, setState) => {
    return (dispatch) => {
      api.invoke(
        RESET_PASSWORD_URL,
        "post",
        (data, success, statusCode) => {
          if (success) {
            if (statusCode === 200) {
              dispatch(resetPasswordReducer(data));
              message.destroy();
              message.success(data.message, 3);
              navigate("/");
              setState();
            } else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        },
        val
      );
    };
  };
  export const passwordResetAction = (val, callback) => {
    return (dispatch) => {
      api.invoke(
        PASSWORD_RESET_URL,
        "post",
        (data, success, statusCode) => {
          callback(false);
          if (success) {
            if (statusCode === 200) {
              message.destroy();
              message.success(data.message, 3);
              dispatch(passwordResetReducer(data));
              callback(true);
            } else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        },
        val
      );
    };
  };
  export const profileAction = () => {
    return (dispatch) => {
      api.invoke(PROFILE_URL, "get", (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(profileReducer(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      });
    };
  };
  export const profileUpdateAction = (val, callback) => {
    return (dispatch) => {
      api.invoke(
        PROFILE_UPDATE_URL,
        "post",
        (data, success, statusCode) => {
          callback(false);
          if (success) {
            if (statusCode === 200) {
              callback(true);
              message.destroy();
              message.success(data.message, 3);
              dispatch(profileUpdateReducer(val));
              dispatch(profileAction());
            } else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        },
        val
      );
    };
  };
  