import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Row, Image, Button, Drawer, theme } from "antd";
import {
  MenuOutlined,
  PieChartOutlined,
  TeamOutlined,
  ProfileOutlined,
  SettingOutlined,
  FileOutlined,
  MenuFoldOutlined,
  PayCircleOutlined,
  CloseOutlined
} from "@ant-design/icons";
import commonCssStyles from "../CommonCssStyles.module.css";

import IntelliSpidersLogo from "../../../Assets/Image/IntelliSpdersLogo.png";
import IntelliSpidersLogo2 from "../../../Assets/Image/IntelliSpdersLogo2.png";

const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const SideBar = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const location = useLocation();

  const [selectedKey, setSelectedKey] = useState(
    location.pathname !== "/" ? location.pathname : "/dashboard"
  );

  const [collapsed, setCollapsed] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    if (location) {
      if (selectedKey !== location.pathname) {
        if (location.pathname === "/") {
          setSelectedKey("/dashboard");
        } else if (location.pathname === "/dashboard") {
          setSelectedKey("/dashboard");
        } else if (location.pathname === "/staff") {
          setSelectedKey("/staff");
        } else if (location.pathname === "/staff/staffDetailPage") {
          setSelectedKey("/staff");
        } else if (location.pathname === "/staff/staffDetailProfile") {
          setSelectedKey("/staff");
        } else if (location.pathname === "/attendance") {
          setSelectedKey("/attendance");
        } else if (location.pathname === "/reports") {
          setSelectedKey("/reports");
        } else if (location.pathname === "/settings") {
          setSelectedKey("/settings");
        } else if (location.pathname === "/payroll") {
          setSelectedKey("/payroll");
        }
      }
    }
  }, [location, selectedKey]);

  useEffect(() => {
    const handleResize = () => {
      const isMobileOrTablet = window.matchMedia("(max-width: 992px)").matches;
      setIsMobileOrTablet(isMobileOrTablet);
    };

    handleResize(); 
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const closeSidebar = () => {
    if (isMobileOrTablet) {
      setShowDrawer(false);
    }
  };

  const handleMenuItemClick = () => {
    if (isMobileOrTablet) {
      setShowDrawer(false);
    }
  };

  const items = [
    getItem(
      "Dashboard",
      "/dashboard",
      <Link to="/dashboard">
        <PieChartOutlined style={{ fontSize: "14px" }} />
      </Link>
    ),
    getItem(
      "Staff",
      "/staff",
      <Link to="/staff">
        <TeamOutlined style={{ fontSize: "16px" }} />
      </Link>
    ),
    // getItem(
    //   "Payroll",
    //   "/payroll",
    //   <Link to="/payroll">
    //     {/* <TeamOutlined style={{ fontSize: "16px" }} /> */}
    //     <PayCircleOutlined style={{ fontSize: "16px" }} />
    //   </Link>
    // ),
    getItem(
      "Attendance",
      "/attendance",
      <Link to="/attendance">
        <ProfileOutlined style={{ fontSize: "14px" }} />
      </Link>
    ),
    getItem(
      "Reports",
      "/reports",
      <Link to="/reports">
        <FileOutlined style={{ fontSize: "14px" }} />
      </Link>
    ),
    getItem(
      "Settings",
      "/settings",
      <Link to="/settings">
        <SettingOutlined style={{ fontSize: "14px" }} />
      </Link>
    ),
  ];

  // Content for the Drawer
  const drawerContent = (
    <Menu
      mode="inline"
      selectedKeys={[selectedKey]}
      className={`${commonCssStyles.sideMenu} `}
      items={items}
      style={{
        marginTop: "30px",
      }}
    >
      {items.map((item) => (
        <Menu.Item
          key={item.key}
          icon={item.icon}
          onClick={handleMenuItemClick}
        >
          {item.children}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      {isMobileOrTablet && (
        <Header
          className={commonCssStyles.header}
          style={{
            padding: 0,
            background: "white", 
          }}
        >
          {/* Add your header content here */}
          <Row justify="space-between" align="middle">
            {/* <h1 style={{color:"red"}}>Your Header Title</h1> */}

            <Image
                preview={false}
                width={150}
                src={IntelliSpidersLogo}
                style={{ marginLeft: 20 }}
              />

            {/* Add any other content or buttons for the header */}
            {isMobileOrTablet && (
              <Button
                type="text"
                onClick={toggleDrawer}
                style={{ color: "#ffffff" }}
              >
                {showDrawer ? <MenuFoldOutlined /> : <MenuOutlined />}
              </Button>
            )}
          </Row>
        </Header>
      )}
      {!isMobileOrTablet && (
        <Sider
          collapsible
          className={commonCssStyles.sideMenu}
          theme="dark"
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="logo" />
          <Row justify={"center"} style={{ marginTop: 20 }}>
            {collapsed && collapsed ? (
              <Image preview={false} width={40} src={IntelliSpidersLogo2} />
            ) : (
              <Image preview={false} width={150} src={IntelliSpidersLogo} />
            )}
          </Row>
          <Menu
            mode="inline"
            className={`${commonCssStyles.sideMenu} `}
            selectedKeys={[selectedKey]}
            items={items}
            style={{
              marginTop: "30px",
            }}
          />
        </Sider>
      )}

      {isMobileOrTablet && (
        <Drawer
          title={
            <Row justify="space-between" align="middle">
              <Image
                preview={false}
                width={150}
                src={IntelliSpidersLogo}
                // style={{ marginBottom: 20 }}
              />
              <Button
                type="text"
                onClick={toggleDrawer}
                style={{ color: "#ffffff", margin: "0px", padding: "0px" }}
              >
                {/* {showDrawer ? <MenuFoldOutlined /> : <MenuOutlined />} */}
                <CloseOutlined />
              </Button>
            </Row>
          }
          placement="left"
          closable={false}
          onClose={closeSidebar}
          visible={showDrawer}
          // visible={true}
          width={300}
          headerStyle={{ background: "var(--sideMenu--header-clr)" }}
          bodyStyle={{ padding: 0, background: "var(--sideMenu--header-clr)" }}
        >
          {drawerContent} {/* Show the Drawer content */}
        </Drawer>
      )}
    </>
  );
};

export default SideBar;
