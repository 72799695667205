import { SETTINGS_HOLIDAY_POLICY_RULE_URL } from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
import { getHolidayDetailsSlice, getHolidayPolicySlice } from "../Slice/HolidayPolicySlice";
const api = new AxiosApi();


export const getHolidayPolicyAction = () => {
    return (dispatch) => {
      api.invoke(
        SETTINGS_HOLIDAY_POLICY_RULE_URL,
        "get",
        (data, success, statusCode) => {
          if (success) {
            if (statusCode === 200) dispatch(getHolidayPolicySlice(data));
            else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        }
      );
    };
  };
  
  
  export const postHolidayPolicyAction = (val,callback) => {
      return (dispatch) => {
        api.invoke(
            SETTINGS_HOLIDAY_POLICY_RULE_URL,
          "post",
          (data, success, statusCode) => {
              callback(false);
            if (success) {
              if (statusCode === 200) {
                  callback(true);
                message.destroy();
                message.success(data.message, 3);
                dispatch(getHolidayPolicyAction());
              } else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          },
          val
        );
      };
    };
  


    export const deleteHolidayPolicyAction = (id) => {
      return (dispatch) => {
        api.invoke(
          SETTINGS_HOLIDAY_POLICY_RULE_URL + "/" + id,
          "delete",
          (data, success, statusCode) => {
            // callback(false);
            if (success) {
              if (statusCode === 200) {
                // callback(true);
                message.destroy();
                message.success(data.message, 3);
                dispatch(getHolidayPolicyAction());
              } else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          }
        );
      };
    };



    export const getHolidayDetailsAction = (id) => {
      return (dispatch) => {
        api.invoke(SETTINGS_HOLIDAY_POLICY_RULE_URL+"/"+id, "get", (data, success, statusCode) => {
          if (success) {
            if (statusCode === 200) dispatch(getHolidayDetailsSlice(data));
            else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        });
      };
    };
  

    export const putHolidayPolicyAction = (val,callback) => {
      return (dispatch) => {
        api.invoke(
            SETTINGS_HOLIDAY_POLICY_RULE_URL + "/"+val.id,
          "put",
          (data, success, statusCode) => {
              callback(false);
            if (success) {
              if (statusCode === 200) {
                  callback(true);
                message.destroy();
                message.success(data.message, 3);
                dispatch(getHolidayPolicyAction());
              } else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          },
          val
        );
      };
    };
  