import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
// import loadingIcon from "./Slice/LoadingIconSlice";

import authentication from "./Slice/AuthenticationSlice"
import settings from './Slice/SettingsSlice'
import shifts from './Slice/ShiftsSlice'
import lateentry from './Slice/LateEntryRuleSlice'
import breaksRule from './Slice/BreaksRuleSlice'
import earlyExit from './Slice/EarlyExitRuleSlice'
import overtimeRule from './Slice/OverTimeRuleSlice'
import holidayPolicy from './Slice/HolidayPolicySlice'
import leavePolicy from './Slice/LeavePolicySlice'
import departments from './Slice/DepartmentsSlice'
import staff from './Slice/StaffSlice'
import attendance from './Slice/AttendanceSlice'


const rootReducer = combineReducers({
  authentication,
  settings,
  shifts,
  lateentry,
  breaksRule,
  earlyExit,
  overtimeRule,
  holidayPolicy,
  leavePolicy,
  departments,
  staff,
  attendance
});


const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: true,
});
export default store;
