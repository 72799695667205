import React, { useEffect } from "react";
import { useLocation } from "react-router";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import { Card, Col, Row } from "antd";
import { getStaffDetailsAction } from "../../../Reusable/Store/Action/StaffAction";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
function StaffOtherDetails({ getStaffDetailsAction, staffDetails }) {
  const location = useLocation();
  console.log(location);

  useEffect(() => {
    if (location && location.state && location.state.id) {
      getStaffDetailsAction(location.state.id);
    }
  }, [location?.state?.id]);

  console.log(staffDetails && staffDetails, "staffDetails staffDetails");
  return (
    <div>
      <Row justify={"space-between"}>
        <Col>
          <Link
            to="/staff/staff-detail-profile"
            state={{ id: location.state.id }}
          >
            <h1 className={commonCssStyles.BackButtonTxt}>
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>

      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt} style={{ margin: 0 }}>
            Other Details
          </h1>{" "}
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                {/* <UserOutlined
                  style={{ fontSize: "16px", marginRight: "20px" }}
                /> */}
                Staff's personal Information{" "}
              </p>
            }
            className="custom-card1"
          >
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>DOB</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.dob ? staffDetails.dob : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Gender</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.gender
                  ? staffDetails.gender
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Address</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.address
                  ? staffDetails.address
                  : "NA"}{" "}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                {/* <UserOutlined
                  style={{ fontSize: "16px", marginRight: "20px" }}
                /> */}
                Employment Information{" "}
              </p>
            }
            className="custom-card1"
          >
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b> Staff ID</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.empId ? staffDetails.empId : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b> Department</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.department ? staffDetails.department : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Date Of Joining</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.joiningDate
                  ? dayjs(staffDetails.joiningDate).format("DD/MM/YYYY")
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Designation</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.designation
                  ? staffDetails.designation
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b> UAN Number</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.uan ? staffDetails.uan : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>PAN Number</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.pan ? staffDetails.pan : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>ESI Number</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {staffDetails && staffDetails.esi ? staffDetails.esi : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    staffDetails: state.staff.staffDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaffDetailsAction: (id) => {
      dispatch(getStaffDetailsAction(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StaffOtherDetails);
