import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    overtime: null,
    overtimeDetails: null,

    overtimeAssign : null,

    earlyOvertimeAssign : null,

  };


  export const overTimeSlice = createSlice({
    name: "overtimeRule",
    initialState,
    reducers: {
      getOverTimeRuleSlice(state, action) {
        state.overtime = action.payload.result;
      },
      getOverTimeRuleDetaisSlice(state, action) {
        state.overtimeDetails = action.payload.result;
      },
     

      getOverTimeRuleAssignSlice(state, action) {
        state.overtimeAssign = action.payload.result;
      },
      
      getEarlyOverTimeRuleAssignSlice(state, action) {
        state.earlyOvertimeAssign = action.payload.result;
      },
    },
  });

  export const {
    getOverTimeRuleSlice,
    getOverTimeRuleDetaisSlice,
    getOverTimeRuleAssignSlice,
    getEarlyOverTimeRuleAssignSlice
  } = overTimeSlice.actions;
  export default overTimeSlice.reducer;