import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  accessToken: null,
  expires_in: 0,
  profileData: null,
  isverify: false,
};
export const dashboardSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    loginReducer: (state, action) => {
      localStorage.setItem("token", action.payload.api_key.original.token);
      localStorage.setItem("loginCount", action.payload.api_key.original.loginCount);
      localStorage.setItem(
        "expires_in",
        action.payload.api_key.original.expires_in
      );
      localStorage.setItem("date", new Date());
      state.accessToken = action.payload.api_key.original.token;
      state.expires_in = action.payload.api_key.original.expires_in;
    },
    logoutReducer: (state) => {
      localStorage.clear();
      state.accessToken = null;
      state.expires_in = 0;
    },
    refreshReducer: (state, action) => {
      localStorage.setItem("token", action.payload.api_key.original.token);
      localStorage.setItem(
        "expires_in",
        action.payload.api_key.original.expires_in
      );
      localStorage.setItem("date", new Date());
      state.accessToken = action.payload.api_key.original.token;
      state.expires_in = action.payload.api_key.original.expires_in;
    },
    sendResetLinkReducer: (state, action) => {},
    verifyLinkReducer: (state, action) => {
      localStorage.clear();
      state.accessToken = null;
      state.expires_in = 0;
      state.isverify = action.payload;
    },
    resetPasswordReducer: (state, action) => {},
    passwordResetReducer: (state, action) => {},
    profileReducer: (state, action) => {
      state.profileData = action.payload.result;
    },
    profileUpdateReducer: (state, action) => {
      state.profileData = { ...state.profileData, ...action.payload };
    },
  },
});
export const {
  loginReducer,
  logoutReducer,
  passwordResetReducer,
  profileReducer,
  profileUpdateReducer,
  refreshReducer,
  resetPasswordReducer,
  sendResetLinkReducer,
  verifyLinkReducer,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
