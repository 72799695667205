import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    holidayPolicy: null,
    holidayDetails:null
  };


  export const HolidayPolicySlice = createSlice({
    name: "holidayPolicy",
    initialState,
    reducers: {
      getHolidayPolicySlice(state, action) {
        state.holidayPolicy = action.payload.result;
      },
      getHolidayDetailsSlice(state, action) {
        state.holidayDetails = action.payload.result;
      },
    },
  });

  export const {
    getHolidayPolicySlice,
    getHolidayDetailsSlice
  } = HolidayPolicySlice.actions;
  export default HolidayPolicySlice.reducer;
