import React, { useEffect } from "react";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TimePicker,
} from "antd";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { postStaffAction } from "../../../Reusable/Store/Action/StaffAction";
import { getShiftsAction } from "../../../Reusable/Store/Action/ShiftsAction";

const { Option } = Select;

function AddContractStaff({getShiftsAction, shifts, postStaffAction }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    getShiftsAction();
  }, []);

  const onFinish = (values) => {
    console.log("Form values:", values);
    const data = {
      ...values,
      employmentCategory: "contract",
    };

    postStaffAction(data, (success) => {
      if (success) {
        navigate("/staff");
      }
    });
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link to="/staff">
            <h1 className={commonCssStyles.BackButtonTxt}>
              {" "}
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>

      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>
            Create Contract Staff
          </h1>
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col xs={24}>
          <Card>
            <Row>
              <Col md={24}>
                <Form
                  style={{ margin: "5px" }}
                  initialValues={{
                    shifttype: "fixed",
                    attendanceAccess: "0",
                    salaryDetailsAccess: "0",
                  }}
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                >
                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            First Name
                          </p>
                        }
                        name="firstName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Last Name
                          </p>
                        }
                        name="lastName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>Email</p>
                        }
                        name="email"
                        rules={[{ required: true, type: "email" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Password
                          </p>
                        }
                        name="password"
                        rules={[{ required: true }]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>Phone</p>
                        }
                        name="phone"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            WhatsApp
                          </p>
                        }
                        name="whatsApp"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Employment Type
                          </p>
                        }
                        name="employmentType"
                        rules={[{ required: true }]}
                      >
                        <Select className="addStaffForm">
                          <Option value="Contract">Contract</Option>
                          <Option value="Contract To Hire">
                            Contract To Hire
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Payment Type
                          </p>
                        }
                        name="paymentType"
                        rules={[{ required: true }]}
                      >
                        <Select className="addStaffForm">
                          <Option value="monthly">Monthly</Option>
                          <Option value="daily">Daily</Option>
                          <Option value="hourly">Hourly</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Salary Cycle
                          </p>
                        }
                        name="salaryCycle"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>Shift</p>
                        }
                        name="shift"
                      >
                        <Select className="addStaffForm">
                          {shifts &&
                            shifts.map((data, index) => {
                              return (
                                <Select.Option index={index} value={data.id}>
                                  {data.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Attendance Access
                          </p>
                        }
                        name="attendanceAccess"
                      >
                        <Select className="addStaffForm">
                          <Option value="1">Yes</Option>
                          <Option value="0">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Salary Details Access
                          </p>
                        }
                        name="salaryDetailsAccess"
                      >
                        <Select className="addStaffForm">
                          <Option value="1">Yes</Option>
                          <Option value="0">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>
                        Salary Amount
                      </p>
                    }
                    name="salaryAmount"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Row style={{ marginTop: "30px" }}>
                    <Col xs={24}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginTop: "20px" }}
                            className={commonCssStyles.addBtnStyles}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export const mapStateToProps = (state) => {
  return { shifts: state.shifts.shifts };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getShiftsAction: () => {
      dispatch(getShiftsAction());
    },
    postStaffAction: (val, callback) => {
      dispatch(postStaffAction(val, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContractStaff);
