import React, { useEffect, useRef, useState } from "react";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Typography,
  Upload,
  message,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  RightOutlined,
  UserOutlined,
  BankOutlined,
  SettingOutlined,
  CloseOutlined,
  EditOutlined,
  UploadOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { FilterOutlinedIcon } from "../../IconsComp/Icon";
import { Link, useNavigate } from "react-router-dom";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";
import { connect } from "react-redux";
import { logoutAction } from "../../../Reusable/Store/Action/AuthenticationAction";
import {
  getSettingsAction,
  getSettingsAttendanceAccessAction,
  getSettingsGeneralPayrollAction,
  getSettingsOverviewAction,
  postSettingsAction,
  postSettingsAttendanceAccessAction,
  postSettingsGeneralPayrollAction,
} from "../../../Reusable/Store/Action/SettingsAction";
import { putStaffProfileDetailsAction } from "../../../Reusable/Store/Action/StaffAction";

function Settings({
  settings,
  getSettingsAction,
  logoutAction,
  postSettingsGeneralPayrollAction,
  PayrollGeneralSettings,
  getSettingsGeneralPayrollAction,
  postSettingsAction,
  getSettingsOverviewAction,
  settingsOverview,
  getSettingsAttendanceAccessAction,
  settingsAttendanceAccess,
  postSettingsAttendanceAccessAction,
  putStaffProfileDetailsAction
}) {
  const [enableOrDisable, setEnableOrDisable] = useState("0");
  let btnref = useRef(null);
  const { TextArea } = Input;
  let navigate = useNavigate();

  useEffect(() => {
    getSettingsAction();
    getSettingsOverviewAction();
    getSettingsGeneralPayrollAction();
    getSettingsAttendanceAccessAction();
  }, []);

  useEffect(() => {
    setEnableOrDisable(
      PayrollGeneralSettings && PayrollGeneralSettings.markAbsentOnPreviousDay
    );
  }, [PayrollGeneralSettings]);

  console.log(
    settingsOverview && settingsOverview,
    "settingsOverview settingsOverview"
  );

  const handleChange = (info) => {
    if (info.target.files[0].size <= 5000000) {
      console.log(info.target.files[0], "info.target.files[0]");
      postSettingsAction({ logo: info.target.files[0] }, (success) => {
        if (success) {
          setBusinessSettings(false);
        }
      });
    } else {
      message.warning("This file should be less than 5 MB", 3);
    }
  };

  const fileList = [
    // {
    //   uid: '-1',
    //   name: 'yyy.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //   thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
  ];
  const [businessInfoEdit, setBusinessInfoEdit] = useState("");
  const [accountInfoEdit, setAccountInfoEdit] = useState("");

  // modals/popups useState

  const [attendanceMode, setAttendanceMode] = useState(false);

  const [staffAttendanceMode, setStaffAttendanceMode] = useState(false);

  const [trackInAndOutMode, setTrackInAndOutMode] = useState(false);

  const [markAbentMode, setMarkAbsentMode] = useState(false);

  const [businessSettings, setBusinessSettings] = useState(false);
  const [accountSettings, setAccountSettings] = useState(false);

  // modals/popups useState end

  const [trackInAndOutTime, setTrackInAndOutTime] = useState("0");
  const [noAttendanceWithoutPunchOut, setNoAttendanceWithooutPunchOut] =
    useState("0");

  // Modals Functions
  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        closeAttendancModal();
        closeStaffAttendanceModal();
        closeTrackInAndOutModal();
        closeMarkAsentModal();
        setBusinessInfoEdit("");
        setAccountInfoEdit("");
      }}
    />
  );

  // Attendance mode
  const showAttendancModal = () => {
    setAttendanceMode(true);
  };

  const closeAttendancModal = () => {
    setAttendanceMode(false);
  };
  // Attendance mode end

  // staffAttendanceMode mode
  const showStaffAttendanceModal = () => {
    setStaffAttendanceMode(true);
  };

  const closeStaffAttendanceModal = () => {
    setStaffAttendanceMode(false);
  };
  // staffAttendanceMode mode end

  // showTrackInandOutModal mode
  const showTrackInandOutModal = () => {
    setTrackInAndOutMode(true);
  };

  const closeTrackInAndOutModal = () => {
    setTrackInAndOutMode(false);
  };
  // showTrackInandOutModal mode end

  // markAbentModeModal mode
  const showMarkAbsentModal = () => {
    setMarkAbsentMode(true);
  };

  const closeMarkAsentModal = () => {
    setMarkAbsentMode(false);
  };
  // markAbentModeModal mode end

  // Business mode
  const showBusinessModal = () => {
    setBusinessSettings(true);
  };

  const closeBusinessModal = () => {
    setBusinessSettings(false);
  };
  // Business mode end

  // AccountSettings mode
  const showAccountSettingsModal = () => {
    setAccountSettings(true);
  };

  const closeAccountSettingsModal = () => {
    setAccountSettings(false);
  };
  // AccountSettings mode end

  // Modals Functions end

  //Test code
  const attendanceAccess = [
    { id: "554", name: "Demo", attendanceAccess: 0, phone: "1234" },
    { id: "5654", name: "Demo 1", attendanceAccess: 1, phone: "12345" },
    { id: "567687", name: "Demo 2", attendanceAccess: 0, phone: "1234" },
    { id: "98765", name: "Demo 3", attendanceAccess: 1, phone: "12534" },
    { id: "45325", name: "Demo 4", attendanceAccess: 1, phone: "12634" },
  ];

  const initialValues = {};
  settingsAttendanceAccess.forEach((item) => {
    initialValues[item.id] = item.attendanceAccess === 1;
  });

  const handleFormSubmit = (values) => {
    const checkedIds = Object.entries(values)
      .filter(([id, checked]) => checked)
      .map(([id]) => id);
    const data = {
      userId: checkedIds,
    };
    postSettingsAttendanceAccessAction(data, (success) => {
      if (success) {
        getSettingsOverviewAction();
        closeStaffAttendanceModal();
      }
    });
  };

  //Test code end

  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Settings</h1>
        </Col>
      </Row>

      {/* attendance settings  */}
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                <SettingOutlined
                  style={{ fontSize: "16px", marginRight: "20px" }}
                />
                Attendance Settings
              </p>
            }
            className="custom-card1"
          >
            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                showAttendancModal();
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Attendance Mode</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {PayrollGeneralSettings && PayrollGeneralSettings.attendanceMode
                  ? PayrollGeneralSettings.attendanceMode === "auto"
                    ? "Auto Attendance"
                    : PayrollGeneralSettings.attendanceMode === "manual"
                    ? "Manual Attendance"
                    : PayrollGeneralSettings.attendanceMode === "location"
                    ? "Staff Attendance with Location"
                    : PayrollGeneralSettings.attendanceMode ===
                      "selfie_location"
                    ? "Staff attendance with Selfie & Location"
                    : "NA"
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>
            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                showStaffAttendanceModal();
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Staff Attendance Access</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {settingsOverview &&
                  settingsOverview.attendanceAccess &&
                  settingsOverview.attendanceAccess}{" "}
                Staff
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>

            <Link to="/settings/shifts">
              <div className={commonCssStyles.commonTableStyles}>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  <b> Shift Settings</b>
                </p>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  {settingsOverview &&
                    settingsOverview.fixedShift &&
                    settingsOverview.fixedShift}{" "}
                  Fixed Shift,{" "}
                  {settingsOverview &&
                    settingsOverview.openShift &&
                    settingsOverview.openShift}{" "}
                  Open Shift,{" "}
                  {settingsOverview &&
                    settingsOverview.rotationalShift &&
                    settingsOverview.rotationalShift}{" "}
                  Rotational Shift
                </p>
                <p className={commonCssStyles.staffProfileDetailsBody}>
                  <RightOutlined />
                </p>
              </div>
            </Link>

            <Link to="/settings/attendance-automation">
              <div className={commonCssStyles.commonTableStyles}>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  <b> Automation Rules</b>
                </p>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  Track Late entry, Breaks, Early out, Overtime & Early Overtime
                </p>
                <p className={commonCssStyles.staffProfileDetailsBody}>
                  <RightOutlined />
                </p>
              </div>
            </Link>

            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                showTrackInandOutModal();
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Track In & Out Time</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {PayrollGeneralSettings &&
                PayrollGeneralSettings.trackInOutTime &&
                PayrollGeneralSettings.trackInOutTime == "1"
                  ? "Enable"
                  : "Disable"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>

            <Link to="/settings/comp-off-leave">
              <div className={commonCssStyles.commonTableStyles}>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  <b>Comp Off Leave</b>
                </p>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  {settingsOverview &&
                    settingsOverview.compoffLeave &&
                    settingsOverview.compoffLeave}{" "}
                  Staff
                </p>
                <p className={commonCssStyles.staffProfileDetailsBody}>
                  <RightOutlined />
                </p>
              </div>
            </Link>

            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                showMarkAbsentModal();
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b> Mark Absent on Previous Days</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {PayrollGeneralSettings &&
                PayrollGeneralSettings.markAbsentOnPreviousDay &&
                PayrollGeneralSettings.markAbsentOnPreviousDay == "1"
                  ? "Enable"
                  : "Disable"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      {/* attendance settings end  */}

      {/* Business Settings  */}
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                <SettingOutlined
                  style={{ fontSize: "16px", marginRight: "20px" }}
                />
                Business Settings
              </p>
            }
            className="custom-card1"
          >
            <Link to="/settings/holiday-policy">
              <div className={commonCssStyles.commonTableStyles}>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  <b>Holiday Policy</b>
                </p>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  {settingsOverview &&
                    settingsOverview.holidayPolicy &&
                    settingsOverview.holidayPolicy}{" "}
                  Template
                </p>
                <p className={commonCssStyles.staffProfileDetailsBody}>
                  <RightOutlined />
                </p>
              </div>
            </Link>

            <Link to="/settings/leave-policy">
              <div className={commonCssStyles.commonTableStyles}>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  <b>Leave Policy</b>
                </p>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  {settingsOverview &&
                    settingsOverview.leavePolicy &&
                    settingsOverview.leavePolicy}{" "}
                </p>
                <p className={commonCssStyles.staffProfileDetailsBody}>
                  <RightOutlined />
                </p>
              </div>
            </Link>

            <Link to="/settings/departments">
              <div className={commonCssStyles.commonTableStyles}>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  <b>Departments</b>
                </p>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  {settingsOverview &&
                    settingsOverview.department &&
                    settingsOverview.department}{" "}
                </p>
                <p className={commonCssStyles.staffProfileDetailsBody}>
                  <RightOutlined />
                </p>
              </div>
            </Link>

            <Link to="/settings/staff-weekly-holidays">
              <div className={commonCssStyles.commonTableStyles}>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  <b>Weekly Holidays</b>
                </p>
                <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                  {settingsOverview &&
                    settingsOverview.weeklyHolidays &&
                    settingsOverview.weeklyHolidays}{" "}
                </p>
                <p className={commonCssStyles.staffProfileDetailsBody}>
                  <RightOutlined />
                </p>
              </div>
            </Link>
          </Card>
        </Col>
      </Row>
      {/* Business Settings end  */}

      {/* Salary settings  */}
      {/* <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                <SettingOutlined
                  style={{ fontSize: "16px", marginRight: "20px" }}
                />
                Salary Settings
              </p>
            }
            className="custom-card1"
          >
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Salary Calculation Logic
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Exclude Weekly Offs
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Manage Salary Templates
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                1 Template
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>

            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Salary Details Access to Staff
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Disabled
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>
          </Card>
        </Col>
      </Row> */}
      {/* Salary settings end  */}

      {/* Business  settings  */}
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <Row justify={"space-between"}>
                <Col>
                  <p className={commonCssStyles.staffProfileDetailsHeading}>
                    <SettingOutlined
                      style={{ fontSize: "16px", marginRight: "20px" }}
                    />
                    Business Info
                  </p>
                </Col>
              </Row>
            }
            className="custom-card1"
          >
            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                setBusinessInfoEdit("Business Name");
                setBusinessSettings(true);
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b> Business Name</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {settings && settings.businessName && settings.businessName
                  ? settings.businessName
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>

            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                setBusinessInfoEdit("Business Bank Account");
                setBusinessSettings(true);
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b> Business Bank Account</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Enter Details for Instant Refunds
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>

            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                setBusinessInfoEdit("Business Logo");
                setBusinessSettings(true);
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Business Logo</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {settings && settings.logo ? (
                  <Image
                    preview={false}
                    // width={150}
                    height={50}
                    src={settings.logo}
                    fallback={<Avatar />}
                  />
                ) : (
                  "NA"
                )}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>

            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                setBusinessInfoEdit("Business Address");
                setBusinessSettings(true);
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Business Address</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {/* Not Added */}
                {settings &&
                settings.businessAddress &&
                settings.businessAddress
                  ? settings.businessAddress
                  : "NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      {/* Business  settings end  */}

      {/* Account Settings  */}
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <Row justify={"space-between"}>
                <Col>
                  <p className={commonCssStyles.staffProfileDetailsHeading}>
                    <SettingOutlined
                      style={{ fontSize: "16px", marginRight: "20px" }}
                    />
                    Account Settings
                  </p>
                </Col>
              </Row>
            }
            className="custom-card1"
          >
            <div
              className={commonCssStyles.commonTableStyles}
              // onClick={() => {
              //   setAccountInfoEdit("Name");
              //   showAccountSettingsModal(true);
              // }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b> Name</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
              {settingsOverview &&
                  settingsOverview.userInfo &&
                  settingsOverview.userInfo.firstName?settingsOverview.userInfo.firstName:"NA"}
                  {" "}
                  {settingsOverview &&
                  settingsOverview.userInfo &&
                  settingsOverview.userInfo.lastName?settingsOverview.userInfo.lastName:"NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>
            <div
              className={commonCssStyles.commonTableStyles}
              // onClick={() => {
              //   setAccountInfoEdit("Phone Number");
              //   showAccountSettingsModal(true);
              // }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b> Phone Number</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
              {settingsOverview &&
                  settingsOverview.userInfo &&
                  settingsOverview.userInfo.phone?settingsOverview.userInfo.phone:"NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>

            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Email Address</b>
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                {settingsOverview &&
                  settingsOverview.userInfo &&
                  settingsOverview.userInfo.email?settingsOverview.userInfo.email:"NA"}
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                {/* <RightOutlined /> */}
              </p>
            </div>

            {/* <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                setAccountInfoEdit("Business Type");
                showAccountSettingsModal(true);
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Business Type
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Not Added
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>
            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                setAccountInfoEdit("Add/Delete Business");
                showAccountSettingsModal(true);
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Add/Delete Business
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Not Added
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div>

            <div
              className={commonCssStyles.commonTableStyles}
              onClick={() => {
                setAccountInfoEdit("KYB");
                showAccountSettingsModal(true);
              }}
            >
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                KYB
              </p>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                Complete KYB to avail online payment services
              </p>
              <p className={commonCssStyles.staffProfileDetailsBody}>
                <RightOutlined />
              </p>
            </div> */}
          </Card>
        </Col>
      </Row>
      {/* Account Settings end  */}

      {/* Others  settings  */}
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                Others
              </p>
            }
            className="custom-card1"
          >
            <div className={commonCssStyles.commonTableStyles}>
              <p className={commonCssStyles.staffProfileDetailsBodyFixed}>
                <b>Logout</b>
              </p>

              <Button
                type="primary"
                className={commonCssStyles.logoutBtnStyles}
                onClick={() => {
                  logoutAction(navigate);
                }}
              >
                <Row
                  style={{ gap: "10px", marginTop: "1px" }}
                  justify={"center"}
                >
                  <Col>
                    <p className={`${commonCssStyles.logoutTextStyle} `}>
                      Logout
                    </p>
                  </Col>
                </Row>
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      {/* Others  settings end  */}

      {/* all modals/popups */}

      {/* Attendance mode */}
      <Modal
        title="Attendance Settings"
        open={attendanceMode}
        closeIcon={closeIcon}
        bodyStyle={{
          // maxHeight: "calc(100vh - 210px)",
          overflow: "auto",
        }}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="attendanceModeAutoManual"
            >
              Save & Continue
            </Button>
          </div>,
        ]}
      >
        <Form
          id="attendanceModeAutoManual" // Assi
          onFinish={(values) => {
            console.log(values, "form submitted value");
            postSettingsGeneralPayrollAction(values, (success) => {
              if (success) {
                setAttendanceMode(false);
              }
            });
          }}
          initialValues={{
            attendanceMode:
              PayrollGeneralSettings && PayrollGeneralSettings.attendanceMode,
          }}
        >
          <Form.Item name="attendanceMode">
            <Radio.Group>
              <Row style={{ marginTop: "10px" }}>
                <Col>
                  {/* <p className={commonCssStyles.modalSubHeadingStyles}>
                    Auto Attendance
                  </p> */}
                  {/* <Card
                    className={`${commonCssStyles.settingsModalCard} custom-card--settings-modal`}
                  >
                    <Radio value="auto">
                      <span>
                        <p className={commonCssStyles.modalDataTxtStyles}>
                          <b>Mark Present by Default </b>
                        </p>
                        <p className={commonCssStyles.modalDataTxtStyles}>
                          Default auto present, can be changed manually
                        </p>
                      </span>
                    </Radio>
                  </Card>
                  <p
                    className={commonCssStyles.modalSubHeadingStyles}
                    style={{ marginTop: "10px" }}
                  >
                    Manual Attendance
                  </p> */}
                  <Card
                    className={`${commonCssStyles.settingsModalCard} custom-card--settings-modal`}
                  >
                    <Radio value="manual">
                      <span>
                        <p className={commonCssStyles.modalDataTxtStyles}>
                          <b>Manual Attendance</b>
                        </p>
                        <p className={commonCssStyles.modalDataTxtStyles}>
                          Attendance is neutral by default, should be marked
                          manually
                        </p>
                      </span>
                    </Radio>
                  </Card>
                  <Card
                    className={`${commonCssStyles.settingsModalCard} custom-card--settings-modal`}
                  >
                    <Radio value="location">
                      <span>
                        <p className={commonCssStyles.modalDataTxtStyles}>
                          <b>Staff Attendance with Location</b>
                        </p>
                        <p className={commonCssStyles.modalDataTxtStyles}>
                          Staff can mark their own attendance. Location will be
                          captured automatically
                        </p>
                      </span>
                    </Radio>
                  </Card>
                  <Card
                    className={`${commonCssStyles.settingsModalCard} custom-card--settings-modal`}
                  >
                    <Radio value="selfie_location">
                      <span>
                        <p className={commonCssStyles.modalDataTxtStyles}>
                          <b>Staff attendance with Selfie & Location </b>
                        </p>
                        <p className={commonCssStyles.modalDataTxtStyles}>
                          Staff can mark their own attendance with Selfie.
                          Location will be captured automatically
                        </p>
                      </span>
                    </Radio>
                  </Card>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
          {/* Rest of the modal content */}
        </Form>
      </Modal>
      {/* Attendance mode end  */}

      {/* staffAttendanceMode mode */}
      <Modal
        title="Self Attendance Access"
        open={staffAttendanceMode}
        closeIcon={closeIcon}
        closable={false}
        bodyStyle={{
          // maxHeight: "calc(100vh - 210px)",
          overflow: "auto",
        }}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                closeStaffAttendanceModal();
              }}
            >
              Back
            </Button>
            ,
            <Button
              key="applyFilter4"
              type="primary"
              htmlType="submit"
              className={commonCssStyles.applyFilterStyles}
              form="staffAttendance"
            >
              Save & Continue
            </Button>
            ,
          </div>,
        ]}
      >
        <Form
          id="staffAttendance"
          onFinish={handleFormSubmit}
          layout="vertical"
          initialValues={initialValues}
        >
          <Row style={{ marginTop: "10px" }}>
            <Col xs={24}>
              <p className={commonCssStyles.modalSubHeadingStyles}>
                Selfie & Location
              </p>
              {settingsAttendanceAccess &&
                settingsAttendanceAccess.map((item, index) => (
                  <>
                    <Row
                      key={item.id}
                      justify="space-between"
                      style={{
                        borderBottom:
                          index === settingsAttendanceAccess.length - 1
                            ? "none"
                            : "1px solid #e8e8e8",
                        paddingBottom: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <Col>
                        <Form.Item
                          name={item.id}
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox name={item.id}>
                            <span className={commonCssStyles.formLabelTxt}>
                              {item.name}
                            </span>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col>
                        <span className={commonCssStyles.formLabelTxt}>
                          {item.phone}
                        </span>
                      </Col>
                    </Row>
                  </>
                ))}
            </Col>
          </Row>
          {/* Rest of the modal content */}
        </Form>
      </Modal>
      {/* staffAttendanceMode mode end  */}

      {/* trackInAndOutMode  */}
      <Modal
        title="Track In/Out Time"
        open={trackInAndOutMode}
        closeIcon={closeIcon}
        bodyStyle={{
          // maxHeight: "calc(100vh - 210px)",
          overflow: "auto",
        }}
        footer={[
          <div key="modalFooter" style={{ padding: "0px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="attendanceFormTrackInOut"
            >
              Done
            </Button>
          </div>,
        ]}
      >
        <Form
          id="attendanceFormTrackInOut"
          onFinish={(values) => {
            const data = {
              trackInOutTime: trackInAndOutTime,
              noAttendanceWithoutPunchOut: noAttendanceWithoutPunchOut,
            };
            console.log(data, "form submitted value");

            postSettingsGeneralPayrollAction(data, (success) => {
              if (success) {
                getSettingsOverviewAction();
                setTrackInAndOutMode(false);
              }
            });
          }}
          layout="vertical"
          initialValues={
            {
              // trackInOutTime: true,
              // noAttendanceWithoutPunchOut: false,
            }
          }
        >
          <div className={commonCssStyles.AddStaffCardWithoutoxShadow}>
            <Form.Item name="trackInOutTime">
              <Row>
                <Col xs={20}>
                  <h1
                    className={commonCssStyles.AddStaffcardHeadingTxt}
                    style={{ margin: "0px" }}
                  >
                    <b>Track In & Out Time</b>
                  </h1>
                  <h1
                    className={commonCssStyles.AddStaffcardSubHeadingTxt}
                    style={{ margin: "0px" }}
                  >
                    Record both In & Out time for all staff
                  </h1>
                </Col>
                <Col xs={4}>
                  {PayrollGeneralSettings &&
                  PayrollGeneralSettings.trackInOutTime &&
                  PayrollGeneralSettings.trackInOutTime === "1" ? (
                    <Switch
                      defaultChecked={true}
                      onChange={(val) => {
                        setTrackInAndOutTime(val);
                      }}
                    />
                  ) : (
                    <Switch
                      onChange={(val) => {
                        setTrackInAndOutTime(val);
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Form.Item>
            <hr className={commonCssStyles.hrSeperatorDropDwn} />
            <Form.Item name="noAttendanceWithoutPunchOut">
              <Row>
                <Col xs={20}>
                  <h1
                    className={commonCssStyles.AddStaffcardHeadingTxt}
                    style={{ margin: "0px" }}
                  >
                    <b>No attendance without punch-out</b>
                  </h1>
                  <h1
                    className={commonCssStyles.AddStaffcardSubHeadingTxt}
                    style={{ margin: "0px" }}
                  >
                    Punch out is required to mark attendance
                  </h1>
                  {/* <p
                    className={commonCssStyles.AddStaffcardSubHeadingTxt}
                    style={{ fontSize: "12px", marginTop: "8px" }}
                  >
                    Note: Enable 'Track In & Out Time' setting first
                  </p> */}
                </Col>
                <Col xs={4}>
                  {PayrollGeneralSettings &&
                  PayrollGeneralSettings.noAttendanceWithoutPunchOut &&
                  PayrollGeneralSettings.noAttendanceWithoutPunchOut === "1" ? (
                    <Switch
                      defaultChecked={true}
                      onChange={(val) => {
                        setNoAttendanceWithooutPunchOut(val);
                      }}
                    />
                  ) : (
                    <Switch
                      onChange={(val) => {
                        setNoAttendanceWithooutPunchOut(val);
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Form.Item>
          </div>

          {/* Rest of the modal content */}
        </Form>
      </Modal>
      {/* trackInAndOutMode end  */}

      {/* mark Absent on previous days  */}
      <Modal
        title="Mark Absent on Previous Days"
        open={markAbentMode}
        closeIcon={closeIcon}
        bodyStyle={{
          // maxHeight: "calc(100vh - 210px)",
          overflow: "auto",
        }}
        footer={[
          <div key="modalFooter" style={{ padding: "0px 0px 15px 0px" }}>
            {enableOrDisable && enableOrDisable == "0" ? (
              <Button
                key="applyFilter"
                type="primary"
                htmlType="submit"
                // onClick={closeAttendancModal}
                className={commonCssStyles.applyFilterStylesFull}
                form="attendanceFormEnableDisable"
                onClick={() => {
                  postSettingsGeneralPayrollAction(
                    { markAbsentOnPreviousDay: "1" },
                    (success) => {
                      if (success) {
                        setMarkAbsentMode(false);
                      }
                    }
                  );
                }}
              >
                Enable
              </Button>
            ) : (
              <Button
                key="applyFilter"
                type="primary"
                htmlType="submit"
                // onClick={closeAttendancModal}
                className={commonCssStyles.disableBtnStylesFull}
                form="attendanceForm"
                onClick={() => {
                  postSettingsGeneralPayrollAction(
                    { markAbsentOnPreviousDay: "0" },
                    (success) => {
                      if (success) {
                        setMarkAbsentMode(false);
                      }
                    }
                  );
                }}
              >
                Disable
              </Button>
            )}
          </div>,
        ]}
      >
        <Form
          id="attendanceFormEnableDisable" // Assi
          onFinish={(values) => {
            const enableData = {
              markAbsentOnPreviousDay: enableOrDisable,
            };
            console.log(enableData, " enableData form submitted value");
          }}
          layout="vertical"
        >
          <Form.Item name="markAbsentOnPreviousDay">
            <Row style={{ marginTop: "10px" }}>
              <Col>
                <p className={commonCssStyles.modalSubHeadingStyles}>
                  Enable this feature to mark absent on previous days
                </p>

                <ul>
                  <li>
                    <p className={commonCssStyles.modalDataTxtStyles}>
                      Old staff attendance with no action on that day will be
                      marked as absent
                    </p>
                  </li>
                  <li>
                    <p className={commonCssStyles.modalDataTxtStyles}>
                      Attendance for the last two days will not be affected.
                      Days before those two days will be marked as absent
                    </p>
                  </li>
                </ul>
              </Col>
            </Row>
          </Form.Item>
          {/* Rest of the modal content */}
        </Form>
      </Modal>
      {/* mark Absent on previous days end  */}

      <Modal
        // title="Edit Business Info"

        title={
          businessInfoEdit === "Business Name"
            ? "Edit Business Name"
            : businessInfoEdit === "Business Bank Account"
            ? "Edit Bank Account Details"
            : businessInfoEdit === "Business Logo"
            ? "Edit Business Logo"
            : businessInfoEdit === "Business Address"
            ? "Edit Business Address"
            : "Default Title"
        }
        closeIcon={closeIcon}
        visible={businessSettings}
        onOk={showBusinessModal}
        onCancel={closeBusinessModal}
        footer={
          businessInfoEdit === "Business Logo"
            ? ""
            : [
                <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
                  <Button
                    key="resetFilter"
                    className={commonCssStyles.resetFilterStyles}
                    onClick={() => {
                      closeBusinessModal();
                    }}
                  >
                    Back
                  </Button>
                  ,
                  <Button
                    key="applyFilter"
                    type="primary"
                    htmlType="submit"
                    form="businessForm"
                    className={commonCssStyles.applyFilterStyles}
                  >
                    Save & Continue
                  </Button>
                  ,
                </div>,
              ]
        }
      >
        <Row>
          <Col xs={24}>
            <Form
              id="businessForm"
              layout="vertical"
              onFinish={(val) => {
                postSettingsAction(val, (success) => {
                  if (success) {
                    getSettingsOverviewAction();
                    setBusinessSettings(false);
                   
                  }
                });
              }}
              initialValues={{
                businessName: settings?.businessName,

                accountHolderName: settings?.accountHolderName,
                accountNumber: settings?.accountNumber,
                confirmAccountNumber: settings?.confirmAccountNumber,
                ifscCode: settings?.ifscCode,

                businessAddress: settings?.businessAddress,
                businessCity: settings?.businessCity,
                businessState: settings?.businessState,
                businessCountry: settings?.businessCountry,
                businessPincode: settings?.businessPincode,
              }}
            >
              {businessInfoEdit && businessInfoEdit == "Business Name" && (
                <Form.Item
                  label="Business Name"
                  name="businessName"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              )}

              {businessInfoEdit &&
                businessInfoEdit == "Business Bank Account" && (
                  <>
                    <Form.Item
                      label="Account Holder Name"
                      name="accountHolderName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the account holder's name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Account Number"
                      name="accountNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the account number",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Confirm Account Number"
                      name="confirmAccountNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm the account number",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("accountNumber") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "The two account numbers do not match"
                            );
                          },
                        }),
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="IFSC Code"
                      name="ifscCode"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the IFSC code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                )}

              {businessInfoEdit && businessInfoEdit == "Business Logo" && (
                <Form.Item
                  name="logo"
                  rules={[
                    {
                      message: "Logo is Required",
                    },
                  ]}
                  style={{ borderRadius: "1px", textAlign: "center" }}
                >
                  <div hidden>
                    <input
                      type="file"
                      name="fileUpload"
                      onChange={handleChange}
                      ref={(input) => {
                        btnref = input;
                      }}
                    />
                  </div>
                  {settings.logo ? (
                    <div
                      style={{
                        marginTop: "20px",
                        border: "2px dashed var(--tabs-heading-txt-clr)",
                        borderRadius: "6px",
                        height: "180px",
                        padding: "40px",
                      }}
                    >
                      <Image
                        onClick={(e) => {
                          e.preventDefault();
                          btnref.click();
                          return false;
                        }}
                        alt={<CameraOutlined />}
                        //  style={{ width: "100%", height: "150px" }}
                        width={150}
                        preview={false}
                        src={settings.logo}
                      />
                      <span
                        onClick={(e) => {
                          const data = {
                            logo: null,
                            logoCheck: "false",
                          };
                          postSettingsAction(data, (success) => {
                            if (success) {
                              setBusinessSettings(false);
                            }
                          });

                          return false;
                        }}
                        style={{
                          position: "absolute",
                          top: "40px",
                          right: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <CloseOutlined />
                      </span>
                      <span
                        style={{
                          position: "absolute",
                          top: "60px",
                          right: "10px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          btnref.click();
                          return false;
                        }}
                      >
                        <EditOutlined />
                      </span>
                    </div>
                  ) : (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        btnref.click();
                        return false;
                      }}
                      style={{
                        marginTop: "25px",
                        textAlign: "center",
                        backgroundColor: "var(--input-color)",
                        padding: "20px",
                        height: "180px",
                        border: "2px dashed var(--tabs-heading-txt-clr)",
                        borderRadius: "5px",
                      }}
                    >
                      <div style={{ marginTop: "27px" }}>
                        <UploadOutlined style={{ fontSize: "30px" }} />
                      </div>
                      <div style={{ marginTop: 8 }}>
                        <p>Upload image</p>
                      </div>
                    </div>
                  )}
                </Form.Item>
              )}

              {businessInfoEdit && businessInfoEdit == "Business Address" && (
                <>
                  <Form.Item
                    label="Address"
                    name="businessAddress"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                  </Form.Item>
                  <Form.Item
                    label="City"
                    name="businessCity"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="State"
                    name="businessState"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Country"
                    name="businessCountry"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Pincode"
                    name="businessPincode"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={
          accountInfoEdit === "Name"
            ? "Edit Name"
            : accountInfoEdit === "Phone Number"
            ? "Edit Phone Number"
            : accountInfoEdit === "Email Address"
            ? "Email Address"
            : accountInfoEdit === "Business Type"
            ? "Business Type"
            : accountInfoEdit === "Add/Delete Business"
            ? "Add/Delete Business"
            : accountInfoEdit === "KYB"
            ? "KYB"
            : "Default Title"
        }
        closeIcon={closeIcon}
        visible={accountSettings}
        onOk={showAccountSettingsModal}
        onCancel={closeAccountSettingsModal}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                closeAccountSettingsModal();
              }}
            >
              Back
            </Button>
            ,
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              form="editAccountInfoForm"
              className={commonCssStyles.applyFilterStyles}
            >
              Save & Continue
            </Button>
            ,
          </div>,
        ]}
      >
        <Row>
          <Col xs={24}>
            <Form
              id="editAccountInfoForm"
              layout="vertical"
            
              onFinish={(val) => {
                console.log(val);
                const Data = {
                  id:settingsOverview?.userInfo?.id,
                  updateType:"personal",
                  _method:"PUT",
                  firstName:val.fullName,
                  phone:val.phone,
                  email:val.email,
                }
                putStaffProfileDetailsAction(Data, (success) => {
                  if (success) {
                    getSettingsOverviewAction();
                    closeAccountSettingsModal(false);
                  }
                });
              }}
              initialValues={{
                fullName: settingsOverview?.userInfo?.fullName,
                phone: settingsOverview?.userInfo?.phone,
                email: settingsOverview?.userInfo?.email,
              }}
            >
              {accountInfoEdit && accountInfoEdit == "Name" && (
                <Form.Item
                  label="Name"
                  name="fullName"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              )}
              {accountInfoEdit && accountInfoEdit == "Phone Number" && (
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              )}
              {accountInfoEdit && accountInfoEdit == "Email Address" && (
                <Form.Item
                  label="Email Address"
                  name="contactPersonEmail"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              )}
            </Form>
          </Col>
        </Row>
      </Modal>

      {/* all modals/popups end  */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    settingsOverview: state.settings.settingsOverview,
    PayrollGeneralSettings: state.settings.PayrollGeneralSettings,

    settingsAttendanceAccess: state.settings.settingsAttendanceAccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: (navigate) => {
      dispatch(logoutAction(navigate));
    },
    getSettingsAction: () => {
      dispatch(getSettingsAction());
    },
    getSettingsOverviewAction: () => {
      dispatch(getSettingsOverviewAction());
    },
    postSettingsAction: (val, callback) => {
      dispatch(postSettingsAction(val, callback));
    },

    getSettingsGeneralPayrollAction: () => {
      dispatch(getSettingsGeneralPayrollAction());
    },
    postSettingsGeneralPayrollAction: (val, callback) => {
      dispatch(postSettingsGeneralPayrollAction(val, callback));
    },

    //for settings staff attendance
    getSettingsAttendanceAccessAction: () => {
      dispatch(getSettingsAttendanceAccessAction());
    },

    postSettingsAttendanceAccessAction: (val, callback) => {
      dispatch(postSettingsAttendanceAccessAction(val, callback));
    },

    //for settings staff attendance end


    //Edit user info 
    putStaffProfileDetailsAction: (val, callback) => {
      dispatch(putStaffProfileDetailsAction(val, callback));
    },
    //Edit user info end 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
