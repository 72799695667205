import React from 'react'

function Table() {
  return (
    <div>

      
    </div>
  )
}

export default Table