import React, { useEffect, useState } from "react";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Modal,
  Row,
  Select,
} from "antd";

import { UserAddOutlined, DownOutlined } from "@ant-design/icons";
import { FilterOutlinedIcon } from "../../IconsComp/Icon";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";
import { useNavigate } from "react-router";
import { getStaffFilterAction } from "../../../Reusable/Store/Action/StaffAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getDepartmentAction } from "../../../Reusable/Store/Action/DepartmentsAction";

function Staff({
  getStaffFilterAction,
  staffs,
  departments,
  getDepartmentAction,
}) {
  useEffect(() => {
    getStaffFilterAction({});
    getDepartmentAction();
  }, []);

  const [form] = Form.useForm();

  console.log(staffs && staffs, "staffs");

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.submit();
  };

  const resetFilter = () => {
    getStaffFilterAction({});
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = (values) => {
    getStaffFilterAction(values);
    setIsModalOpen(false);
    console.log("Form values:", values);
  };

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Row justify={"space-between"}>
          <Col>
            <h1 className={commonCssStyles.PageHeadingTxt}>
              Staff Payment Summary
            </h1>
          </Col>
          <Col>
            <Row style={{ gap: "20px" }}>
              <Col>
                <Dropdown
                  placement="bottomLeft"
                  trigger={["click"]}
                  overlayStyle={{ width: "300px" }}
                  overlay={
                    <div className={commonCssStyles.AddStaffCard}>
                      <h1 className={commonCssStyles.AddStaffcardHeadingTxt}>
                        Regular Staff
                      </h1>
                      <h1 className={commonCssStyles.AddStaffcardSubHeadingTxt}>
                        With Salary Components (Basic, HRA, PF, ESI etc.)
                      </h1>

                      <Button
                        style={{ marginTop: "10px", width: "100%" }}
                        className={commonCssStyles.secondaryBtnStyles}
                        onClick={() => {
                          navigate("/staff/add/regular");
                        }}
                      >
                        Add Regular Staff
                      </Button>

                      <hr className={commonCssStyles.hrSeperatorDropDwn} />

                      <h1 className={commonCssStyles.AddStaffcardHeadingTxt}>
                        Contractual Staff
                      </h1>
                      <h1 className={commonCssStyles.AddStaffcardSubHeadingTxt}>
                        Only Gross Salary (Monthly, Weekly, Daily, Hourly,
                        Work-Basis)
                      </h1>

                      <Button
                        style={{ marginTop: "10px", width: "100%" }}
                        className={commonCssStyles.secondaryBtnStyles}
                        onClick={() => {
                          navigate("/staff/add/Contract");
                        }}
                      >
                        Add Contractual Staff
                      </Button>
                    </div>
                  }
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <div>
                      <Button
                        type="primary"
                        className={commonCssStyles.addBtnStyles}
                      >
                        <UserAddOutlined /> Add Staff <DownOutlined />
                      </Button>
                    </div>
                  </a>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ marginTop: "40px" }} justify={"space-between"}>
          <Col>
            <Button
              type="primary"
              className={commonCssStyles.filterBtnStyles}
              onClick={showModal}
            >
              <Row style={{ gap: "10px", marginTop: "1px" }} justify={"center"}>
                <Col>
                  <div style={{ transform: "translateY(2px)" }}>
                    <FilterOutlinedIcon color={"var(--filter--icon-clr)"} />
                  </div>
                </Col>
                <Col>
                  <p className={`${commonCssStyles.filterAndSortTextStyle} `}>
                    Filter
                  </p>
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>

        <Row style={{ marginTop: "40px" }}>
          <h1 className={commonCssStyles.TableSecHeadingTxt}>
            Staff &#40;{" "}
            {staffs && staffs.total && staffs.total > 0 ? staffs.total : 0}{" "}
            &#41;
          </h1>
        </Row>

        <div>
          {staffs &&
            staffs.data &&
            staffs.data.map((data, index) => {
              return (
                <>
                  <Card
                    key={index}
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        <Row style={{ gap: "20px", marginTop: "10px" }}>
                          <Col>
                            <Image
                              preview={false}
                              width={20}
                              height={20}
                              src={DefaultProfileImg}
                            />
                          </Col>
                          <Link
                            to={"/staff/staff-detail-page"}
                            state={{ id: data.id }}
                          >
                            <Col>
                              <p
                                className={commonCssStyles.tableDataBluClrTxt}
                                // onClick={handleClick}
                              >
                                {data.fullName}
                              </p>
                            </Col>
                          </Link>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </>
              );
            })}
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "60px" }}>
  {staffs && staffs.data && staffs.data.length > 0 ? null : <h1 className={commonCssStyles.PageHeadingTxt} >No Data</h1>}
</div>

      </div>

      {/* Filter Modal  */}

      <Modal
        title="Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={[
          <Button
            key="resetFilter"
            onClick={resetFilter}
            className={commonCssStyles.resetFilterStyles}
          >
            Reset Filter
          </Button>,
          <Button
            key="applyFilter"
            type="primary"
            htmlType="submit"
            onClick={handleOk}
            className={commonCssStyles.applyFilterStyles}
          >
            Apply Filter
          </Button>,
        ]}
      >
        <div>
          <Form
            initialValues={{
              sortBy: "",
              employmentType: [],
              department: [],
              shiftType: [],
            }}
            form={form}
            onFinish={handleFormSubmit}
            layout="vertical"
          >
            <Form.Item
              name="sortBy"
              label={<p className={commonCssStyles.formLabelTxt}>Sort By</p>}
            >
              <Select style={{ width: "100%" }}>
                <Select.Option value="employmentType">
                  Employment Type
                </Select.Option>
                <Select.Option value="department">Department</Select.Option>
                <Select.Option value="name">Name</Select.Option>
                <Select.Option value="createdDate">Created Date</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="department"
              label={<p className={commonCssStyles.formLabelTxt}>Department</p>}
            >
              <Select mode="multiple" style={{ width: "100%" }}>
                {departments &&
                  departments.map((x, i) => {
                    return (
                      <Select.Option key={i} value={x.departmentName}>
                        {x.departmentName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              name="employmentType"
              label={
                <p className={commonCssStyles.formLabelTxt}>Employment Type</p>
              }
            >
              <Select mode="multiple" style={{ width: "100%" }}>
                <Select.Option value="Part time">Part Time</Select.Option>
                <Select.Option value="Full Time">Full Time</Select.Option>
                <Select.Option value="Contract">Contract</Select.Option>
                <Select.Option value="Contract To Hire">Contract To Hire</Select.Option>
                <Select.Option value="Temporary">Temporary</Select.Option>
                <Select.Option value="Internship">Internship</Select.Option>
                <Select.Option value="Volunteer">Volunteer</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="shiftType"
              label={<p className={commonCssStyles.formLabelTxt}>Shift Type</p>}
            >
              <Select mode="multiple" style={{ width: "100%" }}>
                {}
                <Select.Option value="fixed">Fixed</Select.Option>
                <Select.Option value="rotational">Rotational</Select.Option>
                <Select.Option value="open">Open</Select.Option>
                <Select.Option value="not_assinged">Not Assinged</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      {/* Filter Modal end  */}
    </>
  );
}

export const mapStateToProps = (state) => {
  return {
    staffs: state.staff.staffs,
    departments: state.departments.departments,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getStaffFilterAction: (val) => {
      dispatch(getStaffFilterAction(val));
    },
    getDepartmentAction: () => {
      dispatch(getDepartmentAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
