import {
  STAFF_FILTER_URL,
  STAFF_URL,
  STAFF_EARNING_DEDUCTION_URL,
  STAFF_EARNING_DEDUCTION_Filter_URL,
  LEAVE_URL,
  LEAVE_BALANCE_URL,
  LEAVE_REQUEST_URL,
  STAFF_LEAVE_REQUEST_URL,
  STAFF_DOCUMENTS,
  STAFF_OVERTIME
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
import {
  getStaffDeductionDetailsSlice,
  getStaffDeductionFilterSlice,
  getStaffDetailsSlice,
  getStaffDocumnetsDetailsSlice,
  getStaffEarningsDetailsSlice,
  getStaffEarningsFilterSlice,
  getStaffFilterSlice,
  getStaffLeaveBalanceSlice,
  getStaffLeaveDetailsSlice,
  getStaffLeaveRequestSlice,
  getStaffOvertimeSlice,
} from "../Slice/StaffSlice";

const api = new AxiosApi();

export const getStaffFilterAction = (val) => {
  return (dispatch) => {
    api.invoke(
      STAFF_FILTER_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getStaffFilterSlice(data));
          } else {
            dispatch(getStaffFilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const getStaffDetailsAction = (id) => {
  return (dispatch) => {
    api.invoke(STAFF_URL + "/" + id, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getStaffDetailsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const postStaffAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getStaffFilterAction({}));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const putStaffProfileDetailsAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_URL + "/" + val.id,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getStaffFilterAction({}));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const deleteStaffAction = (id, callback) => {
  console.log(id, "drlrtr staff");
  return (dispatch) => {
    api.invoke(STAFF_URL + "/" + id, "delete", (data, success, statusCode) => {
      callback(false);
      if (success) {
        if (statusCode === 200) {
          callback(true);
          message.destroy();
          message.success(data.message, 3);
          // dispatch(getLeavePolicyAction());
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

//staff Leave

export const getStaffLeaveBalanceAction = (id) => {
  return (dispatch) => {
    api.invoke(
      LEAVE_BALANCE_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getStaffLeaveBalanceSlice(data));
          else {
            dispatch(getStaffLeaveBalanceSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//for updating leave balance

//staff Leave end

//staff Leave Request

export const getStaffLeaveRequestAction = (id) => {
  return (dispatch) => {
    api.invoke(
      STAFF_LEAVE_REQUEST_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        console.log(data,"Final gyuguyeguyg")
        if (success) {
          if (statusCode === 200) dispatch(getStaffLeaveRequestSlice(data));
          else {
            dispatch(getStaffLeaveRequestSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const updateStaffLeaveBalanceAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      // LEAVE_BALANCE_URL + "/" + val.userId,
      LEAVE_BALANCE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getLeavePolicyAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const postStaffLeaveAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      LEAVE_REQUEST_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getStaffFilterAction({}));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const getStaffLeaveDetailsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      LEAVE_REQUEST_URL + "/" + id.id,
      "get",
      (data, success, statusCode) => {
        console.log(data, "yftrdeswa");
        if (success) {
          if (statusCode === 200) dispatch(getStaffLeaveDetailsSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const updateStaffLeaveDetailsAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      LEAVE_REQUEST_URL + "/" + val.id,
      // LEAVE_REQUEST_URL,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getLeavePolicyAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const deleteStaffLeaveDetailsAction = (id, callback) => {
  console.log(id, "drlrtr staff");
  return (dispatch) => {
    api.invoke(
      LEAVE_REQUEST_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        console.log(data, "LEAVE_REQUEST_URL");
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getLeavePolicyAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//staff Leave Request end

//get earning Filter
export const getStaffEarningFilterAction = (val) => {
  return (dispatch) => {
    api.invoke(
      STAFF_EARNING_DEDUCTION_Filter_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getStaffEarningsFilterSlice(data));
          } else {
            dispatch(getStaffEarningsFilterSlice({ result: [] }));
            message.destroy();
            // message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const postStaffEarningAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_EARNING_DEDUCTION_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getStaffFilterAction({}));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const getStaffEarningDetailsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      STAFF_EARNING_DEDUCTION_URL + "/" + id.id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getStaffEarningsDetailsSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const putStaffEarningDetailsAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_EARNING_DEDUCTION_URL + "/" + val.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getLeavePolicyAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const deleteStaffEarningDetailsAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_EARNING_DEDUCTION_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getLeavePolicyAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//get earning end

//get DeductionFilter
export const getStaffDeductionFilterAction = (val) => {
  return (dispatch) => {
    api.invoke(
      STAFF_EARNING_DEDUCTION_Filter_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getStaffDeductionFilterSlice(data));
          } else {
            dispatch(getStaffDeductionFilterSlice({ result: [] }));
            message.destroy();
            // message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const postStaffDeductionAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_EARNING_DEDUCTION_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getStaffFilterAction({}));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const getStaffDeductionDetailsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      STAFF_EARNING_DEDUCTION_URL + "/" + id.id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getStaffDeductionDetailsSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const putStaffDeductionDetailsAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_EARNING_DEDUCTION_URL + "/" + val.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getLeavePolicyAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const deleteStaffDeductionDetailsAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_EARNING_DEDUCTION_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getLeavePolicyAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//get DeductionFilter end

//Upload Documents
export const postStaffDocumentsAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_DOCUMENTS,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const getStaffDocumentsDetailsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      STAFF_DOCUMENTS + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getStaffDocumnetsDetailsSlice(data));
          else {
            dispatch(getStaffDocumnetsDetailsSlice({result:[]}));
            message.destroy();
            // message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const deleteStaffDocumentsAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_DOCUMENTS + "/" + id,
      "delete",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getLeavePolicyAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//Upload Documents  end



//Staff Overtime 
export const getStaffOvertimeAction = (val) => {
  return (dispatch) => {
    api.invoke(
      STAFF_OVERTIME+"/"+val.id+"/"+val.date,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getStaffOvertimeSlice(data));
          } else {
            dispatch(getStaffOvertimeSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};


export const postStaffOvertimeAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      STAFF_OVERTIME,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//Staff Overtime end 