import React, { useEffect, useState } from "react";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  MoreOutlined,
  RightOutlined,
  UserOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TimePicker,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import {
  getLateEntryDetailsAction,
  putLateEntryRuleAction,
} from "../../../Reusable/Store/Action/LateEntryRuleAction";
import moment from "moment";

function EditLateEntryRule({
  getLateEntryDetailsAction,
  putLateEntryRuleAction,
  lateEntryDetail,
}) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [halfDayLeave, setHalfDayLeave] = useState(false);
  const [fullDayLeave, setFullsDayLeave] = useState(false);

  useEffect(() => {
    getLateEntryDetailsAction(
      location && location.state && location.state.id && location.state.id
    );
  }, [location?.state?.id]);

  useEffect(() => {
    if (lateEntryDetail) {
      setHalfDayLeave(true);
      setFullsDayLeave(true);
    }
  }, [lateEntryDetail]);

  useEffect(() => {
    if (lateEntryDetail) {
      form.setFieldsValue({
        name: lateEntryDetail.name,
        fullDayHours: moment(lateEntryDetail.fullDayHours, "hh:mm A"),
        halfDayHours: moment(lateEntryDetail.halfDayHours, "hh:mm A"),
        weeklyOffDays: ["Monday", "Tuesday"],
      });
    }
  }, [lateEntryDetail, form]);


  const format = "HH:mm";

  // const formatTime4 = (time) => dayjs(time).format("hh:mm");
  const formatTime4 = (time) => {
    if (time === undefined || time === null || time=="00:00") {
      return "00:00";
    } else {
      return dayjs(time).format("hh:mm");
    }
  };
  

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  const onFinish = (values) => {
    const formattedHalfDayHours = values.halfDayHours
      ? values.halfDayHours.format("HH:mm")
      : "00:00";
  
    const formattedFullDayHours = values.fullDayHours
      ? values.fullDayHours.format("HH:mm")
      : "00:00";
  
    const Data = {
      id: location.state.id,
      name: values.name,
      halfDayHours: formattedHalfDayHours,
      fullDayHours: formattedFullDayHours,
    };
  
    console.log(Data, "values values");
  
    putLateEntryRuleAction(Data, (success) => {
      if (success) {
        navigate("/settings/attendance-automation/late-entry");
      }
    });
  };
  

  return (
    <div style={{ marginTop: "20px" }}>
      {" "}
      <Row justify={"space-between"}>
        <Col>
          <Link to="/settings/attendance-automation/late-entry">
            <h1 className={commonCssStyles.BackButtonTxt}>
              {" "}
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>
      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>
            Edit a Late Entry Rule
          </h1>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                Rules
              </p>
            }
            className="custom-card1"
          >
            <Form
              style={{ paddingLeft: "30px" }}
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <Row>
                <Col md={6}>
                  <Form.Item label="Name" name="name"  rules={[
              {
                required: true,
                message: "Please enter the name",
              },
            ]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="weeklyOffDays">
                <Checkbox.Group
                  style={{
                    width: "100%",
                  }}
                  onChange={onChange}
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <Checkbox
                        value="Monday"
                        onChange={(e) => {
                          console.log(
                            setHalfDayLeave(e.target.checked),
                            e.target.checked,
                            "Deduct half day salary"
                          );
                        }}
                      >
                        Deduct half day salary if staff is late by more than
                      </Checkbox>
                    </Col>

                    {halfDayLeave && halfDayLeave ? (
                      <Col>
                        <Form.Item
                          label="Select Time"
                          name="halfDayHours"
                          rules={[{ required: true }]}
                        >
                          <TimePicker format="HH:mm" changeOnBlur={true}/>
                        </Form.Item>
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col xs={24}>
                      <Checkbox
                        value="Tuesday"
                        onChange={(e) => {
                          console.log(
                            setFullsDayLeave(e.target.checked),
                            e.target.checked,
                            "Deduct full day salary"
                          );
                        }}
                      >
                        Deduct full day salary if staff is late by more than
                      </Checkbox>
                    </Col>

                    {fullDayLeave && fullDayLeave ? (
                      <Col>
                        <Form.Item
                          label="Select Time"
                          name="fullDayHours"
                          rules={[{ required: true }]}
                        >
                          <TimePicker format="HH:mm" changeOnBlur={true}/>
                        </Form.Item>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Row>
                <Col xs={24}>
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        marginRight: "40px",
                      }}
                    >
                      <Button
                        type="primary"
                        className={commonCssStyles.addBtnStyles}
                        htmlType="submit"
                      >
                        Save Rule
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lateEntryDetail: state.lateentry.lateEntryDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLateEntryDetailsAction(id) {
      dispatch(getLateEntryDetailsAction(id));
    },
    putLateEntryRuleAction(val, callback) {
      dispatch(putLateEntryRuleAction(val, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditLateEntryRule);
