import React, { useEffect, useState } from "react";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import { connect } from "react-redux";

import {
  DownOutlined,
  ProfileOutlined,
  ShoppingOutlined,
  CloseOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  getStaffOvertimeAction,
  postStaffOvertimeAction,
} from "../../../Reusable/Store/Action/StaffAction";
import { useLocation } from "react-router";

function Overtime({
  staffEarnings,
  StaffOvertimeDetails,
  getStaffOvertimeAction,
  postStaffOvertimeAction,
}) {
  const location = useLocation();
  const [form] = Form.useForm();

  const [deleteAllowanceId, setDeleteAllowanceId] = useState("");
  const [overTimeHoursDisp, setOverTimeHoursDisp] = useState("fixed");
  const [earlyOverTimeHoursDisp, setEarlyOverTimeHoursDisp] = useState("fixed");


  const [dateSelected, setDateSelected] = useState(dayjs().format("YYYY-MM-DD"));

  useEffect(() => {
    if (location && location.state && location.state.id) {
      getStaffOvertimeAction({
        id: location.state.id,
        date: dayjs().format("YYYY-MM-DD"),
      });
    }
  }, [location?.state?.id]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        // form.resetFields();
        handleCancel();
      }}
    />
  );
  
  console.log(
    StaffOvertimeDetails && StaffOvertimeDetails,
    "StaffOvertimeDetails"
  );
  console.log(location, "Data Location");

  //Earnigs and deduction columns

  const columns = [
    {
      title: "Overtime Hours",
      dataIndex: "otHours",
      key: "otHours",
    //   render: (date) => dayjs(date).format("DD-MM-YYYY"),
    },
    {
      title: "Overtime Rule",
      dataIndex: "otRule",
      key: "otRule",
    //   render: (type) => (type === "allowance" ? "Allowance" : "Bonus"),
    },
    {
      title: "Overtime Amount",
      dataIndex: "OtAmount",
      key: "OtAmount",
    //   render: (type) => (type === "allowance" ? "Allowance" : "Bonus"),
    },
    {
      title: "Early Overtime Hours",
      dataIndex: "earlyOtHours",
      key: "earlyOtHours",
    //   render: (isPaid) => (isPaid === 1 ? "Paid" : "Added to Salary"),
    },
    {
      title: "Early Overtime Rule",
      dataIndex: "earlyOtRule",
      key: "earlyOtRule",
    },
    {
      title: "Early Overtime Amount",
      dataIndex: "earlyOtAmount",
      key: "earlyOtAmount",
    },
    {
      title: " ",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (data, render) => (
        <>
          <Dropdown
            placement="bottomLeft"
            // trigger={["click"]}
            overlayStyle={{ width: "250px" }}
            overlay={
              <div className={commonCssStyles.AddStaffCard}>
                <Button
                  style={{ marginTop: "10px", width: "100%" }}
                  className={commonCssStyles.secondaryBtnStyles}
                  onClick={() => {
                    // getStaffEarningDetailsAction({
                    //   id: render.id,
                    //   // type: "earning",
                    // });
                    // showAllowanceEditModal();
                  }}
                >
                  Edit
                </Button>

                <hr className={commonCssStyles.hrSeperatorDropDwn} />

                <Button
                  style={{ marginTop: "10px", width: "100%" }}
                  className={commonCssStyles.secondaryBtnStyles}
                  onClick={() => {
                    setDeleteAllowanceId(render.id);
                    // showAllowanceDeleteModal();
                  }}
                >
                  Delete
                </Button>
              </div>
            }
          >
            <a onClick={(e) => e.preventDefault()}>
              <div>
                <MoreOutlined
                  style={{
                    fontSize: "35px",
                    padding: "0px",
                    margin: "0px",
                    color: "var(--main-heading-clr)",
                  }}
                />
              </div>
            </a>
          </Dropdown>
        </>
      ),
    },
  ];

  //Earnigs and deduction columns end

  return (
    <div>
      <Card
        className={`${commonCssStyles.ThingsToDoCardDsgn5} custom-card1`}
        style={{ padding: "0px !important" }}
      >
        <Row justify={"space-between"}>
          <Col>
            <Row>
              {/* <Col>
                        <p className={commonCssStyles.attendancetableDataTxt}>
                        Allowance & Bonus
                         ( Jan 2023 - Dec 2023 )
                        </p>
                      </Col> */}
              <Col>
                <Form
                  initialValues={{
                    date: dayjs(),
                  }}
                >
                  <Form.Item name="date">
                    <DatePicker
                      locale="en"
                    //   picker="month"
                    //   format="MMMM YYYY"
                      placeholder="Select Date"
                      disabledDate={(currentDate) =>
                        currentDate && currentDate.isAfter(dayjs(), "day")
                      }
                      onChange={(val) => {
                        const AllowanceDate = dayjs(val).format("YYYY-MM-DD");
                        setDateSelected(AllowanceDate);
                        getStaffOvertimeAction({
                            id: location.state.id,
                            date:dateSelected,
                        });
                      }}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>
              <Col>
                <p className={commonCssStyles.attendancetableTimeTxt}>
                  Total Allowance :{" "}
                  <b>
                    {" "}
                    ₹{" "}
                    {staffEarnings && staffEarnings.totalAllowance
                      ? staffEarnings.totalAllowance
                      : 0}
                  </b>
                </p>
                <p className={commonCssStyles.attendancetableTimeTxt}>
                  Total Bonus :{" "}
                  <b>
                    {" "}
                    ₹{" "}
                    {staffEarnings && staffEarnings.totalBonus
                      ? staffEarnings.totalBonus
                      : 0}
                  </b>
                </p>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row gutter={[16, 16]}>
              <Col>
                <Button
                  type="primary"
                  className={commonCssStyles.addBtnStyles}
                  onClick={() => {
                    showModal();
                  }}
                >
                  Add Overtime
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <div style={{ marginTop: "40px" }}>
        <Table
          dataSource={
            StaffOvertimeDetails && StaffOvertimeDetails
          }
          columns={columns}
          pagination={false}
          bordered
          scroll={{ x: "max-content" }}
          className="custom-dashboard-payment-table"
        />
      </div>

      <Modal
        title="Overtime"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={closeIcon}
        footer={[
            <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="markPresent"
            >
              Save
            </Button>
          </div>
        ]}
      >
       <Form
       form={form}
       layout="vertical"
       id="markPresent"
       initialValues={{
        otRule: "fixed",
        earlyOtRule: "fixed",
       }}

       onFinish={(val)=>{
        const mkPresent = {
            userId: location.state.id,
            date:val.date&&dayjs(val.date).format("YYYY-MM-DD"),
            // status: "overtime",
           
                otHours: val.otHours
                  ? dayjs(val.otHours).format("HH:mm")
                  : 0,
                otRule: val.otRule,
                otMultiplier: val.otMultiplier,
                otAmount: val.otAmount,
                earlyOtHours: val.earlyOtHours
                  ? dayjs(val.earlyOtHours).format("HH:mm")
                  : 0,
                earlyOtRule: val.earlyOtRule,
                earlyOtMultiplier: val.earlyOtMultiplier,
                earlyOtAmount: val.earlyOtAmount,
          };

          postStaffOvertimeAction(mkPresent,(success)=>{
            if(success){
                handleCancel();
                getStaffOvertimeAction({
                    id: location.state.id,
                    date:dateSelected,
                })
            }
          })

          console.log(mkPresent);
       }}
       >
       <>
                  <Row justify={"space-around"}>
                    <Col xs={24}>
                    <Form.Item name="date" label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Date
                          </p>
                        }
                        rules={[{ required: true,message:"Please select date" }]}
                        >
                    <DatePicker
                      locale="en"
                    //   picker="month"
                    //   format="MMMM YYYY"
                    
                      placeholder="Select Date"
                      disabledDate={(currentDate) =>
                        currentDate && currentDate.isAfter(dayjs(), "day")
                      }
                      onChange={(val) => {
                        const AllowanceDate = dayjs(val).format("YYYY-MM-DD");
                        setDateSelected(AllowanceDate);
                        // getStaffEarningFilterAction({
                        //   type: "earning",
                        //   userId: location.state.id,
                        //   month: AllowanceDate,
                        // });
                      }}
                      style={{width:"100%"}}
                    />
                  </Form.Item>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Overtime Hours
                          </p>
                        }
                        name="otHours"
                      >
                        <TimePicker
                          style={{ width: "100%" }}
                          format={"HH:mm"}
                          changeOnBlur={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Overtime Rule
                          </p>
                        }
                        name="otRule"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            setOverTimeHoursDisp(val);
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="multiplier">
                            Multiplier
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {overTimeHoursDisp == "fixed" ? (
                      <Col xs={24}>
                        <Form.Item
                          label={
                            <p className={commonCssStyles.formLabelTxt}>
                              Overtime Amount
                            </p>
                          }
                          name="otAmount"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col xs={24}>
                        <Form.Item
                          label={
                            <p className={commonCssStyles.formLabelTxt}>
                              Overtime Multiplier
                            </p>
                          }
                          name="otMultiplier"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <Row justify={"space-around"}>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Early Overtime Hours
                          </p>
                        }
                        name="earlyOtHours"
                      >
                        <TimePicker
                          style={{ width: "100%" }}
                          format={"HH:mm"}
                          changeOnBlur={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Early Overtime Rule
                          </p>
                        }
                        name="earlyOtRule"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            setEarlyOverTimeHoursDisp(val);
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="multiplier">
                            Multiplier
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {earlyOverTimeHoursDisp == "fixed" ? (
                      <>
                        {" "}
                        <Col xs={24}>
                          <Form.Item
                            label={
                              <p className={commonCssStyles.formLabelTxt}>
                                Early Overtime Amount
                              </p>
                            }
                            name="earlyOtAmount"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={24}>
                          <Form.Item
                            label={
                              <p className={commonCssStyles.formLabelTxt}>
                                Early Overtime Multiplier
                              </p>
                            }
                            name="earlyOtMultiplier"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                </>
       </Form>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    StaffOvertimeDetails: state.staff.StaffOvertimeDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaffOvertimeAction: (val) => {
      dispatch(getStaffOvertimeAction(val));
    },
    postStaffOvertimeAction: (val, callback) => {
      dispatch(postStaffOvertimeAction(val, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overtime);
