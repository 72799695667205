import React, { useEffect, useRef, useState } from "react";
import Headers from "../../../UI/headersComp/Headers";
import commonCssStyles from "../../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  MoreOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  BonusIcon,
  DeductionsIcon,
  FilterOutlinedIcon,
  OverTimeIcon,
  PaymentIcon,
  RaiseHandIcon,
} from "../../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router";
import moment from "moment";
import { postHolidayPolicyAction } from "../../../../Reusable/Store/Action/HolidayPolicyAction";
import { connect } from "react-redux";
import dayjs from "dayjs";

function AddHolidayPolicy({ postHolidayPolicyAction }) {
  // Holiday template related styles
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const newFieldRef = useRef();

  const navigate = useNavigate();
  const defaultPeriodTo = dayjs().month(11);
  
  useEffect(() => {
    const initialHolidays = [
      { holidayName: "Ganesh Chaturthi", holidayDate: dayjs("2023-09-10") },
      { holidayName: "Maha Shivaratri", holidayDate: dayjs("2023-02-25") },
      { holidayName: "Rama Navami", holidayDate: dayjs("2023-04-02") },
      { holidayName: "Janmashtami", holidayDate: dayjs("2023-08-13") },
    ];
    form.setFieldsValue({ holidays: initialHolidays });
  }, [form]);



  const onFinish = (values) => {
    const formattedValues = {
      ...values,
      periodFrom: values.periodFrom && dayjs(values.periodFrom).format("YYYY-MM"),
      periodTo: dayjs().month(11).format("YYYY-MM"),
      holidayList: values.holidays.map((holiday) => ({
        ...holiday,
        holidayDate: holiday.holidayDate && dayjs(holiday.holidayDate).format("YYYY-MM-DD"),
      })),
    };

    console.log("Submitted values:", formattedValues);

    postHolidayPolicyAction(formattedValues, (success) => {
      if (success) {
        navigate("/settings/holiday-policy");
        console.log("Success");
      }
    });
  };

  const handleAddHoliday = () => {
    const holidays = form.getFieldValue("holidays");
    form.setFieldsValue({
      holidays: [...holidays, { holidayName: "", holidayDate: null }],
    });
    setTimeout(() => {
      newFieldRef.current.focus();
    }, 0);
  };
  // Holiday template related styles end
  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link to="/settings/holiday-policy">
            <h1 className={commonCssStyles.BackButtonTxt}>
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>

      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>
            Holiday Calendar Template
          </h1>
        </Col>
      </Row>
      <Form
        layout="vertical"
        form={form}
        //  onFinish={(val)=>{
        //   console.log(val);
        // }}

       
        onFinish={onFinish}
      >
        <Row style={{ marginTop: "30px" }}>
          <Col xs={24}>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Col xs={24} md={20}>
                <Row
                  style={{ display: "flex", flexDirection: "row", gap: "40px" }}
                >
                  <Col>
                    <Form.Item name="name" label="Template Name">
                      <Input placeholder="Holiday Name" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="periodFrom" label="Annual Holiday Period">
                      <DatePicker
                      locale="en"
                        placeholder="From"
                        picker="month"
                        format="MMMM YYYY"
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="periodTo" label=" ">
                      <DatePicker
                        locale="en"
                        placeholder="To"
                        picker="month"
                        format="MMMM YYYY"
                        defaultValue={defaultPeriodTo}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={4}>
                <Form.Item>
                  <Button
                    type="primary"
                    className={commonCssStyles.addBtnStyles}
                    htmlType="submit"
                    // onClick={handleAddHoliday}
                    // onClick={() => {
                    //   form.submit();
                    // }}
                  >
                    Save & Apply
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Card>
              <Row justify={"space-between"} style={{ marginTop: "20px" }}>
                <Col>
                  <h1 className={commonCssStyles.TableSecHeadingTxt}>
                    Holiday(s)
                  </h1>
                </Col>
                <Button
                  type="primary"
                  className={commonCssStyles.filterBtnStyles}
                  onClick={handleAddHoliday}
                >
                  Add Holiday
                </Button>
              </Row>

              <Row style={{ marginTop: "20px" }}>
                <Col xs={24}>
                  <Form.List name="holidays">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "holidayName"]}
                              fieldKey={[field.fieldKey, "holidayName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the holiday name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Holiday Name"
                                ref={
                                  index === fields.length - 1
                                    ? newFieldRef
                                    : null
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, "holidayDate"]}
                              fieldKey={[field.fieldKey, "holidayDate"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select the holiday date",
                                },
                              ]}
                            >
                              <DatePicker
                                locale="en"
                                placeholder="Holiday Date"
                              />
                            </Form.Item>

                            <Button
                              type="text"
                              onClick={() => remove(field.name)}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Space>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    postHolidayPolicyAction: (val, callback) => {
      dispatch(postHolidayPolicyAction(val, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddHolidayPolicy);
