import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Select, TimePicker, Row, Col, Card } from "antd";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  ArrowLeftOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { postOverTimeRuleAction } from "../../../Reusable/Store/Action/OverTimeRuleAction";

const { Option } = Select;

function CreateOverTimeRule({ postOverTimeRuleAction }) {
  const navigate = useNavigate();
  const format = "HH:mm";
  const [form] = Form.useForm();

  const [selectedOvertimeTypes, setSelectedOvertimeTypes] = useState([]);
  const handleOvertimeTypeChange = (index, value) => {
    const updatedOvertimeTypes = [...selectedOvertimeTypes];
    updatedOvertimeTypes[index] = value;
    setSelectedOvertimeTypes(updatedOvertimeTypes);
  };

  const formatTime4 = (time) => dayjs(time).format("hh:mm");

  const onFinish = (values) => {
    const formattedOvertimeDetails = values.overtimeDetails.map((detail) => ({
      ...detail,
      hours: detail.hours ? dayjs(detail.hours).format("HH:mm") : "00:00",
    }));
   
    const Data = {
      ...values,
      type: "ot",
      name: values.name,
      overtimeDetails: formattedOvertimeDetails,
    };
    console.log(Data, "OverTime Rule values values");
    postOverTimeRuleAction(Data, (success) => {
      if (success) {
        navigate("/settings/attendance-automation/overtime");
      }
    });
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link to="/settings/attendance-automation/overtime">
            <h1 className={commonCssStyles.BackButtonTxt}>
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>
      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Create Overtime Rules</h1>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={
              <p className={commonCssStyles.staffProfileDetailsHeading}>
                Rules
              </p>
            }
            className="custom-card1"
          >
            <Form
              form={form}
              style={{
                paddingLeft: "30px",
                marginTop: "10px",
                paddingRight: "30px",
              }}
              layout="vertical"
              onFinish={onFinish}
              initialValues={{
                type: "ot",
                overtimeDetails: [
                  { overtimeType: "", amountPerHour: "", multiplier: "" },
                ],
              }}
            >
              <Row>
                <Col md={24}>
                  <Form.Item label="Name" name="name"  rules={[
              {
                required: true,
                message: "Please enter the name",
              },
            ]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.List name="overtimeDetails">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row key={field.key} gutter={8}>
                        <Col span={8}>
                          <Form.Item
                            label="Hours"
                            name={[field.name, "hours"]}
                            fieldKey={[field.fieldKey, "hours"]}
                          >
                            <TimePicker
                              format="HH:mm"
                              style={{ width: "100%" }} changeOnBlur={true}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            label="Overtime Type"
                            name={[field.name, "overtimeType"]}
                            fieldKey={[field.fieldKey, "overtimeType"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select overtime type",
                              },
                            ]}
                          >
                            <Select
                              className="addStaffForm"
                              style={{ width: "100%" }}
                              onChange={(value) =>
                                handleOvertimeTypeChange(index, value)
                              }
                            >
                              <Option value="fixed">Fixed</Option>
                              <Option value="multiplier">Multiplier</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        {selectedOvertimeTypes[index] === "fixed" && (
                          <Col span={8}>
                            <Form.Item
                              label="Amount Per Hour"
                              name={[field.name, "amountPerHour"]}
                              fieldKey={[field.fieldKey, "amountPerHour"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter amount per hour",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        )}

                        {selectedOvertimeTypes[index] === "multiplier" && (
                          <Col span={8}>
                            <Form.Item
                              label="Multiplier"
                              name={[field.name, "multiplier"]}
                              fieldKey={[field.fieldKey, "multiplier"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter multiplier",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        )}

                        <Col
                          xs={24}
                        >
                          <div
                            style={{
                              display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        alignItems:"center"
                            }}
                          >
                            {index > 0 &&( <Button
                              type="danger"
                              onClick={() => remove(field.name)}
                              icon={<MinusCircleOutlined />}
                            >
                              Remove
                            </Button>)}
                           
                          </div>
                        </Col>
                      </Row>
                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Row style={{ marginTop: "24px" }}>
                <Col xs={24}>
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        marginRight: "40px",
                      }}
                    >
                      <Button
                        type="primary"
                        className={commonCssStyles.addBtnStyles}
                        htmlType="submit"
                      >
                        Save Rule
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    postOverTimeRuleAction: (val, callback) => {
      dispatch(postOverTimeRuleAction(val, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOverTimeRule);
