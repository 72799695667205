import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    breaksRule: null,
    breaksRuleDetail: null,

    breakRuleAssign : null,

  };


  export const lateEntrySlice = createSlice({
    name: "breaksRule",
    initialState,
    reducers: {
      getBreaksRuleSlice(state, action) {
        state.breaksRule = action.payload.result;
      },
      getBreaksRuleDetailSlice(state, action) {
        state.breaksRuleDetail = action.payload.result;
      },
     

      getBreaksRuleAssignSlice(state, action) {
        state.breakRuleAssign = action.payload.result;
      },
    },
  });

  export const {
    getBreaksRuleSlice,
    getBreaksRuleDetailSlice,
    getBreaksRuleAssignSlice
  } = lateEntrySlice.actions;
  export default lateEntrySlice.reducer;
