import React, { useState, useEffect } from "react";
import { Form, TimePicker, Button, Row, Col, Input } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import Test1 from "./Test1";

function Test() {
  const [form] = Form.useForm();
  const [selectedRows, setSelectedRows] = useState([]);

  const formatTime = (time) => dayjs(time).format("hh:mm A");

  useEffect(() => {
    form.setFieldsValue({
      times: [{}, {}], // Initialize with two empty objects
    });
  }, []);

  useEffect(() => {
    form.validateFields(["times"], { force: true }); // Validate the form after component mount
  }, [form]);

  const onFinish = (values) => {
    // Format the time values before setting selectedRows state
    const formattedValues = {
      ...values,
      times: values.times.map((timeObj) => ({
        ...timeObj,
        startTime: formatTime(timeObj.startTime),
        endTime: formatTime(timeObj.endTime),
        unpaidBreak: formatTime(timeObj.unpaidBreak),
      })),
    };
    setSelectedRows(formattedValues.times);
    console.log("Form values:", formattedValues);
  };

  const calculateNetPayableHours = (startTime, endTime, unpaidBreak) => {
    const start = dayjs(startTime, "HH:mm");
    const end = dayjs(endTime, "HH:mm");
    const unpaid = dayjs(unpaidBreak, "HH:mm");
    const duration = end.diff(start, "minute") - unpaid.hour() * 60 - unpaid.minute();
    const netPayable = Math.max(0, duration / 60);
    return netPayable.toFixed(2); // Return the result with two decimal places
  };



  return (
    <div>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.List name="times">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row gutter={[16, 16]} key={field.key}>
                  <Col xs={6} md={6}>
                    <Form.Item
                      {...field}
                      label={index === 0 ? "Rotational Shift Name" : ""}
                      name={[field.name, "rotationalShiftName"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the rotational shift name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Item
                      {...field}
                      label={index === 0 ? "Start Time" : ""}
                      name={[field.name, "startTime"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select the start time",
                        },
                      ]}
                    >
                      <TimePicker
                        format="hh:mm A" // Updated format to include AM/PM
                        onChange={(time) => {
                          const endTime = form.getFieldValue(["times", index, "endTime"]);
                          const unpaidBreak = form.getFieldValue(["times", index, "unpaidBreak"]);
                          const netPayableHours =""
                         
                          // const netPayableHours = calculateNetPayableHours(
                          //   time,
                          //   endTime,
                          //   unpaidBreak
                          // );
                          form.setFieldsValue({
                            times: {
                              ...form.getFieldValue("times"),
                              [index]: {
                                ...form.getFieldValue(["times", index]),
                                startTime: time,
                                netPayableHours,
                              },
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Item
                      {...field}
                      label={index === 0 ? "End Time" : ""}
                      name={[field.name, "endTime"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select the end time",
                        },
                      ]}
                    >
                      <TimePicker
                        format="hh:mm A" // Updated format to include AM/PM
                        onChange={(time) => {
                          const startTime = form.getFieldValue(["times", index, "startTime"]);
                          const unpaidBreak = form.getFieldValue(["times", index, "unpaidBreak"]);
                          const netPayableHours =""
                          // const netPayableHours = calculateNetPayableHours(
                          //   startTime,
                          //   time,
                          //   unpaidBreak
                          // );
                          form.setFieldsValue({
                            times: {
                              ...form.getFieldValue("times"),
                              [index]: {
                                ...form.getFieldValue(["times", index]),
                                endTime: time,
                                netPayableHours,
                              },
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Item
                      {...field}
                      label={index === 0 ? "Unpaid Break" : ""}
                      name={[field.name, "unpaidBreak"]}
                      validateStatus={field.errors ? "error" : ""}
                      help={field.errors ? field.errors.join(", ") : null}
                    >
                      <TimePicker
                        format="HH:mm" // Updated format to include AM/PM
                        onChange={(time) => {
                          const startTime = form.getFieldValue(["times", index, "startTime"]);
                          const endTime = form.getFieldValue(["times", index, "endTime"]);
                          const netPayableHours = calculateNetPayableHours(
                            startTime,
                            endTime,
                            time
                          );
                          form.setFieldsValue({
                            times: {
                              ...form.getFieldValue("times"),
                              [index]: {
                                ...form.getFieldValue(["times", index]),
                                unpaidBreak: time,
                                netPayableHours,
                              },
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Item
                      {...field}
                      label={index === 0 ? "Net Payable Hours" : ""}
                      name={[field.name, "netPayableHours"]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={6} md={2}>
                    {/* Delete button with CloseCircleOutlined icon */}
                    {index > 1 && (
                      <Button onClick={() => remove(field.name)} icon={<CloseCircleOutlined />} />
                    )}
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  Add Row
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

    </div>
  );
}

export default Test;
