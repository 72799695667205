import React, { useState } from "react";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { FilterOutlinedIcon } from "../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";

const CheckboxGroup = Checkbox.Group;
const { Panel } = Collapse;

function Reports() {
  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Reports</h1>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24}>
          <Collapse style={{ marginBottom: "20px" }}>
            <Panel header="Attendance Reports" key="1">
            <Card
            className={`${commonCssStyles.tableCardsContainer} custom-card`}
            style={{ padding: "0px !important" }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row style={{}}>
                  <Col>
                  <p className={commonCssStyles.ReportsDataTxt}
                  style={{margin:"0px"}}
                    // onClick={handleClick}
                    >Attendance Report </p>
                 <p className={commonCssStyles.tableDataTxt}>Staff level summary for individual attendance cycle</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.addPaymentBtnStyles}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Card>
          <Card
            className={`${commonCssStyles.tableCardsContainer} custom-card`}
            style={{ padding: "0px !important" }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row style={{}}>
                  <Col>
                  <p className={commonCssStyles.ReportsDataTxt}
                  style={{margin:"0px"}}
                    // onClick={handleClick}
                    >Compliance OT Report </p>
                 <p className={commonCssStyles.tableDataTxt}>Staff level Overtime report as per government norms</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.addPaymentBtnStyles}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Card>
          <Card
            className={`${commonCssStyles.tableCardsContainer} custom-card`}
            style={{ padding: "0px !important" }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row style={{}}>
                  <Col>
                  <p className={commonCssStyles.ReportsDataTxt}
                  style={{margin:"0px"}}
                    // onClick={handleClick}
                    >Daily Attendance Report </p>
                 <p className={commonCssStyles.tableDataTxt}>Day wise attendance summary, individual attendance view and punch logs</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.addPaymentBtnStyles}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Card>
          <Card
            className={`${commonCssStyles.tableCardsContainer} custom-card`}
            style={{ padding: "0px !important" }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row style={{}}>
                  <Col>
                  <p className={commonCssStyles.ReportsDataTxt}
                  style={{margin:"0px"}}
                    // onClick={handleClick}
                    >Leave Balance Report </p>
                 <p className={commonCssStyles.tableDataTxt}>Staff level view of allowed leaves, leaves taken, leave remaining, etc.</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.addPaymentBtnStyles}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Card>
          <Card
            className={`${commonCssStyles.tableCardsContainer} custom-card`}
            style={{ padding: "0px !important" }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row style={{}}>
                  <Col>
                  <p className={commonCssStyles.ReportsDataTxt}
                  style={{margin:"0px"}}
                    // onClick={handleClick}
                    >Muster Roll Report</p>
                 <p className={commonCssStyles.tableDataTxt}>Monthly view of day wise attendance, fine, OT, etc. of all staff</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.addPaymentBtnStyles}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Card>
              {/* Add more cards as needed */}
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col xs={24}>
          <Collapse>
            <Panel header="Compliance Reports" key="2">
            <Card
            className={`${commonCssStyles.tableCardsContainer} custom-card`}
            style={{ padding: "0px !important" }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row style={{}}>
                  <Col>
                  <p className={commonCssStyles.ReportsDataTxt}
                  style={{margin:"0px"}}
                    // onClick={handleClick}
                    >ESI Report</p>
                 <p className={commonCssStyles.tableDataTxt}>ESI report with staff and employer contribution</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.addPaymentBtnStyles}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Card>
          <Card
            className={`${commonCssStyles.tableCardsContainer} custom-card`}
            style={{ padding: "0px !important" }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row style={{}}>
                  <Col>
                  <p className={commonCssStyles.ReportsDataTxt}
                  style={{margin:"0px"}}
                    // onClick={handleClick}
                    >LWF Report</p>
                 <p className={commonCssStyles.tableDataTxt}>Report on Labour Welfare Fund contribution of staff and employer</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.addPaymentBtnStyles}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Card>
          <Card
            className={`${commonCssStyles.tableCardsContainer} custom-card`}
            style={{ padding: "0px !important" }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row style={{}}>
                  <Col>
                  <p className={commonCssStyles.ReportsDataTxt}
                  style={{margin:"0px"}}
                    // onClick={handleClick}
                    >PF Compliance Report</p>
                 <p className={commonCssStyles.tableDataTxt}>PF report as per government norms in .txt format</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.addPaymentBtnStyles}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Card>
          <Card
            className={`${commonCssStyles.tableCardsContainer} custom-card`}
            style={{ padding: "0px !important" }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row style={{}}>
                  <Col>
                  <p className={commonCssStyles.ReportsDataTxt}
                  style={{margin:"0px"}}
                    // onClick={handleClick}
                    >PF Excel Report</p>
                 <p className={commonCssStyles.tableDataTxt}>Monthly view of PF contributions by employee and employer</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.addPaymentBtnStyles}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Card>
          <Card
            className={`${commonCssStyles.tableCardsContainer} custom-card`}
            style={{ padding: "0px !important" }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row style={{}}>
                  <Col>
                  <p className={commonCssStyles.ReportsDataTxt}
                  style={{margin:"0px"}}
                    // onClick={handleClick}
                    >PT Report</p>
                 <p className={commonCssStyles.tableDataTxt}>Professional tax report as per your state</p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.addPaymentBtnStyles}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Card>
              {/* Add more cards as needed */}
            </Panel>
            {/* Add more panels as needed */}
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}

export default Reports;
