import {
  Breadcrumb,
  Button,
  Card,
  Col,
  DatePicker,
  Image,
  Result,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import Demo from "./Demo";

const dataSource = [
  {
    key: "1",
    name: "Allowance",
    age: "₹ 0",
    address: "₹ 0",
    action: "Add Allowance",
  },
  {
    key: "2",
    name: "Bonus",
    age: "₹ 0",
    address: "₹ 0",
    action: "Add Allowance",
  },
  {
    key: "3",
    name: "Deduction",
    // name: "₹ 0",
    age: "₹ 0",
    address: "₹ 0",
    action: "Add Allowance",
  },
];

const columns = [
  {
    title: "Type",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "To Be Paid",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Paid",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const dataSource1 = [
  {
    key: "1",
    name: "Allowance",
    age: "₹ 0",
    address: "₹ 0",
    action: "Add Allowance",
  },
  {
    key: "2",
    name: "Bonus",
    age: "₹ 0",
    address: "₹ 0",
    action: "Add Allowance",
  },
  {
    key: "3",
    name: "Deduction",
    // name: "₹ 0",
    age: "₹ 0",
    address: "₹ 0",
    action: "Add Allowance",
  },
];

const columns1 = [
  {
    title: "Day as Date",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Quantity",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Amount",
    dataIndex: "address",
    key: "address",
  },
];

function Dashboard() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileOrTablet = window.matchMedia("(max-width: 992px)").matches;
      setIsMobileOrTablet(isMobileOrTablet);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* <Headers BreadcrumbPage={"Dashboard"} /> */}

      <div style={{ marginTop: "20px" }}>
        <h1 className={commonCssStyles.PageHeadingTxt}>Dashboard</h1>

        <Row gutter={[16, 16]} style={{ marginTop: "40px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card className={commonCssStyles.ThingsToDoCardDsgn}>
              <h1 className={commonCssStyles.DashboardSubHeadingLabelTxt}>
                Things To Do
              </h1>

              <Result status="success" title="All Done" />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card className={commonCssStyles.ThingsToDoCardDsgn}>
              <Row justify={"space-between"}>
                <Col>
                  <h1 className={commonCssStyles.DashboardSubHeadingLabelTxt}>
                    Attendance Overview
                  </h1>
                </Col>
                <Col>
                  <DatePicker />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card
                    // className={commonCssStyles.AttendanceOverViewCard}
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Present
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>4</h1>
                  </Card>
                </Col>

                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Absent
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>4</h1>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Not Marked
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>4</h1>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Half Day
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>4</h1>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      On Leave
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>4</h1>
                  </Card>
                </Col>

                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Overtime
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>4</h1>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Fine hours
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>4</h1>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Deactivated
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>4</h1>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Daily work Entries
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>4</h1>
                  </Card>
                </Col>

                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Upcoming leaves in 7 days
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>4</h1>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>

          <h1
            className={commonCssStyles.PageHeadingTxt}
            style={{ marginTop: "20px" }}
          >
            Payment
          </h1>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {/* <Card className={commonCssStyles.ThingsToDoCardDsgn}> */}
            <Card className={commonCssStyles.ThingsToDoCardDsgn4}>
              <Row justify={"space-between"} style={{ marginBottom: "20px" }}>
                <Col>
                  <h1 className={commonCssStyles.DashboardPaymentTableHeading}>
                    Allowance/Deduction/Bonus
                  </h1>
                </Col>
                <Col>
                  <Select
                    defaultValue="Jul 2023"
                    style={{
                      width: 120,
                    }}
                    // onChange={handleChange}
                    options={[
                      {
                        value: "jack",
                        label: "Jul 2023",
                      },
                    ]}
                  />
                </Col>
              </Row>

              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                bordered
                scroll={{ x: "max-content" }}
                className="custom-dashboard-payment-table"
              />

              {/* <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                  <Card
                    style={{ backgroundColor: "#F67280" }}
                    className={commonCssStyles.AttendanceOverViewCard}
                  >
                    <h1>Allowance</h1>
                    <h1>4</h1>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                  <Card
                    style={{ backgroundColor: "#C06C84" }}
                    className={commonCssStyles.AttendanceOverViewCard}
                  >
                    <h1>Deduction</h1>
                    <h1>4</h1>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                  <Card
                    style={{ backgroundColor: "#6C5B7B" }}
                    className={commonCssStyles.AttendanceOverViewCard}
                  >
                    <h1>Bonus </h1>
                    <h1>4</h1>
                  </Card>
                </Col>
              </Row> */}
            </Card>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ marginTop: "15px" }}
          >
            {/* <Card className={commonCssStyles.ThingsToDoCardDsgn}> */}
            <Card className={commonCssStyles.ThingsToDoCardDsgn4}>
              <Row justify={"space-between"} style={{ marginBottom: "20px" }}>
                <Col>
                  <h1 className={commonCssStyles.DashboardPaymentTableHeading}>
                    Work Report
                  </h1>
                </Col>
                <Col>
                <Row style={{gap:'30px'}}>
                <Col>
                  <Link>
                  Download Report
                  </Link>
                  </Col>

                <Col>
                  <Select
                    defaultValue="Day Wise"
                    style={{
                      width: 120,
                    }}
                    // onChange={handleChange}
                    options={[
                      {
                        value: "jack",
                        label: "Day Wise",
                      },
                    ]}
                  /></Col>


                  <Col>
                  <Select
                    defaultValue="Jul 2023"
                    style={{
                      width: 120,
                    }}
                    // onChange={handleChange}
                    options={[
                      {
                        value: "jack",
                        label: "Jul 2023",
                      },
                    ]}
                  />
                  </Col>
                 
                 
                </Row>
                 
                  {/* <DatePicker /> */}
                </Col>
              </Row>

              <Table
                dataSource={dataSource1}
                columns={columns1}
                pagination={false}
                bordered
                scroll={{ x: "max-content" }}
                className="custom-dashboard-payment-table"
              />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card className={commonCssStyles.ThingsToDoCardDsgn}>
              <h1>Attendance</h1>
              <Row gutter={[16, 16]} justify={"space-between"}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Card
                    style={{ backgroundColor: "#F67280" }}
                    className={commonCssStyles.AttendanceOverViewCard}
                  >
                    <h1>monthly attendance data </h1>
                    <h1>4</h1>
                  </Card>
                </Col>

                <Col>
                  <Button
                    type="default"
                    style={{ border: "2px solid var(--primary-color)" }}
                  >
                    View Overall Report
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
