import React, { useState } from "react";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  MoreOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  BonusIcon,
  DeductionsIcon,
  FilterOutlinedIcon,
  OverTimeIcon,
  PaymentIcon,
  RaiseHandIcon,
} from "../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router";

function AutomationRules() {
  return (
    <div style={{ marginTop: "20px" }}>
      {" "}
      <Row justify={"space-between"}>
        <Col>
          <Link to="/settings">
            <h1 className={commonCssStyles.BackButtonTxt}>
              {" "}
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>
      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1
            className={commonCssStyles.PageHeadingTxt}
            style={{ margin: "0px" }}
          >
            Automation Rules
          </h1>
          <h1 className={commonCssStyles.SubPageHeadingTxt}>
            Set rules for Late Entry, Early Exit, Breaks & Overtime based on
            punch-in and punch-out time. You just have to approve the
            fine/overtime entries.
          </h1>
        </Col>
      </Row>
      <div style={{ marginTop: "40px" }}>
        <Card className="custom-card1" style={{ padding: "0px !important" }}>
          <div className={commonCssStyles.settingsAutomationcommonTableStyles}>
            <p className={commonCssStyles.settingsAutomationDetailsBodyFixed}>
              <UserOutlined style={{ marginRight: "10px" }} />
              <b>Late Entry Rules</b> &#40;Automate late fine for employees who
              are coming late to work&#41;
            </p>

            <p className={commonCssStyles.settingsAutomationDetailsBody}>
              <Link to={"/settings/attendance-automation/late-entry"}>
                <span style={{ color: "#1d4ed8" }}>
                  View All &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </Link>
              <RightOutlined />
            </p>
          </div>
        </Card>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Card className="custom-card1" style={{ padding: "0px !important" }}>
          <div className={commonCssStyles.settingsAutomationcommonTableStyles}>
            <p className={commonCssStyles.settingsAutomationDetailsBodyFixed}>
              <UserOutlined style={{ marginRight: "10px" }} />
              <b>Breaks Rules</b> &#40;Automate late fine for employees who are
              coming late to work&#41;
            </p>

            <p className={commonCssStyles.settingsAutomationDetailsBody}>
              <Link to={"/settings/attendance-automation/break-rule"}>
                <span style={{ color: "#1d4ed8" }}>
                  View All &nbsp;&nbsp;&nbsp;
                </span>
              </Link>
              <RightOutlined />
            </p>
          </div>
        </Card>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Card className="custom-card1" style={{ padding: "0px !important" }}>
          <div className={commonCssStyles.settingsAutomationcommonTableStyles}>
            <p className={commonCssStyles.settingsAutomationDetailsBodyFixed}>
              <UserOutlined style={{ marginRight: "10px" }} />
              <b>Early Exit Rules</b> &#40;Automate fine for employees who are
              leaving earlier than the shift out-time&#41;
            </p>

            <p className={commonCssStyles.settingsAutomationDetailsBody}>
              <Link to={"/settings/attendance-automation/early-exit"}>
                <span style={{ color: "#1d4ed8" }}>
                  View All &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </Link>
              <RightOutlined />
            </p>
          </div>
        </Card>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Card className="custom-card1" style={{ padding: "0px !important" }}>
          <div className={commonCssStyles.settingsAutomationcommonTableStyles}>
            <p className={commonCssStyles.settingsAutomationDetailsBodyFixed}>
              <UserOutlined style={{ marginRight: "10px" }} />
              <b>Overtime Rules</b> &#40;Automate overtime for employees who are
              working extra after their shift hours&#41;
            </p>

            <p className={commonCssStyles.settingsAutomationDetailsBody}>
              <Link to={"/settings/attendance-automation/overtime"}>
                <span style={{ color: "#1d4ed8" }}>
                  View All &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </Link>
              <RightOutlined />
            </p>
          </div>
        </Card>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Card className="custom-card1" style={{ padding: "0px !important" }}>
          <div className={commonCssStyles.settingsAutomationcommonTableStyles}>
            <p className={commonCssStyles.settingsAutomationDetailsBodyFixed}>
              <UserOutlined style={{ marginRight: "10px" }} />
              <b>Early Overtime Rules</b> &#40;Automate overtime for employees
              who are working extra before their shift hours&#41;
            </p>

            <p className={commonCssStyles.settingsAutomationDetailsBody}>
            <Link to={"/settings/attendance-automation/early_overtime"}>
                <span style={{ color: "#1d4ed8" }}>
                  View All &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </Link>
              <RightOutlined />
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default AutomationRules;
