import {
  SETTINGS_OVER_TIME_RULE_URL,
  USER_PAYROLL_OVER_TIME_RULE_URL,
  USER_PAYROLL_EARLY_OVER_TIME_RULE_URL
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
import {
  getEarlyOverTimeRuleAssignSlice,
  getOverTimeRuleAssignSlice,
  getOverTimeRuleDetaisSlice,
  getOverTimeRuleSlice,
} from "../Slice/OverTimeRuleSlice";
const api = new AxiosApi();

export const getOverTimeRuleAction = () => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_OVER_TIME_RULE_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getOverTimeRuleSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postOverTimeRuleAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_OVER_TIME_RULE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getOverTimeRuleAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const deleteOverTimeRuleAction = (id) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_OVER_TIME_RULE_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        // callback(false);
        if (success) {
          if (statusCode === 200) {
            // callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getOverTimeRuleAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const getOverTimeRuleDetailsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_OVER_TIME_RULE_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getOverTimeRuleDetaisSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const putOverTimeRuleRuleAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_OVER_TIME_RULE_URL + "/" + val.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getOverTimeRuleAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//Assign Staff to rules

export const getOverTimeRuleAssignAction = (val) => {
  return (dispatch) => {
    api.invoke(
      USER_PAYROLL_OVER_TIME_RULE_URL + "/" + val,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getOverTimeRuleAssignSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postOverTimeRuleAssignAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      USER_PAYROLL_OVER_TIME_RULE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//Assign Staff to rules end



//Assign Staff to early overtime 

export const getEarlyOverTimeRuleAssignAction = (val) => {
  return (dispatch) => {
    api.invoke(
      USER_PAYROLL_EARLY_OVER_TIME_RULE_URL + "/" + val,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getEarlyOverTimeRuleAssignSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postEarlyOverTimeRuleAssignAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      USER_PAYROLL_EARLY_OVER_TIME_RULE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};


//Assign Staff to early overtime end 