import React, { useEffect, useState } from "react";
import Headers from "../../../UI/headersComp/Headers";
import commonCssStyles from "../../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  MoreOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  BonusIcon,
  DeductionsIcon,
  FilterOutlinedIcon,
  OverTimeIcon,
  PaymentIcon,
  RaiseHandIcon,
} from "../../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router";
import {
  deleteShiftsAction,
  getShiftsAction,
  getShiftsAssignAction,
  postShiftsAssignAction,
} from "../../../../Reusable/Store/Action/ShiftsAction";
import { connect } from "react-redux";

function ShiftSettings({
  shifts,
  getShiftsAction,
  deleteShiftsAction,
  getShiftsAssignAction,
  shiftAssignToStaff,
  postShiftsAssignAction
}) {
  useEffect(() => {
    getShiftsAction();
  }, []);


  const [form] = Form.useForm();



  const initialValues = {};

  useEffect(() => {
    if (shiftAssignToStaff && shiftAssignToStaff.length > 0) {
      shiftAssignToStaff.forEach((item) => {
        initialValues[item.id] = item.isShiftSet === 1; 
      });
  
      initialValues["selectAll"] = false;
  
      form.setFieldsValue(initialValues);
    }
  }, [shiftAssignToStaff, form]);



  console.log(shiftAssignToStaff && shiftAssignToStaff, "shiftAssignToStaff");
  const navigate = useNavigate();
  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        handleCancel();
        handleCancel1();
      }}
    />
  );

  const [deleteId, setDeleteId] = useState("");

  const [ShiftsId,setShiftId]=useState("");

  const handleMenuClick = (e) => {
    if (e.key === "edit") {
      // Handle the edit action
      console.log("Edit clicked");
    } else if (e.key === "delete") {
      // Handle the delete action
      console.log("Delete clicked");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteShiftsAction(deleteId);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //Assign Staff
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  //Assign Staff end
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Row justify={"space-between"}>
          <Col>
            <Link to="/settings">
              <h1 className={commonCssStyles.BackButtonTxt}>
                {" "}
                <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
                &nbsp; Back
              </h1>
            </Link>
          </Col>
        </Row>

        <Row justify={"space-between"} style={{ marginTop: "20px" }}>
          <Col>
            <h1 className={commonCssStyles.PageHeadingTxt}>Shift Settings</h1>
          </Col>
          <Col>
            <Link to="/settings/shifts/add">
              <Button type="primary" className={commonCssStyles.addBtnStyles}>
                Add Shift
              </Button>
            </Link>
          </Col>
        </Row>

        {/* <Row style={{ marginTop: "40px" }}>
          <h1 className={commonCssStyles.TableSecHeadingTxt}>
            Fixed Shift &#40;{" "}
            {shifts && shifts.length > 0 ? shifts.length : "0"} &#41;
          </h1>
        </Row> */}

        <div style={{ marginTop: "40px" }}>
          {shifts &&
            shifts.map((data, index) => {
              return (
                <>
                  <Card
                    key={index}
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        <Row style={{ gap: "20px", marginTop: "10px" }}>
                          <Col>
                            <p
                              className={commonCssStyles.leavePolicyettingsName}
                            >
                              {data && data.name && data.name
                                ? data.name
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ display: "flex", alignSelf: "center" }}>
                        <Dropdown
                          placement="bottomRight"
                          overlayStyle={{ width: "300px" }}
                          overlay={
                            <div className={commonCssStyles.AddStaffCard1}>
                              <Link
                                to={"/settings/shifts/edit"}
                                state={{
                                  id: data.id,
                                }}
                              >
                                <Button
                                  style={{ marginTop: "10px", width: "100%" }}
                                  className={commonCssStyles.secondaryBtnStyles}
                                >
                                  Edit
                                </Button>
                              </Link>

                              <Button
                                style={{ marginTop: "10px", width: "100%" }}
                                className={commonCssStyles.secondaryBtnStyles}
                                onClick={() => {
                                  // deleteShiftsAction(data.id)

                                  setDeleteId(data.id);

                                  showModal();
                                }}
                              >
                                Delete
                              </Button>

                              <Button
                                style={{ marginTop: "10px", width: "100%" }}
                                className={commonCssStyles.secondaryBtnStyles}
                                onClick={() => {
                                  setShiftId(data.id);
                                  getShiftsAssignAction(data.id);
                                  showModal1();
                                }}
                              >
                                Assign Shift
                              </Button>
                            </div>
                          }
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <div>
                              <Col>
                                <MoreOutlined
                                  style={{
                                    fontSize: "30px",
                                    marginRight: "15px",
                                    color: "var(--main-heading-clr)",
                                  }}
                                />
                              </Col>
                            </div>
                          </a>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Card>
                </>
              );
            })}
        </div>
      </div>
      <Modal
        title="Delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleOk();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <p className={commonCssStyles.modalSubHeadingStyles}>
          Are you sure you want to delete
        </p>
      </Modal>

      <Modal
        title="Assign Shift"
        open={isModalOpen1}
        closeIcon={closeIcon}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleCancel1();
              }}
            >
              Back
            </Button>
            <Button
              key="applyFilter4"
              type="primary"
              htmlType="submit"
              className={commonCssStyles.applyFilterStyles}
              form="staffAttendance"
            >
              Save & Continue
            </Button>
          </div>,
        ]}
      >
        <Form id="staffAttendance" layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinish={(values) => {
          const selectedIds = Object.keys(values).filter(itemId => values[itemId]);
          
          const Data = {
            shiftId:ShiftsId,
            userId:selectedIds
          }
          postShiftsAssignAction(Data,(success)=>{
            if(success){
              form.resetFields();
              handleCancel1();
            }
          })
          console.log(Data,"Final data")
        }}
        >
          <Row style={{ marginTop: "10px" }}>
            <Col xs={24}>
            {shiftAssignToStaff && shiftAssignToStaff.length > 0 ? (
        shiftAssignToStaff.map((item, index) => (
          <React.Fragment key={item.id}>
            <Row
              justify="space-between"
              style={{
                borderBottom:
                  index === shiftAssignToStaff.length - 1
                    ? "none"
                    : "1px solid #e8e8e8",
                paddingBottom: "5px",
                marginBottom: "5px",
              }}
            >
              <Col>
                <Form.Item
                  name={item.id}
                  initialValue={item.isShiftSet}
                  valuePropName="checked"
                  noStyle
                >
                  <Checkbox>
                    <span className={commonCssStyles.formLabelTxt}>
                      {item.name}
                    </span>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        ))
      ) : (
        <p>No Data</p>
      )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    shifts: state.shifts.shifts,
    shiftAssignToStaff: state.shifts.shiftAssignToStaff,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShiftsAction: () => {
      dispatch(getShiftsAction());
    },

   

    deleteShiftsAction: (id) => {
      dispatch(deleteShiftsAction(id));
    },

    //Assign SHift 

    getShiftsAssignAction: (val) => {
      dispatch(getShiftsAssignAction(val));
    },

    postShiftsAssignAction: (val,callback) => {
      dispatch(postShiftsAssignAction(val,callback));
    },

    //Assign SHift end 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftSettings);
