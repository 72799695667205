import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    staffs:null,
    staffDetails:null,

    staffDocumentsDetails:null,

    staffLeaveBalance:null,
    staffLeaveRequest:null,

    staffLeaveDetails:null,

   

    staffEarnings:null,
    staffEarningsDetails:null,

    staffDeduction:null,
    staffDeductionDetails:null,


    StaffOvertimeDetails : null,
}

export const StaffSlice = createSlice({
    name:'staff',
    initialState,
    reducers:{
        getStaffFilterSlice(state,action){
            state.staffs=action.payload.result;
        },
        getStaffDetailsSlice(state,action){
            state.staffDetails=action.payload.result;
        },
       
        //Documents 
        getStaffDocumnetsDetailsSlice(state,action){
            state.staffDocumentsDetails=action.payload.result;
        },
        //Documents end 
        //Leave
        getStaffLeaveBalanceSlice(state,action){
            state.staffLeaveBalance=action.payload.result;
        },


        getStaffLeaveRequestSlice(state,action){
            state.staffLeaveRequest=action.payload;
        },

        getStaffLeaveDetailsSlice(state,action){
            state.staffLeaveDetails=action.payload.result;
        },

//Leave end


        getStaffEarningsFilterSlice(state,action){
            state.staffEarnings=action.payload.result;
        },
        getStaffEarningsDetailsSlice(state,action){
            state.staffEarningsDetails=action.payload.result;
        },


        getStaffDeductionFilterSlice(state,action){
            state.staffDeduction=action.payload.result;
        },
        getStaffDeductionDetailsSlice(state,action){
            state.staffDeductionDetails=action.payload.result;
        },


        //overtime 
        getStaffOvertimeSlice(state,action){
            state.StaffOvertimeDetails=action.payload.result;
        },
        //overtime end 
    }
})

export const {
    getStaffFilterSlice,
    getStaffDetailsSlice,
    getStaffDeductionFilterSlice,
    getStaffEarningsFilterSlice,
    getStaffEarningsDetailsSlice,
    getStaffDeductionDetailsSlice,
    getStaffLeaveBalanceSlice,
    getStaffLeaveRequestSlice,
    getStaffLeaveDetailsSlice,
    getStaffDocumnetsDetailsSlice,
    getStaffOvertimeSlice
} = StaffSlice.actions;

export default StaffSlice.reducer;