import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TimePicker,
} from "antd";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import dayjs from "dayjs";
import {
  getStaffDetailsAction,
  putStaffProfileDetailsAction,
} from "../../../Reusable/Store/Action/StaffAction";
import { getDepartmentAction } from "../../../Reusable/Store/Action/DepartmentsAction";

function StaffProfileEdit({
  getStaffDetailsAction,
  staffDetails,
  putStaffProfileDetailsAction,
  departments,
  getDepartmentAction,
}) {
  useEffect(() => {
    getDepartmentAction();
  }, []);

  const location = useLocation();
  const { TextArea } = Input;

  console.log(location && location);
  const navigate = useNavigate();
  const { Option } = Select;
  const [form] = Form.useForm();

  const [addressCheck, setAddressCheck] = useState("0");

  useEffect(() => {
    if (location && location.state && location.state.id) {
      getStaffDetailsAction(location.state.id);
    }
  }, [location?.state?.id]);

  console.log(staffDetails && staffDetails, "staffDetails staffDetails");

  useEffect(() => {
    if (staffDetails) {
      form.setFieldsValue({
        firstName: staffDetails.firstName,
        lastName: staffDetails.lastName,
        phone: staffDetails.phone,
        whatsApp: staffDetails.whatsApp,
        dob: staffDetails.dob && dayjs(staffDetails.dob),
        gender: staffDetails.gender,
        empId: staffDetails.empId,
        status: staffDetails.status,
        joiningDate:
          staffDetails.joiningDate && dayjs(staffDetails.joiningDate),
        designation: staffDetails.designation,
        department: staffDetails.department,
        bankName: staffDetails.bankName,
        bankBranchName: staffDetails.bankBranchName,
        AccountName: staffDetails.AccountName,
        AccountNumber: staffDetails.AccountNumber,
        ifsc: staffDetails.ifsc,
        upi: staffDetails.upi,
        uan: staffDetails.uan,
        pan: staffDetails.pan,
        esi: staffDetails.esi,

        location: staffDetails.addressInfo[0].location,
        address: staffDetails.addressInfo[0].address,
        city: staffDetails.addressInfo[0].city,
        state: staffDetails.addressInfo[0].state,
        country: staffDetails.addressInfo[0].country,
        pincode: staffDetails.addressInfo[0].pincode,

        permanantlocation: staffDetails.addressInfo[1].location,
        permanantaddress: staffDetails.addressInfo[1].address,
        permanantCity: staffDetails.addressInfo[1].city,
        permanantState: staffDetails.addressInfo[1].state,
        permanantCountry: staffDetails.addressInfo[1].country,
        permanantPincode: staffDetails.addressInfo[1].pincode,
      });
      // setAddressCheck(staffDetails.addressCheck);
    }
  }, [staffDetails, form]);

  const onChange = (e) => {
    if (e.target.checked == true) {
      setAddressCheck("1");
    } else {
      setAddressCheck("0");
    }
    console.log(`checked = ${e.target.checked}`);
  };

  const onFinish = (values) => {
    const addressInfo = [];
    if (addressCheck === "0") {
      addressInfo.push({
        type: "current",
        location: values.location,
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country,
        pincode: values.pincode,
      });
      addressInfo.push({
        type: "permanent",
        location: values.permanantlocation,
        address: values.permanantaddress,
        city: values.permanantCity,
        state: values.permanantState,
        country: values.permanantCountry,
        pincode: values.permanantPincode,
      });
    } else {
      addressInfo.push({
        type: "current",
        location: values.location,
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country,
        pincode: values.pincode,
      });
      addressInfo.push({
        type: "permanent",
        location: values.location,
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country,
        pincode: values.pincode,
      });
    }
    const data = {
      ...values,
      _method: "PUT",
      updateType: "personal",
      dob: dayjs(values.dob).format("YYYY/MM/DD"),
      joiningDate: dayjs(values.joiningDate).format("YYYY/MM/DD"),
      addressCheck: addressCheck,
      addressInfo: addressInfo,
      id: location.state.id,
    };
    console.log("Form values:", data);

    putStaffProfileDetailsAction(data, (success) => {
      if (success) {
        console.log("Success");
        navigate("/staff/staff-detail-profile", {
          state: {
            id: location.state.id,
          },
        });
      }
    });
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link
            to="/staff/staff-detail-profile"
            state={{ id: location.state.id }}
          >
            <h1 className={commonCssStyles.BackButtonTxt}>
              {" "}
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>{" "}
      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Edit Staff Profile</h1>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col xs={24}>
          <Card>
            <Row>
              <Col md={24}>
                <Form
                  style={{ margin: "5px" }}
                  initialValues={{
                    shifttype: "fixed",
                  }}
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                >
                   <p
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                    className={commonCssStyles.TableSecHeadingTxt}
                  >
                   <b> Personal Details </b> 
                  </p>
                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            First Name
                          </p>
                        }
                        name="firstName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Last Name
                          </p>
                        }
                        name="lastName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>Phone</p>
                        }
                        name="phone"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            WhatsApp
                          </p>
                        }
                        name="whatsApp"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item name="dob" label="DOB">
                        <DatePicker
                          // placeholder="periodFrom"
                          style={{ width: "100%" }}
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item name="gender" label="Gender">
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            console.log(val, "selected value");
                          }}
                        >
                          <Select.Option value="male">Male</Select.Option>
                          <Select.Option value="female">Female</Select.Option>
                          <Select.Option value="other">Other</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Employee ID
                          </p>
                        }
                        name="empId"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item name="joiningDate" label="Joining Date">
                        <DatePicker
                          // placeholder="periodFrom"
                          style={{ width: "100%" }}
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Designation
                          </p>
                        }
                        name="designation"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Department
                          </p>
                        }
                        name="department"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                        >
                          {departments &&
                            departments.map((data, index) => (
                              <Option key={index} value={data.departmentName}>
                                {data.departmentName}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <p
                    style={{ marginTop: "40px", marginBottom: "20px" }}
                    className={commonCssStyles.TableSecHeadingTxt}
                  >
                   <b> Bank Details </b> 
                  </p>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            BankName
                          </p>
                        }
                        name="bankName"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Bank Branch Name
                          </p>
                        }
                        name="bankBranchName"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Account Name
                          </p>
                        }
                        name="AccountName"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Account Number
                          </p>
                        }
                        name="AccountNumber"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>IFSC</p>
                        }
                        name="ifsc"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>UPI</p>
                        }
                        name="upi"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <p
                    style={{ marginTop: "40px", marginBottom: "20px" }}
                    className={commonCssStyles.TableSecHeadingTxt}
                  >
                   <b> Other Details </b> 
                  </p>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>UAN</p>
                        }
                        name="uan"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>PAN</p>
                        }
                        name="pan"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>ESI</p>
                        }
                        name="esi"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item name="status" label="Status">
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            console.log(val, "selected value");
                          }}
                        >
                          <Select.Option value="active">Active</Select.Option>
                          <Select.Option value="inactive">
                            Inactive
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <p
                    style={{ marginTop: "40px", marginBottom: "20px" }}
                    className={commonCssStyles.TableSecHeadingTxt}
                  >
                   <b> Current Address </b> 
                  </p>

                  {/* <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>location</p>
                    }
                    name="location"
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item> */}

                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>Address</p>
                    }
                    name="address"
                    // rules={[{ required: true }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>City</p>
                        }
                        name="city"
                        // rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>State</p>
                        }
                        name="state"
                        // rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify={"space-between"}>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Country
                          </p>
                        }
                        name="country"
                        // rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className={commonCssStyles.formDivisionWidth}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Pincode
                          </p>
                        }
                        name="pincode"
                        // rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Checkbox
                    // defaultChecked={
                    //   staffDetails && staffDetails.addressCheck === "1"
                    // }
                    onChange={onChange}
                  >
                    Current address is same as permanent address
                  </Checkbox>

                  {addressCheck && addressCheck == "0" ? (
                    <>
                      <div style={{ marginTop: "20px" }}>
                        {/* <Form.Item
                          label={
                            <p className={commonCssStyles.formLabelTxt}>
                              location
                            </p>
                          }
                          name="permanantlocation"
                          // rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item> */}

                        <p
                          style={{ marginTop: "40px", marginBottom: "20px" }}
                          className={commonCssStyles.TableSecHeadingTxt}
                        >
                        <b> Permanant Address </b> 
                        </p>

                        <Form.Item
                          label={
                            <p className={commonCssStyles.formLabelTxt}>
                              Address
                            </p>
                          }
                          name="permanantaddress"
                          // rules={[{ required: true }]}
                        >
                          <TextArea rows={4} />
                        </Form.Item>

                        <Row justify={"space-between"}>
                          <Col className={commonCssStyles.formDivisionWidth}>
                            <Form.Item
                              label={
                                <p className={commonCssStyles.formLabelTxt}>
                                  City
                                </p>
                              }
                              name="permanantCity"
                              // rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col className={commonCssStyles.formDivisionWidth}>
                            <Form.Item
                              label={
                                <p className={commonCssStyles.formLabelTxt}>
                                  State
                                </p>
                              }
                              name="permanantState"
                              // rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row justify={"space-between"}>
                          <Col className={commonCssStyles.formDivisionWidth}>
                            <Form.Item
                              label={
                                <p className={commonCssStyles.formLabelTxt}>
                                  Country
                                </p>
                              }
                              name="permanantCountry"
                              // rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col className={commonCssStyles.formDivisionWidth}>
                            <Form.Item
                              label={
                                <p className={commonCssStyles.formLabelTxt}>
                                  Pincode
                                </p>
                              }
                              name="permanantPincode"
                              // rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <Row style={{ marginTop: "30px" }}>
                    <Col xs={24}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginTop: "20px" }}
                            className={commonCssStyles.addBtnStyles}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    staffDetails: state.staff.staffDetails,
    departments: state.departments.departments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaffDetailsAction: (id) => {
      dispatch(getStaffDetailsAction(id));
    },
    putStaffProfileDetailsAction: (val, callback) => {
      dispatch(putStaffProfileDetailsAction(val, callback));
    },

    getDepartmentAction: () => {
      dispatch(getDepartmentAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffProfileEdit);
