import { Col, Row, Form, Input, Button, Image } from "antd";
import React from "react";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";

import IntelliSpidersLogo from "../../../Assets/Image/DefaultProfileImg.png";
import IntelliSpidersLogo1 from "../../../Assets/Image/IntelliSpdersLogo1.png";
import IntelliSpidersLogo4 from "../../../Assets/Image/IntelliSpdersLogo.png";
import { connect } from "react-redux";
import { loginAction, sendResetLinkhAction } from "../../../Reusable/Store/Action/AuthenticationAction";
import { Link, useNavigate } from "react-router-dom";

function ForgotPassword({sendResetLinkhAction, isverify}) {

  let navigate = useNavigate();


  const leftColStyle = {
    backgroundColor: "#061242",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const rightColStyle = {
    backgroundColor: "#ffffff",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const onFinish = (values) => {
    // Handle form submission here
    sendResetLinkhAction({ ...values, type: "web" }, navigate)
    // You can perform any further actions like sending data to a server
  };

  const onFinishFailed = (errorInfo) => {
    // Handle form submission error here
    console.log("Form submission failed:", errorInfo);
  };

  return (
    <Row style={{ height: "100%", backgroundColor: "#ffffff" }}>
      <Col
        xs={0}
        sm={12}
        style={leftColStyle}
        className={commonCssStyles.loginLeftCardStyle}
      >
        <Image
          preview={false}
          width={400}
          src={IntelliSpidersLogo4}
          className={commonCssStyles.leftCardImage}
        />
      </Col>
      <Col xs={24} sm={12} style={rightColStyle}>
        <Form
          style={{ width: "100%", padding: "0px 100px" }}
          className={commonCssStyles.loginFormStyles}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          {/* <Row style={{ marginBottom: "80px" }} justify="center">
          <Col>
            <Image preview={false} width={150} src={IntelliSpidersLogo1} />
          </Col>
        </Row> */}

          <Row
            style={{ marginBottom: "40px" }}
            justify="center"
            className={commonCssStyles.hideImgeInDsktpMode}
          >
            <Col>
              <Image preview={false} width={150} src={IntelliSpidersLogo1} />
            </Col>
          </Row>

          <Row style={{ marginBottom: "20px" }} justify="center">
            <Col>
              <h1
                className={commonCssStyles.PageHeadingTxt}
                style={{
                  fontSize: "36px",
                }}
              >
                Forgot Password
              </h1>
              <p className={commonCssStyles.attendanceLableTxt}>Enter the registered email, we will send the reset link for resetting password into your email along with instructions</p>
            </Col>
          </Row>

          <Form.Item
            label="Email"
            name="email"
            // rules={[{ required: true }]}
            rules={[
              {
                required: true,
                message: "Email is Required",
              },
              {
                pattern: /^([a-zA-Z0-9\s.]+)@([a-zA-Z0-9]+)\.([a-zA-Z]{2,5})$/,
                message: "Invalid Email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Row justify={"center"} style={{ marginTop: "60px" }}>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={commonCssStyles.addBtnStyles}
                >
                  Send rest link
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendResetLinkhAction: (val, navigate) => {
      dispatch(sendResetLinkhAction(val, navigate));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
