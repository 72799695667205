import React, { useEffect, useState } from "react";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tabs,
  TimePicker,
  message,
} from "antd";

import {
  DownOutlined,
  ProfileOutlined,
  ShoppingOutlined,
  CloseOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  BonusIcon,
  DeductionsIcon,
  OverTimeIcon,
  RaiseHandIcon,
} from "../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router";
import { connect } from "react-redux";
import {
  deleteStaffDeductionDetailsAction,
  deleteStaffEarningDetailsAction,
  deleteStaffLeaveDetailsAction,
  getStaffDeductionDetailsAction,
  getStaffDeductionFilterAction,
  getStaffDetailsAction,
  getStaffEarningDetailsAction,
  getStaffEarningFilterAction,
  getStaffLeaveBalanceAction,
  getStaffLeaveDetailsAction,
  getStaffLeaveRequestAction,
  postStaffEarningAction,
  postStaffLeaveAction,
  putStaffDeductionDetailsAction,
  putStaffEarningDetailsAction,
  updateStaffLeaveBalanceAction,
  updateStaffLeaveDetailsAction,
} from "../../../Reusable/Store/Action/StaffAction";
import dayjs from "dayjs";
import {
  getAttendanceFilterAction,
  putAttendanceAction,
} from "../../../Reusable/Store/Action/AttendanceAction";
import Overtime from "./Overtime";

function StaffDetails({
  getStaffDetailsAction,
  staffDetails,
  getStaffEarningFilterAction,
  getStaffDeductionFilterAction,
  staffDeduction,
  staffEarnings,
  postStaffEarningAction,
  getStaffEarningDetailsAction,
  staffEarningsDetails,
  putStaffEarningDetailsAction,
  deleteStaffEarningDetailsAction,
  getStaffDeductionDetailsAction,
  putStaffDeductionDetailsAction,
  deleteStaffDeductionDetailsAction,
  staffDeductionDetails,
  postStaffLeaveAction,
  leavePolicy,
  getStaffLeaveBalanceAction,
  staffLeaveBalanceData,
  updateStaffLeaveBalanceAction,
  staffLeaveRequest,
  getStaffLeaveRequestAction,
  getStaffLeaveDetailsAction,
  staffLeaveDetails,
  updateStaffLeaveDetailsAction,
  deleteStaffLeaveDetailsAction,
  attendance,
  getAttendanceFilterAction,
  putAttendanceAction,
}) {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [editAllowanceForm] = Form.useForm();

  const [editDeductionForm] = Form.useForm();

  const [addLeaveForm] = Form.useForm();
  const [editMarkLeaveForm] = Form.useForm();
  const [startDate, setStartDate] = useState(null);
  const [startDateEdit, setStartDateEdit] = useState(null);

  const [leaveBalanceForm] = Form.useForm();

  const [fullOrHalfDay, setFullOrHalfDay] = useState("fullDay");
  const [editFullOrHalfDay, setEditFullOrHalfDay] = useState("");
  const [selectedDate, setSelecteddate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const { TextArea } = Input;

  const location = useLocation();

  //Attendance Team

  const [selectedButton, setSelectedButton] = useState("present");

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  console.log(location && location);

  const handleSearch = (value) => {
    // Logic for handling search query
    console.log("Search query:", value);
  };

  const handleClear = () => {
    // Logic for clearing the search query
    console.log("Clear search query");
  };
  //Attendance Team end

  //Attendance Related Code
  const [markPresentId, setMarkPresentId] = useState("");
  const [logsDisp, setLogsDisp] = useState("");

  const [dispSave, setDispSave] = useState(false);

  const [dispOvertimeSave, setDispOvertimeSave] = useState(false);


  //Modal For Attendance
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  //end

  //Attendance Related Code end
  const [overTimeHoursDisp, setOverTimeHoursDisp] = useState("fixed");
  const [earlyOverTimeHoursDisp, setEarlyOverTimeHoursDisp] = useState("fixed");

  useEffect(() => {
    if (location && location.state && location.state.id) {
      getStaffDetailsAction(location.state.id);
      getStaffLeaveBalanceAction(location.state.id);
      getStaffLeaveRequestAction(location.state.id);
      getAttendanceFilterAction({
        userId: location.state.id,
        month: dayjs().format("YYYY-MM-DD"),
      });
    }
  }, [location?.state?.id]);

  //Deduction and Allowances
  useEffect(() => {
    if (location && location.state && location.state.id) {
      getStaffEarningFilterAction({
        type: "earning",
        userId: location.state.id,
        month: dayjs().format("YYYY-MM-DD"),
      });

      getStaffDeductionFilterAction({
        type: "deduction",
        userId: location.state.id,
        month: dayjs().format("YYYY-MM-DD"),
      });
    }
  }, [location?.state?.id]);

  //Deduction and Allowances end

  // Allowances/Bonus Edit Modal
  useEffect(() => {
    if (staffEarningsDetails) {
      const dateAsDateObject = dayjs(staffEarningsDetails.date);
      editAllowanceForm.setFieldsValue({
        type: staffEarningsDetails.type,
        amount: staffEarningsDetails.amount,
        description: staffEarningsDetails.description,
        isPaid: staffEarningsDetails.isPaid,
        date: dateAsDateObject,
      });
    }
  }, [staffEarningsDetails, editAllowanceForm]);
  // Allowances/Bonus Edit Modal End

  //edit Mark Leave Form
  useEffect(() => {
    if (staffLeaveDetails && staffLeaveDetails.type === "fullDay") {
      const startDateObject = dayjs(staffLeaveDetails.startDate);
      const endDateObject = dayjs(staffLeaveDetails.endDate);
      setEditFullOrHalfDay("fullDay");
      editMarkLeaveForm.setFieldsValue({
        startDate: startDateObject,
        endDate: endDateObject,
        type: staffLeaveDetails.type,
        
        leaveCategoryId: staffLeaveDetails.leaveCategoryId,
        description: staffLeaveDetails.description,
        duration: staffLeaveDetails.duration,
      });
    } else if (staffLeaveDetails && staffLeaveDetails.type === "halfDay") {
      const startDateObject = dayjs(staffLeaveDetails.startDate);
      setEditFullOrHalfDay("halfDay");
      editMarkLeaveForm.setFieldsValue({
        startDate: startDateObject,
        type: staffLeaveDetails.type,
        
        leaveCategoryId: staffLeaveDetails.leaveCategoryId,
        description: staffLeaveDetails.description,
        duration: 0.5,
      });
    }
  }, [staffLeaveDetails, editMarkLeaveForm]);

  //edit Mark Leave Form End

  console.log(staffDetails && staffDetails, "staffDetails staffDetails");

  //Edit Deduction Modal

  useEffect(() => {
    if (staffDeductionDetails) {
      const dateAsDateObject = dayjs(staffDeductionDetails.date);
      editDeductionForm.setFieldsValue({
        date: dateAsDateObject,
        amount: staffDeductionDetails.amount,
        deductionType: staffDeductionDetails.deductionType,
        description: staffDeductionDetails.description,
      });
    }
  }, [staffDeductionDetails, editDeductionForm]);

  //Edit Deduction Modal End

  console.log(staffLeaveRequest && staffLeaveRequest, "staffLeaveDetails 4");

  const [activeTab, setActiveTab] = useState(0);

  const [deleteAllowanceId, setDeleteAllowanceId] = useState("");
  const [deleteDeductionId, setDeleteDeductionId] = useState("");
  const [deleteMarkLeaveId, setDeleteMarkLeaveId] = useState("");

  //leave date and time calculations
  const [daysDifference, setDaysDifference] = useState(0);
  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });

  const handleStartDateChange = (date) => {
    setDaysDifference(null);
    setStartDate(date);
    addLeaveForm.setFieldsValue({ endDate: null });
  };

  const handleEndDateChange = (date) => {
    addLeaveForm.setFieldsValue({ endDate: date });
    calculateDaysDifference(startDate, date); // Calculate days difference when changing end date
  };

  const disabledEndDate = (current) => {
    if (!startDate) {
      return current && current.isBefore(dayjs(), "day");
    }
    return (
      current &&
      (current.isBefore(dayjs(), "day") || current.isBefore(startDate, "day"))
    );
  };

  const calculateDaysDifference = (start, end) => {
    if (start && end) {
      const startDay = dayjs(start);
      const endDay = dayjs(end);
      const difference = endDay.diff(startDay, "day") + 1;
      setDaysDifference(difference);
    }
  };

  const disabledEditEndDate = (current) => {
    if (!startDateEdit) {
      return current && current.isBefore(dayjs(), "day");
    }
    return (
      current &&
      (current.isBefore(dayjs(), "day") ||
        current.isBefore(startDateEdit, "day"))
    );
  };
  //leave date and time calculations end

  //for edit day difference calculation
  const updateDaysEditDayDifference = (startDate, endDate) => {
    if (startDate && endDate) {
      const start = dayjs(startDate, "DD/MM/YYYY");

      const end = dayjs(endDate, "DD/MM/YYYY");

      const difference = end.diff(start, "day") + 1;

      updateDaysEditDayDifference(difference);
    }
  };
  //for edit day difference calculation end
  // Leave Balance Calculations
  const valuesChangeCalculate = (changedValues, allValues) => {
    const a = Number(allValues.annualLeave);
    const b = Number(allValues.casualLeave);
  };

  const calculateTotalLeaveBalance = () => {
    const values = leaveBalanceForm.getFieldsValue();
    const annualLeave = values.annualLeave || 0;
    const casualLeave = values.casualLeave || 0;
    const sickLeave = values.sickLeave || 0;
    const compOffLeave = values.compOffLeave || 0;

    return annualLeave + casualLeave + sickLeave + compOffLeave;
  };

  // Leave Balance Calculations end

  //Mark Leave Modal

  const [markLeaveModalOpen, setMarkLeaveModalOpen] = useState(false);
  const showMarkLeaveModal = () => {
    setMarkLeaveModalOpen(true);
  };
  const handleMarkLeaveOk = () => {
    setMarkLeaveModalOpen(false);
  };
  const handleMarkLeaveCancel = () => {
    setMarkLeaveModalOpen(false);
  };
  //Mark Leave Modal End

  //Mark Leave Edit Modal

  const [markLeaveEditModalOpen, setMarkLeaveEditModalOpen] = useState(false);
  const showMarkLeaveEditModal = () => {
    setMarkLeaveEditModalOpen(true);
  };
  const handleMarkEditLeaveOk = () => {
    setMarkLeaveEditModalOpen(false);
  };
  const handleMarkLeaveEditCancel = () => {
    setMarkLeaveEditModalOpen(false);
  };
  //Mark Leave Edit Modal End

  //Edit leave Balance Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  //Edit leave Balance Modal End

  //Allowances/Bonus Modal
  const [isAllowanceModalOpen, setAllowanceModalOpen] = useState(false);
  const showAllowanceModal = () => {
    setAllowanceModalOpen(true);
  };
  const handleAllowanceModalOk = () => {
    setAllowanceModalOpen(false);
  };
  const handleAllowanceModalCancel = () => {
    setAllowanceModalOpen(false);
  };
  //Allowances/Bonus Modal End

  //Allowances/Bonus Delete Modal
  const [isAllowanceDeleteModalOpen, setAllowanceDeleteModalOpen] =
    useState(false);
  const showAllowanceDeleteModal = () => {
    setAllowanceDeleteModalOpen(true);
  };
  const handleAllowanceDeleteModalOk = () => {
    deleteStaffEarningDetailsAction(deleteAllowanceId, (success) => {
      if (success) {
        getStaffEarningFilterAction({
          type: "earning",
          userId: location.state.id,
          month: dayjs(),
        });
      }
    });
    setAllowanceDeleteModalOpen(false);
  };
  const handleAllowanceDeleteModalCancel = () => {
    setAllowanceDeleteModalOpen(false);
  };
  //Allowances/Bonus Delete Modal End

  //Allowances/Bonus Edit Modal

  const [isAllowanceEditModalOpen, setAllowanceEditModalOpen] = useState(false);
  const showAllowanceEditModal = () => {
    setAllowanceEditModalOpen(true);
  };
  const handleAllowanceEditModalOk = () => {
    setAllowanceEditModalOpen(false);
  };
  const handleAllowanceEditModalCancel = () => {
    setAllowanceEditModalOpen(false);
  };

  //Allowances/Bonus Edit Modal End

  //Deductions Modal

  const [isDeductionsModalOpen, setDeductionsModalOpen] = useState(false);
  const showDeductionsModal = () => {
    setDeductionsModalOpen(true);
  };
  const handleDeductionsModalOk = () => {
    setDeductionsModalOpen(false);
  };
  const handleDeductionsModalCancel = () => {
    setDeductionsModalOpen(false);
  };

  //Deductions Modal End

  //Deductions Edit Modal

  const [isDeductionsEditModalOpen, setDeductionsEditModalOpen] =
    useState(false);
  const showDeductionsEditModal = () => {
    setDeductionsEditModalOpen(true);
  };
  const handleDeductionsEditModalOk = () => {
    setDeductionsEditModalOpen(false);
  };
  const handleDeductionsEditModalCancel = () => {
    setDeductionsEditModalOpen(false);
  };

  //Deductions Edit Modal End

  //Deductions Delete Modal
  const [isDeductionsDeleteModalOpen, setDeductionsDeleteModalOpen] =
    useState(false);
  const showDeductionsDeleteModal = () => {
    setDeductionsDeleteModalOpen(true);
  };
  const handleDeductionsDeleteModalOk = () => {
    deleteStaffDeductionDetailsAction(deleteDeductionId, (success) => {
      if (success) {
        getStaffDeductionFilterAction({
          type: "deduction",
          userId: location.state.id,
          month: dayjs().format("YYYY-MM-DD"),
        });
      }
    });
    setDeductionsDeleteModalOpen(false);
  };
  const handleDeductionsDeleteModalCancel = () => {
    setDeductionsDeleteModalOpen(false);
  };
  //Deductions Delete Modal End

  //Delete Mark Leave
  const [isDeleteMarkLeaveDeleteModalOpen, setDeleteMarkLeaveDeleteModalOpen] =
    useState(false);
  const showDeleteMarkLeaveDeleteModal = () => {
    setDeleteMarkLeaveDeleteModalOpen(true);
  };
  const handleDeleteMarkLeaveDeleteModalOk = () => {
    deleteStaffLeaveDetailsAction(deleteMarkLeaveId, (success) => {
      if (success) {
        getStaffLeaveRequestAction(location.state.id);
      }
    });
    setDeleteMarkLeaveDeleteModalOpen(false);
  };
  const handleDeleteMarkLeaveDeleteModalCancel = () => {
    setDeleteMarkLeaveDeleteModalOpen(false);
  };
  //Delete Mark Leave end
  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        setDispSave(false);
        setDispOvertimeSave(false);
        handleCancel();
        handleAllowanceModalCancel();
        handleDeductionsModalCancel();
        handleAllowanceEditModalCancel();
        handleDeductionsEditModalCancel();

        handleAllowanceDeleteModalCancel();
        setMarkLeaveModalOpen();
        handleCancel1();
        form.resetFields();
      }}
    />
  );

  //Leave Related Columns

  const LeaveColumns = [
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate) => dayjs(startDate).format("DD-MM-YYYY"),
    },
    {
      title: "Type (half day / full day)",
      dataIndex: "type",
      key: "type",
      render: (deductionType) => {
        if (deductionType === "halfDay") {
          return "Half Day";
        } else if (deductionType === "fullDay") {
          return "Full Day";
        } else {
          return "NA";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status == "approved") {
          return "Approved";
        } else if (status == "rejected") {
          return "Reject";
        } else if (status == "pending") {
          return "Pending";
        } else if (status == "deleted") {
          return "Deleted";
        }
      },
    },
    {
      title: " ",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (data, render) => {
        return (
          <>
            <Dropdown
              placement="bottomLeft"
              overlayStyle={{ width: "300px" }}
              overlay={
                <div className={commonCssStyles.AddStaffCard1}>
                  <Button
                    style={{ marginTop: "10px", width: "100%" }}
                    className={commonCssStyles.secondaryBtnStyles}
                    onClick={() => {
                      getStaffLeaveDetailsAction({
                        id: render.id,
                      });

                      showMarkLeaveEditModal();
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    style={{ marginTop: "10px", width: "100%" }}
                    className={commonCssStyles.secondaryBtnStyles}
                    onClick={() => {
                      setDeleteMarkLeaveId(render.id);
                      showDeleteMarkLeaveDeleteModal();
                    }}
                  >
                    Delete
                  </Button>
                </div>
              }
            >
              <a onClick={(e) => e.preventDefault()}>
                <div>
                  <MoreOutlined
                    style={{
                      fontSize: "35px",
                      padding: "0px",
                      margin: "0px",
                      color: "var(--main-heading-clr)",
                    }}
                  />
                </div>
              </a>
            </Dropdown>
          </>
        );
      },
    },
  ];
  //Leave Related Columns end

  //Earnigs and deduction columns

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("DD-MM-YYYY"),
    },
    {
      title: "Allowance/Bonus",
      dataIndex: "type",
      key: "type",
      render: (type) => (type === "allowance" ? "Allowance" : "Bonus"),
    },
    {
      title: "Paid/Not Paid",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (isPaid) => (isPaid === 1 ? "Paid" : "Added to Salary"),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: " ",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (data, render) => (
        <>
          <Dropdown
            placement="bottomLeft"
            overlayStyle={{ width: "300px" }}
            overlay={
              <div className={commonCssStyles.AddStaffCard1}>
                <Button
                  style={{ marginTop: "10px", width: "100%" }}
                  className={commonCssStyles.secondaryBtnStyles}
                  onClick={() => {
                    getStaffEarningDetailsAction({
                      id: render.id,
                      // type: "earning",
                    });
                    showAllowanceEditModal();
                  }}
                >
                  Edit
                </Button>

                <Button
                  style={{ marginTop: "10px", width: "100%" }}
                  className={commonCssStyles.secondaryBtnStyles}
                  onClick={() => {
                    setDeleteAllowanceId(render.id);
                    showAllowanceDeleteModal();
                  }}
                >
                  Delete
                </Button>
              </div>
            }
          >
            <a onClick={(e) => e.preventDefault()}>
              <div>
                <MoreOutlined
                  style={{
                    fontSize: "35px",
                    padding: "0px",
                    margin: "0px",
                    color: "var(--main-heading-clr)",
                  }}
                />
              </div>
            </a>
          </Dropdown>
        </>
      ),
    },
  ];

  //Earnigs and deduction columns end

  const columns4 = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("DD-MM-YYYY"),
    },
    {
      title: "Type",
      dataIndex: "deductionType",
      key: "deductionType",
      render: (deductionType) => {
        if (deductionType === "pf") {
          return "PF";
        } else if (deductionType === "esi") {
          return "ESI";
        } else if (deductionType === "other") {
          return "OTHER";
        } else {
          return "NA";
        }
      },
    },
    // {
    //   title: "Paid/Not Paid",
    //   dataIndex: "isPaid",
    //   key: "isPaid",
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: " ",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (data, render) => {
        return (
          <>
            <Dropdown
              placement="bottomLeft"
              // trigger={["click"]}
              overlayStyle={{ width: "300px" }}
              overlay={
                <div className={commonCssStyles.AddStaffCard1}>
                  <Button
                    style={{ marginTop: "10px", width: "100%" }}
                    className={commonCssStyles.secondaryBtnStyles}
                    onClick={() => {
                      getStaffDeductionDetailsAction({
                        id: render.id,
                      });

                      showDeductionsEditModal();
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    style={{ marginTop: "10px", width: "100%" }}
                    className={commonCssStyles.secondaryBtnStyles}
                    onClick={() => {
                      setDeleteDeductionId(render.id);
                      showDeductionsDeleteModal();
                    }}
                  >
                    Delete
                  </Button>
                </div>
              }
            >
              <a onClick={(e) => e.preventDefault()}>
                <div>
                  <MoreOutlined
                    style={{
                      fontSize: "35px",
                      padding: "0px",
                      margin: "0px",
                      color: "var(--main-heading-clr)",
                    }}
                  />
                </div>
              </a>
            </Dropdown>
          </>
        );
      },
    },
  ];
  const onChange = (key) => {};
  const navigate = useNavigate();

  const initialItems = [
    {
      label: (
        <span>
          <ProfileOutlined />
          Summary
        </span>
      ),
      children: (
        <>
          <Card
            size="large"
            style={{
              borderRadius: "7px",
              minHeight: "100vh",
            }}
          >
            sample data 1
          </Card>
        </>
      ),
      key: "1",
    },
    {
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <RaiseHandIcon
            color={activeTab == "2" ? "var(--Color-Primary-all)" : "#000000E0"}
          />
          &nbsp; Attendance
        </span>
      ),
      children: (
        <>
          <Card
            size="large"
            style={{
              borderRadius: "7px",
              minHeight: "100vh",
            }}
          >
            <div>
              <Row justify={"space-between"} style={{ marginLeft: "20px" }}>
                <Col>
                  <Form
                    initialValues={{
                      date: dayjs(),
                    }}
                  >
                    <Form.Item name="date">
                      <DatePicker
                        locale="en"
                        format="DD-MM-YYYY"
                        disabledDate={(currentDate) =>
                          currentDate && currentDate.isAfter(dayjs(), "day")
                        }
                        onChange={(val) => {
                          const attendanceDate =
                            dayjs(val).format("YYYY-MM-DD");
                          getAttendanceFilterAction({
                            userId: location.state.id,
                            month: attendanceDate,
                          });
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col>
                  <h1 className={commonCssStyles.DashboardSubHeadingLabelTxt}>
                    {/* All Approved */}
                  </h1>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                  <Card
                    // className={commonCssStyles.AttendanceOverViewCard}
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>Days</h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>
                      {attendance &&
                      attendance.summary &&
                      attendance.summary.totalDays
                        ? attendance.summary.totalDays
                        : "0"}
                    </h1>
                  </Card>
                </Col>

                <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Present
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>
                      {attendance &&
                      attendance.summary &&
                      attendance.summary.present
                        ? attendance.summary.present
                        : "0"}
                    </h1>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Absent
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>
                      {attendance &&
                      attendance.summary &&
                      attendance.summary.absent
                        ? attendance.summary.absent
                        : "0"}
                    </h1>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Half Day
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>
                      {attendance &&
                      attendance.summary &&
                      attendance.summary.halfDay
                        ? attendance.summary.halfDay
                        : "0"}
                    </h1>
                  </Card>
                </Col>

                <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <h1 className={commonCssStyles.attendanceLableTxt}>
                      Paid Leave
                    </h1>
                    <h1 className={commonCssStyles.attendanceNumberTxt}>
                      {attendance &&
                      attendance.summary &&
                      attendance.summary.paidLeave
                        ? attendance.summary.paidLeave
                        : "0"}
                    </h1>
                  </Card>
                </Col>
              </Row>
            </div>

            <Row style={{ marginTop: "40px" }}></Row>

            <div>
              {attendance &&
                attendance.result &&
                attendance.result.data &&
                attendance.result.data.map((data, index) => {
                  return (
                    <>
                      <Card
                        className={`${commonCssStyles.tableCardsContainer} attendance-custom-card`}
                        style={{ padding: "0px !important" }}
                      >
                        <Row justify={"space-between"}>
                          <Col>
                            <Row>
                              <Col>
                                <p
                                  className={
                                    commonCssStyles.attendancetableDataTxt
                                  }
                                >
                                  {dayjs(data.attendanceDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <p
                                  className={
                                    commonCssStyles.attendancetableTimeTxt
                                  }
                                >
                                  {data.subText}
                                </p>
                                <p
                                  className={
                                    commonCssStyles.attendancetableTimeTxt
                                  }
                                  style={{ margin: "0px" }}
                                >
                                  {data.note}
                                </p>
                                <div style={{ display: "flex" }}>
                                  <p
                                    className={commonCssStyles.addNoteBtnStyles}
                                    onClick={() => {
                                      form.setFieldsValue({
                                        note: data.note,
                                      });
                                      setMarkPresentId(data.id);
                                      handleButtonClick("Add Note");
                                      showModal1();
                                    }}
                                  >
                                    {data.note ? "Edit Note" : "Add Note"}
                                  </p>
                                  <p
                                    className={commonCssStyles.addNoteBtnStyles}
                                  >
                                    &nbsp; | &nbsp;
                                  </p>
                                  <p
                                    className={commonCssStyles.addNoteBtnStyles}
                                    onClick={() => {
                                      setMarkPresentId(data.id);
                                      setLogsDisp(data.log);
                                      handleButtonClick("View Logs");
                                      showModal1();
                                    }}
                                  >
                                    View Logs
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row gutter={[16, 16]}>
                                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Button
                                      type={
                                        data.status === "present"
                                          ? "primary"
                                          : "default"
                                      }
                                      onClick={() => {
                                        setMarkPresentId(data.id);
                                        handleButtonClick("present");
                                        // form.setFieldsValue({
                                        //   startTime:
                                        //     data.startTime == "00:00"
                                        //       ? dayjs()
                                        //       : data.startTime,
                                        //   endTime:
                                        //     data.endTime == "00:00"
                                        //       ? dayjs()
                                        //       : data.endTime,
                                        // });
                                        form.setFieldsValue({
                                          startTime:
                                      data.startTime === "00:00"
                                        ? dayjs() 
                                        : dayjs(data.startTime, 'hh:mm A'), 
                                    endTime:
                                      data.endTime === "00:00"
                                        ? dayjs()
                                        : dayjs(data.endTime, 'hh:mm A'), 
                                        });
                                        showModal1();
                                      }}
                                      style={{
                                        backgroundColor:
                                          data.status === "present"
                                            ? "rgb(16, 170, 112)"
                                            : null,
                                      }}
                                      className={
                                        commonCssStyles.presentAbsentBtnStyles
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {/* <p className={commonCssStyles.presentAbsentBtnTxtStyles}>P</p>
                          <p className={commonCssStyles.presentAbsentBtnTxtStyles}>&nbsp;|&nbsp;</p> */}
                                        <p
                                          className={
                                            commonCssStyles.presentAbsentBtnTxtStyles
                                          }
                                        >
                                          Present
                                        </p>
                                      </div>
                                    </Button>
                                  </Col>

                                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Button
                                      type={
                                        data.status === "halfDay"
                                          ? "primary"
                                          : "default"
                                      }
                                      onClick={() => {
                                        setMarkPresentId(data.id);
                                        handleButtonClick("halfDay");
                                        form.setFieldsValue({
                                          startTime:
                                          data.startTime === "00:00"
                                            ? dayjs() 
                                            : dayjs(data.startTime, 'hh:mm A'), 
                                        endTime:
                                          data.endTime === "00:00"
                                            ? dayjs()
                                            : dayjs(data.endTime, 'hh:mm A'), 
                                        });
                                        showModal1();
                                      }}
                                      style={{
                                        backgroundColor:
                                          data.status === "halfDay"
                                            ? "rgb(239, 122, 44)"
                                            : null,
                                      }}
                                      className={
                                        commonCssStyles.presentAbsentBtnStyles
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {/* <p className={commonCssStyles.presentAbsentBtnTxtStyles}>HD</p>
                          <p className={commonCssStyles.presentAbsentBtnTxtStyles}>&nbsp;|&nbsp;</p> */}
                                        <p
                                          className={
                                            commonCssStyles.presentAbsentBtnTxtStyles
                                          }
                                        >
                                          Half Day
                                        </p>
                                      </div>
                                    </Button>
                                  </Col>

                                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Button
                                      type={
                                        data.status === "absent"
                                          ? "primary"
                                          : "default"
                                      }
                                      onClick={() => {
                                        const mkAbsent = {
                                          id: data.id,
                                          status: "absent",
                                        };

                                        setMarkPresentId(data.id);
                                        handleButtonClick("absent");

                                        putAttendanceAction(
                                          mkAbsent,
                                          (success) => {
                                            if (success) {
                                              getAttendanceFilterAction({
                                                userId: location.state.id,
                                                month: selectedDate,
                                              });

                                              form.resetFields();
                                            }
                                          }
                                        );
                                      }}
                                      style={{
                                        backgroundColor:
                                          data.status === "absent"
                                            ? "rgb(224, 32, 32)"
                                            : null,
                                      }}
                                      className={
                                        commonCssStyles.presentAbsentBtnStyles
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {/* <p className={commonCssStyles.presentAbsentBtnTxtStyles} >A</p>
                          <p className={commonCssStyles.presentAbsentBtnTxtStyles} >&nbsp;|&nbsp;</p> */}
                                        <p
                                          className={
                                            commonCssStyles.presentAbsentBtnTxtStyles
                                          }
                                        >
                                          Absent
                                        </p>
                                      </div>
                                    </Button>
                                  </Col>
                                </Row>

                                <Row
                                  gutter={[16, 16]}
                                  style={{ marginTop: "10px" }}
                                >
                                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Button
                                      type={
                                        data.fineText ? "primary" : "default"
                                      }
                                      onClick={() => {
                                        setMarkPresentId(data.id);
                                        handleButtonClick("fine");

                                        if( data &&
                                          Object.keys(data.fine).length > 0){
                                            setDispSave(true);
                                          }
                                        if (
                                          data.status === "present" ||
                                          data.status === "halfDay"
                                        ) {
                                          if (
                                            data &&
                                            Object.keys(data.fine).length > 0
                                          ) {
                                            form.setFieldsValue({
                                              lateEntryHours: dayjs(
                                                data.fine.lateEntryHours,
                                                "HH:mm"
                                              ),
                                              lateEntryType:
                                                data.fine.lateEntryType,
                                              lateEntryAmount:
                                                data.fine.lateEntryAmount,
                                              earlyOutAmount:
                                                data.fine.earlyOutAmount,
                                              earlyOutHours: dayjs(
                                                data.fine.earlyOutHours,
                                                "HH:mm"
                                              ),
                                              earlyOutType:
                                                data.fine.earlyOutType,
                                              excessBreakAmount:
                                                data.fine.excessBreakAmount,
                                              excessBreakHours: dayjs(
                                                data.fine.excessBreakHours,
                                                "HH:mm"
                                              ),
                                              excessBreakType:
                                                data.fine.excessBreakType,
                                            });
                                          }

                                          showModal1();
                                        } else {
                                          message.error(
                                            "Please mark the attendance first to add a Fine entry"
                                          );
                                        }
                                      }}
                                      style={{
                                        backgroundColor:
                                          data.fine &&
                                          data.fine.status &&
                                          data.fine.status == "pending"
                                            ? "rgb(224 32 32 / 50%)"
                                            : data.fine.status == "approved"
                                            ? "rgb(224, 32, 32)"
                                            : "transparent",
                                      }}
                                      className={
                                        commonCssStyles.presentAbsentBtnStyles
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginTop: "5px",
                                        }}
                                      >
                                        <p
                                          className={
                                            commonCssStyles.presentAbsentBtnTxtStyles
                                          }
                                        >
                                          Fine{" "}
                                          {data.fineText ? (
                                            <> | {data.fineText} Hrs</>
                                          ) : (
                                            <></>
                                          )}
                                        </p>
                                      </div>
                                    </Button>
                                  </Col>

                                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Button
                                       type={data.otText ? "primary" : "default"}
                                      onClick={() => {
                                        setMarkPresentId(data.id);
                                        handleButtonClick("overtime");
                                        if( data &&
                                          Object.keys(data.overtime).length > 0){
                                            setDispOvertimeSave(true);
                                          }
                                        if (
                                          data.status === "present" ||
                                          data.status === "halfDay"
                                        ) {
                                          if (
                                            data &&
                                            Object.keys(data.overtime).length >
                                              0
                                          ) {
                                            setOverTimeHoursDisp(
                                              data.overtime.otRule
                                            );
                                            setEarlyOverTimeHoursDisp(
                                              data.overtime.earlyOtRule
                                            );

                                            form.setFieldsValue({
                                              earlyOtHours: dayjs(
                                                data.overtime.earlyOtHours,
                                                "HH:mm"
                                              ),
                                              otHours: dayjs(
                                                data.overtime.otHours,
                                                "HH:mm"
                                              ),
                                              otRule: data.overtime.otRule,
                                              OtAmount: data.overtime.OtAmount,
                                              earlyOtRule:
                                                data.overtime.earlyOtRule,
                                              earlyOtAmount:
                                                data.overtime.earlyOtAmount,
                                              earlyOtMultiplier:
                                                data.overtime.earlyOtMultiplier,
                                              otMultiplier:
                                                data.overtime.otMultiplier,
                                            });
                                          }
                                          showModal1();
                                        } else {
                                          message.error(
                                            "Please mark the attendance first to add overtime"
                                          );
                                        }
                                      }}
                                      // style={{
                                      //   backgroundColor:
                                      //     data.status === "overtime"
                                      //       ? "rgb(16, 170, 112)"
                                      //       : null,
                                      // }}

                                      
                                style={{
                                  backgroundColor: data.overtime&&data.overtime.status&&
                                  data.overtime.status=="pending"?"rgb(16 170 112 / 50%)":data.overtime.status=="approved"?"rgb(16, 170, 112)":"transparent",
                                }}

                                      className={
                                        commonCssStyles.presentAbsentBtnStyles
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {/* <p className={commonCssStyles.presentAbsentBtnTxtStyles}>OT</p>
                          <p className={commonCssStyles.presentAbsentBtnTxtStyles}>&nbsp;|&nbsp;</p> */}
                                        <p
                                          className={
                                            commonCssStyles.presentAbsentBtnTxtStyles
                                          }
                                        >
                                          Overtime{" "}
                                    {data.otText ? (
                                      <> | {data.otText} Hrs</>
                                    ) : (
                                      <></>
                                    )}
                                        </p>
                                      </div>
                                    </Button>
                                  </Col>

                                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Button
                                      type={
                                        data.status === "leave"
                                          ? "primary"
                                          : "default"
                                      }
                                      onClick={() => {
                                        getStaffLeaveBalanceAction(data.userId);
                                        setMarkPresentId(data.id);
                                        handleButtonClick("leave");
                                        showModal1();
                                      }}
                                      style={{
                                        backgroundColor:
                                          data.status === "leave"
                                            ? "rgb(16, 170, 112)"
                                            : null,
                                      }}
                                      className={
                                        commonCssStyles.presentAbsentBtnStyles
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {/* <p className={commonCssStyles.presentAbsentBtnTxtStyles} >L</p>
                          <p className={commonCssStyles.presentAbsentBtnTxtStyles} >&nbsp;|&nbsp;</p> */}
                                        <p
                                          className={
                                            commonCssStyles.presentAbsentBtnTxtStyles
                                          }
                                        >
                                          Leave
                                        </p>
                                      </div>
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </>
                  );
                })}
            </div>
          </Card>
        </>
      ),
      key: "2",
    },
    {
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <ShoppingOutlined />
          &nbsp; Leave
        </span>
      ),
      children: (
        <>
          <Card
            size="large"
            style={{
              borderRadius: "7px",
              minHeight: "100vh",
            }}
          >
            <div>
              <Card
                className={`${commonCssStyles.ThingsToDoCardDsgn5} custom-card1`}
                style={{ padding: "0px !important" }}
              >
                <Row justify={"space-between"}>
                  <Col>
                    <Row>
                      <Col>
                        <p className={commonCssStyles.attendancetableDataTxt}>
                          Leave Summary ( Jan 2023 - Dec 2023 )
                        </p>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "20px" }}>
                      <Col>
                        <p className={commonCssStyles.attendancetableTimeTxt}>
                          Total Leaves Taken :{" "}
                          <b>
                            {staffLeaveRequest &&
                            staffLeaveRequest.summary &&
                            staffLeaveRequest.summary.totalTaken
                              ? staffLeaveRequest.summary.totalTaken
                              : "NA"}
                          </b>
                        </p>
                        <p className={commonCssStyles.attendancetableTimeTxt}>
                          Balance Leaves :{" "}
                          <b>
                            {staffLeaveRequest &&
                            staffLeaveRequest.summary &&
                            staffLeaveRequest.summary.totalBalance
                              ? staffLeaveRequest.summary.totalBalance
                              : "NA"}
                          </b>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row gutter={[16, 16]}>
                      <Col>
                        <Dropdown
                          placement="bottomLeft"
                          // trigger={["click"]}
                          overlayStyle={{ width: "300px" }}
                          overlay={
                            <div className={commonCssStyles.AddStaffCard}>
                              <Button
                                style={{ marginTop: "10px", width: "100%" }}
                                className={commonCssStyles.secondaryBtnStyles}
                                onClick={() => {
                                  showModal();
                                }}
                              >
                                Edit Leave Balance
                              </Button>

                              <hr
                                className={commonCssStyles.hrSeperatorDropDwn}
                              />

                              <Button
                                style={{ marginTop: "10px", width: "100%" }}
                                className={commonCssStyles.secondaryBtnStyles}
                                onClick={() => {}}
                              >
                                Encash Leaves
                              </Button>
                            </div>
                          }
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <div>
                              <Button
                                type="primary"
                                className={commonCssStyles.filterBtnStyles1}
                              >
                                Actions <DownOutlined />
                              </Button>
                            </div>
                          </a>
                        </Dropdown>
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          className={commonCssStyles.addBtnStyles}
                          onClick={() => {
                            showMarkLeaveModal();
                          }}
                        >
                          Mark Leave
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </div>

            <div style={{ marginTop: "40px" }}>
              <Table
                dataSource={
                  staffLeaveRequest &&
                  staffLeaveRequest.result &&
                  staffLeaveRequest.result
                }
                columns={LeaveColumns}
                pagination={false}
                bordered
                scroll={{ x: "max-content" }}
                className="custom-dashboard-payment-table"
              />
            </div>
          </Card>
        </>
      ),
      key: "3",
      closable: false,
    },
    // {
    //   label: (
    //     <span style={{ display: "flex", alignItems: "center" }}>
    //       <PaymentIcon
    //         color={activeTab == "3" ? "var(--Color-Primary-all)" : "#000000E0"}
    //       />
    //       &nbsp; Payments
    //     </span>
    //   ),
    //   children: "Content of Tab 3",
    //   key: "3",
    //   closable: false,
    // },
    // {
    //   label: (
    //     <span>
    //       <FileProtectOutlined />
    //       Pay Slip
    //     </span>
    //   ),
    //   children: "Content of Tab 4",
    //   key: "4",
    //   closable: false,
    // },
    // {
    //   label: (
    //     <span style={{ display: "flex", alignItems: "center" }}>
    //       <OverTimeIcon
    //         color={activeTab == "5" ? "var(--Color-Primary-all)" : "#000000E0"}
    //       />
    //       &nbsp; Overtime
    //     </span>
    //   ),
    //   children: (
    //     <>
    //       <Card
    //         size="large"
    //         style={{
    //           borderRadius: "7px",
    //           minHeight: "100vh",
    //         }}
    //       >
    //        <Overtime />
    //       </Card>
    //     </>
    //   ),
    //   key: "5",
    //   closable: false,
    // },
    {
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <BonusIcon
            color={activeTab == "6" ? "var(--Color-Primary-all)" : "#000000E0"}
          />
          &nbsp; Allowances/Bonus
        </span>
      ),
      children: (
        <>
          <Card
            size="large"
            style={{
              borderRadius: "7px",
              minHeight: "100vh",
            }}
          >
            <div>
              <Card
                className={`${commonCssStyles.ThingsToDoCardDsgn5} custom-card1`}
                style={{ padding: "0px !important" }}
              >
                <Row justify={"space-between"}>
                  <Col>
                    <Row>
                      {/* <Col>
                        <p className={commonCssStyles.attendancetableDataTxt}>
                        Allowance & Bonus
                         ( Jan 2023 - Dec 2023 )
                        </p>
                      </Col> */}
                      <Col>
                        <Form
                          initialValues={{
                            date: dayjs(),
                          }}
                        >
                          <Form.Item name="date">
                            <DatePicker
                              locale="en"
                              picker="month"
                              format="MMMM YYYY"
                              placeholder="Select Date"
                              disabledDate={(currentDate) =>
                                currentDate &&
                                currentDate.isAfter(dayjs(), "day")
                              }
                              onChange={(val) => {
                                const AllowanceDate =
                                  dayjs(val).format("YYYY-MM-DD");
                                getStaffEarningFilterAction({
                                  type: "earning",
                                  userId: location.state.id,
                                  month: AllowanceDate,
                                });
                              }}
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "20px" }}>
                      <Col>
                        <p className={commonCssStyles.attendancetableTimeTxt}>
                          Total Allowance :{" "}
                          <b>
                            {" "}
                            ₹{" "}
                            {staffEarnings && staffEarnings.totalAllowance
                              ? staffEarnings.totalAllowance
                              : 0}
                          </b>
                        </p>
                        <p className={commonCssStyles.attendancetableTimeTxt}>
                          Total Bonus :{" "}
                          <b>
                            {" "}
                            ₹{" "}
                            {staffEarnings && staffEarnings.totalBonus
                              ? staffEarnings.totalBonus
                              : 0}
                          </b>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row gutter={[16, 16]}>
                      <Col>
                        <Button
                          type="primary"
                          className={commonCssStyles.addBtnStyles}
                          onClick={() => {
                            showAllowanceModal();
                          }}
                        >
                          Add Allowance/Bonus
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </div>

            <div style={{ marginTop: "40px" }}>
              <Table
                dataSource={
                  staffEarnings && staffEarnings.result && staffEarnings.result
                }
                columns={columns}
                pagination={false}
                bordered
                scroll={{ x: "max-content" }}
                className="custom-dashboard-payment-table"
              />
            </div>
          </Card>
        </>
      ),
      key: "6",
      closable: false,
    },
    {
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <DeductionsIcon
            color={activeTab == "7" ? "var(--Color-Primary-all)" : "#000000E0"}
          />
          &nbsp; Deductions
        </span>
      ),
      children: (
        <>
          <Card
            size="large"
            style={{
              borderRadius: "7px",
              minHeight: "100vh",
            }}
          >
            <div>
              <Card
                className={`${commonCssStyles.ThingsToDoCardDsgn5} custom-card1`}
                style={{ padding: "0px !important" }}
              >
                <Row justify={"space-between"}>
                  <Col>
                    <Row>
                      {/* <Col>
                        <p className={commonCssStyles.attendancetableDataTxt}>
                          Deductions ( Jan 2023 - Dec 2023 )
                        </p>
                      </Col> */}
                      <Col>
                        <Form
                          initialValues={{
                            date: dayjs(),
                          }}
                        >
                          <Form.Item name="date">
                            <DatePicker
                              locale="en"
                              picker="month"
                              format="MMMM YYYY"
                              placeholder="Select Date"
                              disabledDate={(currentDate) =>
                                currentDate &&
                                currentDate.isAfter(dayjs(), "day")
                              }
                              onChange={(val) => {
                                const DeductionDate =
                                  dayjs(val).format("YYYY-MM-DD");
                                getStaffDeductionFilterAction({
                                  type: "deduction",
                                  userId: location.state.id,
                                  month: DeductionDate,
                                });
                              }}
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "20px" }}>
                      <Col>
                        <p className={commonCssStyles.attendancetableTimeTxt}>
                          Total Deduction :{" "}
                          <b>
                            {" "}
                            ₹{" "}
                            {staffDeduction && staffDeduction.totalDeduction
                              ? staffDeduction.totalDeduction
                              : 0}
                          </b>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row gutter={[16, 16]}>
                      <Col>
                        <Button
                          type="primary"
                          className={commonCssStyles.addBtnStyles}
                          onClick={() => {
                            showDeductionsModal();
                          }}
                        >
                          Add Deductions
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </div>

            <div style={{ marginTop: "40px" }}>
              <Table
                dataSource={
                  staffDeduction &&
                  staffDeduction.result &&
                  staffDeduction.result
                }
                columns={columns4}
                pagination={false}
                bordered
                scroll={{ x: "max-content" }}
                className="custom-dashboard-payment-table"
              />
            </div>
          </Card>
        </>
      ),
      key: "7",
      closable: false,
    },
  ];

  const [tabPosition, setTabPosition] = useState("left");

  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link to="/staff">
            <h1 className={commonCssStyles.BackButtonTxt}>
              {" "}
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>

      <div style={{ marginTop: "20px" }}>
        <Card
          className={`${commonCssStyles.tableCardsContainer} attendance-custom-card`}
          style={{ padding: "0px !important" }}
        >
          <Row justify={"space-between"}>
            <Col>
              {" "}
              <Row style={{ gap: "20px" }}>
                <Col>
                  <Image
                    preview={false}
                    width={30}
                    height={30}
                    src={DefaultProfileImg}
                  />
                </Col>
                <Col>
                  <p className={commonCssStyles.staffNameTxt}>
                    {staffDetails && staffDetails.firstName
                      ? staffDetails.firstName
                      : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Link
                to={"/staff/staff-detail-profile"}
                state={{ id: location?.state?.id }}
              >
                <Button
                  style={{ width: "100%" }}
                  className={commonCssStyles.filterBtnStyles}
                >
                  Staff Profile
                </Button>
              </Link>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12} md={4} lg={4} xl={4}>
              <Card
                // className={commonCssStyles.AttendanceOverViewCard}
                style={{
                  border: "none",
                }}
                className="custom-card-padding-remove"
              >
                <h1 className={commonCssStyles.staffAttendanceLableTxt}>
                  Mobile Number
                </h1>
                <h1 className={commonCssStyles.staffAttendanceNumberTxt}>
                  {staffDetails && staffDetails.phone
                    ? staffDetails.phone
                    : "NA"}
                </h1>
              </Card>
            </Col>

            <Col xs={24} sm={12} md={4} lg={4} xl={4}>
              <Card
                style={{
                  border: "none",
                }}
                className="custom-card-padding-remove"
              >
                <h1 className={commonCssStyles.staffAttendanceLableTxt}>
                  Staff Type
                </h1>
                <h1 className={commonCssStyles.staffAttendanceNumberTxt}>
                  {staffDetails && staffDetails.employmentType
                    ? staffDetails.employmentType
                    : "NA"}
                </h1>
              </Card>
            </Col>

            <Col xs={24} sm={12} md={4} lg={4} xl={4}>
              <Card
                style={{
                  border: "none",
                }}
                className="custom-card-padding-remove"
              >
                <h1 className={commonCssStyles.staffAttendanceLableTxt}>
                  Monthly Gross Salary
                </h1>
                <h1 className={commonCssStyles.staffAttendanceNumberTxt}>
                  {staffDetails &&
                    staffDetails.salaryAmount &&
                    staffDetails.salaryAmount &&
                    "₹"}{" "}
                  {staffDetails && staffDetails.salaryAmount
                    ? staffDetails.salaryAmount
                    : "NA"}
                </h1>
              </Card>
            </Col>
          </Row>
        </Card>
      </div>

      <Row>
        <Col xs={24}>
          {/* <Card> */}
          <Tabs
            className="leftTabsSettingsStyle fixedStyle"
            tabPosition="left"
            items={initialItems}
            // size="large"
            onTabClick={(index) => setActiveTab(index)}
            size="small"
            defaultActiveKey="1"
            style={{
              // height: "90%",

              overflowY: "auto",

              overflowX: "hidden",
            }}
          />
          {/* </Card> */}
        </Col>
      </Row>

      {/* Mark Leave Modal */}

      <Modal
        title="Mark Leave"
        open={markLeaveModalOpen}
        onOk={handleMarkLeaveOk}
        onCancel={handleMarkLeaveCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="addMarkLeave"
            >
              Apply Leave
            </Button>
          </div>,
        ]}
      >
        <Form

          form={addLeaveForm}
          id="addMarkLeave"
          layout="vertical"
          style={{
           
            marginTop: "20px",
          }}
          initialValues={{
            type: "fullDay",
            isPaid: 1,
          }}
          onFinish={(val) => {
            if (val.type === "fullDay") {
              const markLeaveData = {
                ...val,
                userId: location.state.id,
                startDate: dayjs(val.startDate).format("YYYY-MM-DD"),
                endDate: dayjs(val.endDate).format("YYYY-MM-DD"),
                duration: daysDifference,


              };
              postStaffLeaveAction(markLeaveData, (success) => {
                if (success) {
                  getStaffLeaveRequestAction(location.state.id);
                  handleMarkLeaveCancel();
                  addLeaveForm.resetFields();
                  setDaysDifference("");
                }
              });
            } else if (val.type === "halfDay") {
              const markLeaveData = {
                userId: location.state.id,
                type: val.type,
                leaveCategoryId: val.leaveCategoryId,
                description: val.description,
                startDate: dayjs(val.startDate).format("YYYY-MM-DD"),
                duration: 0.5,
              };
              postStaffLeaveAction(markLeaveData, (success) => {
                if (success) {
                  getStaffLeaveRequestAction(location.state.id);
                  handleMarkLeaveCancel();
                  addLeaveForm.resetFields();
                  setDaysDifference("");
                }
              });
            }
          }}
        >
          <Form.Item label="" name="type">
            <Radio.Group
              onChange={(e) => {
                addLeaveForm.setFieldsValue({
                  startDate: undefined,
                  endDate: undefined,
                  leaveCategoryId: undefined,
                  description: undefined,
                });
                setFullOrHalfDay(e.target.value);
              }}
            >
              <Radio value="fullDay">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b> Full Day</b>
                  </p>
                </span>
              </Radio>
              <Radio value="halfDay">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b> Half Day</b>
                  </p>
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>

          {fullOrHalfDay && fullOrHalfDay == "fullDay" ? (
            <>
              <Form.Item
                label={
                  <p className={commonCssStyles.formLabelTxt}>
                    Leave Start Date
                  </p>
                }
                name="startDate"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  onChange={handleStartDateChange}
                  disabledDate={(currentDate) =>
                    currentDate && currentDate.isBefore(dayjs(), "day")
                  }
                />
              </Form.Item>

              <Form.Item
                label={
                  <p className={commonCssStyles.formLabelTxt}>Leave End Date</p>
                }
                name="endDate"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  onChange={handleEndDateChange}
                  disabledDate={disabledEndDate}
                />
              </Form.Item>

              <Form.Item
                label={
                  <p
                    className={commonCssStyles.formLabelTxt}
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    Leave Duration
                  </p>
                }
                name="startDate"
              >
                {daysDifference !== null && (
                  <p style={{ margin: "0px", padding: "0px" }}>
                    {daysDifference} day(s) Selected
                  </p>
                )}
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label={
                  <p className={commonCssStyles.formLabelTxt}>
                    Leave Start Date
                  </p>
                }
                name="startDate"
              >
                <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
              </Form.Item>
              <Form.Item
                label={
                  <p className={commonCssStyles.formLabelTxt}>Leave Duration</p>
                }
              >
                <p style={{ margin: "0px" }}>0.5 day(s) Selected</p>
              </Form.Item>
            </>
          )}

          <Form.Item
            name="leaveCategoryId"
            label={
              <p className={commonCssStyles.formLabelTxt}>Select Leave Type</p>
            }
          >
            <Select
              className="addStaffForm"
              style={{ width: "100%" }}
              onChange={(val) => {}}
            >
              {staffLeaveBalanceData &&
                staffLeaveBalanceData.map((data, index) => (
                  <Select.Option
                    key={index}
                    value={data.id}
                    disabled={data.balance == 0}
                  >
                    {data.type} &#40;{data.balance}&#41;Left
                  </Select.Option>
                ))}
              <Select.Option value="Unpaid Leave">Unpaid Leave</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={<p className={commonCssStyles.formLabelTxt}>Description</p>}
            name="description"
          >
            <TextArea rows={2} />
          </Form.Item>
        </Form>
      </Modal>

      {/* Mark Leave Modal End  */}

      {/* Mark Edit Leave Modal */}
      <Modal
        title="Edit Mark Leave"
        open={markLeaveEditModalOpen}
        onOk={handleMarkEditLeaveOk}
        onCancel={handleMarkLeaveEditCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="editMarkLeave"
            >
              Update
            </Button>
          </div>,
        ]}
      >
        <Form
          form={editMarkLeaveForm}
          id="editMarkLeave"
          layout="vertical"
          style={{
            marginTop: "20px",
          }}
          initialValues={{
            type: "fullDay",
            isPaid: 1,
          }}
          onFinish={(val) => {
            if (val.type === "fullDay") {
              const editMarkLeaveData = {
                ...val,
                userId: location.state.id,
                id: staffLeaveDetails.id,
                status: staffLeaveDetails.status,
                startDate: dayjs(val.startDate).format("YYYY-MM-DD"),
                endDate: dayjs(val.endDate).format("YYYY-MM-DD"),
                duration: daysDifference,
              };
              updateStaffLeaveDetailsAction(editMarkLeaveData, (success) => {
                if (success) {
                  getStaffLeaveRequestAction(location.state.id);
                  handleMarkLeaveEditCancel();
                }
              });
            } else if (val.type === "halfDay") {
              const editMarkLeaveData = {
                id: staffLeaveDetails.id,
                userId: location.state.id,
                type: val.type,
                status: staffLeaveDetails.status,
                leaveCategoryId: val.leaveCategoryId,
                description: val.description,
                startDate: dayjs(val.startDate).format("YYYY-MM-DD"),
                duration: 0.5,
              };
              updateStaffLeaveDetailsAction(editMarkLeaveData, (success) => {
                if (success) {
                  getStaffLeaveRequestAction(location.state.id);
                  handleMarkLeaveEditCancel();
                }
              });
            }
          }}
        >
          <Form.Item label="" name="type">
            <Radio.Group
              onChange={(e) => {
                addLeaveForm.setFieldsValue({
                  startDate: undefined,
                  endDate: undefined,
                  leaveCategoryId: undefined,
                  description: undefined,
                });
                setEditFullOrHalfDay(e.target.value);
              }}
            >
              <Radio value="fullDay">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b> Full Day</b>
                  </p>
                </span>
              </Radio>
              <Radio value="halfDay">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b> Half Day</b>
                  </p>
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>

          {editFullOrHalfDay && editFullOrHalfDay == "fullDay" ? (
            <>
              <Form.Item
                label={
                  <p className={commonCssStyles.formLabelTxt}>
                    Leave Start Date
                  </p>
                }
                name="startDate"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={"DD/MM/YYYY"}
                  onChange={(val) => {
                    editMarkLeaveForm.setFieldsValue({ endDate: null });
                    setStartDateEdit(val);
                  }}
                  disabledDate={(currentDate) =>
                    currentDate && currentDate.isBefore(dayjs(), "day")
                  }
                />
              </Form.Item>

              <Form.Item
                label={
                  <p className={commonCssStyles.formLabelTxt}>Leave End Date</p>
                }
                name="endDate"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={"DD/MM/YYYY"}
                  disabledDate={disabledEditEndDate}
                />
              </Form.Item>

              {/* <Form.Item
                label={
                  <p
                    className={commonCssStyles.formLabelTxt}
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    Leave Duration
                  </p>
                }
                name="startDate"
              >
                <p style={{ margin: "0px", padding: "0px" }}>
                  {daysDifference} day(s) Selected
                </p>
              </Form.Item> */}
            </>
          ) : (
            <>
              <Form.Item
                label={
                  <p className={commonCssStyles.formLabelTxt}>
                    Leave Start Date
                  </p>
                }
                name="startDate"
              >
                <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
              </Form.Item>
              <Form.Item
                label={
                  <p className={commonCssStyles.formLabelTxt}>Leave Duration</p>
                }
              >
                <p style={{ margin: "0px" }}>0.5 day(s) Selected</p>
              </Form.Item>
            </>
          )}

          <Form.Item
            name="leaveCategoryId"
            label={
              <p className={commonCssStyles.formLabelTxt}>Select Leave Type</p>
            }
          >
            <Select
              className="addStaffForm"
              style={{ width: "100%" }}
              onChange={(val) => {}}
            >
              {staffLeaveBalanceData &&
                staffLeaveBalanceData.map((data, index) => (
                  <Select.Option
                    key={index}
                    value={data.id}
                    disabled={data.balance == 0}
                  >
                    {data.type} &#40;{data.balance}&#41;
                  </Select.Option>
                ))}
              <Select.Option value="Unpaid Leave">Unpaid Leave</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={<p className={commonCssStyles.formLabelTxt}>Description</p>}
            name="description"
          >
            <TextArea rows={2} />
          </Form.Item>
        </Form>
      </Modal>
      {/* Mark Leave Modal Edit */}

      {/* Edit leave Balance Modal  */}

      <Modal
        title="Edit Leave Balance"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="leaveBalanceModal4"
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <Form
          id="leaveBalanceModal4"
          form={leaveBalanceForm}
          layout="vertical"
          style={{
            marginTop: "20px",
          }}
          initialValues={{}}
          onValuesChange={valuesChangeCalculate}
          onFinish={(values) => {
            const balLeave = {
              userId: location.state.id,
              balanceDetails: [values],
            };

            updateStaffLeaveBalanceAction(
              balLeave,

              (success) => {
                if (success) {
                  handleCancel();
                  getStaffLeaveBalanceAction(location.state.id);
                }
              }
            );
          }}
        >
          <p>Set new balances for leave types</p>
          {staffLeaveBalanceData &&
            staffLeaveBalanceData.map((data, index) => {
              return (
                <>
                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>
                        {data.type}
                      </p>
                    }
                    name={data.type}
                    initialValue={data.balance}
                  >
                    <Input />
                  </Form.Item>
                </>
              );
            })}

          <Form.Item name="totalLeaveBalance" hidden>
            <Input />
          </Form.Item>
          {/* <p>
            Total Leave Balance:{" "}
            {leaveBalanceForm.getFieldValue("totalLeaveBalance")} days
          </p> */}
        </Form>
      </Modal>

      {/* Edit leave Balance Modal End */}

      {/* Allowances/Bonus Modal */}
      <Modal
        title="Allowances/Bonus"
        open={isAllowanceModalOpen}
        onOk={handleAllowanceModalOk}
        onCancel={handleAllowanceModalCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="AllowanceBonus"
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form1}
          id="AllowanceBonus"
          layout="vertical"
          style={{
            marginTop: "20px",
          }}
          initialValues={{
            type: "allowance",
            isPaid: 1,
          }}
          onFinish={(val) => {
            const allowanceData = {
              ...val,
              userId: location.state.id,
              date: dayjs(val.date).format("YYYY-MM-DD"),
            };
            postStaffEarningAction(allowanceData, (success) => {
              if (success) {
                getStaffEarningFilterAction({
                  type: "earning",
                  userId: location.state.id,
                  month: dayjs().format("YYYY-MM-DD"),
                });
                handleAllowanceModalCancel();
                form1.resetFields();
              }
            });
          }}
        >
          <Form.Item label="" name="type">
            <Radio.Group>
              <Radio value="allowance">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>Allowance</b>
                  </p>
                </span>
              </Radio>
              <Radio value="bonus">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>Bonus</b>
                  </p>
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={<p className={commonCssStyles.formLabelTxt}>Date</p>}
            name="date"
          >
            <DatePicker
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
              disabledDate={(currentDate) =>
                currentDate && currentDate.isAfter(dayjs(), "day")
              }
            />
          </Form.Item>

          <Form.Item
            label={<p className={commonCssStyles.formLabelTxt}>Amount</p>}
            name="amount"
          >
            <Input />
          </Form.Item>

          <Form.Item label="" name="isPaid">
            <Radio.Group>
              <Radio value={1}>
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>Already Paid</b>
                  </p>
                </span>
              </Radio>
              <Radio value={0}>
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>Add to Salary</b>
                  </p>
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <p className={commonCssStyles.formLabelTxt}>
                Description (Optional)
              </p>
            }
            name="description"
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      {/* Allowances/Bonus Modal End  */}

      {/* Allowances/Bonus Edit Modal */}
      <Modal
        title="Edit Allowances/Bonus"
        open={isAllowanceEditModalOpen}
        onOk={handleAllowanceEditModalOk}
        onCancel={handleAllowanceEditModalCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="EditAllowanceBonus"
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <Form
          form={editAllowanceForm}
          id="EditAllowanceBonus"
          layout="vertical"
          style={{
            marginTop: "20px",
          }}
          initialValues={{}}
          onFinish={(val) => {
            const EditAllowanceData = {
              ...val,
              id: staffEarningsDetails.id,
              userId: location.state.id,
              date: dayjs(val.date).format("YYYY-MM-DD"),
            };
            putStaffEarningDetailsAction(EditAllowanceData, (success) => {
              if (success) {
                getStaffEarningFilterAction({
                  type: "earning",
                  userId: location.state.id,
                  month: dayjs(),
                });
                handleAllowanceEditModalCancel();
              }
            });
          }}
        >
          <Form.Item label="" name="type">
            <Radio.Group>
              <Radio value="allowance">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>Allowance</b>
                  </p>
                </span>
              </Radio>
              <Radio value="bonus">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>Bonus</b>
                  </p>
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={<p className={commonCssStyles.formLabelTxt}>Date</p>}
            name="date"
          >
            <DatePicker
              style={{ width: "100%" }}
              locale="en"
              format="DD/MM/YYYY"
              disabledDate={(currentDate) =>
                currentDate && currentDate.isAfter(dayjs(), "day")
              }
            />
          </Form.Item>
          <Form.Item
            label={<p className={commonCssStyles.formLabelTxt}>Amount</p>}
            name="amount"
          >
            <Input />
          </Form.Item>
          <Form.Item label="" name="isPaid">
            <Radio.Group>
              <Radio value={1}>
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>Already Paid</b>
                  </p>
                </span>
              </Radio>
              <Radio value={0}>
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>Add to Salary</b>
                  </p>
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <p className={commonCssStyles.formLabelTxt}>
                Description (Optional)
              </p>
            }
            name="description"
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      {/* Allowances/Bonus  Edit Modal End  */}

      {/* Allowances/Bonus Delete Modal  */}
      <Modal
        title="Delete Allowance/Bonus"
        open={isAllowanceDeleteModalOpen}
        onOk={handleAllowanceDeleteModalOk}
        onCancel={handleAllowanceDeleteModalCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleAllowanceDeleteModalCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleAllowanceDeleteModalOk();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <p className={commonCssStyles.modalSubHeadingStyles}>
          Are you sure you want to delete
        </p>
      </Modal>
      {/* Allowances/Bonus Delete Modal End  */}

      {/* Deductions Modal */}
      <Modal
        title="Deductions"
        open={isDeductionsModalOpen}
        onOk={handleDeductionsModalOk}
        onCancel={handleDeductionsModalCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="deductionForm"
            >
              Enter Deduction
            </Button>
          </div>,
        ]}
      >
        <Form
          style={{
            marginTop: "20px",
          }}
          layout="vertical"
          id="deductionForm"
          onFinish={(val) => {
            const deductVal = {
              ...val,
              userId: location.state.id,
              type: "deduction",
              date: dayjs(val.date).format("YYYY-MM-DD"),
            };

            postStaffEarningAction(deductVal, (success) => {
              if (success) {
                getStaffDeductionFilterAction({
                  type: "deduction",
                  userId: location.state.id,
                  month: dayjs().format("YYYY-MM-DD"),
                });
                handleDeductionsModalCancel();
              }
            });
          }}
        >
          <Form.Item
            label={<p className={commonCssStyles.formLabelTxt}>Date</p>}
            name="date"
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
              disabledDate={(currentDate) =>
                currentDate && currentDate.isAfter(dayjs(), "day")
              }
            />
          </Form.Item>

          <Form.Item
            label={<p className={commonCssStyles.formLabelTxt}>Amount</p>}
            name="amount"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <p className={commonCssStyles.formLabelTxt}>
                Select Deduction Type
              </p>
            }
            name="deductionType"
          >
            <Radio.Group>
              <Radio value="pf">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>PF</b>
                  </p>
                </span>
              </Radio>
              <Radio value="esi">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>ESI</b>
                  </p>
                </span>
              </Radio>
              <Radio value="other">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>OTHERS</b>
                  </p>
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <p className={commonCssStyles.formLabelTxt}>
                Description (Optional)
              </p>
            }
            name="description"
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
      {/* Deductions Modal End  */}

      {/* Deductions Edit Modal */}
      <Modal
        title="Edit Deductions"
        open={isDeductionsEditModalOpen}
        onOk={handleDeductionsEditModalOk}
        onCancel={handleDeductionsEditModalCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="editDeductionForm"
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <Form
          form={editDeductionForm}
          style={{
            marginTop: "20px",
          }}
          layout="vertical"
          id="editDeductionForm"
          onFinish={(val) => {
            const editDeductVal = {
              ...val,
              id: staffDeductionDetails.id,
              userId: location.state.id,
              type: "deduction",
              date: dayjs(val.date).format("YYYY-MM-DD"),
            };

            putStaffDeductionDetailsAction(editDeductVal, (success) => {
              if (success) {
                getStaffDeductionFilterAction({
                  type: "deduction",
                  userId: location.state.id,
                  month: dayjs().format("YYYY-MM-DD"),
                });

                handleDeductionsEditModalCancel();
              }
            });
          }}
        >
          <Form.Item
            label={<p className={commonCssStyles.formLabelTxt}>Date</p>}
            name="date"
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
              disabledDate={(currentDate) =>
                currentDate && currentDate.isAfter(dayjs(), "day")
              }
            />
          </Form.Item>

          <Form.Item
            label={<p className={commonCssStyles.formLabelTxt}>Amount</p>}
            name="amount"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              <p className={commonCssStyles.formLabelTxt}>
                Select Deduction Type
              </p>
            }
            name="deductionType"
          >
            <Radio.Group>
              <Radio value="pf">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>PF</b>
                  </p>
                </span>
              </Radio>
              <Radio value="esi">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>ESI</b>
                  </p>
                </span>
              </Radio>
              <Radio value="other">
                <span>
                  <p className={commonCssStyles.formLabelTxt}>
                    <b>OTHER</b>
                  </p>
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <p className={commonCssStyles.formLabelTxt}>
                Description (Optional)
              </p>
            }
            name="description"
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
      {/* Deductions Edit Modal End  */}

      {/* Deductions Delete Modal */}
      <Modal
        title="Delete Deductions"
        open={isDeductionsDeleteModalOpen}
        onOk={handleDeductionsDeleteModalOk}
        onCancel={handleDeductionsDeleteModalCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleDeductionsDeleteModalCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleDeductionsDeleteModalOk();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <p className={commonCssStyles.modalSubHeadingStyles}>
          Are you sure you want to delete
        </p>
      </Modal>
      {/* Deductions Delete Modal End  */}

      {/* Delete Mark Leave  */}
      <Modal
        title="Delete Mark Leave"
        open={isDeleteMarkLeaveDeleteModalOpen}
        onOk={handleDeleteMarkLeaveDeleteModalOk}
        onCancel={handleDeleteMarkLeaveDeleteModalCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleDeleteMarkLeaveDeleteModalCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleDeleteMarkLeaveDeleteModalOk();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <p className={commonCssStyles.modalSubHeadingStyles}>
          Are you sure you want to delete
        </p>
      </Modal>
      {/* Delete Mark Leave end  */}

      {/* Attendance popup Modal  */}
      <Modal
        // title="Present"
        title={
          selectedButton == "present"
            ? "Present"
            : selectedButton == "halfDay"
            ? "Half Day"
            : selectedButton == "Add Note"
            ? "Add Note"
            : selectedButton == "fine"
            ? "Fine"
            : selectedButton == "overtime"
            ? "Overtime"
            : selectedButton == "View Logs"
            ? "View Logs"
            : selectedButton == "leave"
            ? "leave"
            : "NA"
        }
        closeIcon={closeIcon}
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
         footer={[
          selectedButton === "View Logs" ? (
            <>
              <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
                <Button
                  key="applyFilter"
                  type="primary"
                  className={commonCssStyles.applyFilterStylesFull}
                  onClick={handleCancel}
                >
                  Done
                </Button>
              </div>
            </>
          ) : (
            <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
              {selectedButton == "fine" ? (
                <>
                  {dispSave && (
                    <Button
                      key="applyFilter"
                      type="primary"
                      htmlType="submit"
                      // onClick={closeAttendancModal}
                      className={commonCssStyles.applyFilterStylesFull}
                      form="markPresent"
                    >
                      Save
                    </Button>
                  )}
                </>
              ) : selectedButton == "overtime" ? (
                <>
                 {dispOvertimeSave &&
                 (
                    <Button
                      key="applyFilter"
                      type="primary"
                      htmlType="submit"
                      // onClick={closeAttendancModal}
                      className={commonCssStyles.applyFilterStylesFull}
                      form="markPresent"
                    >
                      Save
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  key="applyFilter"
                  type="primary"
                  htmlType="submit"
                  // onClick={closeAttendancModal}
                  className={commonCssStyles.applyFilterStylesFull}
                  form="markPresent"
                >
                  Save
                </Button>
              )}
            </div>
          ),
        ]}
      >
        <Form
         style={{
          height:300,
          overflowY:"auto",
        }}
          form={form}
          layout="vertical"
          id="markPresent"
          initialValues={{
            lateEntryHours: dayjs("00:00", "HH:mm"),
            excessBreakHours: dayjs("00:00", "HH:mm"),
            earlyOutHours: dayjs("00:00", "HH:mm"),

            lateEntryType: "fixed",
            excessBreakType: "fixed",
            earlyOutType: "fixed",

            otRule: "fixed",
            earlyOtRule: "fixed",
          }}
          onFinish={(val) => {
            if (selectedButton === "present" || selectedButton === "halfDay") {
              const formatTime = (time) => dayjs(time).format("hh:mm A");
              const formattedStartTime = formatTime(val.startTime);
              const formattedEndTime = formatTime(val.endTime);

              const mkPresent = {
                id: markPresentId,
                status: selectedButton,
                timeDetails:{
                  startTime: formattedStartTime,
                   endTime: formattedEndTime,
               }

              };
              putAttendanceAction(mkPresent, (success) => {
                if (success) {
                  getAttendanceFilterAction({
                    userId: location.state.id,
                    month: selectedDate,
                  });
                  handleCancel1();
                  form.resetFields();
                }
              });
            } else if (selectedButton === "Add Note") {
              const mkPresent = {
                id: markPresentId,
                note: val.note,
              };
              putAttendanceAction(mkPresent, (success) => {
                if (success) {
                  getAttendanceFilterAction({
                    userId: location.state.id,
                    month: selectedDate,
                  });
                  handleCancel1();
                  form.resetFields();
                }
              });
            } else if (selectedButton === "fine") {
              const mkPresent = {
                id: markPresentId,
                status: selectedButton,
                fineDetails: [
                  {
                    lateEntryHours: dayjs(val.lateEntryHours).format("HH:mm"),
                    excessBreakHours: dayjs(val.excessBreakHours).format(
                      "HH:mm"
                    ),
                    earlyOutHours: dayjs(val.earlyOutHours).format("HH:mm"),
                    lateEntryType: val.lateEntryType,
                    excessBreakType: val.excessBreakType,
                    earlyOutType: val.earlyOutType,
                    lateEntryAmount: val.lateEntryAmount
                      ? val.lateEntryAmount
                      : 0,
                    excessBreakAmount: val.excessBreakAmount
                      ? val.excessBreakAmount
                      : 0,
                    earlyOutAmount: val.earlyOutAmount ? val.earlyOutAmount : 0,
                  },
                ],
              };
              putAttendanceAction(mkPresent, (success) => {
                if (success) {
                  getAttendanceFilterAction({
                    attendanceDate: selectedDate,
                  });
                  handleCancel1();
                  form.resetFields();
                }
              });
            } else if (selectedButton === "overtime") {
              const mkPresent = {
                id: markPresentId,
                status: selectedButton,
                overtimeDetails: [
                  {
                    otHours: val.otHours
                      ? dayjs(val.otHours).format("HH:mm")
                      : 0,
                    otRule: val.otRule,
                    otMultiplier: val.otMultiplier,
                    otAmount: val.otAmount,
                    earlyOtHours: val.earlyOtHours
                      ? dayjs(val.earlyOtHours).format("HH:mm")
                      : 0,
                    earlyOtRule: val.earlyOtRule,
                    earlyOtMultiplier: val.earlyOtMultiplier,
                    earlyOtAmount: val.earlyOtAmount,
                  },
                ],
              };

              putAttendanceAction(mkPresent, (success) => {
                if (success) {
                  getAttendanceFilterAction({
                    attendanceDate: selectedDate,
                  });
                  handleCancel1();
                  form.resetFields();
                }
              });
              console.log(mkPresent);
            } else if (selectedButton === "leave") {
              const mkPresent = {
                id: markPresentId,
                status: selectedButton,
                leaveCategoryId: val.leaveCategoryId,
              };
              putAttendanceAction(mkPresent, (success) => {
                if (success) {
                  getAttendanceFilterAction({
                    attendanceDate: selectedDate,
                  });
                  handleCancel1();
                  form.resetFields();
                }
              });
            }
          }}
        >
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              {selectedButton === "present" || selectedButton === "halfDay" ? (
                <>
                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>Start Time</p>
                    }
                    name="startTime"
                    rules={[
                      { required: true, message: "Please select a start time" },
                    ]}
                  >
                    <TimePicker
                      format="hh:mm A"
                      changeOnBlur={true}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>End Time</p>
                    }
                    name="endTime"
                    rules={[
                      { required: true, message: "Please select an end time" },
                    ]}
                  >
                    <TimePicker
                      format="hh:mm A"
                      changeOnBlur={true}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </>
              ) : selectedButton === "Add Note" ? (
                <>
                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>Add Note</p>
                    }
                    name="note"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </>
              ) : selectedButton === "fine" ? (
                <>
                  <Row justify={"space-around"}>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Late Entry
                          </p>
                        }
                        name="lateEntryHours"
                      >
                        <TimePicker format={"HH:mm"}
                         changeOnBlur={true} 
                         onChange={() => {
                          setDispSave(true);
                        }}
                         />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Fine Amount
                          </p>
                        }
                        name="lateEntryAmount"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>Type</p>
                        }
                        name="lateEntryType"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            console.log(val, "selected value");
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="halfDay">HalfDay</Select.Option>
                          <Select.Option value="fullDay">FullDay</Select.Option>
                          <Select.Option value="pardon">Pardon</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify={"space-around"}>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Excess Breaks
                          </p>
                        }
                        name="excessBreakHours"
                      >
                        <TimePicker format={"HH:mm"} 
                        changeOnBlur={true}
                        onChange={() => {
                          setDispSave(true);
                        }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Fine Amount
                          </p>
                        }
                        name="excessBreakAmount"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>Type</p>
                        }
                        name="excessBreakType"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            console.log(val, "selected value");
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="halfDay">HalfDay</Select.Option>
                          <Select.Option value="fullDay">FullDay</Select.Option>
                          <Select.Option value="pardon">Pardon</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify={"space-around"}>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Early Out
                          </p>
                        }
                        name="earlyOutHours"
                      >
                        <TimePicker format={"HH:mm"} 
                        changeOnBlur={true} 
                        onChange={() => {
                          setDispSave(true);
                        }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Fine Amount
                          </p>
                        }
                        name="earlyOutAmount"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>Type</p>
                        }
                        name="earlyOutType"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            console.log(val, "selected value");
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="halfDay">HalfDay</Select.Option>
                          <Select.Option value="fullDay">FullDay</Select.Option>
                          <Select.Option value="pardon">Pardon</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : selectedButton === "overtime" ? (
                <>
                  <Row justify={"space-around"}>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Overtime Hours
                          </p>
                        }
                        name="otHours"
                      >
                        <TimePicker
                          style={{ width: "100%" }}
                          format={"HH:mm"}
                          changeOnBlur={true}
                          onChange={()=>{
                            setDispOvertimeSave(true)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Overtime Rule
                          </p>
                        }
                        name="otRule"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            setOverTimeHoursDisp(val);
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="multiplier">
                            Multiplier
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {overTimeHoursDisp == "fixed" ? (
                      <Col xs={24}>
                        <Form.Item
                          label={
                            <p className={commonCssStyles.formLabelTxt}>
                              Overtime Amount
                            </p>
                          }
                          name="otAmount"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col xs={24}>
                        <Form.Item
                          label={
                            <p className={commonCssStyles.formLabelTxt}>
                              Overtime Multiplier
                            </p>
                          }
                          name="otMultiplier"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <Row justify={"space-around"}>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Early Overtime Hours
                          </p>
                        }
                        name="earlyOtHours"
                      >
                        <TimePicker
                          style={{ width: "100%" }}
                          format={"HH:mm"}
                          changeOnBlur={true}
                          onChange={()=>{
                            setDispOvertimeSave(true);
                          }}

                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Early Overtime Rule
                          </p>
                        }
                        name="earlyOtRule"
                      >
                        <Select
                          className="addStaffForm"
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            setEarlyOverTimeHoursDisp(val);
                          }}
                        >
                          <Select.Option value="fixed">Fixed</Select.Option>
                          <Select.Option value="multiplier">
                            Multiplier
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {earlyOverTimeHoursDisp == "fixed" ? (
                      <>
                        {" "}
                        <Col xs={24}>
                          <Form.Item
                            label={
                              <p className={commonCssStyles.formLabelTxt}>
                                Early Overtime Amount
                              </p>
                            }
                            name="earlyOtAmount"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={24}>
                          <Form.Item
                            label={
                              <p className={commonCssStyles.formLabelTxt}>
                                Early Overtime Multiplier
                              </p>
                            }
                            name="earlyOtMultiplier"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              ) : selectedButton === "leave" ? (
                <>
                  <Form.Item label="" name="leaveCategoryId">
                    <Radio.Group>
                      {staffLeaveBalanceData &&
                        staffLeaveBalanceData.map((data, index) => (
                          <div key={index} style={{ marginTop: "10px" }}>
                            <Radio value={data.id}>
                              <p className={commonCssStyles.formLabelTxt}>
                                {data.type} &#40;{data.balance}&#41;
                              </p>
                            </Radio>
                          </div>
                        ))}
                      <br />
                      <Radio value={"others"} style={{ marginTop: "10px" }}>
                        <p className={commonCssStyles.formLabelTxt}>Others</p>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              ) : (
                <>
                  <p>
                    {logsDisp && logsDisp.length > 0 ? (
                      <>
                        {logsDisp.map((data, index) => (
                          <>
                            <p
                              className={commonCssStyles.AddStaffcardHeadingTxt}
                              style={{ padding: "0px", margin: "0px" }}
                              key={index}
                            >
                              <b>{data.description}</b>
                            </p>
                            <p
                              className={
                                commonCssStyles.AddStaffcardSubHeadingTxt
                              }
                              key={index}
                            >
                              By {data.addedBy} on {data.createdAt}
                            </p>
                          </>
                        ))}
                      </>
                    ) : (
                      "No Data"
                    )}
                  </p>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* Attendance popup Modal end  */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    //leave
    staffLeaveBalanceData: state.staff.staffLeaveBalance,
    staffLeaveRequest: state.staff.staffLeaveRequest,
    staffLeaveDetails: state.staff.staffLeaveDetails,
    //leave end

    //Staff details
    staffDetails: state.staff.staffDetails,
    staffDetails: state.staff.staffDetails,
    //Staff details end

    //Staff earnings
    staffEarnings: state.staff.staffEarnings,
    staffEarningsDetails: state.staff.staffEarningsDetails,
    //Staff earnings end

    //Staff deductions
    staffDeduction: state.staff.staffDeduction,
    staffDeductionDetails: state.staff.staffDeductionDetails,
    //Staff deductions end

    //attendance
    attendance: state.attendance.attendance,

    //attendance end
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaffDetailsAction: (id) => {
      dispatch(getStaffDetailsAction(id));
    },

    //Staff Leave

    getStaffLeaveBalanceAction: (id) => {
      dispatch(getStaffLeaveBalanceAction(id));
    },
    getStaffLeaveDetailsAction: (id) => {
      dispatch(getStaffLeaveDetailsAction(id));
    },

    getStaffLeaveRequestAction: (id) => {
      dispatch(getStaffLeaveRequestAction(id));
    },

    postStaffLeaveAction: (val, callback) => {
      dispatch(postStaffLeaveAction(val, callback));
    },

    updateStaffLeaveBalanceAction: (id, callback) => {
      dispatch(updateStaffLeaveBalanceAction(id, callback));
    },

    updateStaffLeaveDetailsAction: (id, callback) => {
      dispatch(updateStaffLeaveDetailsAction(id, callback));
    },

    deleteStaffLeaveDetailsAction: (val, callback) => {
      dispatch(deleteStaffLeaveDetailsAction(val, callback));
    },
    //Staff Leave end

    //Staff Earning Related Url
    getStaffEarningFilterAction: (val) => {
      dispatch(getStaffEarningFilterAction(val));
    },
    postStaffEarningAction: (val, callback) => {
      dispatch(postStaffEarningAction(val, callback));
    },
    getStaffEarningDetailsAction: (val, callback) => {
      dispatch(getStaffEarningDetailsAction(val, callback));
    },
    putStaffEarningDetailsAction: (val, callback) => {
      dispatch(putStaffEarningDetailsAction(val, callback));
    },
    deleteStaffEarningDetailsAction: (val, callback) => {
      dispatch(deleteStaffEarningDetailsAction(val, callback));
    },
    //Staff Earning Related Url End

    //Staff Deduction Related Url
    getStaffDeductionFilterAction: (val) => {
      dispatch(getStaffDeductionFilterAction(val));
    },

    getStaffDeductionDetailsAction: (val, callback) => {
      dispatch(getStaffDeductionDetailsAction(val, callback));
    },

    putStaffDeductionDetailsAction: (val, callback) => {
      dispatch(putStaffDeductionDetailsAction(val, callback));
    },

    deleteStaffDeductionDetailsAction: (val, callback) => {
      dispatch(deleteStaffDeductionDetailsAction(val, callback));
    },

    //Staff Deduction Related Url End

    // Attendance
    getAttendanceFilterAction: (val) => {
      dispatch(getAttendanceFilterAction(val));
    },

    putAttendanceAction: (val, callback) => {
      dispatch(putAttendanceAction(val, callback));
    },
    // Attendance end
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetails);
