import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    shifts: null,
    shiftDetails: null,

    shiftAssignToStaff: null,
  };

  export const shiftSlice = createSlice({
    name: "shifts",
    initialState,
    reducers: {
      getShiftSlice(state, action) {
        state.shifts = action.payload.result;
      },
      getShiftDetailsSlice(state, action) {
        state.shiftDetails = action.payload.result;
      },


      getShiftAssignToStaffSlice(state, action) {
        state.shiftAssignToStaff = action.payload.result;
      },
    //   postSettingsSlice(state, action) {
    //     state.settings = { ...state.settings, ...action.payload };
    //   },
    //   getSettingsSubscriptionSlice(state, action) {
    //     state.settingsSubscription = action.payload.result;
    //   },
    //   getSettingsWorklocationSlice(state, action) {
    //     state.settingsWorklocation = action.payload.result;
    //   },
    //   deleteSettingsWorkLocationSlice(state, action) {
    //     state.settingsWorklocation = state.settingsWorklocation.filter(
    //       (data) => data.id !== action.payload
    //     );
    //   },
    },
  });

  export const {
    getShiftSlice,
    getShiftDetailsSlice,
    getShiftAssignToStaffSlice
  } = shiftSlice.actions;
  export default shiftSlice.reducer;