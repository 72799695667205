import React from 'react';
import { Card } from 'antd';

const { Meta } = Card;

function KpiCard({ title, value }) {
  return (
    <Card
      style={{
        width: 240,
        margin: '16px',
        boxShadow: "var(--card-boxShadow)",
        backgroundColor:"var(--Card--Color)",
       
    }}
    >
        <Meta
        title={<span style={{ color: 'var(--table-text-color)' }}>{title}</span>}
        description={<span style={{ color: 'var(--table-text-color)' }}>{value}</span>}
      />
    </Card>
  );
}

function KpiCardContainer() {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap',marginTop:"50px" }}>
      <KpiCard title="Revenue" value="$1,000,000" />
      <KpiCard title="Users" value="10,000" />
      <KpiCard title="Orders" value="500" />
      <KpiCard title="Conversion Rate" value="5%" />
    </div>
  );
}

export default KpiCardContainer;
