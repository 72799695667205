import React, { useEffect, useState } from "react";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { FilterOutlinedIcon } from "../../IconsComp/Icon";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";
import { Navigate, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteDepartmentAction,
  getDepartmentAction,
  getDepartmentDetailsAction,
  postDepartmentAction,
  putDepartmentAction,
} from "../../../Reusable/Store/Action/DepartmentsAction";
const { Option } = Select;

function Departments({
  getDepartmentAction,
  departments,
  postDepartmentAction,
  deleteDepartmentAction,
  departmentDetail,
  putDepartmentAction,
  getDepartmentDetailsAction,
}) {
  useEffect(() => {
    getDepartmentAction();
  }, []);

  const [form] = Form.useForm();

  const [editForm] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  // Edit Modal Code
  const [editModalOpen, setEditModalOpen] = useState(false);
  const showEditModal = () => {
    setEditModalOpen(true);
  };
  const handleEditOk = () => {
    setEditModalOpen(false);
  };
  const handleEditCancel = () => {
    form.resetFields();
    setEditModalOpen(false);
  };
  // Edit Modal Code End

  // Delete Modal Code
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const showDeleteModal = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteOk = () => {
    deleteDepartmentAction(deleteId);
    setDeleteModalOpen(false);
  };
  const handleDeleteCancel = () => {
    form.resetFields();
    setDeleteModalOpen(false);
  };
  // Delete Modal Code end

  const [deleteId, setDeleteId] = useState("");
  const [updateId, setUpdateId] = useState("");

  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        handleCancel();
      }}
    />
  );

  useEffect(() => {
    if (departmentDetail) {
      editForm.setFieldsValue({
        departmentName: departmentDetail.departmentName,
      });
    }
  }, [departmentDetail, editForm]);

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        {" "}
        <Row justify={"space-between"}>
          <Col>
            <Link to="/settings">
              <h1 className={commonCssStyles.BackButtonTxt}>
                <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
                &nbsp; Back
              </h1>
            </Link>
          </Col>
        </Row>
        <Row justify={"space-between"} style={{ marginTop: "20px" }}>
          <Col>
            <h1 className={commonCssStyles.PageHeadingTxt}>Departments</h1>
          </Col>
          <Col>
            <Button
              type="primary"
              className={commonCssStyles.addBtnStyles}
              onClick={showModal}
            >
              Create Department
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "40px" }}>
          <h1 className={commonCssStyles.TableSecHeadingTxt}>
            Departments &#40;
            {departments && departments.length > 0 ? departments.length : "0"}
            &#41;
          </h1>
        </Row>
        <div>
          {departments &&
            departments.map((data, index) => {
              return (
                <>
                  <Card
                    key={index}
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        <Row style={{ gap: "20px", marginTop: "10px" }}>
                          <Col>
                            <p
                              className={commonCssStyles.leavePolicyettingsName}
                            >
                              {data &&
                              data.departmentName &&
                              data.departmentName
                                ? data.departmentName
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ display: "flex", alignSelf: "center" }}>
                        <Dropdown
                          placement="bottomRight"
                          overlayStyle={{ width: "300px" }}
                          overlay={
                            <div className={commonCssStyles.AddStaffCard1}>
                              <Button
                                style={{ marginTop: "10px", width: "100%" }}
                                className={commonCssStyles.secondaryBtnStyles}
                                onClick={() => {
                                  setUpdateId(data.id);
                                  getDepartmentDetailsAction(data.id);
                                  showEditModal();
                                }}
                              >
                                Edit
                              </Button>

                              <Button
                                style={{ marginTop: "10px", width: "100%" }}
                                className={commonCssStyles.secondaryBtnStyles}
                                onClick={() => {
                                  setDeleteId(data.id);
                                  showDeleteModal();
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          }
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <div>
                              <Col>
                                <MoreOutlined
                                  style={{
                                    fontSize: "30px",
                                    marginRight: "15px",
                                    color:"var(--main-heading-clr)"
                                  }}
                                />
                              </Col>
                            </div>
                          </a>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Card>
                </>
              );
            })}
        </div>
      </div>

      <Modal
        title="Add Department"
        closeIcon={closeIcon}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="addDept"
            >
              Create Department
            </Button>
          </div>,
        ]}
      >
        <Row style={{ marginTop: "40px" }}>
          <Col xs={24}>
            <Form
              layout="vertical"
              id="addDept"
              onFinish={(val) => {
                console.log(val, "Final Values");
                postDepartmentAction(val, (success) => {
                  if (success) {
                    handleCancel();
                  }
                });
              }}
            >
              <Form.Item
                label="Department Name"
                name="departmentName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the department name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      {/* Edit Department Modal  */}

      <Modal
        title="Edit Department"
        closeIcon={closeIcon}
        open={editModalOpen}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 15px 0px" }}>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              // onClick={closeAttendancModal}
              className={commonCssStyles.applyFilterStylesFull}
              form="editDept"
            >
              Create Department
            </Button>
          </div>,
        ]}
      >
        <Row style={{ marginTop: "40px" }}>
          <Col xs={24}>
            <Form
              form={editForm}
              layout="vertical"
              id="editDept"
              initialValues={{
                // departmentDetail
                departmentName:
                  departmentDetail && departmentDetail.departmentName,
              }}
              onFinish={(val) => {
                const data ={
                  ...val,
                  id:updateId
                }
                console.log(data, "Final Values");
                // updateId
                putDepartmentAction(data, (success) => {
                  if (success) {
                    handleEditCancel();
                  }
                });
              }}
            >
              <Form.Item
                label="Department Name"
                name="departmentName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the department name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      {/* Edit Department Modal End */}

      {/* Delete Department Modal  */}
      <Modal
        title="Delete"
        open={deleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleDeleteCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleDeleteOk();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <p className={commonCssStyles.modalSubHeadingStyles}>
          Are you sure you want to delete
        </p>
      </Modal>
      {/* Delete Department Modal end  */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    departments: state.departments.departments,
    departmentDetail: state.departments.departmentDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDepartmentAction: () => {
      dispatch(getDepartmentAction());
    },

    postDepartmentAction: (val, callback) => {
      dispatch(postDepartmentAction(val, callback));
    },
    deleteDepartmentAction: (id) => {
      dispatch(deleteDepartmentAction(id));
    },

    getDepartmentDetailsAction: (id) => {
      dispatch(getDepartmentDetailsAction(id));
    },

    putDepartmentAction: (val, callback) => {
      dispatch(putDepartmentAction(val, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
