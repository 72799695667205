import { ATTENDANCE_FILTER_URL,ATTENDANCE_URL } from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
import { getAttendanceFilterSlice } from "../Slice/AttendanceSlice";
const api = new AxiosApi();

export const getAttendanceFilterAction = (val) => {
  return (dispatch) => {
    api.invoke(
      ATTENDANCE_FILTER_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getAttendanceFilterSlice(data));
          } else {
            dispatch(getAttendanceFilterSlice({result:[]}));

            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};


export const putAttendanceAction = (val,callback) => {
  return (dispatch) => {
    api.invoke(
      ATTENDANCE_URL + "/"+val.id,
      "put",
      (data, success, statusCode) => {
          callback(false);
        if (success) {
          if (statusCode === 200) {
              callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getLeavePolicyAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};