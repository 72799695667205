import React, { useEffect, useState } from "react";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  MoreOutlined,
  RightOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  CloseOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  Menu,
  Row,
  Dropdown,
  Card,
  Table,
  Form,
  Modal,
  Checkbox,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteOverTimeRuleAction,
  getOverTimeRuleAction,
  getOverTimeRuleAssignAction,
  postOverTimeRuleAssignAction,
} from "../../../Reusable/Store/Action/OverTimeRuleAction";

function OverTimeRule({
  getOverTimeRuleAction,
  overtime,
  deleteOverTimeRuleAction,
  overtimeAssign,
  postOverTimeRuleAssignAction,
  getOverTimeRuleAssignAction,
}) {
  useEffect(() => {
    getOverTimeRuleAction();
  }, []);

  console.log(overtime && overtime, "lateEntryRule");

  const [form] = Form.useForm();
  const initialValues = {};

  useEffect(() => {
    if (overtimeAssign && overtimeAssign.length > 0) {
      overtimeAssign.forEach((item) => {
        initialValues[item.id] = item.isOvertimeSet === 1; 
      });
  
      initialValues["selectAll"] = false;
  
      form.setFieldsValue(initialValues);
    }
  }, [overtimeAssign, form]);


  const [deleteId, setDeleteId] = useState("");
  const [ShiftsId, setShiftId] = useState("");


  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteOverTimeRuleAction(deleteId);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleMenuClick = (e) => {
    if (e.key === "edit") {
      // Handle the edit action
      console.log("Edit clicked");
    } else if (e.key === "delete") {
      // Handle the delete action
      console.log("Delete clicked");
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

     //Assign Staff
     const [isModalOpen1, setIsModalOpen1] = useState(false);
     const showModal1 = () => {
       setIsModalOpen1(true);
     };
     const handleOk1 = () => {
       setIsModalOpen1(false);
     };
     const handleCancel1 = () => {
       setIsModalOpen1(false);
     };
     //Assign Staff end


  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        handleCancel();
        handleCancel1();
      }}
    />
  );


  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Row justify={"space-between"}>
          <Col>
            <Link to="/settings/attendance-automation">
              <h1 className={commonCssStyles.BackButtonTxt}>
                <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
                &nbsp; Back to Automation Rules
              </h1>
            </Link>
          </Col>
        </Row>

        <Row justify={"space-between"} style={{ marginTop: "20px" }}>
          <Col>
            <h1 className={commonCssStyles.PageHeadingTxt}>Overtime Rule</h1>
          </Col>
          <Col>
            <Link to={"/settings/attendance-automation/overtime/add"}>
              <Button
                type="primary"
                className={commonCssStyles.addBtnStyles}
                // onClick={handleAddHoliday}
              >
                Add
              </Button>
            </Link>
          </Col>
        </Row>

        <Row style={{ marginTop: "40px" }}>
          <h1 className={commonCssStyles.TableSecHeadingTxt}>
            Rules &#40;{" "}
            {overtime && overtime.length > 0 ? overtime.length : "0"} &#41;
          </h1>
        </Row>

        <div>
          {overtime &&
            overtime.map((data, index) => {
              return (
                <>
                  <Card
                    key={index}
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        <Row style={{ gap: "20px", marginTop: "10px" }}>
                          <Col>
                            <p
                              className={commonCssStyles.leavePolicyettingsName}
                            >
                              {data && data.name && data.name
                                ? data.name
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ display: "flex", alignSelf: "center" }}>
                        <Dropdown
                          placement="bottomRight"
                          overlayStyle={{ width: "300px" }}
                          overlay={
                            <div className={commonCssStyles.AddStaffCard1}>
                              <Link
                                to={
                                  "/settings/attendance-automation/overtime/edit"
                                }
                                state={{ id: data.id }}
                              >
                                <Button
                                  style={{ marginTop: "10px", width: "100%" }}
                                  className={commonCssStyles.secondaryBtnStyles}
                                >
                                  Edit
                                </Button>
                              </Link>
                              <Button
                                style={{ marginTop: "10px", width: "100%" }}
                                className={commonCssStyles.secondaryBtnStyles}
                                onClick={() => {
                                  // deleteShiftsAction(data.id)

                                  setDeleteId(data.id);

                                  showModal();
                                }}
                              >
                                Delete
                              </Button>
                              <Button
                                style={{ marginTop: "10px", width: "100%" }}
                                className={commonCssStyles.secondaryBtnStyles}
                                onClick={() => {
                                  setShiftId(data.id);
                                  getOverTimeRuleAssignAction(data.id);
                                  showModal1();
                                }}
                              >
                                Assign Rule
                              </Button>
                            </div>
                          }
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <div>
                              <Col>
                                <MoreOutlined
                                  style={{
                                    fontSize: "30px",
                                    marginRight: "15px",
                                    color:"var(--main-heading-clr)"
                                  }}
                                />
                              </Col>
                            </div>
                          </a>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Card>
                </>
              );
            })}
        </div>
      </div>
      <Modal
        title="Delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleOk();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <p className={commonCssStyles.modalSubHeadingStyles}>
          Are you sure you want to delete
        </p>
      </Modal>


      <Modal
        title="Assign Overtime Rule"
        open={isModalOpen1}
        closeIcon={closeIcon}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleCancel1();
              }}
            >
              Back
            </Button>
            <Button
              key="applyFilter4"
              type="primary"
              htmlType="submit"
              className={commonCssStyles.applyFilterStyles}
              form="staffAttendance"
            >
              Save & Continue
            </Button>
          </div>,
        ]}
      >
        <Form
          id="staffAttendance"
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={(values) => {
            const selectedIds = Object.keys(values).filter(
              (itemId) => values[itemId]
            );

            const Data = {
              overtimeId: ShiftsId,
              userId: selectedIds,
            };
            postOverTimeRuleAssignAction(Data, (success) => {
              if (success) {
                form.resetFields();
                handleCancel1();
              }
            });
            console.log(Data, "Final data");
          }}
        >
          <Row style={{ marginTop: "10px" }}>
            <Col xs={24}>
            {overtimeAssign && overtimeAssign.length > 0 ? (
  overtimeAssign.map((item, index) => (
    <React.Fragment key={item.id}>
      <Row
        justify="space-between"
        style={{
          borderBottom:
            index === overtimeAssign.length - 1
              ? "none"
              : "1px solid #e8e8e8",
          paddingBottom: "5px",
          marginBottom: "5px",
        }}
      >
        <Col>
          <Form.Item
            name={item.id}
            initialValue={item.isOvertimeSet}
            valuePropName="checked"
            noStyle
          >
            <Checkbox>
              <span className={commonCssStyles.formLabelTxt}>
                {item.name}
              </span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  ))
) : (
  <p>No Data</p>
)}

            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    overtime: state.overtimeRule.overtime,
    overtimeAssign: state.overtimeRule.overtimeAssign,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOverTimeRuleAction: () => {
      dispatch(getOverTimeRuleAction());
    },

    deleteOverTimeRuleAction: (id) => {
      dispatch(deleteOverTimeRuleAction(id));
    },


    getOverTimeRuleAssignAction: (val) => {
      dispatch(getOverTimeRuleAssignAction(val));
    },
    postOverTimeRuleAssignAction: (val,callback) => {
      dispatch(postOverTimeRuleAssignAction(val,callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverTimeRule);
