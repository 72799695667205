import {
  SETTINGS_BREAKS_RULE_URL,
  USER_PAYROLL_BREAKS_RULE_URL,
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
import {
  getBreaksRuleAssignSlice,
  getBreaksRuleDetailSlice,
  getBreaksRuleSlice,
} from "../Slice/BreaksRuleSlice";
const api = new AxiosApi();

export const getBreaksRuleAction = () => {
  return (dispatch) => {
    api.invoke(SETTINGS_BREAKS_RULE_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getBreaksRuleSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const postBreaksRuleAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_BREAKS_RULE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getBreaksRuleAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const deleteBreakRuleAction = (id) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_BREAKS_RULE_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        // callback(false);
        if (success) {
          if (statusCode === 200) {
            // callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getBreaksRuleAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const getBreakRuleDetailsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_BREAKS_RULE_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getBreaksRuleDetailSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const putBreakRuleAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_BREAKS_RULE_URL + "/" + val.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getBreaksRuleAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//Assign Staff to rules

export const getBreakRuleAssignAction = (val) => {
  return (dispatch) => {
    api.invoke(
      USER_PAYROLL_BREAKS_RULE_URL + "/" + val,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getBreaksRuleAssignSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postBreakRuleAssignAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      USER_PAYROLL_BREAKS_RULE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//Assign Staff to rules end
