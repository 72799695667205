import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    earlyExitRules: null,
    earlyExitRulesDetails: null,

    earlyExitRuleAssign : null,

  };


  export const EarlyExitSlice = createSlice({
    name: "earlyExit",
    initialState,
    reducers: {
      getEarlyExitSlice(state, action) {
        state.earlyExitRules = action.payload.result;
      },
      getEarlyExitDetailsSlice(state, action) {
        state.earlyExitRulesDetails = action.payload.result;
      },
     

      getEarlyExitRuleAssignSlice(state, action) {
        state.earlyExitRuleAssign = action.payload.result;
      },

    },
  });

  export const {
    getEarlyExitSlice,
    getEarlyExitDetailsSlice,
    getEarlyExitRuleAssignSlice
  } = EarlyExitSlice.actions;
  export default EarlyExitSlice.reducer;
