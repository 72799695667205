import React, { useEffect, useState } from "react";
import Headers from "../../../UI/headersComp/Headers";
import commonCssStyles from "../../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TimePicker,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  BonusIcon,
  DeductionsIcon,
  FilterOutlinedIcon,
  OverTimeIcon,
  PaymentIcon,
  RaiseHandIcon,
} from "../../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import {
  getShiftsDetailsAction,
  putDepartmentAction,
} from "../../../../Reusable/Store/Action/ShiftsAction";
import moment from "moment";

function EditShift({
  getShiftsDetailsAction,
  shiftDetails,
  putDepartmentAction,
}) {
  console.log(shiftDetails, "shiftDetails");
  const [form] = Form.useForm();
  const location = useLocation();
  const Navigate = useNavigate();

  console.log(location && location, "location");

  useEffect(() => {
    getShiftsDetailsAction(
      location && location.state && location.state.id && location.state.id
    );
  }, [location?.state?.id]);

  console.log(shiftDetails && shiftDetails, "shiftDetails");

  //time calculation

  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [timeDuration, setTimeDuration] = useState(null);
  const [unpaidHours, setUnpaidHours] = useState(null);
  const [unpaidErrorMessage, setUnpaidErrorMessage] = useState(null);

  const onUnpaidBreakChange = (time, timeString) => {
    const duration = dayjs(time, "HH:mm").hour();
    setUnpaidHours(duration);

    // Validate Unpaid Break
    if (timeString && duration >= timeDuration) {
      form.setFields([
        {
          name: "unpaidBreak",
          errors: ["Unpaid Break must be less than Time Duration"],
        },
      ]);
      setUnpaidErrorMessage("Unpaid Break must be less than Time Duration");
    } else {
      form.setFields([{ name: "unpaidBreak", errors: [] }]);
      setUnpaidErrorMessage(null);
    }
  };

  //time calculation end

  const calculateNetPayableHours = (startTime, endTime, unpaidBreak) => {
    const start = dayjs(startTime, "HH:mm");
    const end = dayjs(endTime, "HH:mm");
    const unpaid = dayjs(unpaidBreak, "HH:mm");
    const duration =
      end.diff(start, "minute") - unpaid.hour() * 60 - unpaid.minute();
    const netPayable = Math.max(0, duration / 60);
    return netPayable.toFixed(2); // Return the result with two decimal places
  };

  const format = "HH:mm";

  const [shift, setShift] = useState("");

  const [hideNetPayableHours,setHideNetPayableHours]=useState(true);

  //Checkbox related
  const [showNetPayableHours, setShowNetPayableHours] = useState(false);

  const CheckboxGroup = Checkbox.Group;
  const plainOptions = ["Apple", "Pear", "Orange"];
  const defaultCheckedList = ["Apple", "Orange"];

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  //Checkbox related end

  const formatTime = (time) => dayjs(time).format("hh:mm A");
  const formatTime4 = (time) => dayjs(time).format("HH:mm");

  const onFinish = (values) => {
    if (shift == "fixed") {
      const formattedStartTime = formatTime(values.startTimefixed);
      const formattedEndTime = formatTime(values.endTimefixed);
      const formattedUnpaidBreak = values.unpaidBreak
        ? formatTime4(values.unpaidBreak)
        : "00:00";
      const data = {
        id: location && location.state && location.state.id,
        shiftStartTime: formattedStartTime,
        shiftEndTime: formattedEndTime,
        name: values.name,
        shiftType: values.shiftType,
        unpaidBreak: formattedUnpaidBreak,
      };

      if (!unpaidErrorMessage) {
        putDepartmentAction(data, (success) => {
          if (success) {
            console.log("Added successfully");
            Navigate("/settings/shifts");
          }
        });
      }

      console.log(data);
    }

    if (shift == "open") {
      const formattedWorkHours = formatTime4(values.netPayableHours);
      const data1 = {
        id: location && location.state && location.state.id,
        name: values.name,
        shiftType: values.shiftType,
        netPayableHours: formattedWorkHours,
      };
      putDepartmentAction(data1, (success) => {
        if (success) {
          Navigate("/settings/shifts");
        }
      });

      console.log(data1);
    }

    if (shift == "rotational") {
      const formattedValues = {
        ...values,
        id: location && location.state && location.state.id,
        shiftType:"rotational",
        shiftDetails: values.shiftDetails.map((timeObj) => ({
          ...timeObj,
          // shiftType: values.shifttype,
          startTime: formatTime(timeObj.startTime),
          endTime: formatTime(timeObj.endTime),
          unpaidBreak: formatTime4(timeObj.unpaidBreak),
          netPayableHours:formatTime4(timeObj.netPayableHours),
        })),
      };

      putDepartmentAction(formattedValues, (success) => {
        if (success) {
          Navigate("/settings/shifts");
        }
      });

      console.log(formattedValues);
    }

    // putDepartmentAction

    // postShiftsAction(data, (success) => {
    //   if (success) {
    //     console.log("Added successfully")
    //   }
    // });
  };

  //for setting initial values
  const initialStartTime = shiftDetails
    ? dayjs(shiftDetails.shiftStartTime, "hh:mm A")
    : null;
  const initialEndTime = shiftDetails
    ? dayjs(shiftDetails.shiftEndTime, "hh:mm A")
    : null;

  const unPaidBreak = shiftDetails
    ? dayjs(shiftDetails.unpaidBreak, "hh:mm")
    : null;
  //for setting initial values end
  useEffect(() => {
    // Set initial form values when shiftDetails changes
    if (shiftDetails) {
      const {
        shiftStartTime,
        shiftEndTime,
        unpaidBreak,
        shiftType,
        netPayableHours,
        rotationalShift,
      } = shiftDetails;
      const initialStartTime = dayjs(shiftStartTime, "hh:mm A");
      const initialEndTime = dayjs(shiftEndTime, "hh:mm A");
      setSelectedStartTime(initialStartTime);
      setSelectedEndTime(initialEndTime);
      setShift(shiftType);
      form.setFieldsValue({
        shiftType: shiftType,
        name: shiftDetails.name,
        workhours: shiftDetails.workhours,
        netPayableHours: dayjs(netPayableHours, "HH:mm"),
        startTimefixed: selectedStartTime,
        endTimefixed: selectedEndTime,
        unpaidBreak: dayjs(unpaidBreak, "HH:mm"),
      });

      if (
        shiftType === "rotational" &&
        rotationalShift &&
        rotationalShift.length > 0
      ) {
        form.setFieldsValue({
          shiftDetails: rotationalShift.map((shift) => ({
            rotationalShiftName: shift.rotationalShiftName,
            startTime: dayjs(shift.startTime, "hh:mm A"),
            endTime: dayjs(shift.endTime, "hh:mm A"),
            unpaidBreak: dayjs(shift.unpaidBreak, "HH:mm"),
            netPayableHours: dayjs(shift.netPayableHours,"HH:mm"),
          })),
        });
      }


    }
  }, [shiftDetails, form]);

  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link to="/settings/shifts">
            <h1 className={commonCssStyles.BackButtonTxt}>
              {" "}
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>

      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Edit Shift</h1>
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col xs={24}>
          <Card>
            <Row>
              <Col md={14}>
                <Form
                  style={{ margin: "5px" }}
                  initialValues={
                    shiftDetails && {
                      shiftype: shiftDetails?.shiftType,
                      name: shiftDetails?.name,
                      // startTimefixed: shiftDetails.shiftStartTime,
                      // endTimefixed: shiftDetails.shiftEndTime,
                      unpaidBreak: unPaidBreak,
                    }
                  }
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                >
                  <Form.Item
                    label={
                      <p className={commonCssStyles.formLabelTxt}>Shift Type</p>
                    }
                    name="shiftType"
                  >
                    <Select
                      className="addStaffForm"
                      disabled
                      style={{ width: "100%" }}
                      onChange={(val) => {
                        console.log(val, "selected value");
                        setShift(val);
                      }}
                    >
                      <Select.Option value="fixed">Fixed Shift</Select.Option>
                      <Select.Option value="rotational">
                        Rotational Shift
                      </Select.Option>
                      <Select.Option value="open">Open Shift</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Shift Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please enter a Shift Name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  {shift && shift == "fixed" && (
                    <>
                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            Start Time
                          </p>
                        }
                        name="startTimefixed"
                        rules={[
                          {
                            required: true,
                            message: "Please select a Start Time",
                          },
                        ]}
                      >
                        <TimePicker
                          format="hh:mm A"
                          style={{ width: "100%" }}
                          changeOnBlur={true}
                          onChange={() => {
                            setShowNetPayableHours(true);
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label={
                          <p className={commonCssStyles.formLabelTxt}>
                            End Time
                          </p>
                        }
                        name="endTimefixed"
                        rules={[
                          {
                            required: true,
                            message: "Please select an End Time",
                          },
                        ]}
                      >
                        <TimePicker
                          format="hh:mm A"
                          style={{ width: "100%" }}
                          changeOnBlur={true}
                          onChange={() => {
                            setShowNetPayableHours(true);
                          }}
                        />
                      </Form.Item>
                    </>
                  )}

                  {shift && shift == "fixed" && (
                    <Form.Item
                      label={
                        <p className={commonCssStyles.formLabelTxt}>
                          Unpaid Break
                        </p>
                      }
                      name="unpaidBreak"
                    >
                      <TimePicker
                        changeOnBlur={true}
                        format="HH:mm"
                        style={{ width: "100%" }}
                        onChange={() => {
                          setShowNetPayableHours(true);
                        }}
                      />
                    </Form.Item>
                  )}

                  {shift == "fixed" && !showNetPayableHours
                    ? `Net Payable Hours: ${
                        shiftDetails &&
                        shiftDetails.netPayableHours &&
                        shiftDetails.netPayableHours
                      }`
                    : ""}

                  {shift && shift == "open" && (
                    <Form.Item
                      label={
                        <p className={commonCssStyles.formLabelTxt}>
                          Work hours
                        </p>
                      }
                      name="netPayableHours"
                    >
                      <TimePicker
                        changeOnBlur={true}
                        // defaultValue={dayjs("12:08", format)}
                        format={format}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}

                  {shift && shift == "rotational" && (
                    <>
                      <h1>Add Shift Details</h1>

                      <Form.List name="shiftDetails">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field, index) => (
                              <Row gutter={[16, 16]} key={field.key}>
                                <Col xs={6} md={6}>
                                  <Form.Item
                                    {...field}
                                    label={
                                      index === 0 ? "Rotational Shift Name" : ""
                                    }
                                    name={[field.name, "rotationalShiftName"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please enter the rotational shift name",
                                      },
                                    ]}
                                  >
                                    <Input 
                                    
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Item
                                    {...field}
                                    label={index === 0 ? "Start Time" : ""}
                                    name={[field.name, "startTime"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select the start time",
                                      },
                                    ]}
                                  >
                                    <TimePicker
                                      changeOnBlur={true}
                                      format="hh:mm A" // Updated format to include AM/PM
                                      onChange={(time) => {
                                        setHideNetPayableHours(false);
                                        const endTime = form.getFieldValue([
                                          "shiftDetails",
                                          index,
                                          "endTime",
                                        ]);
                                        const unpaidBreak = form.getFieldValue([
                                          "shiftDetails",
                                          index,
                                          "unpaidBreak",
                                        ]);
                                        const netPayableHours =
                                          calculateNetPayableHours(
                                            time,
                                            endTime,
                                            unpaidBreak
                                          );
                                        form.setFieldsValue({
                                          shiftDetails: {
                                            ...form.getFieldValue(
                                              "shiftDetails"
                                            ),
                                            [index]: {
                                              ...form.getFieldValue([
                                                "shiftDetails",
                                                index,
                                              ]),
                                              startTime: time,
                                              netPayableHours,
                                            },
                                          },
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Item
                                    {...field}
                                    label={index === 0 ? "End Time" : ""}
                                    name={[field.name, "endTime"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select the end time",
                                      },
                                    ]}
                                  >
                                    <TimePicker
                                      changeOnBlur={true}
                                      format="hh:mm A" // Updated format to include AM/PM
                                      onChange={(time) => {
                                        setHideNetPayableHours(false);
                                        const startTime = form.getFieldValue([
                                          "shiftDetails",
                                          index,
                                          "startTime",
                                        ]);
                                        const unpaidBreak = form.getFieldValue([
                                          "shiftDetails",
                                          index,
                                          "unpaidBreak",
                                        ]);
                                        const netPayableHours =
                                          calculateNetPayableHours(
                                            startTime,
                                            time,
                                            unpaidBreak
                                          );
                                        form.setFieldsValue({
                                          shiftDetails: {
                                            ...form.getFieldValue(
                                              "shiftDetails"
                                            ),
                                            [index]: {
                                              ...form.getFieldValue([
                                                "shiftDetails",
                                                index,
                                              ]),
                                              endTime: time,
                                              netPayableHours,
                                            },
                                          },
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={6} md={4}>
                                  <Form.Item
                                    {...field}
                                    label={index === 0 ? "Unpaid Break" : ""}
                                    name={[field.name, "unpaidBreak"]}
                                    validateStatus={field.errors ? "error" : ""}
                                    help={
                                      field.errors
                                        ? field.errors.join(", ")
                                        : null
                                    }
                                  >
                                    <TimePicker
                                      changeOnBlur={true}
                                      format="HH:mm" // Updated format to include AM/PM
                                      onChange={(time) => {
                                        setHideNetPayableHours(false);
                                        const startTime = form.getFieldValue([
                                          "shiftDetails",
                                          index,
                                          "startTime",
                                        ]);
                                        const endTime = form.getFieldValue([
                                          "shiftDetails",
                                          index,
                                          "endTime",
                                        ]);
                                        const netPayableHours =
                                          calculateNetPayableHours(
                                            startTime,
                                            endTime,
                                            time
                                          );
                                        form.setFieldsValue({
                                          shiftDetails: {
                                            ...form.getFieldValue(
                                              "shiftDetails"
                                            ),
                                            [index]: {
                                              ...form.getFieldValue([
                                                "shiftDetails",
                                                index,
                                              ]),
                                              unpaidBreak: time,
                                              netPayableHours,
                                            },
                                          },
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={6} md={4}>
                                {hideNetPayableHours&&  <Form.Item
                                    {...field}
                                    label={
                                      index === 0 ? "Net Payable Hours" : ""
                                    }
                                    name={[field.name, "netPayableHours"]}
                                  >
                                    {/* <Input readOnly /> */}
                                    <TimePicker
                                    format="HH:mm"
                                  disabled 
                                    />
                                  </Form.Item>}
                                </Col>
                                <Col xs={6} md={2}>
                                  {/* Delete button with CloseCircleOutlined icon */}
                                  {index > 1 && (
                                    <Button
                                      onClick={() => remove(field.name)}
                                      icon={<CloseCircleOutlined />}
                                    />
                                  )}
                                </Col>
                              </Row>
                            ))}
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} block>
                                Add Row
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </>
                  )}

                  {/* Demo  */}

                  {/* Demo end   */}

                  {/* <Row style={{ marginTop: "20px" }}>
                <Col xs={24}>
                  <Card className="custom-card1">
                    <Collapse defaultActiveKey={["1"]} ghost>
                      <Collapse.Panel
                        header={
                          <p className={commonCssStyles.formLabelTxt}>
                            Assigned Staff
                          </p>
                        }
                        //   header="Assigned Staff "

                        key="1"
                      >
                        <Checkbox
                          indeterminate={indeterminate}
                          onChange={onCheckAllChange}
                          checked={checkAll}
                        >
                          Check all
                        </Checkbox>
                        <Divider />
                        <CheckboxGroup
                          options={plainOptions}
                          value={checkedList}
                          onChange={onChange}
                        />
                      </Collapse.Panel>
                    </Collapse>
                  </Card>
                </Col>
              </Row> */}

                  <Row style={{ marginTop: "30px" }}>
                    <Col xs={24}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginTop: "20px" }}
                            className={commonCssStyles.addBtnStyles}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    shiftDetails: state.shifts.shiftDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShiftsDetailsAction: (id) => {
      dispatch(getShiftsDetailsAction(id));
    },
    putDepartmentAction: (val, callback) => {
      dispatch(putDepartmentAction(val, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditShift);
