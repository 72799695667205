import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import { Button, Card, Col, Modal, Row } from "antd";
import {
  deleteStaffDocumentsAction,
  getStaffDetailsAction,
  getStaffDocumentsDetailsAction,
  postStaffDocumentsAction,
} from "../../../Reusable/Store/Action/StaffAction";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  ArrowLeftOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import UploadDocumentModal from "./UploadDocumentModal";
import { DownloadIcon, FileAddIcon, TrashIcon } from "../../IconsComp/Icon";
import pdf from "../../../Assets/Image/pdf.png";
import word from "../../../Assets/Image/word.png";

function StaffDocuments({
  postStaffDocumentsAction,
  getStaffDocumentsDetailsAction,
  deleteStaffDocumentsAction,
  staffDocumentsDetails,
}) {
  const location = useLocation();
  const [hoverRedClr, setHoverRedClr] = useState("var(--tabs-heading-txt-clr)");
  const [experienceModalId, setExperienceModalId] = useState("");
  const [deleteModalName, setDeleteModalName] = useState("");
  const [deleteUploadId, setDeleteUploadId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (location && location.state && location.state.id) {
      getStaffDocumentsDetailsAction(location.state.id);
    }
  }, [location?.state?.id]);

  console.log(
    staffDocumentsDetails && staffDocumentsDetails,
    "staffDocumentsDetails staffDocumentsDetails"
  );
  //upload Documets Related Code
  const [uploadDocumentsModal, setUploadDocumentsModal] = useState(false);

  const showUploadDocumentsModal = () => {
    setUploadDocumentsModal(true);
  };
  const onOkHandle = (val) => {
    console.log(val, "Uploaded Values");
    setUploadDocumentsModal(false);
    postStaffDocumentsAction(val, (success) => {
      if (success) {
        getStaffDocumentsDetailsAction(location.state.id);
        console.log("Documents Uploaded SuccessFully!!!!");
      }
    });
  };
  const handleUploadDocumentsCancel = () => {
    setUploadDocumentsModal(false);
  };
  //upload Documets Related Code end

  //delete staff modal
  const [isDeleteStaffModalOpen, setDeleteStaffModalOpen] = useState(false);
  const showDeleteStaffeModal = () => {
    setDeleteStaffModalOpen(true);
  };
  const handleAllowanceDeleteModalOk = () => {
    deleteStaffDocumentsAction(deleteUploadId, (success) => {
      if (success) {
        getStaffDocumentsDetailsAction(location.state.id);
      }
    });
    setDeleteStaffModalOpen(false);
  };
  const handleAllowanceDeleteModalCancel = () => {
    setDeleteStaffModalOpen(false);
  };
  //delete staff modal end

  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        handleAllowanceDeleteModalCancel();
      }}
    />
  );

  return (
    <div>
      {" "}
      <Row justify={"space-between"}>
        <Col>
          <Link
            to="/staff/staff-detail-profile"
            state={{ id: location.state.id }}
          >
            <h1 className={commonCssStyles.BackButtonTxt}>
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>
      {/* <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt} style={{ margin: 0 }}>
            Documents
          </h1>{" "}
        </Col>
      </Row> */}
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <Row justify={"space-between"}>
              <Col>
                <h1
                  className={commonCssStyles.PageHeadingTxt}
                  style={{ margin: 0 }}
                >
                  Documents
                </h1>{" "}
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={commonCssStyles.addBtnStyles}
                  onClick={() => {
                    showUploadDocumentsModal();
                  }}
                >
                  <PlusOutlined /> Add Document
                </Button>
              </Col>
            </Row>

            <Row style={{ marginTop: "40px" }}>
              {staffDocumentsDetails && staffDocumentsDetails.length == 0 ? (
                <Col xs={24}>
                  <Row align={"middle"} justify={"space-around"}>
                    <Col>
                      <FileAddIcon color={"var(--tabs-heading-txt-clr)"} />
                      <p  style={{ marginLeft: "-60px" }}>
                        No Document have been uploaded yet
                      </p>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <>
                  {staffDocumentsDetails &&
                    staffDocumentsDetails.map((data) => (
                      <Col
                        key={data.id}
                        style={{ width: "100%", padding: "0px 20px" }}
                      >
                        <Row
                          justify="space-between"
                          style={{
                            // backgroundColor: "var(--secondary-card-color)",
                            border: "2px dashed var(--tabs-heading-txt-clr)",
                            padding: "10px 10px",
                            margin: "31px 0px 0px 0px",
                            borderRadius: "5px",
                          }}
                        >
                          <Col>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              {data.documentName &&
                              data.documentName.split(".").pop() === "pdf" ? (
                                <img
                                  src={pdf}
                                  alt="pdf"
                                  style={{ width: "40px", height: "40px" }}
                                />
                              ) : (
                                <img
                                  style={{ width: "31px" }}
                                  src={word}
                                  alt="word"
                                />
                              )}
                              <p
                                style={{
                                  margin: "0px",
                                  color: "var(--body-text-color)",
                                }}
                              >
                                {data.documentName && data.documentName}
                              </p>
                            </div>
                          </Col>
                          <Col>
                            <Row
                              align="middle"
                              style={{ gap: "10px", marginTop: "10px" }}
                            >
                              <Col>
                                <span
                                style={{cursor:'pointer'}}
                                  onClick={() => {
                                    window.open(data.uploadPath, "_blank");
                                  }}
                                >
                                  <DownloadIcon
                                    color={"var(--tabs-heading-txt-clr)"}
                                  />
                                </span>
                              </Col>
                              <Col>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onMouseEnter={() => {
                                    setHoverRedClr("#F83A3A");
                                    setExperienceModalId(data.id);
                                  }}
                                  onMouseLeave={() => {
                                    setHoverRedClr(
                                      "var(--tabs-heading-txt-clr)"
                                    );
                                  }}
                                  onClick={() => {
                                    showDeleteStaffeModal();
                                    setDeleteModalName("documentDelete");
                                    setDeleteUploadId(data.id);
                                    setIsModalOpen(true);
                                  }}
                                >
                                  <TrashIcon
                                    color={
                                      experienceModalId === data.id
                                        ? hoverRedClr
                                        : "var(--tabs-heading-txt-clr)"
                                    }
                                  />
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                </>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
      <UploadDocumentModal
        uploadModalOpen={uploadDocumentsModal}
        uploadModalClose={handleUploadDocumentsCancel}
        userId={location.state.id}
        onOkHandle={onOkHandle}
      />
      <Modal
        title="Delete Document"
        open={isDeleteStaffModalOpen}
        onOk={handleAllowanceDeleteModalOk}
        onCancel={handleAllowanceDeleteModalCancel}
        closeIcon={closeIcon}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleAllowanceDeleteModalCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleAllowanceDeleteModalOk();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <p className={commonCssStyles.modalSubHeadingStyles}>
        Are you sure you want to delete this Document
        </p>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    staffDocumentsDetails: state.staff.staffDocumentsDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //Upload Documents
    postStaffDocumentsAction: (val, callback) => {
      dispatch(postStaffDocumentsAction(val, callback));
    },
    getStaffDocumentsDetailsAction: (val) => {
      dispatch(getStaffDocumentsDetailsAction(val));
    },
    deleteStaffDocumentsAction: (val, callback) => {
      dispatch(deleteStaffDocumentsAction(val, callback));
    },
    //Upload Documents end
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StaffDocuments);
