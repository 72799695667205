import { Checkbox, ConfigProvider, Layout, Radio, Skeleton, theme } from "antd";
import React, { useState, useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Components/Screens/Dashboard/Dashboard";
import SideBar from "./Components/UI/sidebarComp/SideBar";
import Headers from "./Components/UI/headersComp/Headers";
import "antd/dist/reset.css";

import commonCssStyles from "./Components/UI/CommonCssStyles.module.css";
import Table from "./Components/Screens/Tables.js/Table";
import Card from "./Components/Screens/Card/Card";
import CustomTable from "./Components/Screens/Tables.js/CustomTable";
import SampleForm from "./Components/Screens/Form/SampleForm";
import Staff from "./Components/Screens/Staff/Staff";
import Attendance from "./Components/Screens/Attendance/Attendance";
import StaffDetails from "./Components/Screens/Staff/StaffDetails";
import Settings from "./Components/Screens/Settings/Settings";

import AutomationRules from "./Components/Screens/Settings/AutomationRules";
import Test from "./Components/Screens/Test";
import CompoffLeaveDetailPage from "./Components/Screens/Settings/CompoffLeaveDetailPage";
import LeavePolicyPage from "./Components/Screens/Settings/LeavePolicyPage";
import Login from "./Components/Screens/Login/Login";
import Departments from "./Components/Screens/Settings/Departments";
import StaffWeeklyHolidays from "./Components/Screens/Settings/StaffWeeklyHolidays";
import LateEntryRules from "./Components/Screens/Settings/LateEntryRules";
import CreateLateEntryRule from "./Components/Screens/Settings/CreateLateEntryRule";
import Reports from "./Components/Screens/Reports/Reports";
import { connect } from "react-redux";
import { getSettingsAction } from "./Reusable/Store/Action/SettingsAction";
import ConfirmPassword from "./Components/Screens/Login/ConfirmPassword";
import ForgotPassword from "./Components/Screens/Login/ForgotPassword";
import Payroll from "./Components/Screens/Payroll/Payroll";
import BreakRules from "./Components/Screens/Settings/BreakRules";
import EarlyExitRule from "./Components/Screens/Settings/EarlyExitRule";
import OverTimeRule from "./Components/Screens/Settings/OverTimeRule";
import CreateBreakRules from "./Components/Screens/Settings/CreateBreakRules";
import CreateEarlyExitRule from "./Components/Screens/Settings/CreateEarlyExitRule";
import CreateOverTimeRule from "./Components/Screens/Settings/CreateOverTimeRule";

import AddLeavePolicyPage from "./Components/Screens/Settings/AddLeavePolicyPage";

import ShiftSettings from "./Components/Screens/Settings/ShiftSettings/ShiftSettings";
import AddShift from "./Components/Screens/Settings/ShiftSettings/AddShift";
import EditShift from "./Components/Screens/Settings/ShiftSettings/EditShift";
import Test1 from "./Components/Screens/Test1";
import HolidayPoliciy from "./Components/Screens/Settings/HolidayPolicy/HolidayPoliciy";
import AddHolidayPolicy from "./Components/Screens/Settings/HolidayPolicy/AddHolidayPolicy";
import EditHolidayPolicy from "./Components/Screens/Settings/HolidayPolicy/EditHolidayPolicy";
import EditLateEntryRule from "./Components/Screens/Settings/EditLateEntryRule";
import EditBreakRules from "./Components/Screens/Settings/EditBreakRules";
import EditEarlyExitRule from "./Components/Screens/Settings/EditEarlyExitRule";
import EditLeavePolicy from "./Components/Screens/Settings/EditLeavePolicy";
import EditOverTimeRule from "./Components/Screens/Settings/EditOverTimeRule";
import EarlyOvertimeRule from "./Components/Screens/Settings/EarlyOvertimeRule";
import CreateEarlyOvertimeRule from "./Components/Screens/Settings/CreateEarlyOvertimeRule";
import EditEarlyOvertimeRule from "./Components/Screens/Settings/EditEarlyOvertimeRule";
import AddRegularStaff from "./Components/Screens/Staff/AddRegularStaff";
import AddContractStaff from "./Components/Screens/Staff/AddContractStaff";
import StaffProfile from "./Components/Screens/Staff/StaffProfile";
import StaffProfileEdit from "./Components/Screens/Staff/StaffProfileEdit";
import StaffOtherDetails from "./Components/Screens/Staff/StaffOtherDetails";
import StaffDocuments from "./Components/Screens/Staff/StaffDocuments";
const { Content } = Layout;

const testClr = "#164E63";

const lightTheme = {};

const darkTheme = {};

function App({ token, settings, getSettingsAction }) {
  let [email, setEmail] = useState(null);
  let [code, setCode] = useState(null);

  const setState = () => {
    setCode(null);
    setEmail(null);
  };

  useEffect(() => {
    if (token) {
      getSettingsAction();
    }
  }, []);

  // const { token } = theme.useToken();

  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileOrTablet = window.matchMedia("(max-width: 992px)").matches;
      setIsMobileOrTablet(isMobileOrTablet);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleTheme = () => {
    setCurrentTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const [currentTheme, setCurrentTheme] = useState("light");

  if (currentTheme == "light") {
    //new
    document.documentElement.style.setProperty(
      "--sideMenu--header-clr",
      "var(---primary-color)"
    );

    document.documentElement.style.setProperty(
      "--sideMenu--header--txt-clr",
      "var(--menu-txt-clr)"
    );

    document.documentElement.style.setProperty(
      "--sideMenu--background--active-clr",
      "var(--orange-Color)"
    );

    document.documentElement.style.setProperty(
      "--sideMenu--txt--active-clr",
      "var(--menu-txt-clr)"
    );

    document.documentElement.style.setProperty(
      "--heading-txt-clr",
      "var(--main-heading-clr)" //#475569
    );

    document.documentElement.style.setProperty(
      "--sub-heading-txt-clr",
      "var(--sub-heading-clr)"
    );

    document.documentElement.style.setProperty(
      "--primary-btn-bg-clr",
      "var(---primary-color)"
    );

    document.documentElement.style.setProperty(
      "--primary-btn-txt-clr",
      "var(--menu-txt-clr)"
    );

    document.documentElement.style.setProperty(
      "--primary-btn-bg-clr",
      "var(---primary-color)"
    );

    document.documentElement.style.setProperty(
      "--secondary-btn-bg-clr",
      "var(--layout-bgColor)"
    );

    document.documentElement.style.setProperty(
      "--seconadry-btn-border-clr",
      "var(---primary-color)"
    );

    document.documentElement.style.setProperty(
      "--seconadry-btn-txt-clr",
      "var(---primary-color)"
    );

    document.documentElement.style.setProperty(
      "--filter--icon-clr",
      "var(---primary-color)"
    );

    document.documentElement.style.setProperty(
      "--tabs-heading-txt-clr",
      "var(--main-heading-clr)" //#475569
    );

    document.documentElement.style.setProperty(
      "--inputSearch-btn-clr",
      "var(---primary-color)"
    );

    document.documentElement.style.setProperty(
      "--table-bottom-card-clr",
      "1px solid rgb(234, 234, 234)"
    );

    document.documentElement.style.setProperty(
      "--table-heading-card-clr",
      "var(--main-heading-clr)" //#475569
    );

    document.documentElement.style.setProperty(
      "--table-body-card-clr",
      "var(--sub-heading-clr)"
    );

    document.documentElement.style.setProperty(
      "--form-field-label-clr",
      "var(--form-label-clr)"
    );

    document.documentElement.style.setProperty(
      "--logout-btn-bg-clr",
      "#fce9e9"
    );
    document.documentElement.style.setProperty(
      "--logout-btn-txt-clr",
      "#e02020"
    );

    document.documentElement.style.setProperty(
      "--card-hover-primary-clr",
      "var(---primary-color)"
    );

    document.documentElement.style.setProperty(
      "--form-label-txt-clr",
      "var(--form-label-clr)"
    );


    document.documentElement.style.setProperty(
      "--Color-Primary-all",
      "var(---primary-color)"
    );


    //new end



    //Icons Light Theme Clr
    document.documentElement.style.setProperty(
      "--sideMenu-Icon_clr-focused",
      "var(--card-bg-clr-Light)"
    );
    document.documentElement.style.setProperty(
      "--sideMenu-Icon_clr-notFocused",
      "var(--card-bg-clr-Light)"
    );

    //Icons Light Theme Clr end

    document.documentElement.style.setProperty(
      "--sideMenur-bg-light",
      "var(--sideBar-bg-light)"
    );

    document.documentElement.style.setProperty("--sample-txt-clr", "#2F7BF5");

    document.documentElement.style.setProperty(
      "--switch-bg",
      "var(--sampleTxt-Color)"
    );

    document.documentElement.style.setProperty(
      "--main-layout-bg-clr",
      "var(--primary-color)"
    );

    document.documentElement.style.setProperty(
      "--content-layout-bg-clr",
      "var(--content-layout-bgClr-light)"
    );

    document.documentElement.style.setProperty(
      "--side-menu-text-clr-active",
      "var(--active-menu-txt-clr-light)"
    );

    document.documentElement.style.setProperty(
      "--side-menu-text-clr-inActive",
      "var(---menu-txt-clr-light)"
    );

    document.documentElement.style.setProperty(
      "--table-bg-color",
      "var(--table-bg-clr-light)"
    );

    document.documentElement.style.setProperty("--table-text-color", "#1e293b");

    document.documentElement.style.setProperty(
      "--Card--Color",
      "var(--card-bg-clr-Light)"
    );
  } else {
    document.documentElement.style.setProperty(
      "--sideMenur-bg-light",
      "var(--sideBar-bg-dark)"
    );

    document.documentElement.style.setProperty("--sample-txt-clr", "red");

    document.documentElement.style.setProperty("--switch-bg", "red");

    document.documentElement.style.setProperty(
      "--main-layout-bg-clr",
      "var(--sideBar-bg-dark)"
    );

    document.documentElement.style.setProperty(
      "--content-layout-bg-clr",
      "var(--content-layout-bgClr-dark)"
    );

    document.documentElement.style.setProperty(
      "--side-menu-text-clr-active",
      "var(--active-menu-txt-clr-dark)"
    );

    document.documentElement.style.setProperty(
      "--side-menu-text-clr-inActive",
      "var(---menu-txt-clr-dark)"
    );

    document.documentElement.style.setProperty(
      "--table-bg-color",
      "var(--table-bg-clr-dark)"
    );

    document.documentElement.style.setProperty("--table-text-color", "#CBD5E1");

    document.documentElement.style.setProperty(
      "--Card--Color",
      "var(--card-bg-clr-Dark)"
    );
  }

  return (
    <HashRouter>
      <React.Fragment>
        <ConfigProvider
          theme={{
            token: currentTheme === "light" ? lightTheme : darkTheme,
            components: {
              Checkbox: {
                // colorPrimary:'purple'
                // colorPrimary:'brown'
              },
              Menu: {
                // colorPrimary:token.sideBarMenuActiveTxtColor,
                // backgroundColor:'red',
                // colorTextPrimary:'yellow',
                // colorActive:'yellow'
              },
              Layout: {
                // backgroundColor:'red'
              },
              DatePicker: {
                colorPrimary: "var(--Color-Primary-all)",
              },
              Select: {
                colorPrimary: "var(--Color-Primary-all)",
                colorPrimaryHover: "var(--Color-Primary-all)",
              },
              Radio: {
                colorPrimaryHover:"var(--Color-Primary-all)",
                colorPrimary: "var(--Color-Primary-all)",
                colorText: "var(--form-label-clr)",
              },
              Checkbox: {
                colorPrimary: "var(--Color-Primary-all)",
                colorPrimaryHover: "var(--Color-Primary-all)",
              },
              Tabs: {
                colorPrimary: "var(--Color-Primary-all)",
                fontSize: "16px",
                colorPrimaryHover: "var(--Color-Primary-all)",
                fontFamily: "font-Medium",
              },
              Modal: {
                titleFontSize: "22px",
                titleColor: "var(--heading-txt-clr)",
              },
              Switch: {
                colorPrimary: "var(--Color-Primary-all)",
                colorPrimaryHover: "var(--Color-Primary-all)",
              },
              // siderMenuActiveColor: '#ff0000',
            },
          }}
        >
          {token ? (
            settings ? (
              <Layout>
                <SideBar />
                <Layout style={{ height: "100vh" }}>
                  {!isMobileOrTablet && <Headers />}{" "}
                  {/* Render Header only if not in mobile or tablet */}
                  <Content
                    style={{
                      padding: "30px",
                      zIndex: 4,
                      maxHeight: "100vh",
                      overflowY: "scroll",
                      backgroundColor: "var(--background-color)",
                    }}
                  >
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/card" element={<Card />} />
                      <Route path="/form" element={<SampleForm />} />
                      <Route path="/attendance" element={<Attendance />} />
                      <Route path="/reports" element={<Reports />} />
                      <Route path="/staff" element={<Staff />} />
                      <Route path="/staff-documents" element={<StaffDocuments />} />
                      <Route
                        path="/staff/add/regular"
                        element={<AddRegularStaff />}
                      />
                      <Route
                        path="/staff/add/Contract"
                        element={<AddContractStaff />}
                      />
                      
                      <Route
                        path="/staff/staff-detail-page"
                        element={<StaffDetails />}
                      />
                      <Route
                        path="/staff/staff-detail-profile"
                        element={<StaffProfile />}
                      />
                      <Route
                        path="/staff/staff-other-details-profile"
                        element={<StaffOtherDetails />}
                      />
                      <Route
                        path="/staff/staff-detail-profile/edit"
                        element={<StaffProfileEdit />}
                      />
                      <Route path="/settings" element={<Settings />} />
                      <Route
                        path="/settings/shifts"
                        element={<ShiftSettings />}
                      />
                      <Route
                        path="/settings/shifts/add"
                        element={<AddShift />}
                      />
                      <Route
                        path="/settings/shifts/edit"
                        element={<EditShift />}
                      />
                      <Route
                        path="/settings/attendance-automation"
                        element={<AutomationRules />}
                      />
                      <Route
                        path="/settings/comp-off-leave"
                        element={<CompoffLeaveDetailPage />}
                      />
                      <Route
                        path="/settings/holiday-policy"
                        element={<HolidayPoliciy />}
                      />
                      <Route
                        path="/settings/holiday-policy/add"
                        element={<AddHolidayPolicy />}
                      />
                      <Route
                        path="/settings/holiday-policy/edit"
                        element={<EditHolidayPolicy />}
                      />
                      <Route
                        path="/settings/leave-policy"
                        element={<LeavePolicyPage />}
                      />
                      <Route
                        path="/settings/leave-policy/add"
                        element={<AddLeavePolicyPage />}
                      />
                      <Route
                        path="/settings/leave-policy/edit"
                        element={<EditLeavePolicy />}
                      />
                      <Route
                        path="/settings/departments"
                        element={<Departments />}
                      />
                      <Route
                        path="/settings/staff-weekly-holidays"
                        element={<StaffWeeklyHolidays />}
                      />
                      <Route
                        path="/settings/attendance-automation/late-entry"
                        element={<LateEntryRules />}
                      />
                      <Route
                        path="/settings/attendance-automation/late-entry/add"
                        element={<CreateLateEntryRule />}
                      />
                      <Route
                        path="/settings/attendance-automation/late-entry/edit"
                        element={<EditLateEntryRule />}
                      />
                      <Route
                        path="/settings/attendance-automation/break-rule"
                        element={<BreakRules />}
                      />
                      <Route
                        path="/settings/attendance-automation/break-rule/add"
                        element={<CreateBreakRules />}
                      />
                      <Route
                        path="/settings/attendance-automation/break-rule/edit"
                        element={<EditBreakRules />}
                      />
                      <Route
                        path="/settings/attendance-automation/early-exit"
                        element={<EarlyExitRule />}
                      />
                      <Route
                        path="/settings/attendance-automation/early-exit/add"
                        element={<CreateEarlyExitRule />}
                      />
                      <Route
                        path="/settings/attendance-automation/early-exit/edit"
                        element={<EditEarlyExitRule />}
                      />
                      <Route
                        path="/settings/attendance-automation/overtime"
                        element={<OverTimeRule />}
                      />
                      <Route
                        path="/settings/attendance-automation/overtime/add"
                        element={<CreateOverTimeRule />}
                      />
                      <Route
                        path="/settings/attendance-automation/overtime/edit"
                        element={<EditOverTimeRule />}
                      />
                      <Route
                        path="/settings/attendance-automation/early_overtime"
                        element={<EarlyOvertimeRule />}
                      />
                      <Route
                        path="/settings/attendance-automation/early_overtime/add"
                        element={<CreateEarlyOvertimeRule />}
                      />
                      <Route
                        path="/settings/attendance-automation/early_overtime/edit"
                        element={<EditEarlyOvertimeRule />}
                      />
                      <Route path="/payroll" element={<Payroll />} />
                      <Route path="/test" element={<Test />} />
                      <Route path="/test1" element={<Test1 />} />
                    </Routes>
                  </Content>
                </Layout>
              </Layout>
            ) : (
              <Skeleton />
            )
          ) : (
            <Layout style={{ height: "100vh" }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    email && code ? (
                      <ConfirmPassword
                        setState={setState}
                        code={code}
                        email={email}
                      />
                    ) : (
                      <Login />
                    )
                  }
                />
                {/* <Route path="/" element={<Login />} /> */}

                <Route path="/forgotpassword" element={<ForgotPassword />} />
              </Routes>
            </Layout>
          )}
        </ConfigProvider>
      </React.Fragment>
    </HashRouter>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.authentication.accessToken
      ? state.authentication.accessToken
      : localStorage.getItem("token"),
    settings: state.settings.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettingsAction: () => {
      dispatch(getSettingsAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
