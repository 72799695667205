import React, { useEffect, useRef, useState } from "react";
import Headers from "../../UI/headersComp/Headers";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  MoreOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  BonusIcon,
  DeductionsIcon,
  FilterOutlinedIcon,
  OverTimeIcon,
  PaymentIcon,
  RaiseHandIcon,
} from "../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router";
import moment from "moment";
import { connect } from "react-redux";
import { postLeavePolicyAction } from "../../../Reusable/Store/Action/LeavePolicyAction";
function AddLeavePolicyPage({ postLeavePolicyAction }) {
  // Holiday template related styles
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const newFieldRef = useRef();

  const [leavePolicy, setLeavePolicy] = useState("monthly");
  const navigate = useNavigate();

  const [scrollToTop, setScrollToTop] = useState(true);
  const [hiddenRows, setHiddenRows] = useState([]);

  useEffect(() => {
    const initialHolidays = [{}];
    form.setFieldsValue({ leaveCategory: initialHolidays });
  }, [form]);

  const onFinish = (values) => {
    if (values.periodFrom) {
      const Data = {
        ...values,
        periodFrom:
          values.periodFrom && moment(values.periodFrom).format("MMMM YYYY"),
        periodTo:
          values.periodTo && moment(values.periodTo).format("MMMM YYYY"),
      };
      postLeavePolicyAction(Data, (success) => {
        if (success) {
          navigate("/settings/leave-policy");
          console.log("Success");
        }
      });
    } else {
      postLeavePolicyAction(values, (success) => {
        if (success) {
          navigate("/settings/leave-policy");
          console.log("Success");
        }
      });
      console.log(values, "values jiojeoijy");
    }

    // postLeavePolicyAction(values, (success) => {
    //   if (success) {
    //     navigate("/settings/leave-policy");
    //     console.log("Success");
    //   }
    // });
  };

  const handleAddHoliday = () => {
    const holidays = form.getFieldValue("leaveCategory");
    form.setFieldsValue({
      leaveCategory: [...holidays, { holidayName: "", holidayDate: null }],
    });
    setTimeout(() => {
      newFieldRef.current.focus();
    }, 0);
  };
  // Holiday template related styles end

  const handleUnusedLeaveRuleChange = (index, value) => {
    const newHiddenRows = [...hiddenRows];
    if (value === "lapse") {
      newHiddenRows.push(index);
    } else {
      const indexToRemove = newHiddenRows.indexOf(index);
      if (indexToRemove !== -1) {
        newHiddenRows.splice(indexToRemove, 1);
      }
    }
    setHiddenRows(newHiddenRows);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link to="/settings/leave-policy">
            <h1 className={commonCssStyles.BackButtonTxt}>
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>{" "}
      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>
            Create Leave Template
          </h1>
        </Col>
      </Row>{" "}
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.TableSecHeadingTxt}>
            Template Settings
          </h1>
        </Col>
      </Row>
      <Form
        layout="vertical"
        form={form}
        //  onFinish={(val)=>{
        //   console.log(val);
        // }}

        onFinish={onFinish}
      >
        <Row>
          <Col md={24}>
            <Card>
              <Row>
                <Col xs={24}>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="name"
                        label="Template Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the template name",
                          },
                        ]}
                      >
                        <Input placeholder="Leave Template Name" />
                      </Form.Item>

                      <Form.Item
                        name="leaveCycle"
                        label="Leave Policy Cycle"
                        initialValue={leavePolicy}
                      >
                        <Radio.Group
                          onChange={(e) => {
                            setLeavePolicy(e.target.value);
                          }}
                        >
                          <Radio value="monthly">Monthly</Radio>
                          <Radio value="yearly">Yearly</Radio>
                        </Radio.Group>
                      </Form.Item>

                      {leavePolicy && leavePolicy == "yearly" && (
                        <>
                          <Form.Item
                            name="periodFrom"
                            label="Leave Period From"
                          >
                            <DatePicker
                              // placeholder="periodFrom"
                              style={{ width: "100%" }}
                              picker="month"
                              format="MMMM YYYY"
                            />
                          </Form.Item>
                          <Form.Item name="periodTo" label="Leave Period To">
                            <DatePicker
                              // placeholder="Holiday Name"
                              style={{ width: "100%" }}
                              picker="month"
                              format="MMMM YYYY"
                            />
                          </Form.Item>

                          <Form.Item name="accuralType" label="Accrual Type">
                            <Select
                              className="addStaffForm"
                              style={{ width: "100%" }}
                              onChange={(val) => {
                                console.log(val, "selected value");
                              }}
                            >
                              <Select.Option value="all_at_once">
                                All At Once
                              </Select.Option>
                              <Select.Option value="month_start">
                                Month Start
                              </Select.Option>
                              <Select.Option value="month_end">
                                Month End
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <h1 className={commonCssStyles.TableSecHeadingTxt}>
              Leave Categories
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Card>
              <Row justify={"space-between"} style={{ marginTop: "20px" }}>
                <Col>
                  <h1 className={commonCssStyles.TableSecHeadingTxt}>
                    Leave Categories
                  </h1>
                </Col>
              </Row>

              <Row style={{ marginTop: "20px" }}>
                <Col xs={24}>
                  <Form.List name="leaveCategory">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Row key={field.key} gutter={8}>
                            <Col span={5}>
                              <Form.Item
                                {...field}
                                label={index === 0 ? "Leave Category Name" : ""}
                                name={[field.name, "categoryName"]}
                                fieldKey={[field.fieldKey, "categoryName"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter the category name",
                                  },
                                ]}
                              >
                                <Input
                                  ref={
                                    index === fields.length - 1
                                      ? newFieldRef
                                      : null
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col span={4}>
                              <Form.Item
                                {...field}
                                label={index === 0 ? "Leave Count" : ""}
                                name={[field.name, "leaveCount"]}
                                fieldKey={[field.fieldKey, "leaveCount"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter the leave count",
                                  },
                                ]}
                              >
                                <Input
                                  ref={
                                    index === fields.length - 1
                                      ? newFieldRef
                                      : null
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col span={4}>
                              <Form.Item
                                {...field}
                                label={index === 0 ? "Unused Leave Rule" : ""}
                                name={[field.name, "unusedLeaveRule"]}
                                fieldKey={[field.fieldKey, "unusedLeaveRule"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select the unused leave rule",
                                  },
                                ]}
                              >
                                <Select
                                  className="addStaffForm"
                                  style={{ width: "100%" }}
                                  onChange={(val) => {
                                    handleUnusedLeaveRuleChange(index, val);
                                  }}
                                  ref={
                                    index === fields.length - 1
                                      ? newFieldRef
                                      : null
                                  }
                                >
                                  <Select.Option value="lapse">
                                    Lapse
                                  </Select.Option>
                                  <Select.Option value="carry_forward">
                                    Carry Forward
                                  </Select.Option>
                                  <Select.Option value="encash">
                                    Encash
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col span={4}>
                              <Form.Item
                                {...field}
                                label={
                                  index === 0
                                    ? "Encashment/Carry Forward Limit"
                                    : ""
                                }
                                name={[field.name, "limit"]}
                                fieldKey={[field.fieldKey, "limit"]}
                                rules={[
                                  {
                                    required: !hiddenRows.includes(index),
                                    message: "Please enter the encashment/carry forward limit",
                                  },
                                ]}
                              >
                                <Input
                                  ref={
                                    index === fields.length - 1
                                      ? newFieldRef
                                      : null
                                  }
                                  disabled={hiddenRows.includes(index)}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={4}>
                              {index > 0 && (
                                <Button
                                  type="text"
                                  onClick={() => remove(field.name)}
                                >
                                  <DeleteOutlined />
                                </Button>
                              )}
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                  </Form.List>

                  <Form.Item>
                    <Button
                      type="primary"
                      className={commonCssStyles.filterBtnStyles}
                      onClick={handleAddHoliday}
                    >
                      Add Leave Category
                    </Button>
                  </Form.Item>

                  <Row style={{ marginTop: "34px" }}>
                    <Col xs={24}>
                      <Form.Item>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            marginRight: "40px",
                          }}
                        >
                          <Button
                            type="primary"
                            className={commonCssStyles.addBtnStyles}
                            htmlType="submit"
                            onClick={() => {
                              form1.submit();
                            }}
                          >
                            Save & Apply
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    postLeavePolicyAction: (val, callback) => {
      dispatch(postLeavePolicyAction(val, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLeavePolicyPage);
