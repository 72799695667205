import { SETTINGS_LEAVE_POLICY_RULE_URL } from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
import { getLeavePolicyDetails, getLeavePolicySlice } from "../Slice/LeavePolicySlice";
const api = new AxiosApi();


export const getLeavePolicyAction = () => {
    return (dispatch) => {
      api.invoke(
        SETTINGS_LEAVE_POLICY_RULE_URL,
        "get",
        (data, success, statusCode) => {
          if (success) {
            if (statusCode === 200) dispatch(getLeavePolicySlice(data));
            else {
              message.destroy();
              message.error(data.message, 3);
            }
          } else {
            message.destroy();
            message.error("Something went wrong", 3);
          }
        }
      );
    };
  };
  
  
  export const postLeavePolicyAction = (val,callback) => {
      return (dispatch) => {
        api.invoke(
            SETTINGS_LEAVE_POLICY_RULE_URL,
          "post",
          (data, success, statusCode) => {
              callback(false);
            if (success) {
              if (statusCode === 200) {
                  callback(true);
                message.destroy();
                message.success(data.message, 3);
                dispatch(getLeavePolicyAction());
              } else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          },
          val
        );
      };
    };


    export const deleteLeavePolicyAction = (id) => {
      return (dispatch) => {
        api.invoke(
          SETTINGS_LEAVE_POLICY_RULE_URL + "/" + id,
          "delete",
          (data, success, statusCode) => {
            // callback(false);
            if (success) {
              if (statusCode === 200) {
                // callback(true);
                message.destroy();
                message.success(data.message, 3);
                dispatch(getLeavePolicyAction());
              } else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          }
        );
      };
    };


    export const getLeavePolicyDetailsAction = (id) => {
      return (dispatch) => {
        api.invoke(
          SETTINGS_LEAVE_POLICY_RULE_URL+"/"+id,
          "get",
          (data, success, statusCode) => {
            if (success) {
              if (statusCode === 200) dispatch(getLeavePolicyDetails(data));
              else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          }
        );
      };
    };


    export const putLeavePolicyAction = (val,callback) => {
      return (dispatch) => {
        api.invoke(
          SETTINGS_LEAVE_POLICY_RULE_URL + "/"+val.id,
          "put",
          (data, success, statusCode) => {
              callback(false);
            if (success) {
              if (statusCode === 200) {
                  callback(true);
                message.destroy();
                message.success(data.message, 3);
                dispatch(getLeavePolicyAction());
              } else {
                message.destroy();
                message.error(data.message, 3);
              }
            } else {
              message.destroy();
              message.error("Something went wrong", 3);
            }
          },
          val
        );
      };
    };
  