import React from "react";
import { Row, Col, Card, Button } from "antd";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";

function Payroll() {
  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify="space-between">
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>Payroll</h1>
        </Col>
        <Col>
          <Row style={{ gap: "20px" }}>
            <Col>
              <Button
                style={{ marginTop: "10px", width: "100%" }}
                className={commonCssStyles.addBtnStyles}
              >
                Process In Bulk
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 40 }}>
        <Col xs={24} style={{ padding: 8 }}>
          <Card style={{padding:"20px"}}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Row gutter={[16, 16]} style={{ flexDirection: "column" }}>
                  <Col>
                    <p>Pending</p>
                  </Col>
                  <Col>
                    <p>1</p>
                  </Col>
                  <Col>
                    <p>Process payroll</p>
                  </Col>
                </Row>
              </div>
              <div style={{marginTop:"50px"}}>|</div>
              <div>
                <Row gutter={[16, 16]} style={{ flexDirection: "column" }}>
                  <Col>
                    <p>Pending</p>
                  </Col>
                  <Col>
                    <p>1</p>
                  </Col>
                  <Col>
                    <p>Process payroll</p>
                  </Col>
                </Row>
              </div>
              <div style={{marginTop:"50px"}}>|</div>
              <div>
                <Row gutter={[16, 16]} style={{ flexDirection: "column" }}>
                  <Col>
                    <p>Pending</p>
                  </Col>
                  <Col>
                    <p>1</p>
                  </Col>
                  <Col>
                    <p>Process payroll</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Payroll;
