import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  settings: null,
  settingsOverview: null,
  PayrollGeneralSettings: [],
  settingsAttendanceAccess: [],
  settingsCompOffLeave: [],

  weeklyHolidays:null
};
export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    getSettingsSlice(state, action) {
      state.settings = action.payload.result;
    },
    getSettingsOverviewSlice(state, action) {
      state.settingsOverview = action.payload.result;
    },
    getPayrollGeneralSettingsSlice(state, action) {
      state.PayrollGeneralSettings = action.payload.result;
    },
   
    getSettingsAttendanceAccessSlice(state, action) {
      state.settingsAttendanceAccess = action.payload.result;
    },

    getSettingsCompOffSlice(state, action) {
      state.settingsCompOffLeave = action.payload.result;
    },


    getWeeklyHolidaysSlice(state, action) {
      state.weeklyHolidays = action.payload;
    },

  },
});

export const {
  getSettingsSlice,
  getPayrollGeneralSettingsSlice,
  getSettingsOverviewSlice,
  getSettingsAttendanceAccessSlice,
  getSettingsCompOffSlice,
  getWeeklyHolidaysSlice
} = settingsSlice.actions;
export default settingsSlice.reducer;
