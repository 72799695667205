import { SETTINGS_LATE_ENTRY_RULE_URL,USER_PAYROLL_LATE_ENTRY_URL } from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
import {
  getLateEntryAssignSlice,
  getLateEntryDetailSlice,
  getLateEntrySlice,
} from "../Slice/LateEntryRuleSlice";
const api = new AxiosApi();

export const getLateEntryAction = () => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_LATE_ENTRY_RULE_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getLateEntrySlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postLateEntryAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_LATE_ENTRY_RULE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getLateEntryAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const deleteLateEntryRuleAction = (id) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_LATE_ENTRY_RULE_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        // callback(false);
        if (success) {
          if (statusCode === 200) {
            // callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getLateEntryAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const getLateEntryDetailsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_LATE_ENTRY_RULE_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getLateEntryDetailSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const putLateEntryRuleAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_LATE_ENTRY_RULE_URL + "/" + val.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getLateEntryAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};



//Assign Staff to rules 

export const getLateEntryAssignAction = (val) => {
  return (dispatch) => {
    api.invoke(USER_PAYROLL_LATE_ENTRY_URL+"/"+val, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getLateEntryAssignSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};


export const postLateEntryAssignAction = (val,callback) => {
  return (dispatch) => {
    api.invoke(
      USER_PAYROLL_LATE_ENTRY_URL,
      "post",
      (data, success, statusCode) => {
        callback(false)
        if (success) {
          if (statusCode === 200) {
            callback(true)
            message.destroy();
            message.success(data.message, 3);
            
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};


//Assign Staff to rules end 