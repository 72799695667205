import React, { useState } from 'react';
import { Form, DatePicker, Typography } from 'antd'; // Import Typography for displaying the days difference
import dayjs from 'dayjs';

const { Text } = Typography;

function Test1() {
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState(null);
  const [daysDifference, setDaysDifference] = useState(null); // State to store the days difference

  const handleStartDateChange = (date) => {
    setStartDate(date);
    form.setFieldsValue({ endDate: null });
    setDaysDifference(null); // Reset days difference when changing start date
  };

  const handleEndDateChange = (date) => {
    form.setFieldsValue({ endDate: date });
    calculateDaysDifference(startDate, date); // Calculate days difference when changing end date
  };

  const disabledEndDate = (current) => {
    if (!startDate) {
      return current && current.isBefore(dayjs(), 'day');
    }
    return current && (current.isBefore(dayjs(), 'day') || current.isBefore(startDate, 'day'));
  };

  const calculateDaysDifference = (start, end) => {
    if (start && end) {
      const startDay = dayjs(start);
      const endDay = dayjs(end);
      const difference = endDay.diff(startDay, 'day') + 1;
      setDaysDifference(difference);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Form form={form} layout="vertical">
        <Form.Item label="Start Date" name="startDate">
          <DatePicker
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            onChange={handleStartDateChange}
          />
        </Form.Item>
        <Form.Item label="End Date" name="endDate">
          <DatePicker
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            onChange={handleEndDateChange}
            disabledDate={disabledEndDate}
          />
        </Form.Item>
        {daysDifference !== null && (
          <Text strong>
            Days Difference: {daysDifference}
          </Text>
        )}
      </Form>
    </div>
  );
}

export default Test1;
