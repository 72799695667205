import React, { useEffect, useState } from "react";
import Headers from "../../../UI/headersComp/Headers";
import commonCssStyles from "../../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  BonusIcon,
  DeductionsIcon,
  FilterOutlinedIcon,
  OverTimeIcon,
  PaymentIcon,
  RaiseHandIcon,
} from "../../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router";
import { connect } from "react-redux";
import {
  deleteHolidayPolicyAction,
  getHolidayPolicyAction,
} from "../../../../Reusable/Store/Action/HolidayPolicyAction";

function HolidayPoliciy({
  holidayPolicy,
  getHolidayPolicyAction,
  deleteHolidayPolicyAction,
}) {
  useEffect(() => {
    getHolidayPolicyAction();
  }, []);

  const [deleteId, setDeleteId] = useState("");

  const handleMenuClick = (e) => {
    if (e.key === "edit") {
      // Handle the edit action
      console.log("Edit clicked");
    } else if (e.key === "delete") {
      // Handle the delete action
      console.log("Delete clicked");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteHolidayPolicyAction(deleteId);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Row justify={"space-between"}>
          <Col>
            <Link to="/settings">
              <h1 className={commonCssStyles.BackButtonTxt}>
                {" "}
                <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
                &nbsp; Back
              </h1>
            </Link>
          </Col>
        </Row>

        <Row justify={"space-between"} style={{ marginTop: "20px" }}>
          <Col>
            <h1 className={commonCssStyles.PageHeadingTxt}>Holiday Policiy</h1>
          </Col>
          <Col>
            <Link to="/settings/holiday-policy/add">
              <Button type="primary" className={commonCssStyles.addBtnStyles}>
                Add Holiday
              </Button>
            </Link>
          </Col>
        </Row>

        <Row style={{ marginTop: "40px" }}>
          <h1 className={commonCssStyles.TableSecHeadingTxt}>
            Holiday Templates &#40;{" "}
            {holidayPolicy && holidayPolicy.length > 0
              ? holidayPolicy.length
              : "0"}{" "}
            &#41;
          </h1>
        </Row>

        <div>
          {holidayPolicy &&
            holidayPolicy.map((data, index) => {
              return (
                <>
                  <Card
                    key={index}
                    className={`${commonCssStyles.tableCardsContainer} custom-card`}
                    style={{ padding: "0px !important" }}
                  >
                    <Row justify={"space-between"}>
                      <Col>
                        <Row style={{ gap: "20px", marginTop: "10px" }}>
                          <Col>
                            <p
                              className={
                                commonCssStyles.holidayPolicyettingsName
                              }
                            >
                              {data && data.name && data.name
                                ? data.name
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ display: "flex", alignSelf: "center" }}>
                        <Dropdown
                          placement="bottomRight"
                          overlayStyle={{ width: "300px" }}
                          overlay={
                            <div className={commonCssStyles.AddStaffCard1}>
                              <Link
                                to={"/settings/holiday-policy/edit"}
                                state={{
                                  id: data.id,
                                }}
                              >
                                <Button
                                  style={{ marginTop: "10px", width: "100%" }}
                                  className={commonCssStyles.secondaryBtnStyles}
                                >
                                  Edit
                                </Button>
                              </Link>
                              <Button
                                style={{ marginTop: "10px", width: "100%" }}
                                className={commonCssStyles.secondaryBtnStyles}
                                onClick={() => {
                                  // deleteShiftsAction(data.id)

                                  setDeleteId(data.id);

                                  showModal();
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          }
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <div>
                              <Col>
                                <MoreOutlined
                                  style={{
                                    fontSize: "30px",
                                    marginRight: "15px",
                                    color: "var(--main-heading-clr)",
                                  }}
                                />
                              </Col>
                            </div>
                          </a>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Card>
                </>
              );
            })}
        </div>

        {holidayPolicy && holidayPolicy.length > 0 ? (
         <></>
        ) : (
          <div style={{ marginTop: "50px",marginLeft:"50%" }}>
           <h1 className={commonCssStyles.PageHeadingTxt} >No Data</h1>
            
            </div>
          
        )}
      </div>

      <Modal
        title="Delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div key="modalFooter" style={{ padding: "5px 0px 0px 0px" }}>
            <Button
              key="resetFilter"
              className={commonCssStyles.resetFilterStyles}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="applyFilter"
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleOk();
              }}
              className={commonCssStyles.applyFilterStyles}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <p className={commonCssStyles.modalSubHeadingStyles}>
          Are you sure you want to delete
        </p>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    holidayPolicy: state.holidayPolicy.holidayPolicy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHolidayPolicyAction: () => {
      dispatch(getHolidayPolicyAction());
    },

    deleteHolidayPolicyAction: (id) => {
      dispatch(deleteHolidayPolicyAction(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidayPoliciy);
