import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Upload,
  message,
  Row,
  Col,
  Select,
  Image,
  Progress,
} from "antd";
import word from "../../../Assets/Image/word.png";
import pdf from "../../../Assets/Image/pdf.png";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import commonCssStyles from "../../UI/CommonCssStyles.module.css";

function UploadDocumentModal(props) {
  let btnref = useRef(null);
  const [filesData, setFilesData] = useState([]);
  const [fileResult, setFileResult] = useState(false);
  const [fileType, setFileType] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [progress, setProgress] = useState(0);

  const closeIcon = (
    <CloseOutlined
      style={{ color: "var(--heading-txt-clr)", fontSize: "22px" }}
      onClick={() => {
        props.handleUploadDocumentsCancel();
      }}
    />
  );

  const handleChange = (info) => {
    if (info.target.files.length > 5) {
      message.warning("At a time only 5 files can select", 3);
      setProgress(0);
      return;
    }
    setFileResult(false);
    for (let i = 0; i < info.target.files.length; i++) {
      if (!(info.target.files[i].size <= 5000000)) {
        message.warning("This file should be less than 5 MB", 3);
        setProgress(0);
        return;
      }
    }
    let array = [];
    for (let i = 0; i < info.target.files.length; i++) {
      array.push(info.target.files[i]);
    }
    setFilesData(array);
    setProgress(50);
    setTimeout(() => {
      setProgress(100);
    }, 300);
  };

  const uploadDataHandler = (data) => {
    if (!filesData) {
      setFileResult(true);
      return;
    }
    const val = {
      userId: props.userId,
      //uploadType: data.uploadType,
      document: filesData,
    };
    console.log(val, "Uploaded Documents");
    props.onOkHandle(val);
    setFileType("");
    setFilesData([]);
  };
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    if (e.dataTransfer.files.length > 5) {
      message.warning("At a time only 5 files can select", 3);
      setProgress(0);
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let arr = [];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        arr.push(e.dataTransfer.files[i]);
      }
      setFilesData(arr);
      setProgress(50);
      setTimeout(() => {
        setProgress(100);
      }, 300);
    }
  };

  return (
    <>
      <Modal
        title="Upload Document"
        open={props.uploadModalOpen}
        closable={false}
        closeIcon={closeIcon}
        width={706}
        footer={false}

        // open={true}
      >
        <Form
          onDragEnter={handleDrag}
          onFinish={(val) => {
            uploadDataHandler(val);
          }}
          layout="vertical"
        >
          <Form.Item
            rules={[
              {
                required: false,
                message: "Please select document",
              },
            ]}
            name="document"
            style={{ padding: "0px 30px" }}
          >
            <>
              <input
                accept="application/pdf,.docx"
                hidden
                type="file"
                name={["fileUpload"]}
                multiple
                onChange={handleChange}
                ref={(input) => {
                  btnref = input;
                }}
              />
              {(filesData && filesData.length !== 0) || dragActive ? (
                <div
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                  style={{
                    marginTop:"20px",
                    textAlign: "center",
                    cursor: "pointer",
                    padding: "20px",
                    height: "183px",
                    borderRadius: "7px",
                    backgroundColor: "var(--input-color)",
                    borderRadius: "5px",
                    paddingBottom: "29px",
                    border: "2px dashed var(--tabs-heading-txt-clr)",
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      btnref.click();
                      return false;
                    }}
                  >
                    <div>
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined color={"var(--tabs-heading-txt-clr)"}  style={{fontSize:"40px"}}/>
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ color: "var(--tabs-heading-txt-clr)",fontSize:"20px" }}
                      >
                        Upload from device
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      btnref.click();
                      return false;
                    }}
                    style={{
                      marginTop:"20px",
                      textAlign: "center",
                      cursor: "pointer",
                      padding: "20px",
                      height: "183px",
                      borderRadius: "7px",
                      backgroundColor: "var(--input-color)",
                      borderRadius: "5px",
                      paddingBottom: "29px",
                      border: "2px dashed var(--tabs-heading-txt-clr)",
                    }}
                  >
                    <div>
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined color={"var(--tabs-heading-txt-clr)"} 
                        style={{fontSize:"40px"}}
                        />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ color: "var(--tabs-heading-txt-clr)",fontSize:"20px" }}
                      >
                        Upload from device
                      </p>
                    </div>
                  </div>
                </>
              )}
            </>
          </Form.Item>
          {filesData &&
            filesData.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{
                    margin: "22px",
                    display: "flex",
                    alignItems: "center",
                    padding: "14px 8px 17px 8px",
                    backgroundColor: "var(--progress-card)",
                    borderRadius: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Image
                      onClick={(e) => {
                        e.preventDefault();
                        btnref.click();
                        return false;
                      }}
                      preview={false}
                      src={data.type === "application/pdf" ? pdf : word}
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "6px",
                        position: "relative",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      margin: "0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.formInputLabel}`}
                      >
                        {filesData[index].name}
                      </p>
                      <p
                        className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.formInputLabel}`}
                      >
                        {progress == "0"
                          ? "0%"
                          : progress == "50"
                          ? "50%"
                          : "100%"}
                      </p>
                    </div>
                    <Progress percent={progress} size="small" />
                  </div>
                  <div>
                    <span
                      onClick={() => {
                        let arrayIndex = filesData.filter(
                          (x, i) => x.name !== data.name
                        );
                        setFilesData(arrayIndex);
                        // setFileResult(true);
                      }}
                      style={{
                        position: "relative",
                        paddingTop: "5px",
                        paddingLeft: "1px",
                        cursor: "pointer",
                      }}
                    >
                      <CloseOutlined color={"var(--headings-color)"} />
                    </span>
                  </div>
                </div>
              );
            })}
          {fileResult && (
            <p
              style={{
                fontSize: "14px",
                color: "#ff4d4f",
                padding: "0px 30px",
              }}
            >
              Please select file
            </p>
          )}
          <Row
            className={commonCssStyles.footerShadowForTemplete}
            style={{ gap: "17px" }}
            justify="end"
          >
            <Col>
              <Button
                className={commonCssStyles.resetFilterStyles}
                onClick={() => {
                  setFilesData([]);
                  setFileResult(false);
                  props.uploadModalClose();
                }}
                type="default"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                className={commonCssStyles.applyFilterStyles}
                htmlType="submit"
              >
                Save Document
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default UploadDocumentModal;
