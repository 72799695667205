import React, { useEffect, useRef, useState } from "react";
import Headers from "../../../UI/headersComp/Headers";
import commonCssStyles from "../../../UI/CommonCssStyles.module.css";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from "antd";

import {
  UserAddOutlined,
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  ProfileOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  TrademarkCircleOutlined,
  FileProtectOutlined,
  MoreOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  BonusIcon,
  DeductionsIcon,
  FilterOutlinedIcon,
  OverTimeIcon,
  PaymentIcon,
  RaiseHandIcon,
} from "../../../IconsComp/Icon";
import { Link } from "react-router-dom";
import DefaultProfileImg from "../../../../Assets/Image/DefaultProfileImg.png";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useLocation, useNavigate } from "react-router";
import moment from "moment";
import { connect } from "react-redux";
import { getHolidayDetailsAction, putHolidayPolicyAction } from "../../../../Reusable/Store/Action/HolidayPolicyAction";
import dayjs from "dayjs";

function EditHolidayPolicy({
  getHolidayDetailsAction,
  holidayDetails,
  putHolidayPolicyAction,
}) {
  const location = useLocation();

  const navigate = useNavigate();


  useEffect(() => {
    getHolidayDetailsAction(
      location && location.state && location.state.id && location.state.id
    );
  }, [location?.state?.id]);

  console.log(holidayDetails && holidayDetails, "holidayDetails");

  // Holiday template related styles
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const newFieldRef = useRef();

  useEffect(() => {
    if (holidayDetails) {
      const initialHolidays = holidayDetails.holidayList.map((holiday) => ({
        holidayName: holiday.holidayName,
        holidayDate: dayjs(holiday.holidayDate, "YYYY-MM-DD"),
      }));

      form.setFieldsValue({ holidayList: initialHolidays });
    }
  }, [holidayDetails,form]);

  useEffect(() => {
    if (holidayDetails) {
      form.setFieldsValue({
        name: holidayDetails.name,
        periodFrom: dayjs(holidayDetails.periodFrom),
        periodTo: dayjs(holidayDetails.periodTo),
      });
    }
  }, [holidayDetails, form]);

  const onFinish = (values) => {
    const formattedValues = {
      ...values,
      id:location?.state?.id,
      periodFrom:
        values.periodFrom && dayjs(values.periodFrom).format("YYYY-MM-DD"),
      periodTo: values.periodTo && dayjs(values.periodTo).format("YYYY-MM-DD"),
      holidayList: values.holidayList.map((holiday) => ({
        ...holiday,
        holidayDate:
          holiday.holidayDate &&
          dayjs(holiday.holidayDate).format("YYYY-MM-DD"),

      })),
    };

    console.log("Submitted values:", formattedValues);

    // putHolidayPolicyAction


    putHolidayPolicyAction(formattedValues, (success) => {
      if (success) {
        navigate("/settings/holiday-policy");
        console.log("Success");
      }
    });
  };

  const handleAddHoliday = () => {
    const holidays = form.getFieldValue("holidayList");
    form.setFieldsValue({
      holidayList: [...holidays, { holidayName: "", holidayDate: null }],
    });
    setTimeout(() => {
      newFieldRef.current.focus();
    }, 0);
  };
  // Holiday template related styles end

  return (
    <div style={{ marginTop: "20px" }}>
      <Row justify={"space-between"}>
        <Col>
          <Link to="/settings/holiday-policy">
            <h1 className={commonCssStyles.BackButtonTxt}>
              <ArrowLeftOutlined className={commonCssStyles.BackButtonIcon} />
              &nbsp; Back
            </h1>
          </Link>
        </Col>
      </Row>

      <Row justify={"space-between"} style={{ marginTop: "20px" }}>
        <Col>
          <h1 className={commonCssStyles.PageHeadingTxt}>
            Edit Holiday Calendar Template
          </h1>
        </Col>
      </Row>
      <Form
        layout="vertical"
        form={form}
        //  onFinish={(val)=>{
        //   console.log(val);
        // }}

        onFinish={onFinish}
      >
        <Row style={{ marginTop: "30px" }}>
          <Col xs={24}>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Col xs={24} md={20}>
                <Row
                  style={{ display: "flex", flexDirection: "row", gap: "40px" }}
                >
                  <Col>
                    <Form.Item name="name" label="Template Name">
                      <Input placeholder="Holiday Name" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="periodFrom" label="Annual Holiday Period">
                      <DatePicker
                       locale="en"
                       placeholder="From"
                       picker="month"
                       format="MMMM YYYY"
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="periodTo" label=" ">
                      <DatePicker
                         locale="en"
                         placeholder="To"
                         picker="month"
                         format="MMMM YYYY"
                         disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={4}>
                <Form.Item>
                  <Button
                    type="primary"
                    className={commonCssStyles.addBtnStyles}
                    htmlType="submit"
                    // onClick={handleAddHoliday}
                    // onClick={() => {
                    //   form.submit();
                    // }}
                  >
                    Save & Apply
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Card>
              <Row justify={"space-between"} style={{ marginTop: "20px" }}>
                <Col>
                  <h1 className={commonCssStyles.TableSecHeadingTxt}>
                    Holiday(s)
                  </h1>
                </Col>
                <Button
                  type="primary"
                  className={commonCssStyles.filterBtnStyles}
                  onClick={handleAddHoliday}
                >
                  Add Holiday
                </Button>
              </Row>

              <Row style={{ marginTop: "20px" }}>
                <Col xs={24}>
                  <Form.List name="holidayList">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "holidayName"]}
                              fieldKey={[field.fieldKey, "holidayName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the holiday name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Holiday Name"
                                ref={
                                  index === fields.length - 1
                                    ? newFieldRef
                                    : null
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, "holidayDate"]}
                              fieldKey={[field.fieldKey, "holidayDate"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select the holiday date",
                                },
                              ]}
                            >
                              <DatePicker
                                locale="en"
                                placeholder="Holiday Date"
                              />
                            </Form.Item>

                            <Button
                              type="text"
                              onClick={() => remove(field.name)}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Space>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    holidayDetails: state.holidayPolicy.holidayDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHolidayDetailsAction: (id) => {
      dispatch(getHolidayDetailsAction(id));
    },
    putHolidayPolicyAction: (val,callback) => {
      dispatch(putHolidayPolicyAction(val,callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditHolidayPolicy);
