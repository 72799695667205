import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    lateEntryRule: null,
    lateEntryDetail: null,

    laterEntryRuleAssign : null,
  };


  export const lateEntrySlice = createSlice({
    name: "lateentry",
    initialState,
    reducers: {
      getLateEntrySlice(state, action) {
        state.lateEntryRule = action.payload.result;
      },
      getLateEntryDetailSlice(state, action) {
        state.lateEntryDetail = action.payload.result;
      },

      getLateEntryAssignSlice(state, action) {
        state.laterEntryRuleAssign = action.payload.result;
      },
    },
  });

  export const {
    getLateEntrySlice,
    getLateEntryDetailSlice,
    getLateEntryAssignSlice
  } = lateEntrySlice.actions;
  export default lateEntrySlice.reducer;