export const LOGIN_URL = "login";
export const LOGOUT_URL = "logout";
export const REFRESH_URL = "refresh";
export const SEND_RESET_LINK_URL = "password/send_reset_link";
export const VERIFY_LINK_URL = "password/verify_link";
export const RESET_PASSWORD_URL = "password/reset_password";
export const PASSWORD_RESET_URL = "profile/password_reset";
export const PROFILE_URL = "profile";
export const PROFILE_UPDATE_URL = "profile/profile_update";



export const SETTINGS_URL = "settings";

export const SETTINGS_OVERVIEW_URL = "payroll_setting_overview";

export const SETTINGS_PAYROLL_URL = "payroll_setting";


export const SETTINGS_ATTENDANCE_ACCESS_URL = "user_payroll/attendance_access";


export const SETTINGS_COMPOFFLEAVE_URL = "user_payroll/compoff_leave";

export const SETTINGS_USERPAYROLL_URL = "user_payroll/weekly_holiday";


//Shifts 

export const SETTINGS_SHIFTS_URL = "shift";

export const SHIFT_ASSIGN_ACTION_URL = "user_payroll/shift";
//Shifts end 

//Create Late Entry Rule Action 
export const SETTINGS_LATE_ENTRY_RULE_URL = "late_entry_rule";

export const USER_PAYROLL_LATE_ENTRY_URL = "user_payroll/late_entry";

//Create Late Entry Rule Action End

//Create Break Rule 
export const SETTINGS_BREAKS_RULE_URL = "break_rule";

export const USER_PAYROLL_BREAKS_RULE_URL = "user_payroll/break";
//Create Break Rule End 


//create Early Exit Rule 
export const SETTINGS_EARLY_EXIT_RULE_URL = "early_exit_rule";

export const USER_PAYROLL_EARLY_EXIT_RULE_URL = "user_payroll/early_exit";

//create Early Exit Rule End


//create Overtime Rule 
export const SETTINGS_OVER_TIME_RULE_URL = "overtime_rule";

export const USER_PAYROLL_OVER_TIME_RULE_URL = "user_payroll/overtime";

export const USER_PAYROLL_EARLY_OVER_TIME_RULE_URL = "user_payroll/early_overtime";

//create Overtime Rule End


//Holiday Policy Rule
export const SETTINGS_HOLIDAY_POLICY_RULE_URL = "holiday_policy";

//Holiday Policy Rule End



//Leave Policy Rule
export const SETTINGS_LEAVE_POLICY_RULE_URL = "leave_policy";
//Leave Policy Rule End


//Department Rule
export const SETTINGS_DEPARTMENT_RULE_URL = "department";
//Department Rule


//Staff Url

// export const STAFF_FILTER_URL = "staff_filter?page=1"
export const STAFF_FILTER_URL = "staff_filter"

export const STAFF_URL = "staff"

export const STAFF_EARNING_DEDUCTION_Filter_URL = "staff_earning_deduction_filter"

export const STAFF_DOCUMENTS = "staff_documents"

export const STAFF_EARNING_DEDUCTION_URL = "staff_earning_deduction"

export const LEAVE_URL = "leave"

export const LEAVE_BALANCE_URL = "leave_balance"

export const LEAVE_REQUEST_URL = "leave_request"


export const STAFF_LEAVE_REQUEST_URL = "staff_leave_request"

export const STAFF_OVERTIME = "overtime"


//Staff Url End


//Attendance Url 
export const ATTENDANCE_FILTER_URL = "attendance_filter"

export const ATTENDANCE_URL = "attendance"
//Attendance Url  end 